import {AdFilterParameter} from './ad-filter-parameter';
import {AdFilterSource} from './ad-filter-source';
import {AdFilterSortingOption} from './ad-filter-sorting-option';

export class AdFilter {
  guid: string;
  accountBusinessCategoryGuid: string;
  name: string;
  type: string;
  description: string;
  fallbackFilterGuid: string;
  category: string;
  parameters: AdFilterParameter[];
  sources: AdFilterSource[];
  sortOption: AdFilterSortingOption;

  constructor() {
    this.guid = null;
    this.accountBusinessCategoryGuid = null;
    this.name = null;
    this.type = null;
    this.description = null;
    this.fallbackFilterGuid = null;
    this.category = null;
    this.parameters = [];
    this.sources = [];
    this.sortOption = new AdFilterSortingOption();
  }
}

