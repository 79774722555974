export class MappingRule {
  guid: string;
  accountId: number;
  adField: string;
  inventoryValue: string;
  replacementValue: string;

  constructor() {
    this.guid = null;
    this.accountId = null;
    this.adField = null;
    this.inventoryValue = null;
    this.replacementValue = null;
  }
}
