<auto-main-navigation [accountId]="accountId"></auto-main-navigation>
<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">MPOP Settings</h1>
  </p-header>
  <div class="main-content-wrapper p-grid">
    <div class="p-col-3">
      <p-panelMenu [model]="settingsSideNav"></p-panelMenu>
    </div>
    <p-panel class="p-col ad-types-wrapper">
      <ng-template pTemplate="header">
        <div class="panel-header-content">
          <h2>Media Management</h2>
          <button pButton class="p-button-success save-button" label="Save"
                  [disabled]="getSaveButtonDisabled()"
                  (click)="saveMediaManagementSettings()"></button>
        </div>
      </ng-template>
      <p-tabView>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span>
              Google <i class="pi pi-fw pi-google"></i>
            </span>
          </ng-template>
          <auto-google-settings
            *ngIf="mediaManagementSettings"
            [settings]="mediaManagementSettings"
            [account]="account"
            (dataEmitter)="onGoogleFormGroupInitialized($event)">
          </auto-google-settings>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span>
              Meta <i class="pi pi-fw pi-facebook"></i>
            </span>
          </ng-template>
          <auto-facebook-settings
            *ngIf="mediaManagementSettings"
            [settings]="mediaManagementSettings"
            [account]="account"
            (dataEmitter)="onFacebookFormGroupInitialized($event)">
          </auto-facebook-settings>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span>
              Microsoft <i class="pi pi-fw pi-microsoft"></i>
            </span>
          </ng-template>
          <auto-microsoft-settings
            *ngIf="mediaManagementSettings"
            [settings]="mediaManagementSettings"
            [account]="account"
            (dataEmitter)="onMicrosoftFormGroupInitialized($event)">
          </auto-microsoft-settings>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span>
              Media Tags <i class="pi pi-fw pi-tag"></i>
            </span>
          </ng-template>
          <auto-media-tags-settings
            [accountId]="accountId"
          ></auto-media-tags-settings>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span>
              General <i class="pi pi-fw pi-cog"></i>
            </span>
          </ng-template>
          <auto-general-settings
            *ngIf="mediaManagementSettings"
            [settings]="mediaManagementSettings"
            (dataEmitter)="onGeneralFormGroupInitialized($event)">
          </auto-general-settings>
        </p-tabPanel>
      </p-tabView>
    </p-panel>
  </div>
</p-panel>
<auto-footer></auto-footer>
