import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MediaManagementComponent} from "../media-management.component";
import {MediaManagementModel} from "../../../../../model/media-management.model";
import {AccountInterface} from "../../../../../model/account.interface";
import {MediaManagementEnumerator} from "../../../../../enumerators/media-management-enumerator";

@Component({
  selector: 'auto-google-settings',
  templateUrl: './google-settings.component.html',
  styleUrl: './google.settings.component.scss'
})
export class GoogleSettingsComponent extends MediaManagementComponent implements OnInit {

  @Output() dataEmitter = new EventEmitter<UntypedFormGroup>();
  @Input() settings: MediaManagementModel;
  @Input() account: AccountInterface;
  googleSettingsForm: UntypedFormGroup;
  finalUrlOptions = [
    {label: 'Leaderboard', value: 'leaderboard'},
    {label: 'VDP', value: 'vdp'}
  ];
  uploadGvaFeedLoading = false;
  populatingGoogleAnalyticsProperty = false;

  ngOnInit(): void {
    this.initializeGoogleSettingsFormGroup()
  }

  /**
   * Initializes Google Settings Form Group
   */
  initializeGoogleSettingsFormGroup() {
    this.googleSettingsForm =this.formBuilder.group({
      ncsAccountId: new UntypedFormControl(this.settings.ncsAccountId),
      googleStoreCode: new UntypedFormControl(this.settings.googleStoreCode),
      useDealerId: new UntypedFormControl(this.settings.useDealerId),
      advertiseAllGoogleVehicles: new UntypedFormControl(this.settings.advertiseAllGoogleVehicles),
      gbpNewFinalUrl: new UntypedFormControl(this.settings.gbpNewFinalUrl),
      gbpUsedFinalUrl: new UntypedFormControl(this.settings.gbpUsedFinalUrl),
      carsForSaleEnabled: new UntypedFormControl(this.settings.carsForSaleEnabled),
      gmbPostsEnabled: new UntypedFormControl(this.settings.gmbPostsEnabled),
      minimumImageRequirement: new UntypedFormControl(this.settings.minimumImageRequirement),
      googleAnalyticsMeasurementId: new UntypedFormControl(this.settings.googleAnalyticsMeasurementId),
      useGoogleAnalyticsTracking: new UntypedFormControl(this.settings.useGoogleAnalyticsTracking),
      googleVlaFeedCatalogUrl: new UntypedFormControl(
        this.replaceUrlPlaceholders(
          MediaManagementEnumerator.GoogleVlaFeedCatalogUrl,
          this.account.accountFolder
        )
      )
    });
    this.dataEmitter.emit(this.googleSettingsForm);
  }

  /**
   * Uploads Google Vehicle Ads Feed
   */
  public uploadGoogleVehicleAdsFeed() {
    this.uploadGvaFeedLoading = true;
    this.mediaManagementService.uploadGoogleVehicleAdsFeed(this.googleSettingsForm.controls.ncsAccountId.value).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Google Vehicle Ads Feed uploaded successfully'
        });
        this.uploadGvaFeedLoading = false;
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to upload Google Vehicle Ads Feed'
        });
        this.uploadGvaFeedLoading = false;
      }
    });
  }

  /**
   * Populates Google Analytics Property
   */
  public populateGoogleAnalyticsProperty() {
    this.populatingGoogleAnalyticsProperty = true;
    let accountId = this.googleSettingsForm.controls.ncsAccountId.value;
    let measurementId = this.googleSettingsForm.controls.googleAnalyticsMeasurementId.value;
    this.mediaManagementService.populateGoogleAnalyticsProperty(accountId, measurementId).subscribe({
      next: (response) => {
        let properties = response;
        if (!properties['Conversion Event']['Created'].length && !properties['Custom Dimensions']['Created'].length) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Skipping property population – all custom dimensions and conversions exist in the property'
          });
        } else if (properties['Conversion Event']['Failed'].length || properties['Custom Dimensions']['Failed'].length) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to populate Google Analytics Property'
          });
        } else {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Google Analytics Property populated successfully'
          });
        }
        this.populatingGoogleAnalyticsProperty = false;
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to populate Google Analytics Property'
        });
        this.populatingGoogleAnalyticsProperty = false;
      }
    });
  }
}
