import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SecurityModule} from './security/security.module';
import {HttpClientModule} from '@angular/common/http';


import {PanelModule} from 'primeng/panel';
import {MessageService} from 'primeng/api';
import {AdTypesComponent} from './components/views/settings/ad-types/ad-types.component';
import {NewAdTypesComponent} from './components/views/settings/new-ad-types/new-ad-types.component';

import {NavigationService} from './service/navigation.service';
import {UserService} from './service/user.service';
import { authInterceptorProviders } from './inteceptors/auth.inteceptor';
import {CookieService} from './service/cookie.service';
import { MainNavigationComponent } from './components/navigation/main-navigation/main-navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { DynamicFormItemComponent } from './components/forms/dynamic-form-item/dynamic-form-item.component';
import { DynamicFormComponent } from './components/forms/dynamic-form/dynamic-form.component';
import {FormControlService} from './service/form-control.service';
import {DynamicFormItemsService} from './service/dynamic-form-items.service';
import {ToolbarModule} from 'primeng/toolbar';
import {MenubarModule} from 'primeng/menubar';
import {MenuModule} from 'primeng/menu';
import {SidebarModule} from 'primeng/sidebar';
import {CardModule} from 'primeng/card';
import {PanelMenuModule} from 'primeng/panelmenu';
import {InputSwitchModule} from 'primeng/inputswitch';
import {SliderModule} from 'primeng/slider';
import {AccordionModule} from 'primeng/accordion';
import {TabViewModule} from 'primeng/tabview';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {TabMenuModule} from 'primeng/tabmenu';
import {CheckboxModule} from 'primeng/checkbox';
import { TrackUnsavedChangesDirective } from './directives/track-unsaved-changes.directive';
import {ToastModule} from 'primeng/toast';
import {AdTypesSettingsService} from './service/ad-types-settings.service';
import {BusinessCategoryConfigurationsService} from './service/business-category-configurations.service';
import {SentenceCase} from './pipes/sentence-case-pipe';
import { ManageFiltersComponent } from './components/views/ad-filters/manage-filters/manage-filters.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MultiSelectModule} from 'primeng/multiselect';
import { MappingRulesComponent } from './components/views/inventory/mapping-rules/mapping-rules.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TooltipModule} from 'primeng/tooltip';
import { PagesListComponent } from './components/views/pages/pages-list/pages-list.component';
import {SlideMenuModule} from 'primeng/slidemenu';
import {DialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {CommonModule} from '@angular/common';
import { GoogleAdsSettingsComponent } from './components/views/settings/google-ads-settings/google-ads-settings.component';
import { HasProperty} from './pipes/has-property-pipe';
import {CalendarModule} from 'primeng/calendar';
import { ManagePasswordComponent } from './components/views/password-settings/manage-password/manage-password.component';
import {PasswordModule} from 'primeng/password';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { SourceShortcodesComponent } from './components/views/settings/source-shortcodes/source-shortcodes.component';
import { AccountShortcodesComponent } from './components/views/settings/account-shortcodes/account-shortcodes.component';
import {PageFilterPipe} from './pipes/page-filter.pipe';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {TableModule} from 'primeng/table';
import { BudgetManagementComponent } from './components/views/settings/google-ads-settings/budget-management/budget-management.component';
import {ProgressBarModule} from 'primeng/progressbar';
import { AccountInventoryUrlsComponent } from './components/views/inventory/account-inventory-urls/account-inventory-urls.component';
import {DividerModule} from 'primeng/divider';
import { ForgotPasswordComponent } from './components/views/password-settings/forgot-password/forgot-password.component';
import { EventPageTemplateComponent } from './components/views/templates/event-page-template/event-page-template.component';
import { ResetPasswordComponent } from './components/views/password-settings/reset-password/reset-password.component';
import {PageConfigurationComponent} from './components/views/pages/page-configuration/page-configuration.component';
import { BudgetTemplateComponent } from './components/views/templates/budget-template/budget-template.component';
import { AccountDashboardComponent } from './components/views/dashboard/account-dashboard/account-dashboard.component';
import { PaginatorModule } from 'primeng/paginator';
import { AccountCardComponent } from './components/views/dashboard/account-dashboard/account-card/account-card.component';
import { GoogleAdsAutomationComponent } from './components/views/settings/google-ads-settings/google-ads-automation/google-ads-automation.component';
import { AutomationAccountSettingsComponent } from './components/views/settings/google-ads-settings/google-ads-automation/automation-account-settings/automation-account-settings.component';
import { MpopUsersComponent } from './components/views/mpop-side-nav/users/mpop-users/mpop-users.component';
import { UserFilterPipe } from './pipes/user-filter.pipe';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import { UserProfileComponent } from './components/views/mpop-side-nav/users/user-profile/user-profile.component';
import {OfferRulesComponent} from './components/views/settings/new-ad-types/offer-rules/offer-rules.component';
import {AutomationConfigurationSettingsComponent} from './components/views/settings/google-ads-settings/google-ads-automation/automation-configuration-settings/automation-configuration-settings.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {AccountVlpUrlsComponent} from './components/views/inventory/account-vlp-urls/account-vlp-urls.component';
import {MediaManagementComponent} from './components/views/settings/media-management/media-management.component';
import {GoogleSettingsComponent} from './components/views/settings/media-management/google/google-settings.component';
import {FacebookSettingsComponent} from './components/views/settings/media-management/facebook/facebook-settings.component';
import {MicrosoftSettingsComponent} from './components/views/settings/media-management/microsoft/microsoft-settings.component';
import {MediaTagsSettingsComponent} from './components/views/settings/media-management/media-tags/media-tags-settings.component';
import {GeneralSettingsComponent} from './components/views/settings/media-management/general/general-settings.component';

@NgModule({
    declarations: [
        AppComponent,
        AdTypesComponent,
        MainNavigationComponent,
        FooterComponent,
        DynamicFormItemComponent,
        DynamicFormComponent,
        TrackUnsavedChangesDirective,
        SentenceCase,
        ManageFiltersComponent,
        MappingRulesComponent,
        PagesListComponent,
        PageFilterPipe,
        GoogleAdsSettingsComponent,
        HasProperty,
        ManagePasswordComponent,
        SourceShortcodesComponent,
        AccountShortcodesComponent,
        BudgetManagementComponent,
        AccountInventoryUrlsComponent,
        AccountVlpUrlsComponent,
        ForgotPasswordComponent,
        EventPageTemplateComponent,
        ResetPasswordComponent,
        PageConfigurationComponent,
        BudgetTemplateComponent,
        AccountDashboardComponent,
        AccountCardComponent,
        GoogleAdsAutomationComponent,
        NewAdTypesComponent,
        AutomationAccountSettingsComponent,
        NewAdTypesComponent,
        MpopUsersComponent,
        UserFilterPipe,
        UserProfileComponent,
        OfferRulesComponent,
        AutomationConfigurationSettingsComponent,
        MediaManagementComponent,
        GoogleSettingsComponent,
        FacebookSettingsComponent,
        MicrosoftSettingsComponent,
        MediaTagsSettingsComponent,
        GeneralSettingsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        SecurityModule,
        PanelModule,
        HttpClientModule,
        ToolbarModule,
        MenubarModule,
        MenuModule,
        SidebarModule,
        CardModule,
        PanelMenuModule,
        SliderModule,
        AccordionModule,
        InputSwitchModule,
        TabViewModule,
        InputTextModule,
        ReactiveFormsModule,
        DropdownModule,
        ButtonModule,
        TabMenuModule,
        ToastModule,
        CheckboxModule,
        AutoCompleteModule,
        InputTextareaModule,
        MultiSelectModule,
        DragDropModule,
        TooltipModule,
        SlideMenuModule,
        DialogModule,
        ConfirmDialogModule,
        CommonModule,
        CardModule,
        CalendarModule,
        PasswordModule,
        ProgressSpinnerModule,
        ToggleButtonModule,
        NgIdleKeepaliveModule.forRoot(),
        TableModule,
        ProgressBarModule,
        DividerModule,
        PaginatorModule,
        VirtualScrollerModule,
        OverlayPanelModule
    ],
    providers: [
        Title,
        MessageService,
        NavigationService,
        UserService,
        CookieService,
        FormControlService,
        DynamicFormItemsService,
        authInterceptorProviders,
        AdTypesSettingsService,
        BusinessCategoryConfigurationsService,
        ConfirmationService,
    ],
    exports: [
        TrackUnsavedChangesDirective
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
