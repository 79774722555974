import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FeatureFlagGuard} from '../../../../../feature-flag.guard';
import {AdTypesSettingsService} from '../../../../../service/ad-types-settings.service';
import {AdRuleDataMapping} from '../../../../../model/ad-rule-data-mapping.model';
import {VehicleAdOfferRule} from '../../../../../model/vehicle-ad-offer-rule.model';
import {StringFormatterService} from '../../../../../service/utilities/string-formatter.service';

@Component({
  selector: 'auto-offer-rules',
  templateUrl: './offer-rules.component.html',
  styleUrls: ['./offer-rules.component.scss']
})
export class OfferRulesComponent implements OnInit {
  @Input() accountId: string;
  @Input() condition: string;
  @Output() formDirty = new EventEmitter<boolean>();
  @Output() deleteRules = new EventEmitter<unknown>();
  public offerRulesForm: FormGroup;
  featureEnabled: boolean;
  allSelected = false;
  adDataOptions: string[] = [];
  stringValues: string[] = [];
  numericValues: string[] = [];
  dollarValues: string[] = [];
  percentageValues: string[] = [];
  numberOperators = [
    'greater than',
    'less than',
    'greater than or equal to',
    'less than or equal to',
    'equal to',
    'not equal to'
  ];
  textOperators = [
    'is',
    'is not',
    'contains',
    'does not contain'
  ];
  actionOptions = [
    'Hide Purchase Offer',
    'Hide Savings Offer',
    'Hide Finance Offer',
    'Hide APR Offer',
    'Hide Lease Offer',
    'Hide Alternate Lease Offer'
  ];
  isRulesLoaded: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    protected featureFlagGuard: FeatureFlagGuard,
    private adTypesSettingsService: AdTypesSettingsService,
    private stringFormatterService: StringFormatterService,
    private messageService: MessageService
    ) {}

  /**
   * Init method
   */
  async ngOnInit() {
    this.featureEnabled = await this.featureFlagGuard.isFeatureOn('vehicleAdOfferRules');
    this.offerRulesForm = this.formBuilder.group({
      rules: this.formBuilder.array([]),
    });

    try {
      await this.getAdRuleDataMapping();
      this.initForm(this.accountId, this.condition);
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to load Vehicle Ad Offer Rules.',
        life: 5000
      });
    }
  }

  /**
   * Get method to initialize rules form
   */
  get rules(): FormArray {
    return this.offerRulesForm.get('rules') as FormArray;
  }

  /**
   * Method to initialize form and populate with existing rules
   */
  initForm(accountId: string, condition: string): void {
    this.getVehicleAdOfferRules(accountId, condition, true);
    this.offerRulesForm.valueChanges.subscribe(() => {
      if (this.offerRulesForm.dirty) {
        this.formDirty.emit(true);
      }
    });
  }

  /**
   * Method to get mapping rules from the backend and populate the dropdowns correctly
   */
  getAdRuleDataMapping(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.adTypesSettingsService.getAdRuleDataMapping().subscribe(response => {
        response.forEach((option: AdRuleDataMapping) => {
          this.adDataOptions.push(option.fieldDisplayName);
          if (option.dataType === 'text') {
            this.stringValues.push(option.fieldDisplayName);
          }
          if (option.dataType === 'number') {
            this.numericValues.push(option.fieldDisplayName);
            if (option.valueFormat === 'dollar') {
              this.dollarValues.push(option.fieldDisplayName);
            }
            if (option.valueFormat === 'percent') {
              this.percentageValues.push(option.fieldDisplayName);
            }
          }
        });
        resolve();
      }, (error) => {
        reject(error);
      });
    });
  }

  /**
   * Method to add rule to the form
   */
  addRule(): void {
    const rule = this.formBuilder.group({
      ruleId: [0],
      selected: [this.allSelected],
      adDataOption: ['', Validators.required],
      dataType: [''],
      operators: [[]],
      selectedOperator: ['', Validators.required],
      value: ['', Validators.required],
      action: ['', Validators.required]
    });
    this.handleOperators(rule);
    this.rules.push(rule);
  }

  /**
   * Method to handle depended fields based on adDataOption
   * @param rule
   */
  handleOperators(rule: AbstractControl<any>) {
    rule.get('adDataOption').valueChanges.subscribe(adDataOption => {
      let result = [];
      if (this.stringValues.includes(adDataOption)) {
        result = this.textOperators;
        rule.get('value').setValidators([Validators.required]);
      }
      if (this.numericValues.includes(adDataOption)) {
        result = this.setNumberOperators(adDataOption);
        rule.get('value').setValidators([Validators.required, Validators.pattern(/^\$?\d+(\.\d+)?%?$/)]);
      }
      rule.get('operators').setValue(result);
    });
  }

  /**
   * Method to remove rule from form
   * @param index
   */
  removeRule(index: number): void {
    const rule = this.rules.at(index);
    this.confirmationService.close();
    this.rules.removeAt(index);
    if (rule.value.ruleId !== 0) {
      this.adTypesSettingsService.deleteVehicleAdOfferRules(rule.value.ruleId).subscribe(
        () => {},
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to remove Vehicle Ad Offer Rule.',
            life: 5000
          });
        });
    }
    this.deleteRules.emit('');
  }

  /**
   * Method to select all rules
   */
  selectAll(): void {
    this.allSelected = !this.allSelected;
    this.rules.controls.forEach(control => {
      control.get('selected').setValue(this.allSelected);
    });
  }

  /**
   * Method to determine whether any rules are selected for the remove selected button
   */
  anySelected(): boolean {
    return this.rules.controls.some(control => control.get('selected').value);
  }

  /**
   * Method to open delete rule modal
   * @param index
   */
  openDeleteOfferRuleModal(index: number) {
    this.confirmationService.confirm({
      key: 'deleteOfferRule',
      defaultFocus: 'none',
      message: 'Are you sure you want to remove this offer rule?',
      header: `Delete Offer Rule`,
      accept: () => {
        this.removeRule(index);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  /**
   * Method to open delete selected offer rules modal
   */
  openDeleteSelectedOfferRulesModal() {
    this.confirmationService.confirm({
      key: 'deleteSelectedOfferRules',
      defaultFocus: 'none',
      message: 'Are you sure you want to remove the selected offer rules?',
      header: `Delete Offer Rules`,
      accept: () => {
        this.removeSelected();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  /**
   * Method to remove all selected rules
   */
  removeSelected(): void {
    let removedIds = [];
    this.confirmationService.close();
    this.rules.controls.forEach(control => {
      if (control.value.selected && control.value.ruleId !== 0) {
        removedIds.push(control.value.ruleId);
      }
    });
    this.rules.controls = this.rules.controls.filter(control => !control.value.selected);
    if (removedIds.length > 0) {
      this.adTypesSettingsService.deleteVehicleAdOfferRules(removedIds.join(',')).subscribe(
        () => {},
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to remove selected Vehicle Ad Offer Rules.',
            life: 5000
          });
        });
    }
    this.deleteRules.emit('');
  }

  /**
   * Method to save rules
   */
  saveRules() {
    let vehicleAdOfferRules = [];
    this.rules.controls.forEach(control => {
      vehicleAdOfferRules.push(this.createVehicleAdOfferRule(control));
    });
    this.adTypesSettingsService.updateVehicleAdOfferRules(vehicleAdOfferRules).subscribe(
      async () => {
        await this.getVehicleAdOfferRules(this.accountId, this.condition, false);
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save Vehicle Ad Offer Rules.',
          life: 5000
        });
      });
    this.offerRulesForm.markAsPristine();
  }

  /**
   * Method to create Vehicle Ad Offer Rules
   */
  createVehicleAdOfferRule(rule: AbstractControl<any, any>): VehicleAdOfferRule {
    let action = rule.value.action.replace('Hide ', '');
    action = this.stringFormatterService.toCamelCase(action);

    return new VehicleAdOfferRule(
      rule.value.ruleId,
      this.accountId,
      this.condition,
      rule.value.adDataOption,
      rule.value.selectedOperator,
      rule.value.value,
      'hideOffer',
      action);
  }

  /**
   * Helper method to get the proper operators
   * @param adDataOption
   */
  getOperators(adDataOption: string): string[] {
    let result = [];
    if (this.stringValues.includes(adDataOption)) {
      result = this.textOperators;
    }
    if (this.numericValues.includes(adDataOption)) {
      result = this.setNumberOperators(adDataOption);
    }

    return result;
  }

  /**
   * Get the data type for the operator
   * @param adDataOption
   */
  getOperatorDataType(adDataOption: string): string {
    let result = '';
    if (this.dollarValues.includes(adDataOption)) {
      result = 'dollar';
    }
    if (this.percentageValues.includes(adDataOption)) {
      result = 'percent';
    }

    return result;
  }

  /**
   * TODO: Method to toggle rules active/inactive
   */
  toggleRulesActive() {
    console.log('Toggled Rules Active (not really though)');
  }

  /**
   * Method to get vehicle ad offer rules for account + condition
   * @param accountId
   * @param condition
   * @param onInit
   */
  getVehicleAdOfferRules(accountId: string, condition: string, onInit: boolean) {
    this.adTypesSettingsService.getVehicleAdOfferRules(accountId, condition)
      .subscribe(response => {
        if (!onInit) {
          this.offerRulesForm = this.formBuilder.group({
            rules: this.formBuilder.array([]),
          });
        }
        response.forEach((rule: VehicleAdOfferRule) => {
          let result = this.getOperators(rule.adDataField);
          const dataType = this.getOperatorDataType(rule.adDataField);
          const action = this.formatActionValue(rule.actionValue);
          const newRule = this.formBuilder.group({
            ruleId: [rule.ruleId],
            selected: [''],
            adDataOption: [rule.adDataField, Validators.required],
            dataType: [dataType],
            operators: [result],
            selectedOperator: [rule.operator, Validators.required],
            value: [rule.value, Validators.required],
            action: [action]
          });
          this.handleOperators(newRule);
          this.rules.push(newRule);
        });
        this.isRulesLoaded = true;
      }, () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load Vehicle Ad Offer Rules.',
          life: 5000
        });
      });
  }

  /**
   * Method to update the data type based on the adDataOption
   * @param rule
   */
  updateDataType(rule: AbstractControl): void {
    const adDataOption = rule.get('adDataOption').value;
    const dataType = this.getOperatorDataType(adDataOption);
    rule.get('dataType').setValue(dataType);
  }

  /**
   * Method to format the action value
   * @param actionValue
   */
  formatActionValue(actionValue: string): string {
    let action = 'Hide ' + this.stringFormatterService.camelCaseToTitleWithAcronyms(actionValue);
    if (action.includes('Apr')) {
      action = action.replace(/Apr/g, 'APR');
    }

    return action;
  }

  /**
   * Method to set number operators based on adDataOption
   * @param adDataOption
   */
  setNumberOperators(adDataOption: string): string[]{
    let result = this.numberOperators;
    if (adDataOption === 'Certified') {
      result = ['equal to', 'not equal to'];
    }

    return result;
  }
}
