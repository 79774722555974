import {Component, OnInit} from '@angular/core';
import {LoginModel} from '../../model/login_model';
import {SecurityService} from '../../service/security.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {environment} from '../../../environments/environment';
import {Title} from '@angular/platform-browser';
import {CookieService} from "../../service/cookie.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: UntypedFormGroup;
  public submitted = false;
  public forgotLink = `//${environment.launchcontrolAdminDomain}/web/forgot-password`;
  public copyrightYear: string;
  public referrer: string;

  constructor(
    private titleService: Title,
    private securityService: SecurityService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private cookieService: CookieService
  ) {
    this.copyrightYear = `${new Date().getFullYear()}`;
  }

  ngOnInit() {
    this.referrer = this.getReferrerPath();
    this.cookieService.clearSessionCookies();
    this.titleService.setTitle('Login');

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  public async loginUser() {
    this.messageService.clear();
    this.submitted = true;
    const loginModel: LoginModel = Object.assign({}, this.loginForm.value);
    const result = await this.securityService.doUserLogin(loginModel);

    let token = '';
    if (result.hasOwnProperty('token') && typeof result['token'] === 'string') {
      token = result['token'];
    }

    if (token) {
      let href = `//${environment.launchcontrolAdminDomain}/token_authenticate.php?username=${loginModel.username}&token=${token}`;
      if (this.referrer !== '') {
        href += `&referrer=${encodeURIComponent(this.referrer)}`;
      }
      window.location.href = href;
    } else {
      this.submitted = false;
      this.loginForm.reset();
      this.messageService.add({
        severity: 'error',
        summary: 'Login Failed: Access Denied'
      });
    }
  }

  /**
   * Method to get referrer path from cookies (auto-web) or url path (legacy)
   */
  public getReferrerPath(): string
  {
    let referrer = '';
    if (this.cookieService.getCookie('referrer') !== undefined){
      referrer = this.cookieService.getCookie('referrer');
    }
    let urlParam = window.location.search + window.location.hash;
    if (urlParam !== '') {
      urlParam = urlParam.replace('?referrer=', '');
      referrer = urlParam.replace(/%2F/g, '/');
    }

    return referrer;
  }
}
