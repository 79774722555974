export class AccountFeatureModel {
  accountId: number;
  featureName: string;
  enabled: boolean;

  constructor(accountId, featureName, enabled) {
    this.accountId = accountId;
    this.featureName = featureName;
    this.enabled = enabled;
  }
}
