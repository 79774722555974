import {CampaignBudgetModel} from './campaign-budget.model';

export class BudgetManagement{
  campaignBudgets: CampaignBudgetModel[];
  salesforceBudgetName: string;
  status: string;
  budgetType: string;
  totalBudget: number;
  adjustedTotalBudget: number;
  startDate: string;
  endDate: string;
  specificProduct: string;
  accountId: number;
  salesforceBudgetId: number;
  isCurrent: boolean;
  lastUpdatedDate: string;
  lastUpdatedUser: string;

  constructor(campaignBudgets, salesforceBudgetName, status, budgetType, totalBudget, adjustedTotalBudget,
              startDate, endDate, specificProduct, accountId, salesforceBudgetId, isCurrent) {
    this.campaignBudgets = campaignBudgets;
    this.salesforceBudgetName = salesforceBudgetName;
    this.status = status;
    this.budgetType = budgetType;
    this.totalBudget = totalBudget;
    this.adjustedTotalBudget = adjustedTotalBudget;
    this.startDate = startDate;
    this.endDate = endDate;
    this.specificProduct = specificProduct;
    this.accountId = accountId;
    this.salesforceBudgetId = salesforceBudgetId;
    this.isCurrent = isCurrent;
  }
}
