<auto-main-navigation [accountId]=""></auto-main-navigation>
<p-panel style="background-color: #FAFAFF">
  <div class="main-content-wrapper">
    <p-card class="p-col"
            [style]="cardStyle">
      <ng-template pTemplate="header" class="card-header">
        <img src="https://mpop-prod-primary.s3.amazonaws.com/DT/dt_logoGRAY.png"
             alt="Dealer Teamwork Logo"
             class="top-bar-logo"/>
        <div class="card-header-wrapper">
          <label class="card-header-text">Manage Password</label>
        </div>
      </ng-template>
      <form [formGroup]="passwordForm">
        <div class="tab-panel-input-wrapper">
          <label for="current-password" class="field-input-label input-label">Current Password:</label>
          <p-password
            id="current-password"
            class="text-input current-password-input"
            [toggleMask]="true"
            [feedback]="false"
            formControlName="currentPassword"
            styleClass="p-password p-component p-inputwrapper p-input-icon-right">
          </p-password>
        </div>
        <div class="tab-panel-input-wrapper">
          <label for="new-password" class="field-input-label input-label">New Password:</label>
          <p-password
            id="new-password"
            class="text-input new-password-input"
            (keyup)="checkPasswordCriteria()"
            [strongRegex]="strongPassword"
            [mediumRegex]="mediumPassword"
            [toggleMask]="true"
            formControlName="newPassword"
            styleClass="p-password p-component p-inputwrapper p-input-icon-right">
            <ng-template pTemplate="footer">
              <p-divider></p-divider>
              <p style="text-align: left">Must Include:</p>
              <ul class="password-template">
                <li *ngFor="let criteriaKey of Object.keys(passwordCriteria)">
                  {{passwordCriteria[criteriaKey].name}}
                  <i *ngIf="passwordCriteria[criteriaKey].met" class="pi pi-check green-checkmark profile-icon-style"></i>
                  <i *ngIf="!passwordCriteria[criteriaKey].met" class="pi pi-times red-cross profile-icon-style"></i>
                </li>
              </ul>
            </ng-template>
          </p-password>
        </div>
        <div class="tab-panel-input-wrapper confirm-password-input">
          <label for="confirm-password" class="field-input-label input-label">Confirm Password:</label>
          <p-password
            id="confirm-password"
            class="text-input"
            [toggleMask]="true"
            [feedback]="false"
            [strongRegex]="strongPassword"
            [mediumRegex]="mediumPassword"
            formControlName="confirmPassword"
            styleClass="p-password p-component p-inputwrapper p-input-icon-right">
          </p-password>
        </div>
        <div class="password-input-wrapper">
        <span *ngIf="!passwordForm.get('confirmPassword').pristine && passwordForm.get('confirmPassword').hasError('matching')"
              class="p-invalid"> Password must match.</span>
        </div>
        <div *ngIf="!submitted">
          <button pButton
                  id="resetPasswordButton"
                  class="p-button-success reset-button"
                  [disabled]="!passwordForm.valid"
                  label="Set Password"
                  (click)="handleSetPassword()">
          </button>
        </div>
        <div class="loading-indicator" *ngIf="submitted">
          <p-progressSpinner></p-progressSpinner>
        </div>
      </form>
    </p-card>
  </div>
</p-panel>
<auto-footer></auto-footer>
