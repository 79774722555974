<p-panel>
  <p-header class="header-row">
    <div class="filter-row">
      <button class="close-profile-button" pButton icon="pi pi-times" (click)="closeUserProfile()"></button>
      <div>
        <h2>{{user.name}}</h2>
        <div><em>{{user.username}}</em></div>
      </div>

    </div>
    <div class="filter-row">
      <a href="{{ documentProtocol }}//{{ documentHostname }}/users/edit-user/{{user.id}}">
        <button pButton
                *ngIf="userCanEdit()"
                class="p-button-success edit-user-button"
                icon="pi pi-pencil"
                label="Edit User">
        </button>
      </a>
      <a href="{{ documentProtocol }}//{{ documentHostname }}/users/edit-user-account-access/{{user.id}}">
        <button pButton
                *ngIf="userCanEdit()"
                class="p-button-success user-account-access-button"
                icon="pi pi-pencil"
                label="Edit User Account Access">
        </button>
      </a>
    </div>
  </p-header>
  <div class="modal-button-wrapper">
    <p-confirmDialog
      icon="ui-icon-blank"
      acceptLabel="Confirm"
      rejectLabel="Cancel"
      key="sendResetPasswordLink"
      acceptButtonStyleClass="p-button-success modal-button"
      rejectButtonStyleClass="reject-button">
    </p-confirmDialog>
  </div>
  <button pButton class="reset-button" label="Reset Password" (click)="openResetPasswordLinkModal()"></button>
  <div class="account-log-component">
    <div>
      <h3>Account Information</h3>
      <div class="information-label">
        <label><b>Active: </b>
          <i *ngIf="user.active" class="pi pi-check green-checkmark profile-icon-style"></i>
          <i *ngIf="!user.active" class="pi pi-times red-cross profile-icon-style"></i>
        </label>
      </div>
      <div class="information-label">
        <label><b>User Type: </b> {{user.userType}}</label>
      </div>
      <div class="information-label" *ngIf="user.dealerAccountGroup !== ''">
        <label><b>Dealer Group: </b> {{user.dealerAccountGroup.split(':')[0]}}</label>
      </div>
      <div class="information-label">
        <label><b>Role: </b> {{user.role}}</label>
      </div>
      <div class="information-label">
        <label><b>Multi-Factor Authentication: </b>
          <i *ngIf="user.tfaCodeRequired" class="pi pi-check green-checkmark profile-icon-style"></i>
          <i *ngIf="!user.tfaCodeRequired" class="pi pi-times red-cross profile-icon-style"></i>
        </label>
      </div>
      <div class="information-label">
        <label><b>Receive Text Messages: </b>
          <i *ngIf="user.receiveTexts" class="pi pi-check green-checkmark profile-icon-style"></i>
          <i *ngIf="!user.receiveTexts" class="pi pi-times red-cross profile-icon-style"></i>
        </label>
      </div>
      <div class="information-label">
        <label><b>Receive MPOP Alerts via Email: </b>
          <i *ngIf="user.receiveEmails" class="pi pi-check green-checkmark profile-icon-style"></i>
          <i *ngIf="!user.receiveEmails" class="pi pi-times red-cross profile-icon-style"></i>
        </label>
      </div>
    </div>
    <div class="login-log">
      <h3>Recent Logins</h3>
      <div *ngIf="loginLogsLoading">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div *ngIf="!loginLogsLoading">
        <div *ngIf="hasRecentLogins(); else noRecentLogins">
          <div *ngFor="let login of user.recentLogins" class="login-log-field">
            <label>{{login.created}}</label>
          </div>
        </div>
        <ng-template #noRecentLogins>
          <div class="login-log-field">
            <label>No recent logins</label>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div>
    <h3>Contact Information</h3>
    <div class="information-label">
      <label><b>Email: </b> {{user.email}}</label>
    </div>
    <div class="information-label">
      <label><b>Phone: </b> {{user.phone}}</label>
    </div>
  </div>
  <div class="created-label">
    <label><em>Created: </em> {{user.created}}</label>
  </div>
</p-panel>
