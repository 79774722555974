import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {AccountService} from '../../../../service/account.service';
import {GoogleAdsService} from '../../../../service/google-ads.service';
import {MenuItem, MessageService} from 'primeng/api';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AccountBusinessCategoryService} from '../../../../service/account-business-category.service';
import {GoogleAdsSettingsModel} from '../../../../model/google-ads-settings.model';
import {AccountCampaignStrategyModel} from '../../../../model/account-campaign-strategy.model';
import {StringFormatterService} from '../../../../service/utilities/string-formatter.service';
import {UserService} from '../../../../service/user.service';
import {AccountInterface} from '../../../../model/account.interface';
import {EventEmitterService} from '../../../../service/event-emitter.service';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'auto-google-ads-settings',
  templateUrl: './google-ads-settings.component.html',
  styleUrls: ['./google-ads-settings.component.scss']
})
export class GoogleAdsSettingsComponent implements OnInit {
  accountId: number;
  account: AccountInterface;
  userId: number;
  adwordsAccountId: string;
  accountShortName: string;
  accountName: string;
  showAccountConfigurationToolTip: boolean;
  showCampaignManagementToolTip: boolean;
  newAccountBusinessCategoryGuid: string;
  usedAccountBusinessCategoryGuid: string;
  accountConfigurationFormGroup: UntypedFormGroup | null = null;
  campaignManagementFormGroup: UntypedFormGroup | null = null;
  accountCampaignStrategies = [];
  accountCampaignStrategyTemplates = {
    New : {
      id: null,
      name: 'New Campaigns',
      shortName: 'New',
      targetCampaign: null,
      campaignTemplate: '[dt_model]',
      additionalCampaigns: null,
      finalUrlPathTemplate: null,
      enabled: false,
      lastUpdated: {
        date: ''
      },
      tooltipText: 'Enabling this campaign strategy opts this account into automated updates of ad customizers for New Campaigns.',
      placeholderText: null,
      showFinalTemplateUrl: false
    },
    UsedModelYear: {
      id: null,
      name: 'Used Model Year Campaigns',
      shortName: 'UsedModelYear',
      targetCampaign: 'dt_Used3',
      campaignTemplate: null,
      additionalCampaigns: null,
      finalUrlPathTemplate: null,
      enabled: false,
      lastUpdated: {
        date: ''
      },
      tooltipText: 'Enabling this campaign strategy opts this account into automated updates of ad customizers for the Used Model Year Campaign.'
        + ' Ad groups will be created per used year/make/model combination within the account\'s ads.',
      placeholderText: 'example: /search-results?year=[YEAR]&make=[MAKE]&model=[MODEL]',
      showFinalTemplateUrl: false
    },
    UsedModel: {
      id: null,
      name: 'Used Model Campaigns',
      shortName: 'UsedModel',
      targetCampaign: 'dt_Used4',
      campaignTemplate: null,
      additionalCampaigns: null,
      finalUrlPathTemplate: null,
      enabled: false,
      lastUpdated: {
        date: ''
      },
      tooltipText: 'Enabling this campaign strategy opts this account into automated updates of ad customizers for the Used Model Campaign.'
        + ' Ad groups will be created per used make/model combination within the account\'s ads.',
      placeholderText: 'example: /search-results?make=[MAKE]&model=[MODEL]',
      showFinalTemplateUrl: false
    }
  };
  expandIcon = 'pi pi-chevron-down';
  collapseIcon = 'pi pi-chevron-right';
  exportSubMenu: MenuItem[];
  subMenuModel: any;
  requiresPmaxBrandedUpdate: boolean = false;
  requiresPmaxNewSalesUpdate: boolean = false;
  initialFormValues: {
    accountShortName: any;
    slogan1: any;
    slogan2: any;
    slogan3: any;
    customDescription1: any;
    customDescription2: any;
    customDescription3: any;
    salesEvent: any;
    saleEndDate: any;
  } = null;
  campaignTypes: any = {
    'Performance Max - Branded': 1,
    'Performance Max - New Sales': 2
  };

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private googleAdsService: GoogleAdsService,
    private accountService: AccountService,
    private accountBusinessCategoryService: AccountBusinessCategoryService,
    private messageService: MessageService,
    private stringFormatterService: StringFormatterService,
    private userService: UserService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Google Ads Settings');
    this.buildAccountConfigurationFormGroup();
    this.showAccountConfigurationToolTip = false;
    this.userService.getUserPromise().then(result => {
      this.userId = parseInt(result.userId);
    });
    this.subMenuModel = this.buildSubMenu();
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      if (this.accountId) {
        this.accountService.getAccount(this.accountId).subscribe(
          (response) => {
            this.account = response;
          }
        );
        this.googleAdsService.getGoogleAdsSettings(this.accountId).subscribe(
          (googleAdsSettings) => {
            this.accountConfigurationFormGroup.controls.adwordsActive.setValue(googleAdsSettings.adwordsActive);
            this.accountConfigurationFormGroup.controls.adwordsAccountId.setValue(googleAdsSettings.adwordsAccountId);
            this.accountConfigurationFormGroup.controls.accountShortName.setValue(googleAdsSettings.accountShortName);
            this.accountShortName = googleAdsSettings.accountShortName;
            this.accountName = googleAdsSettings.accountName;
            this.adwordsAccountId = googleAdsSettings.adwordsAccountId;
            this.accountConfigurationFormGroup.controls.slogan1.setValue(googleAdsSettings.slogan1);
            this.accountConfigurationFormGroup.controls.slogan2.setValue(googleAdsSettings.slogan2);
            this.accountConfigurationFormGroup.controls.slogan3.setValue(googleAdsSettings.slogan3);
            this.accountConfigurationFormGroup.controls.customHeadline1.setValue(googleAdsSettings.customHeadline1);
            this.accountConfigurationFormGroup.controls.customHeadline2.setValue(googleAdsSettings.customHeadline2);
            this.accountConfigurationFormGroup.controls.customHeadline3.setValue(googleAdsSettings.customHeadline3);
            this.accountConfigurationFormGroup.controls.customDescription1.setValue(googleAdsSettings.customDescription1);
            this.accountConfigurationFormGroup.controls.customDescription2.setValue(googleAdsSettings.customDescription2);
            this.accountConfigurationFormGroup.controls.customDescription3.setValue(googleAdsSettings.customDescription3);
            this.accountConfigurationFormGroup.controls.salesEvent.setValue(googleAdsSettings.salesEvent);
            this.accountConfigurationFormGroup.controls.saleEndDate.setValue(this.getSaleEndDateValue(googleAdsSettings));
            this.handleActiveSwitchDisabledStatus();
            this.setInitialFormValues(googleAdsSettings);
          }
        );
        this.accountBusinessCategoryService.getAccountBusinessCategories(this.accountId).subscribe(
          (accountBusinessCategories) => {
            if (accountBusinessCategories.length !== 0) {
              const newCategory = accountBusinessCategories.filter(accountBusinessCategory => accountBusinessCategory.name === 'New')[0];
              const usedCategory = accountBusinessCategories.filter(accountBusinessCategory => accountBusinessCategory.name === 'Used')[0];
              this.newAccountBusinessCategoryGuid = newCategory.guid;
              this.usedAccountBusinessCategoryGuid = usedCategory.guid;
              this.getAccountCampaignStrategies(this.accountId.toString());
            }
          }
        );
      }
    });
    this.accountSettingsChangeSubscription();
  }

  /**
   * Sets the initial form values for the account configuration form
   * @param googleAdsSettings
   * @param isReset
   */
  setInitialFormValues(googleAdsSettings: GoogleAdsSettingsModel, isReset: boolean = false): void {
    this.initialFormValues = {
      accountShortName: googleAdsSettings.accountShortName,
      slogan1: googleAdsSettings.slogan1,
      slogan2: googleAdsSettings.slogan2,
      slogan3: googleAdsSettings.slogan3,
      customDescription1: googleAdsSettings.customDescription1,
      customDescription2: googleAdsSettings.customDescription2,
      customDescription3: googleAdsSettings.customDescription3,
      salesEvent: googleAdsSettings.salesEvent,
      saleEndDate: googleAdsSettings.saleEndDate,
    };

    if (!isReset) {
      this.initialFormValues.saleEndDate = this.getSaleEndDateValue(googleAdsSettings);
    }
  }

  /**
   * Gets the account campaign strategies and initializes the campaign strategies form
   *
   * @param accountId
   */
  getAccountCampaignStrategies(accountId: string): void {
    this.googleAdsService.getAccountCampaignStrategies(accountId).subscribe(
      (campaignStrategies) => {
        const formArray = [];
        Object.keys(this.accountCampaignStrategyTemplates).forEach((strategyKey) => {
          const campaignStrategy = campaignStrategies.find(strategy => strategy.shortName === strategyKey);
          const strategyForm = this.buildCampaignStrategyFormGroup(this.accountCampaignStrategyTemplates[strategyKey], campaignStrategy);
          if (campaignStrategy) {
            // Merge the template with the modified campaign strategy
            this.accountCampaignStrategyTemplates[strategyKey] = {
              ...this.accountCampaignStrategyTemplates[strategyKey],
              ...campaignStrategy
            };
          }
          formArray.push(strategyForm);
          this.accountCampaignStrategies.push(this.accountCampaignStrategyTemplates[strategyKey]);
        });
        this.buildCampaignManagementFormGroup(formArray);
        this.handleAccountCampaignSwitchDisabledStatus();
      }
    );
  }

  /**
   * Handle the disabled status of the account configuration active switch
   */
  handleActiveSwitchDisabledStatus(): void {
    if (this.accountConfigurationFormGroup.controls.adwordsAccountId.value.trim() === '') {
      this.accountConfigurationFormGroup.controls.adwordsActive.disable();
      this.accountConfigurationFormGroup.controls.adwordsActive.setValue(false);
      this.showAccountConfigurationToolTip = true;
    } else {
      this.accountConfigurationFormGroup.controls.adwordsActive.enable();
      this.showAccountConfigurationToolTip = false;
    }
  }

  /**
   * Handle the disabled status of the campaign strategy enabled toggle
   */
  handleAccountCampaignSwitchDisabledStatus(): void {
    if (this.accountConfigurationFormGroup.controls.adwordsAccountId.pristine &&
        this.accountConfigurationFormGroup.controls.adwordsAccountId.value.trim() === '') {
      this.campaignManagementFormGroup.controls['strategies']['controls'].forEach((control) => {
        control.controls.campaignStrategyEnabled.disable();
      });
      this.showCampaignManagementToolTip = true;
    } else {
      this.campaignManagementFormGroup.controls['strategies']['controls'].forEach((control) => {
        control.controls.campaignStrategyEnabled.enable();
      });
      this.showCampaignManagementToolTip = false;
    }
  }

  /**
   * Account Configuration save form
   */
  handleAccountConfigurationSave(): void {
    const googleAdsSettings = {
      accountId: this.accountId,
      adwordsActive: this.accountConfigurationFormGroup.controls.adwordsActive.value,
      adwordsAccountId: this.adwordsAccountId = this.accountConfigurationFormGroup.controls.adwordsAccountId.value,
      accountShortName: this.accountConfigurationFormGroup.controls.accountShortName.value,
      slogan1: this.accountConfigurationFormGroup.controls.slogan1.value,
      slogan2: this.accountConfigurationFormGroup.controls.slogan2.value,
      slogan3: this.accountConfigurationFormGroup.controls.slogan3.value,
      customHeadline1: this.accountConfigurationFormGroup.controls.customHeadline1.value,
      customHeadline2: this.accountConfigurationFormGroup.controls.customHeadline2.value,
      customHeadline3: this.accountConfigurationFormGroup.controls.customHeadline3.value,
      customDescription1: this.accountConfigurationFormGroup.controls.customDescription1.value,
      customDescription2: this.accountConfigurationFormGroup.controls.customDescription2.value,
      customDescription3: this.accountConfigurationFormGroup.controls.customDescription3.value,
      salesEvent: this.accountConfigurationFormGroup.controls.salesEvent.value,
      saleEndDate: this.accountConfigurationFormGroup.controls.saleEndDate.value
    };

    for (const field in googleAdsSettings) {
      googleAdsSettings[field] = this.stringFormatterService.trimWhiteSpace(googleAdsSettings[field]);
    }

    this.googleAdsService.updateGoogleAdsSettings(googleAdsSettings)
      .subscribe(
        () => {
          this.accountConfigurationFormGroup.markAsPristine();
          this.handleAccountCampaignSwitchDisabledStatus();
          this.eventEmitterService.emitEvent('getTextAssetValidation');
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
            life: 5000
          });
        });
    this.queuePmaxUpdate();
  }

  /**
   * Queues the Performance Max update
   */
  queuePmaxUpdate(): void {
    this.googleAdsService.getAutomationConfigurationSettings(this.accountId).subscribe(
      (response) => {
        let campaignTypeIds = this.getPmaxCampaignTypeIds(response);
        let observables = [];

        campaignTypeIds.forEach((campaignTypeId) => {
          observables.push(this.googleAdsService.queuePmaxUpdate(this.accountId, campaignTypeId));
        });

        forkJoin(observables).subscribe({
          next: (results: any[]) => {
            results.forEach((result, index) => {
              if (result.message === 'The Performance Max Campaigns update process is in progress.') {
                this.messageService.add({
                  severity: 'info',
                  summary: 'In Progress',
                  detail: result.message,
                  life: 5000
                });
              } else {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Process Started',
                  detail: 'Updates to Performance Max campaigns queued to process.',
                  life: 5000
                });
              }
            });
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error.message,
              life: 5000
            });
          }
        });
        this.setInitialFormValues(this.accountConfigurationFormGroup.value, true);
        this.requiresPmaxBrandedUpdate = false;
        this.requiresPmaxNewSalesUpdate = false;
      });
  }

  /**
   * Campaign Management save form
   */
  handleCampaignManagementSave(campaignStrategy): void {
    if (campaignStrategy.id) {
      this.updateAccountCampaignStrategy(campaignStrategy);
    } else {
      this.initializeAccountCampaignStrategy(campaignStrategy);
    }
  }

  /**
   * Handle Create Campaigns process
   */
  handleCreateCampaign(campaignStrategy): void {
    this.createCampaignRequest(campaignStrategy);
  }

  /**
   * Handle populating the Google Ads Account ID field upon Google Ads Account creation
   *
   */
  handleAdwordsAccountIdCreation(): void {
    this.googleAdsService.getGoogleAdsSettings(this.accountId).subscribe(
      (googleAdsSettings) => {
        this.accountConfigurationFormGroup.controls.adwordsAccountId.setValue(googleAdsSettings.adwordsAccountId);
        this.adwordsAccountId = googleAdsSettings.adwordsAccountId;
        this.handleActiveSwitchDisabledStatus();
      }
    );
  }

  /**
   * Account Configuration save form
   */
  updateAccountCampaignStrategy(campaignStrategy): void {
    const index = this.accountCampaignStrategies.findIndex(strategy => strategy.name === campaignStrategy.name);
    const strategyForm = this.campaignManagementFormGroup.controls['strategies']['controls'][index];

    const campaignStrategySettings = {
      id: campaignStrategy.id,
      name: campaignStrategy.name,
      shortName: campaignStrategy.shortName,
      targetCampaign: strategyForm.controls.targetCampaign.value,
      campaignTemplate: strategyForm.controls.campaignTemplate.value,
      additionalCampaigns: strategyForm.controls.additionalCampaigns.value,
      finalUrlPathTemplate: strategyForm.controls.finalUrlPathTemplate.value,
      enabled: strategyForm.controls.campaignStrategyEnabled.value
    };

    for (const field in campaignStrategySettings) {
      campaignStrategySettings[field] = this.stringFormatterService.trimWhiteSpace(campaignStrategySettings[field]);
    }

    this.googleAdsService.updateAccountCampaignStrategy(campaignStrategySettings)
      .subscribe(
        () => {
          strategyForm.markAsPristine();
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Campaign strategy failed to update.',
            life: 5000
          });
        });
  }

  /**
   * Account Configuration save form
   */
  initializeAccountCampaignStrategy(campaignStrategy): void {
    const index = this.accountCampaignStrategies.findIndex(strategy => strategy.name === campaignStrategy.name);
    const strategyForm = this.campaignManagementFormGroup.controls['strategies']['controls'][index];
    let businessCategoryGuid = this.newAccountBusinessCategoryGuid;
    if (campaignStrategy.name.includes('Used')) {
      businessCategoryGuid = this.usedAccountBusinessCategoryGuid;
    }
    this.googleAdsService.initializeAccountCampaignStrategy({
      name: campaignStrategy.name,
      shortName: campaignStrategy.shortName,
      accountBusinessCategoryGuid: businessCategoryGuid,
      targetCampaign: strategyForm.controls.targetCampaign.value,
      campaignTemplate: strategyForm.controls.campaignTemplate.value,
      additionalCampaigns: strategyForm.controls.additionalCampaigns.value,
      enabled: strategyForm.controls.campaignStrategyEnabled.value
    })
      .subscribe(
        (response) => {
          strategyForm.markAsPristine();
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
          this.accountCampaignStrategies[index].id = response.strategyId;
          this.updateCampaignStrategyFormValues(this.accountId.toString(), campaignStrategy.shortName);
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Campaign strategy failed to initialize.',
            life: 5000
          });
        });
  }

  /**
   * Builds form group for the account configuration tab form
   */
  buildAccountConfigurationFormGroup(): void
  {
    const pattern = '^((?!\\s\\s)(?!([a-zA-Z][.,;:?]\\S))(?!([0-9][.,;:?][a-zA-Z]))(?!([a-zA-Z] [.,;:?]))(?!(\\w . . \\w))(?!([!*+])).)*$';
    this.accountConfigurationFormGroup = new UntypedFormGroup({
      adwordsActive: new UntypedFormControl(false),
      adwordsAccountId: new UntypedFormControl(''),
      accountShortName: new UntypedFormControl(''),
      slogan1: new UntypedFormControl('', [Validators.maxLength(90), Validators.pattern(pattern)]),
      slogan2: new UntypedFormControl('', [Validators.maxLength(90), Validators.pattern(pattern)]),
      slogan3: new UntypedFormControl('', [Validators.maxLength(90), Validators.pattern(pattern)]),
      customHeadline1: new UntypedFormControl('', [Validators.maxLength(30), Validators.pattern(pattern)]),
      customHeadline2: new UntypedFormControl('', [Validators.maxLength(30), Validators.pattern(pattern)]),
      customHeadline3: new UntypedFormControl('', [Validators.maxLength(30), Validators.pattern(pattern)]),
      customDescription1: new UntypedFormControl('', [Validators.maxLength(90), Validators.pattern(pattern)]),
      customDescription2: new UntypedFormControl('', [Validators.maxLength(90), Validators.pattern(pattern)]),
      customDescription3: new UntypedFormControl('', [Validators.maxLength(90), Validators.pattern(pattern)]),
      salesEvent: new UntypedFormControl('', Validators.pattern(pattern)),
      saleEndDate: new UntypedFormControl('')
    });
  }

  /**
   * Builds form group and form array for the campaign management configuration tab form
   */
  buildCampaignManagementFormGroup(formArray): void
  {
    this.campaignManagementFormGroup = new UntypedFormGroup({
      strategies: new UntypedFormArray(formArray)
    });
  }

  /**
   *  Helper function to build the campaign strategy form group and populate existing values
   *
   * @param strategyTemplate
   * @param campaignStrategy
   */
  buildCampaignStrategyFormGroup(strategyTemplate, campaignStrategy) {
    const strategyForm = new UntypedFormGroup({
      shortName: new UntypedFormControl(strategyTemplate.shortName),
      campaignStrategyEnabled: new UntypedFormControl(strategyTemplate.enabled),
      targetCampaign: new UntypedFormControl(strategyTemplate.targetCampaign),
      campaignTemplate: new UntypedFormControl(strategyTemplate.campaignTemplate),
      additionalCampaigns: new UntypedFormControl(strategyTemplate.additionalCampaigns),
      finalUrlPathTemplate: new UntypedFormControl(strategyTemplate.finalUrlPathTemplate)
    });
    if (campaignStrategy) {
      this.setAccountCampaignStrategyFormGroupValues(strategyForm, campaignStrategy);
    }

    return strategyForm;
  }

  /**
   * Returns the value of the sale end date
   *
   * @param googleAdsSettings
   */
  getSaleEndDateValue(googleAdsSettings: GoogleAdsSettingsModel|null): Date|null
  {
    let value = null;
    if (googleAdsSettings.saleEndDate) {
      value = new Date(googleAdsSettings.saleEndDate.date);
    }

    return value;
  }

  /**
   * Updates the account campaign strategy form values after initialization
   *
   * @param accountId
   * @param strategyShortName
   */
  updateCampaignStrategyFormValues(accountId: string, strategyShortName: string): void
  {
    this.googleAdsService.getAccountCampaignStrategies(accountId).subscribe(
      (campaignStrategies) => {
        const campaignStrategy = campaignStrategies.find(strategy => strategy.shortName === strategyShortName);
        const strategyForm = this.campaignManagementFormGroup.controls.strategies['controls'].find(group => group.controls.shortName.value === strategyShortName);
        this.setAccountCampaignStrategyFormGroupValues(strategyForm, campaignStrategy);
      }
    );
  }

  /**
   * Sets the account campaign strategy form group values
   *
   * @param strategyForm
   * @param campaignStrategy
   */
  setAccountCampaignStrategyFormGroupValues(strategyForm: UntypedFormGroup, campaignStrategy: AccountCampaignStrategyModel): void
  {
    strategyForm.controls.shortName.setValue(campaignStrategy.shortName);
    strategyForm.controls.campaignStrategyEnabled.setValue(campaignStrategy.enabled);
    strategyForm.controls.targetCampaign.setValue(campaignStrategy.targetCampaign);
    strategyForm.controls.additionalCampaigns.setValue(campaignStrategy.additionalCampaigns);
    strategyForm.controls.campaignTemplate.setValue(campaignStrategy.campaignTemplate);
    strategyForm.controls.finalUrlPathTemplate.setValue(campaignStrategy.finalUrlPathTemplate);
    this.accountCampaignStrategyTemplates[campaignStrategy.shortName].showFinalTemplateUrl = true;
  }

  /**
   * Create Campaigns request
   */
  createCampaignRequest(campaignStrategy): void {
    if (this.account.zip !== '') {
      this.googleAdsService.createCampaignRequest({
        campaignStrategy: campaignStrategy.shortName,
        accountId: this.accountId,
        userId: this.userId
      })
        .subscribe(
          (response) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Update complete',
              detail: 'The Create Campaigns process has begun.',
              life: 5000
            });
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Create Campaigns request failed.',
              life: 5000
            });
          });
    }
    if (this.account.zip === '') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Create Campaigns request failed. A ZIP Code is required for this process to run. This can be added in Dealership Settings.',
        life: 5000
      });
    }
  }

  /**
   * Builds submenu for exporting campaign resources
   */
  buildSubMenu(): MenuItem[]
  {
    this.exportSubMenu = [
      {
        label: 'Export Campaigns',
        icon: 'pi pi-fw pi-download',
        command: event => {
          this.googleAdsService.exportCampaigns(this.accountId).subscribe(blob => {
            this.handleExport(blob, this.getFileName('campaigns'));
          });
        }
      },
      {
        label: 'Export Ad Groups',
        icon: 'pi pi-fw pi-download',
        command: event => {
          this.googleAdsService.exportAdGroups(this.accountId).subscribe((blob) => {
            this.handleExport(blob, this.getFileName('ad-groups'));
          });
        }
      },
      {
        label: 'Export Ads',
        icon: 'pi pi-fw pi-download',
        command: event => {
          this.googleAdsService.exportAds(this.accountId).subscribe((blob) => {
            this.handleExport(blob, this.getFileName('ads'));
          });
        }
      },
      {
        label: 'Export Keywords',
        icon: 'pi pi-fw pi-download',
        command: event => {
          this.googleAdsService.exportKeywords(this.accountId).subscribe((blob) => {
            this.handleExport(blob, this.getFileName('keywords'));
          });
        }
      }
    ];

    return this.exportSubMenu;
  }

  /**
   * Handles exporting a blob to browser
   *
   * @param blob
   * @param fileName
   */
  handleExport(blob: Blob, fileName: string)
  {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  /**
   * Returns formatted file name for CSV exports
   *
   * @param fileType
   */
  getFileName(fileType: string): string
  {
    let accountName = this.accountShortName;
    if (this.accountShortName === '') {
      accountName = this.accountName;
    }
    const formattedAccountName = accountName.replace(/\s+/g, '-').toLowerCase();

    return formattedAccountName + '-' + fileType + '.csv';
  }

  /**
   * Account Configuration form subscription
   */
  accountSettingsChangeSubscription(): void {
    this.accountConfigurationFormGroup.valueChanges.subscribe((values) => {
      this.requiresPmaxBrandedUpdate = false;
      this.requiresPmaxNewSalesUpdate = false;
      if (this.initialFormValues) {
        const keysForBrandedUpdate = ['adwordsActive', 'adwordsAccountId', 'customHeadline1', 'customHeadline2', 'customHeadline3'];
        const keysForNewSalesUpdate = [...keysForBrandedUpdate, 'slogan1', 'slogan2', 'slogan3', 'customDescription1', 'customDescription2', 'customDescription3'];
        this.requiresPmaxBrandedUpdate = this.formHasChanges(values, this.initialFormValues, keysForBrandedUpdate);
        this.requiresPmaxNewSalesUpdate = this.formHasChanges(values, this.initialFormValues, keysForNewSalesUpdate);
      }
    });
  }

  /**
   * Helper function to determine if there are changes in the form values excluding specified keys.
   * @param currentValues
   * @param initialValues
   * @param keysToOmit
   */
  formHasChanges(currentValues: any, initialValues: any, keysToOmit: string[]): boolean {
    const cleanedCurrentValues = this.omitProperties(currentValues, keysToOmit);
    const cleanedInitialValues = this.omitProperties(initialValues, keysToOmit);

    return JSON.stringify(cleanedCurrentValues) !== JSON.stringify(cleanedInitialValues);
  }

  /**
   * Removes specified keys from an object and returns a new object.
   * @param values
   * @param keys
   */
  omitProperties(values: any, keys: string[]): any {
    const newValues = { ...values };
    keys.forEach(key => key in newValues && delete newValues[key]);

    return newValues;
  }

  /**
   * Get the campaign type ids for Performance Max campaigns that require updates
   * @param campaignConfigurations
   */
  getPmaxCampaignTypeIds(campaignConfigurations: any): number[] {
    let campaignTypeIds = [];
    campaignConfigurations.forEach((campaignConfiguration) => {
      const { standardCampaignName, updatesEnabled } = campaignConfiguration;
      const isPerformanceMax = standardCampaignName.includes('Performance Max');
      const isBranded = standardCampaignName === 'Performance Max - Branded' && this.requiresPmaxBrandedUpdate;
      const isNewSales = standardCampaignName === 'Performance Max - New Sales' && this.requiresPmaxNewSalesUpdate;
      if (isPerformanceMax && updatesEnabled && (isBranded || isNewSales)) {
        let campaignTypeId = this.campaignTypes[standardCampaignName];
        campaignTypeIds.push(campaignTypeId);
      }
    });

    return campaignTypeIds;
  }
}
