<auto-main-navigation [accountId]=""></auto-main-navigation>
<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">Global Shortcodes</h1>
  </p-header>
  <div class="p-grid field-form-wrapper">
    <form *ngIf="sourceShortcodes" [formGroup]="sourceShortcodesMainFormGroup" autoTrackUnsavedChanges class="p-col">
      <div class="p-grid">
        <h3 class="p-col-6 field-form-header-wrapper">Shortcode<span class="pi pi-info-circle tooltip-wrapper"
                                                                     pTooltip="Shortcodes must only contain alphanumeric characters, have no spaces, and be in PascalCase (e.g., ExampleShortcode)."
                                                                     tooltipPosition="right"></span></h3>
        <h3 class="p-col-6 field-form-header-wrapper">Description</h3>
      </div>
      <div formArrayName="sourceShortcodesFormArray">
        <div *ngFor="let sourceShortcode of this.sourceShortcodesFormArray.controls; let i=index">
          <div [formGroupName]="i" class="p-grid">
            <div class="p-fluid field-form-inputs-wrapper">
              <input
                id="name"
                type="text"
                pInputText
                class="text-input"
                placeholder="PleaseUsePascalCase"
                formControlName="name"/>
              <small *ngIf="sourceShortcode.get('name').hasError('invalidPascal')"
                     class="p-invalid">
                You're doing it wrong (e.g., ExamplePascalCaseShortcode)
              </small>
            </div>
            <div class="p-fluid field-form-description-wrapper">
              <input
                id="description"
                type="text"
                pInputText
                class="text-input"
                formControlName="description"/>
            </div>
            <div class="p-fluid field-form-inputs-wrapper">
              <button pButton class="delete-button" type="button" icon="pi pi-trash"
                      (click)="openDeleteSourceShortcodeModal(i)"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-button-wrapper">
        <p-confirmDialog header="Delete Shortcode" icon="ui-icon-blank" acceptLabel="Confirm" rejectLabel="Cancel"
                         key="deleteSourceShortcodeModal"
                         acceptButtonStyleClass="p-button-success modal-button"
                         rejectButtonStyleClass="reject-button"></p-confirmDialog>
      </div>
      <div class="p-grid">
        <div class="p-col-6 field-form-inputs-wrapper">
          <button pButton type="button" icon="pi pi-plus"
                  (click)="addSourceShortcode()"></button>
        </div>
        <div class="p-col-6 field-form-inputs-wrapper">
          <button pButton type="button" label="Save" class="save-button"
                  (click)="handleSourceShortcodesSave()"
                  [disabled]="!sourceShortcodesMainFormGroup.valid || sourceShortcodesMainFormGroup.pristine"></button>
        </div>
      </div>
    </form>
  </div>
</p-panel>
<auto-footer></auto-footer>
