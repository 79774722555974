export class FormItemBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  options: { label: string, value: string | boolean }[];
  tooltip: string;
  businessCategory: string;

  constructor(options: {
    value?: T;
    key?: string;
    label?: string;
    required?: boolean;
    order?: number;
    controlType?: string;
    type?: string;
    options?: { label: string, value: string | boolean }[];
    tooltip?: string;
    businessCategory?: string;
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
    this.tooltip = options.tooltip || '';
    this.businessCategory = options.businessCategory || '';
  }
}
