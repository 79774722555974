import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {BudgetFeaturesModel} from '../model/budget-features.model';

@Injectable({
  providedIn: 'root'
})
export class BudgetFeaturesService extends AbstractHttpService{
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  getBudgetFeatures(accountId: number): Observable<BudgetFeaturesModel> {
    return this.httpClient.get<BudgetFeaturesModel>(
      `//${environment.autoDomain}:${environment.autoPort}/api/budget-features/${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
