<auto-main-navigation [accountId]="accountId"></auto-main-navigation>

<p-panel>
  <p-header class="page-header-wrapper">
    <h1 class="page-header-text">Manage Filters</h1>
  </p-header>

  <div class="main-content-wrapper p-grid">

    <div class="p-col-2">
      <p-panel styleClass="panel-content-background-white">
        <p-header>
          <h2>Filters</h2>
        </p-header>

        <div class="manage-filters-left-panel">
          <span class="p-fluid">
            <button pButton
                    label="New"
                    [disabled]="!filterForm.pristine"
                    (click)="handleNewFilter()"
                    style="margin: 1em 0 2em 0">
            </button>
          </span>

          <div class="p-fluid">
            <p-dropdown [options]="accountAdFilters"
                        optionLabel="name"
                        [filter]="true"
                        [(ngModel)]="activeFilter"
                        [disabled]="!filterForm.pristine"
                        (onChange)="setActiveFilterFormValues(activeFilter)">
            </p-dropdown>
          </div>
        </div>

      </p-panel>
    </div>

    <div class="p-col">
      <p-panel styleClass="panel-content-background-white">
        <p-header class="edit-section-header-wrapper">
          <h2 class="edit-section-header-text">Edit Filter</h2>

          <div class="edit-section-header-button-wrapper">
            <button pButton
                    class="p-button-warning edit-section-header-button"
                    (click)="setActiveFilterFormValues(activeFilter)"
                    label="Discard Changes">
            </button>
            <button pButton
                    class="p-button-danger edit-section-header-button"
                    (click)="handleDeleteFilter()"
                    [disabled]="filterForm.invalid || isConflictingName"
                    label="Delete">
            </button>
            <button pButton
                    class="p-button-success edit-section-header-button"
                    (click)="handleSaveFilter()"
                    [disabled]="filterForm.invalid || isConflictingName"
                    label="Save">
            </button>
          </div>
        </p-header>

        <div class="filter-form-wrapper">
          <form [formGroup]="filterForm">

            <div class="filter-details-wrapper p-grid">

              <div class="p-col-6 filter-details-input-wrapper p-field p-grid">
                <label class="p-col-4 filter-input-label"
                       for="filter-name">
                  Name:
                </label>

                <div class="p-col-8 p-fluid">
                  <input pInputText
                         id="filter-name"
                         (change)="setIsConflictingName()"
                         [ngClass]="{'p-invalid': !filterForm.pristine
                         &&  filterForm.get('name').hasError('alphanumeric') || filterForm.get('name').hasError('required')}"
                         formControlName="name"/>

                  <span *ngIf="!filterForm.pristine && filterForm.get('name').hasError('alphanumeric')"
                        class="p-invalid">
                      Only alphanumeric characters allowed.
                  </span>
                  <span *ngIf="!filterForm.pristine && filterForm.get('name').hasError('required')"
                        class="p-invalid">
                      Name is required.
                  </span>
                  <span *ngIf="isConflictingName"
                        class="p-invalid">
                      Filter name must be unique to this account.
                  </span>
                </div>
              </div>

              <div class="p-col-6 filter-details-input-wrapper p-field p-grid">
                <label class="p-col-4 filter-input-label"
                       for="filter-type">
                  Type:
                </label>

                <div class="p-col-8 p-fluid">
                  <input pInputText
                         id="filter-type"
                         readonly
                         class="p-disabled capitalize"
                         formControlName="type"/>
                </div>
              </div>


              <div class="p-col-6 filter-details-input-wrapper p-grid p-field">
                <label class="p-col-4 filter-input-label"
                       for="filter-description">
                  Description:
                </label>

                <div class="p-col-8 p-fluid">
                  <input pInputText
                         id="filter-description"
                         formControlName="description"/>
                </div>
              </div>

              <div class="p-col-6 filter-details-input-wrapper p-field p-grid">
                <label class="p-col-4 filter-input-label"
                       for="filter-fallback">
                  Fallback Filter:
                </label>

                <div class="p-col-8 p-fluid">
                  <p-dropdown id="filter-fallback"
                              [options]="availableFallbackFilters"
                              filter="true"
                              dataKey="fallbackFilterGuid"
                              formControlName="fallbackFilterGuid">
                  </p-dropdown>
                </div>
              </div>

            </div>

            <br/>

            <div class="filter-parameters-wrapper p-grid">

              <div class="p-col-3 filter-parameter-input-wrapper p-field">
                <label class="filter-input-label"
                       for="filter-category">
                  Category:
                </label>

                <div class="p-fluid filter-parameter-input">
                  <input pInputText id="filter-category"
                         readonly
                         class="p-disabled capitalize"
                         formControlName="category"/>
                </div>
              </div>

              <div class="p-col-3 filter-parameter-input-wrapper p-field">
                <label class="filter-input-label"
                       for="filter-subcategory">
                  Subcategory:
                </label>

                <div class="p-fluid filter-parameter-input">
                  <p-multiSelect id="filter-subcategory"
                                 [options]="accountServiceCategories | async"
                                 dataKey="value"
                                 formControlName="subcategories"
                                 optionLabel="label">
                  </p-multiSelect>
                  <span *ngIf="!filterForm.pristine && filterForm.get('subcategories').hasError('multiSelectMinimumOne')"
                        class="p-invalid">
                      At least one selection is required.
                  </span>
                </div>
              </div>

              <div class="p-col-3 filter-parameter-input-wrapper p-field">
                <label class="filter-input-label"
                       for="filter-source">
                  Source:
                </label>

                <div class="p-fluid filter-parameter-input">
                  <p-multiSelect id="filter-source"
                                 [options]="accountSources | async"
                                 dataKey="value"
                                 formControlName="sources"
                                 optionLabel="label">
                  </p-multiSelect>
                  <span *ngIf="!filterForm.pristine && filterForm.get('sources').hasError('multiSelectMinimumOne')"
                        class="p-invalid">
                      At least one selection is required.
                  </span>
                </div>
              </div>

            </div>

            <br/>

            <div class="filter-sorting-wrapper p-grid">
              <div class="p-col-3 filter-parameter-input-wrapper p-field">
                <label class="filter-input-label"
                       for="filter-sort-by">
                  Sort By:
                </label>

                <div class="p-fluid filter-parameter-input">
                  <p-dropdown id="filter-sort-by"
                              [options]="sortByFieldOptions"
                              optionLabel="label"
                              formControlName="sortBy">
                  </p-dropdown>
                </div>
              </div>

              <div class="p-col-3 filter-parameter-input-wrapper p-field">
                <label class="filter-input-label"
                       for="filter-sort-order">
                  Sort Order:
                </label>

                <div class="p-fluid filter-parameter-input">
                  <p-dropdown id="filter-sort-order"
                              [options]="sortOrderOptions"
                              optionLabel="label"
                              formControlName="sortOrder">
                  </p-dropdown>
                </div>
              </div>
            </div>

          </form>
        </div>

        <div class="ad-preview-wrapper">
          <p-card>Ad</p-card>
          <br/>
          <p-card>Ad</p-card>
          <br/>
          <p-card>Ad</p-card>
        </div>

      </p-panel>

    </div>

  </div>
</p-panel>
