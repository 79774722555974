<div>
  <form [formGroup]="form">
    <div *ngFor="let formItem of formItems" class="form-row">
      <auto-dynamic-form-item [formName]="formName"
                              [formItem]="formItem"
                              [form]="form">
      </auto-dynamic-form-item>
    </div>
  </form>
</div>
