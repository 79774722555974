import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Validator that checks if two text input fields match
 *
 * @constructor
 */
export function matchValidator(matchTo: string, reverse?: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent && reverse) {
      const abstractControl = (control.parent?.controls as any)[matchTo] as AbstractControl;
      if (abstractControl) {
        abstractControl.updateValueAndValidity();
      }
      return null;
    }
    return !!control.parent && !!control.parent.value &&
    control.value === (control.parent?.controls as any)[matchTo].value ? null : { matching: true };
  };
}
