import {AbstractControl, ValidatorFn} from '@angular/forms';

/**
 * Validator that checks whether a multi-select input has at least one value in its array
 *
 * @constructor
 */
export function MultiSelectMinimumOneValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let result = null;
    if (control.value === null || control.value.length < 1) {
      result = { multiSelectMinimumOne: {value: true} };
    }

    return result;
  };
}
