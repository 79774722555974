import {Component, OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DynamicFormItemsService} from '../../../../service/dynamic-form-items.service';
import {StringFormatterService} from '../../../../service/utilities/string-formatter.service';
import {NavigationService} from '../../../../service/navigation.service';
import {Title} from '@angular/platform-browser';
import {VehicleAdTypesSettingsForm} from '../../../forms/dynamic-form-configs/vehicle-ad-types-settings-form';
import {AdTypesSettingsService} from '../../../../service/ad-types-settings.service';
import {AccountAdTypesInterface} from '../../../../model/account-ad-types.interface';
import {BusinessCategoryConfigurationsService} from '../../../../service/business-category-configurations.service';
import {PromotionalAdCallToAction} from '../../../../model/promotional-ad-call-to-action';
import {Observable} from 'rxjs';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {PromotionalAdsConfigurationInterface} from '../../../../model/promotional-ads-configuration.interface';
import {BusinessSubcategoryInterface} from '../../../../model/business-subcategory.interface';
import {emptyDropdownValidator} from '../../../../validators/empty-dropdown-selection.directive';

@Component({
  selector: 'auto-ad-types',
  templateUrl: './ad-types.component.html',
  styleUrls: ['./ad-types.component.scss']
})
export class AdTypesComponent implements OnInit {
  accountId: number;
  // TODO: this should be an observable w/ async pipe in the template, should not need "| NULL"
  accountAdTypes: AccountAdTypesInterface | null = null;
  activeBusinessCategoryId: string;
  activeBusinessCategoryName: string;
  businessCategoryConfigurations = null;
  businessCategoryTabs: MenuItem[] = [];
  formItems;
  // TODO: this should be in a constants file
  componentName = 'Vehicle Ads';
  // TODO: same as above, this should be an observable w/ async pipe in the template
  masterFormGroup: UntypedFormGroup | null = null;
  settingsSideNav: MenuItem[];
  promotionalAdMasterFormGroup: UntypedFormGroup | null = null;
  vehicleAdTypesCollapsed: boolean;
  promotionalAdTypesCollapsed: boolean;
  accountServiceCategories: BusinessSubcategoryInterface[];
  promotionalAdsConfigurations = null;
  callsToAction: UntypedFormArray;
  actionOptions: Observable<any> | null;
  promotionalAdTabs: MenuItem[] = [];
  targetOptions = [
    {label: 'Current Window', value: '_self'},
    {label: 'New Window/Tab', value: '_blank'}
  ];
  expandIcon = 'pi pi-chevron-right';
  collapseIcon = 'pi pi-chevron-down';
  feeType = [
    {label: 'Include in Payment', value: 'includeInPayment'},
    {label: 'Due at Signing', value: 'dueAtSigning'},
    {label: 'Extra', value: 'extra'},
    {label: 'Not Used', value: 'notUsed'}
  ];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private dynamicFormItemsService: DynamicFormItemsService,
    private formBuilder: UntypedFormBuilder,
    private navigationService: NavigationService,
    private stringFormatter: StringFormatterService,
    private adTypesSettingsService: AdTypesSettingsService,
    private messageService: MessageService,
    private businessCategoryConfigurationsService: BusinessCategoryConfigurationsService
  ) {
  }

  /**
   * Init component
   */
  ngOnInit(): void {
    this.titleService.setTitle('Ad Types Settings');
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.initNavigation();
      this.getAdTypesAccountSettings()
        .then(() => {
          if (this.accountAdTypes.vehicleAdType.enabled && this.accountId) {
            this.getBusinessCategoryConfigurations(this.accountId);
          }
          if (this.accountId) {
            this.getPromotionalAdsConfigurations(this.accountId);
          }
        });
      this.getPromotionalAdTypeSubcategories('');
      this.actionOptions = this.adTypesSettingsService.getActionOptions();
    });
  }

  /**
   * Init navigation
   */
  initNavigation() {
    this.navigationService.getMenuItems(this.accountId, 'settings-view-side-menu.configuration.json')
      .then((navItems) => {
        this.settingsSideNav = navItems;
      });
  }

  /**
   * Get ad types account settings -- needed to set this.vehicleAdsEnabled
   */
  getAdTypesAccountSettings() {
    return new Promise(((resolve, reject) => {
      this.adTypesSettingsService.getAdTypesAccountSettings(this.accountId)
        .subscribe(
          (result: AccountAdTypesInterface) => {
            this.accountAdTypes = result;
            this.parseAdTypes(result);
            resolve(null);
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to find account info. Please try again.',
              life: 5000
            });
            reject();
          });
    }));
  }

  /**
   * Gets promotional ad types subcategories
   */
  getPromotionalAdTypeSubcategories(accountBusinessCategoryGuid) {
    this.adTypesSettingsService.getPromotionalAdTypeSubcategories(accountBusinessCategoryGuid)
      .subscribe(
        (result: BusinessSubcategoryInterface[]) => {
          this.accountServiceCategories = result;
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to find promotional ads subcategories. Please try again.',
            life: 5000
          });
        });
  }

  /**
   *  This is a dirty function to be able to parse the ad types into the defined interface
   *  TODO: clean up this function to not use hard coded strings
   * @param adTypes
   */
  parseAdTypes(adTypes) {
    adTypes.forEach((adType) => {
      this.accountAdTypes.accountId = adType.accountId;
      if (adType.name === 'vehicle') {
        this.accountAdTypes.vehicleAdType = adType;
      } else if (adType.name === 'promotional') {
        this.accountAdTypes.promotionalAdType = adType;
      }
    });
  }

  /**
   * Get account's business category configurations & init the vehicle ads form
   *
   * @param accountId
   */
  getBusinessCategoryConfigurations(accountId) {
    this.businessCategoryConfigurationsService.getBusinessCategoryConfigurations(this.componentName, accountId)
      .subscribe(
        (result) => {
          this.businessCategoryConfigurations = result;
          // TODO: make interfaces for this config objects so TypeScript can use type hinting
          const empty: any = {};
          if (this.businessCategoryConfigurations['businessCategoryTypes'] !== empty && this.masterFormGroup === null) {
            this.initForm(this.businessCategoryConfigurations['businessCategoryTypes']);
          }
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to find business category configurations. Please try again.',
            life: 5000
          });
        });
  }

  /**
   * init form - trigger build FormItems, FormControls, and FormGroups
   */
  initForm(businessCategories: any[]) {
    const formItemsResult = {};
    businessCategories.forEach((businessCategory) => {
      // TODO: should not have to instantiate class here, prefer to do it in dynamicFormItemsService
      businessCategory['configuration']['leaseConfiguration']['leaseIncentiveType'] = businessCategory['configuration']['leaseConfiguration']['leaseIncentiveType'].toString();
      const adTypesSettingsFormConfig = new VehicleAdTypesSettingsForm().getFormConfig();
      formItemsResult[businessCategory.name] = this.dynamicFormItemsService.getDynamicFormItems(adTypesSettingsFormConfig, businessCategory.configuration, businessCategory.name);
    });
    this.formItems = formItemsResult;
    businessCategories.forEach((businessCategory) => {
      const businessCategoryName = businessCategory['name'];
      this.formItems[businessCategoryName]['feesConfiguration'] = this.handleBuildFeesForm(businessCategory);
    });
    this.masterFormGroup = this.buildFormGroups(this.formItems);
    this.buildBusinessCategoryTabs(businessCategories);
  }

  handleBuildFeesForm(businessCategory) {
    const feesConfiguration = businessCategory['configuration']['feesConfiguration'];

    return new UntypedFormGroup({
      documentFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.documentFee.id),
        type: new UntypedFormControl('documentFee'),
        value: new UntypedFormControl(feesConfiguration.documentFee.value),
        isPercent: new UntypedFormControl(feesConfiguration.documentFee.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.documentFee.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.documentFee.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.documentFee.accountBusinessCategoryGuid)
      }),
      licenseFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.licenseFee.id),
        type: new UntypedFormControl('licenseFee'),
        value: new UntypedFormControl(feesConfiguration.licenseFee.value),
        isPercent: new UntypedFormControl(feesConfiguration.licenseFee.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.licenseFee.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.licenseFee.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.licenseFee.accountBusinessCategoryGuid)
      }),
      titleFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.titleFee.id),
        type: new UntypedFormControl('titleFee'),
        value: new UntypedFormControl(feesConfiguration.titleFee.value),
        isPercent: new UntypedFormControl(feesConfiguration.titleFee.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.titleFee.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.titleFee.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.titleFee.accountBusinessCategoryGuid)
      }),
      acquisitionFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.acquisitionFee.id),
        type: new UntypedFormControl('acquisitionFee'),
        value: new UntypedFormControl(feesConfiguration.acquisitionFee.value),
        isPercent: new UntypedFormControl(feesConfiguration.acquisitionFee.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.acquisitionFee.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.acquisitionFee.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.acquisitionFee.accountBusinessCategoryGuid)
      }),
      taxes: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.taxes.id),
        type: new UntypedFormControl('taxes'),
        value: new UntypedFormControl(feesConfiguration.taxes.value),
        isPercent: new UntypedFormControl(feesConfiguration.taxes.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.taxes.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.taxes.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.taxes.accountBusinessCategoryGuid)
      }),
      dispositionFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.dispositionFee.id),
        type: new UntypedFormControl('dispositionFee'),
        value: new UntypedFormControl(feesConfiguration.dispositionFee.value),
        isPercent: new UntypedFormControl(feesConfiguration.dispositionFee.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.dispositionFee.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.dispositionFee.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.dispositionFee.accountBusinessCategoryGuid)
      }),
      customFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.customFee.id),
        type: new UntypedFormControl('customFee'),
        value: new UntypedFormControl(feesConfiguration.customFee.value),
        displayName: new UntypedFormControl(feesConfiguration.customFee.displayName),
        enabled: new UntypedFormControl(feesConfiguration.customFee.enabled),
        isPercent: new UntypedFormControl(false),
        leaseApplication: new UntypedFormControl('notUsed'),
        financeApplication: new UntypedFormControl('notUsed'),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.customFee.accountBusinessCategoryGuid)
      })
    });
  }

  // TODO: this should be refactored into a more generic process & should live in FormControlService
  /**
   * Build out FormControls and FormGroups for ad type settings form
   *
   * @param combinedFormItems
   */
  buildFormGroups(combinedFormItems) {
    const masterFormGroup = {};
    Object.keys(combinedFormItems).forEach((businessCategoryKey) => {
      masterFormGroup[businessCategoryKey] = {};
      const businessCategoryFormItems = combinedFormItems[businessCategoryKey];
      Object.keys(businessCategoryFormItems).forEach((settingsGroupKey) => {
        const settingsGroupItems = businessCategoryFormItems[settingsGroupKey];
        if (settingsGroupKey === 'feesConfiguration') {
          masterFormGroup[businessCategoryKey][settingsGroupKey] = settingsGroupItems;
        }
        if (settingsGroupKey !== 'feesConfiguration') {
          masterFormGroup[businessCategoryKey][settingsGroupKey] = {};
          const thisGroup = {};
          settingsGroupItems.forEach((formItem) => {
            if (formItem.controlType !== 'subheader') {
              thisGroup[formItem.key] = new UntypedFormControl(formItem.value);
            }
          });
          masterFormGroup[businessCategoryKey][settingsGroupKey] = new UntypedFormGroup(thisGroup);
        }
      });
      masterFormGroup[businessCategoryKey] = new UntypedFormGroup(masterFormGroup[businessCategoryKey]);
    });

    return new UntypedFormGroup(masterFormGroup);
  }

  /**
   * GET & set business categories
   */
  buildBusinessCategoryTabs(businessCategories) {
    businessCategories.forEach((businessCategory) => {
      const businessCategoryObject = {
        label: `${businessCategory.name}`,
        id: businessCategory.guid
      };
      this.businessCategoryTabs.push(businessCategoryObject);
    });
    this.setActiveBusinessCategory(this.businessCategoryTabs[0]);
  }

  /**
   * handler function called when Vehicle Ads switch is toggled
   */
  handleVehicleAdsEnabledToggle() {
    this.updateAdTypesAccountSettings(this.accountAdTypes.vehicleAdType);
    this.initializeBusinessCategoryConfigurations();
  }

  /**
   * handler function called when Promotional Ads switch is toggled
   */
  handlePromotionalAdsEnabledToggle() {
    this.updateAdTypesAccountSettings(this.accountAdTypes.promotionalAdType);
    this.initializeBusinessCategoryConfigurations();
    this.initializePromotionalAdsConfigurations();
  }

  /**
   * Update ad types account settings (for now just toggle Vehicle Ads)
   *
   * @param adTypesAccountSettings
   */
  updateAdTypesAccountSettings(adTypesAccountSettings) {
    // Check if need to initialize or update ad types
    if (adTypesAccountSettings.guid === null) {
      adTypesAccountSettings.guid = '';
      this.adTypesSettingsService.createAccountAdTypes(adTypesAccountSettings)
        .subscribe(() => {
            this.getAdTypesAccountSettings().then(r => {
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to initialize Ad Type settings',
              life: 5000
            });
          });
    } else {
      this.adTypesSettingsService.updateAdTypesAccountSettings(adTypesAccountSettings)
        .subscribe(
          () => {
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to save Ad type setting.',
              life: 5000
            });
          });
    }
  }

  /**
   * Initialize & populate business category configurations when Vehicle Ads is turned on for the first time
   */
  initializeBusinessCategoryConfigurations() {
    if ((this.accountAdTypes.vehicleAdType.enabled || this.accountAdTypes.promotionalAdType.enabled)
      && this.businessCategoryConfigurations === null) {
      this.businessCategoryConfigurationsService.initializeBusinessCategoryConfigurations(this.componentName, this.accountId)
        .subscribe(
          () => {
            this.getBusinessCategoryConfigurations(this.accountId);
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to initialize configurations.',
              life: 5000
            });
          });
    }
  }

  /**
   * Initialize & populate promotional ads configurations when Promotional Ads is turned on for the first time
   */
  initializePromotionalAdsConfigurations() {
    if (this.accountAdTypes.promotionalAdType.enabled && this.promotionalAdsConfigurations === null) {
      this.adTypesSettingsService.initializePromotionalAdsConfigurations(this.accountId)
        .subscribe(
          () => {
            this.getPromotionalAdsConfigurations(this.accountId);
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to initialize configurations.',
              life: 5000
            });
          });
    }
  }

  /**
   * set active business category form
   *
   * @param businessCategory
   */
  setActiveBusinessCategory(businessCategory): void {
    this.activeBusinessCategoryName = businessCategory.label;
    this.activeBusinessCategoryId = businessCategory.id;
  }

  /**
   * Vehicle Ads save form
   */
  handleVehicleAdsSave(): void {
    const updatedConfigurations = this.mapConfigurationObject();
    this.businessCategoryConfigurationsService.updateBusinessCategoryConfigurations(updatedConfigurations)
      .subscribe(
        () => {
          this.masterFormGroup.markAsPristine();
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save changes.',
            life: 5000
          });
        });
  }

  /**
   * Helper function to map the form values to the object used in the request body
   */
  mapConfigurationObject(): object {
    const updatedConfiguration = this.businessCategoryConfigurations;
    const formValuesMap = new Map(Object.entries(this.masterFormGroup.value));
    formValuesMap.forEach((configurationValue, key) => {
      let nestedConfiguration = updatedConfiguration.businessCategoryTypes.find(item => item.name === key).configuration;
      nestedConfiguration = configurationValue;
      nestedConfiguration.feesConfiguration = this.convertFeesToNumber(nestedConfiguration.feesConfiguration);

      updatedConfiguration.businessCategoryTypes.find(item => item.name === key).configuration = nestedConfiguration;
    });

    return updatedConfiguration;
  }

  /**
   * Helper function to convert fee values from strings to numbers since the Prime Faces
   * form component converts numeric input fields to strings when calling getValue()
   *
   * @param feesConfiguration
   */
  convertFeesToNumber(feesConfiguration): object {
    const convertedConfigurations = feesConfiguration;
    convertedConfigurations.documentFee.value = +convertedConfigurations.documentFee.value;
    convertedConfigurations.licenseFee.value = +convertedConfigurations.licenseFee.value;
    convertedConfigurations.titleFee.value = +convertedConfigurations.titleFee.value;
    convertedConfigurations.acquisitionFee.value = +convertedConfigurations.acquisitionFee.value;
    convertedConfigurations.taxes.value = +convertedConfigurations.taxes.value;
    convertedConfigurations.dispositionFee.value = +convertedConfigurations.dispositionFee.value;
    convertedConfigurations.customFee.value = +convertedConfigurations.customFee.value;

    return convertedConfigurations;
  }

  handlePromotionalAdsSave(): void {
    let updatedConfigurations = this.mapPromotionalConfigurations();
    updatedConfigurations[0].accountId = this.accountId;
    this.adTypesSettingsService.updatePromotionalAdTypeConfigurations(updatedConfigurations[0])
      .subscribe(
        () => {
          this.promotionalAdMasterFormGroup.markAsPristine();
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save promotional ad changes.',
            life: 5000
          });
        });
  }

  /**
   * Function to get promotional ads configurations and set up promotional ads config form group
   * @param accountId
   */
  getPromotionalAdsConfigurations(accountId: number) {
    if (!this.promotionalAdsConfigurations) {
      this.adTypesSettingsService.getPromotionalAdsConfigurations(accountId)
        .subscribe((promotionalAdsConfigResult: PromotionalAdsConfigurationInterface[]) => {
          this.promotionalAdsConfigurations = promotionalAdsConfigResult;
          const existingCallsToAction = this.buildCallToActionFormGroup();
          this.callsToAction = this.formBuilder.array(existingCallsToAction);
          const configuration = this.promotionalAdsConfigurations[0];
          this.promotionalAdMasterFormGroup = this.formBuilder.group({
            disclosureAddon: new UntypedFormControl(configuration.disclosureText),
            subcategories: new UntypedFormControl(this.buildMultiselectOptions(configuration.businessSubcategories)),
            callsToAction: this.callsToAction
          });
          this.buildPromotionalCategoryTabs(this.promotionalAdsConfigurations);
        });
    }
  }

  /**
   * Function to populate promotional ads tabs - currently limited to one category
   * Will
   * @param promotionalAdCategories
   */
  buildPromotionalCategoryTabs(promotionalAdCategories) {
    promotionalAdCategories.forEach((category) => {
      const promotionalAdCategory = {
        label: `${category.accountBusinessCategoryName}`,
        id: category.guid
      };
      this.promotionalAdTabs.push(promotionalAdCategory);
    });
  }

  /**
   * Function to build promotional ads configuration call to action form group array
   * The result of this function is used to generate the FormArray for callsToAction
   */
  buildCallToActionFormGroup(): UntypedFormGroup[] {
    const callToActionForms = [];
    const configuration = this.promotionalAdsConfigurations[0];
    if (configuration.promotionalAdCallToActions !== undefined) {
      configuration.promotionalAdCallToActions.forEach((callToAction) => {
        callToActionForms.push(this.createCallToActionForm(callToAction));
      });
    }

    return callToActionForms;
  }

  /**
   * Build array of options for multi-select inputs
   *
   * @param subcategoryParameters
   */
  buildMultiselectOptions(subcategoryParameters) {
    const selectedItems = [];
    Object.keys(subcategoryParameters).forEach((key) => {
      const option = subcategoryParameters[key];
      selectedItems.push({
        name: option.name,
        id: option.id
      });
    });

    return selectedItems;
  }

  /**
   * helper function to create call to action form group
   *
   * @param callToAction
   */
  createCallToActionForm(callToAction): UntypedFormGroup {
    return this.formBuilder.group({
      target: new UntypedFormControl({value: callToAction.target}, [emptyDropdownValidator()]),
      actionUrl: new UntypedFormControl(callToAction.url),
      callToActionText: new UntypedFormControl(callToAction.displayText, [Validators.required]),
      actionOptions: new UntypedFormControl({value: callToAction.action}, [emptyDropdownValidator()]),
      order: new UntypedFormControl(callToAction.order)
    });
  }

  /**
   * Add call to action to callsToAction array
   */
  addNewCallToAction() {
    const callsToAction = this.promotionalAdMasterFormGroup.get('callsToAction') as UntypedFormArray;
    const newCallToAction = new PromotionalAdCallToAction();
    callsToAction.push(this.createCallToActionForm(newCallToAction));
    this.promotionalAdMasterFormGroup.markAsDirty();
  }

  /**
   * Delete call to action at CTA form array
   * @param index
   */
  deleteCallToAction(index) {
    const callsToAction = this.promotionalAdMasterFormGroup.get('callsToAction') as UntypedFormArray;
    callsToAction.removeAt(index);
    this.promotionalAdMasterFormGroup.markAsDirty();
    if (callsToAction.length === 0) {
      this.addNewCallToAction();
    }
  }

  /**
   * Function called on drop of reordered call to action
   * @param event
   */
  callToActionDrop(event: CdkDragDrop<string[]>) {
    const callsToAction = this.promotionalAdMasterFormGroup.get('callsToAction') as UntypedFormArray;
    this.moveItemInFormArray(callsToAction, event.previousIndex, event.currentIndex);
    this.promotionalAdMasterFormGroup.markAsDirty();
  }


  /**
   * Moves an item in a FormArray to another position
   * TODO: Move to more reusable class
   * @param formArray
   * @param fromIndex
   * @param toIndex
   */
  moveItemInFormArray(
    formArray: UntypedFormArray,
    fromIndex: number,
    toIndex: number
  ): void {
    const direction = toIndex > fromIndex ? 1 : -1;

    const item = formArray.at(fromIndex);
    for (let iteration = fromIndex; iteration * direction < toIndex * direction; iteration = iteration + direction) {
      const current = formArray.at(iteration + direction);
      formArray.setControl(iteration, current);
    }
    formArray.setControl(toIndex, item);
  }

  /**
   * Function to convert promotional ad configuration properties to format to be saved
   */
  mapPromotionalConfigurations(): object {
    const updatedConfigurations = this.promotionalAdsConfigurations;
    const updatedCallsToAction = [];
    const callsToActionMap = new Map(Object.entries(this.promotionalAdMasterFormGroup.get('callsToAction').value));
    callsToActionMap.forEach((ctaValue, index) => {
      let callToActionReformatted = new PromotionalAdCallToAction();
      callToActionReformatted.action = ctaValue['actionOptions'].value;
      callToActionReformatted.displayText = ctaValue['callToActionText'];
      callToActionReformatted.url = ctaValue['actionUrl'];
      if (callToActionReformatted.url == null){
        callToActionReformatted.url = '';
      }
      callToActionReformatted.order = +index;
      callToActionReformatted.target = ctaValue['target'].value;
      callToActionReformatted.promotionalAdsConfigurationGuid = updatedConfigurations[0].guid;
      updatedCallsToAction.push(callToActionReformatted);
    });

    updatedConfigurations[0].disclosureText = this.promotionalAdMasterFormGroup.get('disclosureAddon').value;
    updatedConfigurations[0].businessSubcategories = this.promotionalAdMasterFormGroup.get('subcategories').value;
    updatedConfigurations[0].promotionalAdCallToActions = updatedCallsToAction;

    return updatedConfigurations;
  }
}
