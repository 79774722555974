export class SourceShortcodeModel  {
  id: number;
  name: string;
  description: string;
  archived: boolean;

  constructor() {
    this.id = null;
    this.name = null;
    this.description = null;
    this.archived = false;
  }
}
