import {Component, Input, OnInit} from '@angular/core';
import {MediaManagementComponent} from "../media-management.component";

@Component({
  selector: 'auto-media-tags-settings',
  templateUrl: './media-tags-settings.component.html',
  styleUrl: './media-tags-settings.component.scss'
})
export class MediaTagsSettingsComponent extends MediaManagementComponent implements OnInit {

  @Input() accountId: number;

  compiledMediaTags: string;
  includedMediaTags = [];
  generateMediaTagsInProgress = false;
  createConversionInProgress = false;
  conversions = [];

  ngOnInit(): void {
    this.mediaManagementService.getMediaTagScripts(this.accountId).subscribe(
      (response) => {
        this.compiledMediaTags = this.getCompiledMediaTags(response.mediaTags);
        if (this.compiledMediaTags === '0') {
          this.compiledMediaTags = '';
        }
        this.includedMediaTags = this.getIncludedMediaTags(response.mediaTags);
      }
    );
    this.mediaManagementService.getConversionsForAccount(this.accountId).subscribe(
      (response) => {
        this.conversions = response;
      }
    );
  }

  /**
   * Generate media tags for the account
   */
  generateMediaTags() {
    this.generateMediaTagsInProgress = true;
    this.mediaManagementService.generateMediaTagForAccount(this.accountId).subscribe(
      (response) => {
        this.mediaManagementService.getMediaTagScripts(this.accountId).subscribe(
          (response) => {
            this.compiledMediaTags = this.getCompiledMediaTags(response.mediaTags);
            this.includedMediaTags = this.getIncludedMediaTags(response.mediaTags);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Media Tags successfully generated.'
            });
            this.generateMediaTagsInProgress = false;
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No media tags were generated. Please check account configuration.'
            });
            this.generateMediaTagsInProgress = false;
          }
        );
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No media tags were generated. Please check account configuration.'
        });
        this.generateMediaTagsInProgress = false;
      }
    )
  }

  /**
   * Create conversion for the account
   */
  createConversionForAccount() {
    this.createConversionInProgress = true;
    this.mediaManagementService.createConversionsForAccount(this.accountId).subscribe(
      (response) => {
        if (response === 'Skipped creating Google Ads conversion actions – all conversion actions already exist in account.') {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Skipping conversion creation – all conversion actions already exist in account.'
          });
          this.createConversionInProgress = false;
        } else {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Conversion successfully created.'
          });
          this.mediaManagementService.getConversionsForAccount(this.accountId).subscribe(
            (response) => {
              this.conversions = response;
            }
          );
          this.createConversionInProgress = false;
        }
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to create conversion.'
        });
        this.createConversionInProgress = false;
      }
    )
  }

  /**
   * Get compiled media tags
   * @param mediaTagsScript
   */
  getCompiledMediaTags(mediaTagsScript: any) {
    let tag = ''
    if (mediaTagsScript.filter(mediaTagsScript => mediaTagsScript.platform === 'Media Tag')) {
      tag = mediaTagsScript.filter(mediaTagsScript => mediaTagsScript.platform === 'Media Tag')[0].tag;
    }

    return tag;
  }

  /**
   * Get included media tags
   * @param mediaTagsScript
   */
  getIncludedMediaTags(mediaTagsScript: any) {
    return mediaTagsScript.filter(mediaTags => mediaTags.platform !== 'Media Tag');
  }
}
