import { Injectable } from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {BudgetManagement} from '../model/budget-management.model';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BudgetManagementService extends AbstractHttpService{
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  constructor(private httpClient: HttpClient) {
    super();
  }
  public getBudgetManagementData(accountId: number) {
    return this.httpClient.get<BudgetManagement[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/budget-management/${accountId}`,
      this.defaultOptions
    )
      .pipe(
        catchError(this.handleError)
      );
  }

  public saveBudgetConfiguration(data: BudgetManagement) {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/budget-management`,
      data,
      this.defaultOptions
    )
      .pipe(
        catchError(this.handleError)
      );
  }
}
