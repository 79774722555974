import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasProperty'
})
export class HasProperty implements PipeTransform {
  transform(object: object, prop: string): boolean {
    let result = false;
    if (object) {
      if (object.hasOwnProperty(prop)) {
        if (object[prop]) {
          result = true;
        }
      }
    }
    return result;
  }
}
