import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PageService} from '../../../../service/page.service';
import {Observable, Subscriber} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {AccountService} from '../../../../service/account.service';
import {environment} from '../../../../../environments/environment';
import {PageModel} from '../../../../model/page.model';
import {AccountInterface} from '../../../../model/account.interface';
import {map} from 'rxjs/operators';
import {CompactMpopAccountInterface} from '../../../../model/compact-mpop-account.interface';
import {ProjectedPageRequestModel} from '../../../../model/projected-page-request.model';
import {ConnectNativePageRequestModel} from '../../../../model/connect-native-page-request.model';
import {DOCUMENT} from '@angular/common';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {SelectItemGroup} from 'primeng/api';
import {PromotePageRequestModel} from '../../../../model/promote-page-request.model';
import {CopyPageToAccountRequestModel} from '../../../../model/copy-page-to-account-request.model';
import {ImportPageRequestModel} from '../../../../model/import-page-request.model';
import {UserService} from '../../../../service/user.service';
import {UserInterface} from '../../../../model/user.interface';

@Component({
  selector: 'auto-pages-list',
  templateUrl: './pages-list.component.html',
  styleUrls: ['./pages-list.component.scss']
})
export class PagesListComponent implements OnInit {
  previewDomain: string;
  documentHostname: string;
  documentProtocol: string;
  accountId: number;
  account: AccountInterface;
  pages: Observable<PageModel[]>;
  displayedNativePages: Observable<PageModel[]>;
  displayedProjectedPages: Observable<PageModel[]>;
  projectedPages: Observable<PageModel[]>;
  nativePages: Observable<PageModel[]>;
  compactAccounts: CompactMpopAccountInterface[];
  compactOEMAccounts: CompactMpopAccountInterface[] = [];
  filteredCompactAccounts: CompactMpopAccountInterface[] = [];
  selectedAccount: CompactMpopAccountInterface;
  selectedSortOption = 'Title (Alphanumeric)';
  selectedShowOption = 'Active Pages';
  selectedPage: PageModel;
  accountPages: SelectItemGroup[];
  promotablePages = [];
  importablePages = [];
  selectedImportPages: PageModel[];
  selectedAccountPageIds: number[];
  sortByOptions: string[];
  showOptions: string[];
  pageTypeMapping: any;
  pageTypeOptions: string[];
  pageSearchFilter: string;
  isProduction = false;
  importSearchFilter: string;
  displayProjectPageModal: boolean;
  displayImportPageModal: boolean;
  onNativePage: boolean;
  displayEditLinksModal = false;
  displayPromotePageModal = false;
  displayPromotePageConfirmationModel = false;
  arrowUp: boolean;
  pageUrl: string;
  inventoryUrl: string;
  pageId: number;
  sourcePageId: number;
  pageName: string;
  selectedPromotePageAccount: CompactMpopAccountInterface;
  accountDropdownDisabled: boolean;
  nativeProjectionSelectedAccountId: number;
  nativeProjectionSelectedParentPageId: number;
  nativeProjectionAccountPages: PageModel[];
  nativeProjectionSelectedChildPage: PageModel;
  displayProjectNativePageModal: boolean;
  mpopCompactAccount: CompactMpopAccountInterface;
  pageToReplace: {label: string, value: number};
  user: UserInterface;
  isLoaded = false;
  viewportHeight: number;
  menuModel: any;
  subMenuModels: { [pageId: string]: any } = {};

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private pageService: PageService,
    private titleService: Title,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private userService: UserService
) {
    this.documentHostname = this.document.location.hostname;
    this.documentProtocol = this.document.location.protocol;
  }

  /**
   * init component
   */
  async ngOnInit() {
    this.titleService.setTitle('Pages');
    this.onNativePage = true;
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.previewDomain = environment.launchcontrolAdminDomain;
      if (this.accountId) {
        this.accountService.getAccount(this.accountId).subscribe(
          (account) => {
            this.account = account;
            this.getPages(account);
            this.updateSubMenus(this.pages);
          }
        );
      }
      if (this.documentHostname === 'admin.dealerteamwork.com') {
        this.isProduction = true;
      }
      this.accountService.getCompactMpopAccounts().subscribe(
        (compactAccounts) => {
          this.compactAccounts = compactAccounts;
          let oemAccountOptions = [];
          compactAccounts.forEach((account) => {
            if (account.accountStatusTypeId === 10){
              oemAccountOptions.push(account);
            }
          });
          this.compactOEMAccounts = oemAccountOptions;
          const initialProjectPageAccount = compactAccounts.filter(function(account){
            return account.id === parseInt(params.get('accountId'));
          });
          this.mpopCompactAccount = initialProjectPageAccount[0];
          this.selectedPromotePageAccount = initialProjectPageAccount[0];
          this.getPromotablePagesForAccount();
        }
      );
    });
    await this.userService.getUserPromise().then(result => {
      this.user = result;
    });
    this.initializePageGroupMultiselect();
    this.setOptions();
    this.menuModel = this.buildPageMenu();
    this.isLoaded = true;
  }

  /**
   * Function to show pages based on active status
   */
  showPages() {
    this.filterByActiveStatus();
    this.sortPage();
  }

  /**
   * Function to get and filter pages
   * @param account
   */
  getPages(account: AccountInterface) {
    this.pages = this.pageService.getPages(account.id, null, false);
    this.nativePages = this.displayedNativePages = this.filterByProjectedStatus(false);
    this.projectedPages = this.displayedProjectedPages = this.filterByProjectedStatus(true);

    this.sortPage();
    this.filterByActiveStatus()
  }

  /**
   * Resets selections when closing out of the modal
   */
  closeModal() {
    this.selectedAccount = null;
    this.importablePages = [];
    this.selectedImportPages = null;
    this.importSearchFilter = null;
  }

  /**
   * Function to filter pages based on projection status
   * @param isProjected
   */
  filterByProjectedStatus(isProjected: boolean) {
    return this.pages.pipe(
      map(pages => pages.filter(page => page.isProjected === isProjected))
    );
  }

  /**
   * Method to filter pages based on active status
   */
  filterByActiveStatus() {
    if (this.selectedShowOption === 'All Pages') {
      this.displayedNativePages = this.nativePages;
      this.displayedProjectedPages = this.projectedPages;
    } else if (this.selectedShowOption === 'Active Pages' || this.selectedShowOption === null) {
      this.displayedNativePages = this.nativePages.pipe(
        map(pages => pages.filter(page => page.active))
      );
      this.displayedProjectedPages = this.projectedPages.pipe(
        map(pages => pages.filter(page => page.active))
      );
    } else if (this.selectedShowOption === 'Inactive Pages') {
      this.displayedNativePages = this.nativePages.pipe(
        map(pages => pages.filter(page => !page.active))
      );
      this.displayedProjectedPages = this.projectedPages.pipe(
        map(pages => pages.filter(page => !page.active))
      );
    }
  }

  /**
   * Displays the project page modal
   */
  addNewPage(): void {
    this.displayProjectPageModal = true;
  }

  /**
   * Displays the import page modal
   */
  importNewPage(): void {
    this.displayImportPageModal = true;
    this.selectedAccount = null;
  }

  /**
   * Gets the list of OEM pages based on the selection
   */
  getImportedPages(): void {
    this.importablePages = [];
    this.selectedImportPages = [];
    this.importSearchFilter = '';
    let importedPages: Observable<PageModel[]>;
    if (this.selectedAccount !== null && this.displayImportPageModal) {
      importedPages = this.pageService.getPages(this.selectedAccount.id, null);
      importedPages.subscribe((pages) => {
          pages.forEach((page) => {
            const curPage = {title: page.titleName, type: page.pageType, id: page.id};
            this.importablePages.push(curPage);
          });
        }
      );
    }
  }

/**
 * Will use auto-api endpoint to import pages asynchronously
 */
  async importPage() {
    const requests = [];

    type Response = {
      createdPageId: string;
      pageTitle: string;
    };

    if (this.selectedImportPages !== null && this.selectedImportPages.length > 0) {
      this.selectedImportPages.forEach((page) =>
        requests.push(new ImportPageRequestModel(page.id, this.accountId, this.selectedAccount.id)));
      this.displayImportPageModal = false;
      for (const page of requests) {
        await this.pageService.importPage(page).toPromise().then(
          resp => {
            this.messageService.add({
              severity: 'success',
              summary: 'Page imported',
              detail: 'Page successfully imported.',
              life: 5000
            });
            this.getPages(this.account);
            if (requests.length === 1) {
              const pageId = ((resp) as Response).createdPageId;
              this.document.location.href = this.documentProtocol + '//' + this.documentHostname +
                '/pages/' + this.accountId + '#/edit/' + pageId + '/';
            }
          }).catch(
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'At least one page failed to import. If this continues, reach out to support for assistance.',
              life: 5000
            });
          });
      }
      this.closeModal();
    }
  }

  /**
   * Will create a new custom page
   */
  createCustomPage() {

    type Response = {
      createdPageId: string;
    };

    this.pageService.addPage(this.accountId).subscribe(
       (resp) => {
         this.messageService.add({
           severity: 'success',
           summary: 'Custom Page Created',
           detail: ' Custom page successfully created.',
           life: 5000
         });
         const pageId = ((resp) as Response).createdPageId;
         this.document.location.href = this.documentProtocol + '//' + this.documentHostname +
           '/pages/' + this.accountId + '#/edit/' + pageId + '/?showEditInfo=true';
       },
       () => {
         this.messageService.add({
           severity: 'error',
           summary: 'Error',
           detail: 'Page failed to create.',
           life: 5000
         });
       }
     );
  }

  /**
   * Resets the Page view everytime the Page tab is changed
   */
  resetView(): void {
    this.onNativePage = !this.onNativePage;
    this.showPages();
  }

  /**
   * Compares values for sorting
   */
  compare(firstValue, secondValue): number {
    let result = 1;
    if (firstValue < secondValue) {
      result = -1;
    }
    return result;
  }

  /**
   * Sorts the page based on dropdown selected
   */
  sortPage(): void {

    let displayedPages = this.displayedProjectedPages;
    if (this.onNativePage) {
       displayedPages = this.displayedNativePages;
    }

    displayedPages = displayedPages.pipe(
      map((page) => {
        page.sort((firstPage: any, secondPage: any) => {
          let result = 0;
          if (this.arrowUp) {
            if (this.selectedSortOption === 'Title (Alphanumeric)' || this.selectedSortOption === null) {
              result = this.compare(secondPage.titleName, firstPage.titleName);
            } else if (this.selectedSortOption === 'Modified Date') {
              result = this.compare(firstPage.modified.date, secondPage.modified.date);
            } else if (this.selectedSortOption === 'Created Date') {
              result = this.compare(firstPage.created.date, secondPage.created.date);
            }
          } else{
            if (this.selectedSortOption === 'Title (Alphanumeric)' || this.selectedSortOption === null) {
              result = this.compare(firstPage.titleName, secondPage.titleName);
            } else if (this.selectedSortOption === 'Modified Date') {
              result = this.compare(secondPage.modified.date, firstPage.modified.date);
            } else if (this.selectedSortOption === 'Created Date') {
              result = this.compare(secondPage.created.date, firstPage.created.date);
            }
          }
          return result;
        });
        return page;
      })
    );

    if (this.onNativePage) {
      this.displayedNativePages = displayedPages;
    } else {
      this.displayedProjectedPages = displayedPages;
    }
  }

  /**
   * Function to set all options for the sort by dropdown
   */
  setOptions(): void {
    this.arrowUp = false;
    this.sortByOptions = [
      'Title (Alphanumeric)',
      'Modified Date',
      'Created Date'
    ];
    this.showOptions = [
      'All Pages',
      'Active Pages',
      'Inactive Pages'
    ];
  }

  /**
   * Function to create the projected page connection
   */
  projectPage(): void {
    this.displayProjectPageModal = false;

    const projectedPageRequest = new ProjectedPageRequestModel();
    projectedPageRequest.projectedPageIds = this.selectedAccountPageIds;
    projectedPageRequest.parentAccountId = this.selectedAccount.id;
    projectedPageRequest.childAccountId = this.accountId;

    this.pageService.projectPage(projectedPageRequest)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Page(s) projected',
            detail: 'Page(s) successfully projected.',
            life: 5000
          });
          this.getPages(this.account);
          this.updateSubMenus(this.pages);
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'At least one page failed to project. If this continues, reach out to support for assistance.',
            life: 5000
          });
        });

    this.initializePageGroupMultiselect();
    this.selectedAccountPageIds = null;
    this.selectedAccount = null;
    this.accountDropdownDisabled = false;
  }

  /**
   * Function to create native page connection
   */
  createNativePageConnection(): void {
    this.displayProjectNativePageModal = false;

    const connectNativePageRequest = new ConnectNativePageRequestModel();
    connectNativePageRequest.projectedPageId = this.nativeProjectionSelectedParentPageId;
    connectNativePageRequest.childPageId = this.nativeProjectionSelectedChildPage.id;

    this.pageService.connectNativePage(connectNativePageRequest)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Page projected',
            detail: 'Page successfully projected.',
            life: 5000
          });
          this.getPages(this.account);
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Page failed to project.',
            life: 5000
          });
        });

    this.nativeProjectionSelectedAccountId = null;
    this.nativeProjectionSelectedParentPageId = null;
  }

  /**
   * Function to filter accounts during Import Page account lookup
   */
  filterAccounts(event) {
    let filtered: CompactMpopAccountInterface[] = [];
    const query = event.query;
    this.compactAccounts.forEach((account) => {
      if (account.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(account);
      }
    });

    this.filteredCompactAccounts = filtered;
  }

  /**
   * Function to get the pages for the selected account in the project page modal
   */
  getPagesForAccount(): void {
    this.initializePageGroupMultiselect();
    if (this.selectedAccount) {
      this.pageService.getPages(this.selectedAccount.id, this.accountId).subscribe(
        (accountPages) => {
          this.buildPageGroupMultiselect(accountPages);
        }
      );
    }
  }

  /**
   * Function to get only promotable pages for the selected account in the promote page modal
   */
  getPromotablePagesForAccount(): void {
    this.promotablePages = [];
    if (this.selectedPromotePageAccount) {
      this.pageService.getPages(this.selectedPromotePageAccount.id, null).subscribe(
        (accountPages) => {
          this.buildPromotablePageSelect(accountPages);
        }
      );
    }
  }

  /**
   * Function to get the pages for the selected account in the project native page modal
   */
  getPagesForAccountForNativeProjection(): void {
    this.nativeProjectionSelectedParentPageId = null;
    if (this.nativeProjectionSelectedAccountId) {
      this.pageService.getPages(this.nativeProjectionSelectedAccountId, null).subscribe(
        (accountPages) => {
          this.nativeProjectionAccountPages = accountPages;
        }
      );
    }
  }

  /**
   * Function to initialize the page group multiselect
   */
  initializePageGroupMultiselect() {
    this.accountPages = [
      {
        label: 'Not Projected',
        items: []
      },
      {
        label: 'Projected',
        items: []
      }
    ];
  }

  /**
   * Function to build the page group multiselect
   * @param pages
   */
  buildPageGroupMultiselect(pages: PageModel[]) {
    pages.forEach((page) => {
      const pageItem = {label: page.titleName, value: page.id};
      if (!page.isProjectedParent) {
        this.accountPages[0].items.push(pageItem);
      } else if (page.isProjectedParent) {
        this.accountPages[1].items.push(pageItem);
      }
    });
  }

  /**
   * Function to filter promotable pages
   * @param pages
   */
  buildPromotablePageSelect(pages: PageModel[]) {
    let promotablePageOptions = [];
    pages.forEach((page) => {
      const pageItem = {label: page.titleName, value: page.id};
      if (page.isProjectedParent) {
        promotablePageOptions.push(pageItem);
      }
    });
    this.promotablePages = promotablePageOptions;
  }

  /**
   * Function to determine whether the account dropdown is disabled or not
   */
  toggleAccountDropdownDisabled() {
    this.accountDropdownDisabled = this.selectedAccountPageIds.length !== 0;
  }

  /**
   * Function to return a list of action items for Page
   */

  buildPageMenu() {
    this.viewportHeight = 85;
    let pageMenuConfig = [
      {
        label: 'Add Custom Page',
        icon: 'pi pi-plus',
        command: event => {
          this.openBuildCustomPageModal();
        }
      }
    ];

    if (this.userService.userHasAccessKeys(this.user, this.accountId, ['admin'])) {
      this.viewportHeight = 175;
      const projectPage = {
        label: 'Project Page',
        icon: 'pi pi-fw pi-images',
        command: event => {
          this.addNewPage();
        }
      }
      const importPage = {
        label: 'Import Page',
        icon: 'pi pi-download',
        command: event => {
          this.importNewPage();
        }
      }
      pageMenuConfig.push(projectPage);
      pageMenuConfig.push(importPage);
    }

    return pageMenuConfig;
  }

  /**
   * Function to return submenu object per page
   * @param page
   */
  buildSubmenu(page: PageModel) {
    let activeLabel = 'Set Inactive';
    let activeIcon = 'pi pi-fw pi-eye-slash';
    if (!page.active) {
      activeLabel = 'Set Active';
      activeIcon = 'pi pi-fw pi-eye';
    }
    let isAdmin = false;
    this.viewportHeight = 130;
    if (this.userService.userHasAccessKeys(this.user, this.accountId, ['admin'])) {
        isAdmin = true;
        this.viewportHeight = 215;
    }
    const submenuConfig = [
      {
        label: 'Dynamic Code',
        icon: 'pi pi-fw pi-copy',
        command: event => {
          this.copyDynamicCode(page.id);
        }
      }
    ];
    if (page.isProjected && isAdmin) {
      submenuConfig.push(
        {
          label: 'Copy to Account',
          icon: 'pi pi-fw pi-download',
          command: event => {
            this.sourcePageId = page.parentPage.id;
            this.openCopyToAccountModal(page.id, page.titleName, page.parentPage.accountId);
          }
        }
      );
      submenuConfig.push(
        {
          label: 'Visit Source Page',
          icon: 'pi pi-fw pi-sitemap',
          command: event => {
            const url = `${this.documentProtocol}//${this.documentHostname}/pages/${page.parentPage.accountId}#/edit/${page.parentPage.id}`;
            window.open(url, '_blank');
          }
        }
      );
    }
    if (!page.isProjected && this.account.accountStatusTypeId !== 10) {
      if (isAdmin) {
        submenuConfig.push(
          {
            label: 'Project Page',
            icon: 'pi pi-fw pi-images',
            command: event => {
              this.openProjectNativePageModal(page);
            }
          }
        );
      }
    }
    submenuConfig.push(
      {
        label: 'Edit URLs',
        icon: 'pi pi-fw pi-pencil',
        command: event => {
          this.openEditLinksModal(page);
        }
      });
    if (this.account.accountStatusTypeId === 10) {
      submenuConfig.push(
        {
          label: 'Promote Page',
          icon: 'pi pi-fw pi-chevron-up',
          command: event => {
            this.openPromotePageModal(page);
          }
        },
      );
    }
    submenuConfig.push(
      {
        label: activeLabel,
        icon: activeIcon,
        command: event => {
          this.updatePageActiveStatus(page);
        }
      }
    );
    if (isAdmin) {
      submenuConfig.push(
        {
          label: 'Delete',
          icon: 'pi pi-fw pi-trash',
          command: event => {
            if (page.isProjected) {
              this.openDeleteProjectedPageModal(page.id, page.titleName);
            } else {
              this.openConfirmDeleteNativePageModal(page, page.titleName);
            }
          }
        }
      );
    }

    return submenuConfig;
  }

  /**
   * Method to updateSubMenus
   * @param pagesObservable
   */
  updateSubMenus(pagesObservable: Observable<PageModel[]>) {
    pagesObservable.subscribe((pagesArray) => {
      pagesArray.forEach(page => {
        this.subMenuModels[page.id] = this.buildSubmenu(page);
      });
    });
  }

  /**
   * Function to copy dynamic code for page rendering
   * @param pageId
   */
  copyDynamicCode(pageId: number) {
    const dynamicCodeText = `<div id="ncs-landing" rel="${pageId}"></div>`;
    navigator.clipboard.writeText(dynamicCodeText)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Copied to clipboard',
          life: 3000
        });
        // console.log(`Copied: '${dynamicCodeText}' to clipboard`);
      }).catch((error) => {
       console.log(`Error copying text to clipboard: ${error}`);
    });
  }

  /**
   * Function to copy a page to an account
   */
  copyToAccount(pageId, pageName, oemId) {
    const copyPageToAccountRequest = new CopyPageToAccountRequestModel();
    copyPageToAccountRequest.targetPageId = parseInt(pageId);
    copyPageToAccountRequest.sourcePageId = this.sourcePageId;
    copyPageToAccountRequest.ncsAccountId = this.accountId;
    copyPageToAccountRequest.accountName = this.account.name;
    copyPageToAccountRequest.pageName = pageName;
    copyPageToAccountRequest.oemId = oemId;
    this.pageService.copyPageToAccount(copyPageToAccountRequest)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
          this.getPages(this.account);
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save changes.',
            life: 5000
          });
        });
  }

  /**
   * Saves the page
   * @param page
   */
  savePage(page) {
    page.modified = page.modified.date;
    page.created = page.created.date;
    page.azPublishedDt = page.azPublishedDt.date;
    this.pageService.updatePage(page)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
          this.getPages(this.account);
          this.updateSubMenus(this.pages);
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save changes.',
            life: 5000
          });
        });
  }

  /**
   * Updates the page active status
   * @param page
   */
  updatePageActiveStatus(page) {
    if (!page.isConnectedSource) {
      page.active = !page.active;
      this.savePage(page);
    } else {
        this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'This page has active projected page connections and cannot be set to inactive.',
            life: 5000
        });
    }
  }

  /**
   * Function to edit page url and inventory url
   */
  editLinks() {
    this.displayEditLinksModal = false;
    this.selectedPage.id = this.pageId;
    this.selectedPage.pageUrl = this.pageUrl;
    this.selectedPage.inventoryUrl = this.inventoryUrl;
    this.savePage(this.selectedPage);
  }

  /**
   * Delete page function
   * @param pageId
   * @param pageName
   */
  deleteProjectedPage(pageId: number, pageName: string) {
    this.pageService.deleteProjectedPage(pageId)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Projected page deleted',
            detail: 'You successfully deleted the projected page ' + pageName + '.',
            life: 5000
          });
          this.getPages(this.account);
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to delete projected page ' + pageName + '.',
            life: 5000
          });
        });
  }

  /**
   * Displays Edit Links Modal
   */
   openEditLinksModal(page) {
    this.pageId = parseInt(page.id);
    this.pageName = page.titleName;
    this.pageUrl = page.pageUrl;
    this.inventoryUrl = page.inventoryUrl;
    this.selectedPage = page;
    this.displayEditLinksModal = true;
  }

  /**
   * Handles creation of confirmation modal for projection of native page
   */
  openConfirmProjectNativePageModal() {
    const parentAccountName = this.compactAccounts.filter(account => account.id === this.nativeProjectionSelectedAccountId)[0].name;
    const pageName = this.nativeProjectionAccountPages.filter(page => page.id === this.nativeProjectionSelectedParentPageId)[0].titleName;
    this.confirmationService.confirm({
      key: 'confirmProjectNativePageModal',
      defaultFocus: 'none',
      message: 'If page projection is used, this page will receive its content from ' + pageName + ' in '
        + parentAccountName + '. Any existing page content will be lost. Please confirm this action.',
      accept: () => {
        // "{{documentProtocol}}//{{documentHostname}}/pages/{{accountId}}#/edit/{{page.id}}"
        this.createNativePageConnection();
      }
    });
  }

  /**
   * Handles creation of confirmation modal for deletion of native page
   */
  openConfirmDeleteNativePageModal(page: PageModel, pageName: string) {
    if (!page.isConnectedSource) {
      this.confirmationService.confirm({
        key: 'confirmDeleteNativePageModal',
        defaultFocus: 'none',
        message: `Are you sure you want to delete this page?`,
        header: `Delete ` + pageName,
        accept: () => {
          this.pageService.deleteNativePage(page.id).subscribe(
            () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Page deleted',
                detail: 'Page successfully deleted.',
                life: 5000
              });
              this.getPages(this.account);
            },
            () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable to delete page.',
                life: 5000
              });
            });
        }
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'This page is unable to be deleted because it is the source page for at least one projected account.',
        life: 5000
      });
    }
  }

  /**
   * Handles creation of confirmation modal for deletion of projected page
   */
  openDeleteProjectedPageModal(pageId, pageTitle) {
    this.confirmationService.confirm({
      key: 'deleteProjectedPageModal',
      defaultFocus: 'none',
      message: 'Are you sure you want to remove this page? <br><i>Note: This action will not delete the source page from the OEM account.</i>',
      header: `Delete ` + pageTitle,
      accept: () => {
        this.deleteProjectedPage(pageId, pageTitle);
      }
    });
  }

  /**
   * Handles creation of confirmation modal for creating a custom page
   */
  openBuildCustomPageModal() {
    this.confirmationService.confirm({
      key: 'buildCustomPage',
      defaultFocus: 'none',
      message: 'Adding a Custom Page will build a new fully-customizable MPOP page.<br><br>Please confirm this action',
      header: `Create Custom Page`,
      accept: () => {
        this.createCustomPage();
      }
    });
  }

  /**
   * Displays Project Native Page Modal
   */
  openProjectNativePageModal(page: PageModel) {
    this.nativeProjectionSelectedAccountId = null;
    this.nativeProjectionSelectedParentPageId = null;
    this.pageName = page.titleName;
    if (page.parentPage) {
      this.nativeProjectionSelectedAccountId = page.parentPage.accountId;
      this.getPagesForAccountForNativeProjection();
      this.nativeProjectionSelectedParentPageId = page.parentPage.id;
    }
    this.nativeProjectionSelectedChildPage = page;
    this.displayProjectNativePageModal = true;
  }

  /**
   * Displays Promote Page Modal
   */
  openPromotePageModal(page) {
    this.displayPromotePageModal = true;
    this.pageId = parseInt(page.id);
    this.pageName = page.titleName;
    this.getPromotablePagesForAccount();
  }

  /**
   * Resets selected OEM account
   */
  resetProjectedPageAccount() {
    this.selectedPromotePageAccount = this.mpopCompactAccount;
    this.pageToReplace = null;
  }

  /**
   * handles creation of confirmation modal for promotion of page
   */
  openConfirmPromotePageModal() {
    this.confirmationService.confirm({
      key: 'promotePageModal',
      defaultFocus: 'none',
      message: `Promoting ${this.pageName} will make it the projecting page source of one or more page connections by taking over for ${this.pageToReplace.label}. Please confirm this action.`,
      accept: () => {
        this.promotePage(this.pageId, this.pageToReplace.value, this.pageName, this.pageToReplace.label);
        this.pageToReplace = null;
        this.displayPromotePageModal = false;
      },
      reject: () => {
        this.displayPromotePageModal = true;
      }
    });
  }

  /**
   * handles creation of confirmation modal for promotion of page
   */
  openCopyToAccountModal(pageId, pageName, oemId) {
    this.confirmationService.confirm({
      defaultFocus: 'none',
      key: 'copyToAccountModal',
      message: `Copying to the account will increase customizability, but disconnect any source page updates from being received. Please confirm this action.`,
      header: `Copy ` + pageName + ` to Account`,
      accept: () => {
        this.copyToAccount(pageId, pageName, oemId);
        const url = `http://${environment.launchcontrolAdminDomain}/pages/${this.accountId}#/edit/${pageId}`;
        window.location.href = url;
      },
      reject: () => {
      }
    });
  }

  /**
   * Handles promotion of page
   */
  promotePage(newProjectedPageId, oldProjectedPageId, newProjectedPageName, oldProjectedPageName) {
    const promotePageRequest = new PromotePageRequestModel();
    promotePageRequest.newProjectedPageId = newProjectedPageId;
    promotePageRequest.oldProjectedPageId = oldProjectedPageId;
    promotePageRequest.oldProjectedPageName = oldProjectedPageName;
    promotePageRequest.newProjectedPageName = newProjectedPageName;
    promotePageRequest.ncsAccountId = this.accountId;
    this.pageService.promotePage(promotePageRequest)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
          this.getPages(this.account);
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save changes.',
            life: 5000
          });
        });
  }
}
