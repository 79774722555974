import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../../../../service/navigation.service';
import {MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators} from '@angular/forms';
import {MappingRulesService} from '../../../../service/mapping-rules.service';
import {MappingRule} from '../../../../model/mapping-rule';
import {AdFieldOption} from '../../../../model/ad-field-option';
import {emptyDropdownValidator} from '../../../../validators/empty-dropdown-selection.directive';
import {AccountInventoryField} from '../../../../model/account-inventory-field';
import {AccountMappingRules} from '../../../../model/account-mapping-rules';

@Component({
  selector: 'auto-mapping-rules',
  templateUrl: './mapping-rules.component.html',
  styleUrls: ['./mapping-rules.component.scss']
})
export class MappingRulesComponent implements OnInit {
  accountId: number;
  settingsSideNav: MenuItem[];
  mappingRulesForm: UntypedFormGroup;
  rules: UntypedFormArray;
  adFieldOptions: AdFieldOption[];
  accountMappingRules: MappingRule[];

  constructor(private navigationService: NavigationService,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private mappingRulesService: MappingRulesService,
              private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      if (this.accountId) {
        this.getAdFields(this.accountId);
        this.getAccountMappingRules(this.accountId);
      }

      this.initNavigation();
    });
  }

  /**
   * Gets the ad field options for populating the ad field dropdown in the forms
   */
  getAdFields(accountId: number) {
    this.mappingRulesService.getAdFields(accountId)
      .subscribe((adFieldsResult) => {
        this.adFieldOptions = [
          {label: 'Select an ad field', value: null}
        ];
        adFieldsResult.forEach((inventoryField: AccountInventoryField) => {
          let newAdField = new AdFieldOption();
          newAdField.label = inventoryField.name;
          newAdField.value = inventoryField.niceName;
          this.adFieldOptions.push(newAdField);
        });
      });
  }

  /**
   * Retrieves the accounts currently saved mapping rules
   *
   * @param accountId
   */
  getAccountMappingRules(accountId: number) {
    this.mappingRulesService.getMappingRules(accountId)
      .subscribe((result) => {
        this.accountMappingRules = result.mappingRules;
        this.initForm();
      });
  }

  /**
   * Initializes the mapping rules form
   */
  initForm() {
    const existingRules = this.buildAccountRuleForms();
    this.rules = this.formBuilder.array(existingRules);
    this.mappingRulesForm = this.formBuilder.group({
      rules: this.rules
    });
  }

  /**
   * Builds existing mapping rule forms based on account's currently saved mapping rules
   */
  buildAccountRuleForms(): UntypedFormGroup[] {
    const accountRuleForms = [];
    if (this.accountMappingRules !== undefined) {
      this.accountMappingRules.forEach((mappingRule) => {
        accountRuleForms.push(this.createRuleForm(mappingRule));
      });
    }

    if (accountRuleForms.length === 0) {
      let newRule = new MappingRule();
      this.accountMappingRules = [];
      this.accountMappingRules.push(newRule);
      accountRuleForms.push(this.createRuleForm(newRule));
    }

    return accountRuleForms;
  }

  /**
   * Create a rule form to add to the rules FormArray
   *
   * @param rule
   */
  createRuleForm(rule): UntypedFormGroup {
    return this.formBuilder.group({
      guid: new UntypedFormControl(rule.guid),
      adField: new UntypedFormControl({value: rule.adField}, [emptyDropdownValidator()]),
      inventoryValue: new UntypedFormControl(rule.inventoryValue, [Validators.required]),
      replacementValue: new UntypedFormControl(rule.replacementValue, [Validators.required])
    });
  }

  /**
   * Adds a new default rule form to the rules FormArray
   */
  addRuleForm(): void {
    this.rules = this.mappingRulesForm.get('rules') as UntypedFormArray;
    const newRule = new MappingRule();
    this.rules.push(this.createRuleForm(newRule));
  }

  /**
   * Saves the mapping rules from the current state of the FormArray
   */
  saveRules() {
    const updatedAccountMappingRules = new AccountMappingRules();
    updatedAccountMappingRules.accountId = this.accountId;
    updatedAccountMappingRules.mappingRules = this.buildUpdatedRules();

    this.mappingRulesService.updateMappingRules(updatedAccountMappingRules)
      .subscribe(
      () => {
        this.mappingRulesForm.markAsPristine();
        this.messageService.add({
          severity: 'success',
          summary: 'Update complete',
          detail: 'Your changes were saved.',
          life: 5000
        });
        this.getAccountMappingRules(this.accountId);
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to update mapping rules.',
          life: 5000
        });
      });
  }

  /**
   * Builds updated mapping rules from current form state
   */
  buildUpdatedRules() {
    const newRules = [];

    this.rules.value.forEach((rule) => {
      const newRule = new MappingRule();
      newRule.guid = '';
      newRule.adField = rule.adField.value;
      newRule.inventoryValue = rule.inventoryValue;
      newRule.replacementValue = rule.replacementValue;
      newRule.accountId = this.accountId;
      newRules.push(newRule);
    });

    return newRules;
  }

  /**
   * Removes a mapping rule from the rules FormArray
   *
   * @param index
   */
  deleteRule(index) {
    this.rules.removeAt(index);
    this.mappingRulesForm.markAsDirty();
    if (this.rules.length === 0) {
      this.addRuleForm();
    }
  }

  /**
   * Init navigation
   */
  initNavigation() {
    this.navigationService.getMenuItems(this.accountId, 'inventory-settings-view-side-menu.configuration.json')
      .then((navItems) => {
        this.settingsSideNav = navItems;
      });
  }
}
