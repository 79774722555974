export class MediaManagementModel {
  ncsAccountId: number;
  facebookAdsEnabled: boolean;
  facebookMarketplaceEnabled: boolean;
  advertiseAllVehicles: boolean;
  facebookPixelId: number;
  facebookPixelHash: string;
  facebookAdsAccountId: number;
  facebookAdsCustomLabel0: string;
  facebookAdsCustomLabel1: string;
  facebookAdsCustomLabel2: string;
  facebookAdsFallbackLabel0: string;
  facebookAdsFallbackLabel1: string;
  facebookAdsFallbackLabel2: string;
  facebookAdsDefaultLabel: string;
  microsoftAdsAccountId: string;
  facebookDealerName: string;
  microsoftAdsEnabled: boolean;
  microsoftAdsUetId: number;
  googleStoreCode: string;
  carsForSaleEnabled: boolean;
  useDealerId: boolean;
  advertiseAllGoogleVehicles: boolean;
  uberallLocationId: number;
  gmbPostsEnabled: boolean;
  usedMediaFriendlyImage: number;
  newMediaFriendlyImage: number;
  facebookPageId: number;
  facebookShareActive: boolean;
  facebookPageAccessToken: string;
  googleAnalyticsMeasurementId: string;
  useGoogleAnalyticsTracking: boolean;
  gbpNewFinalUrl: string;
  gbpUsedFinalUrl: string;
  minimumImageRequirement: number

  constructor() {
    this.ncsAccountId = 0;
    this.facebookAdsEnabled = false;
    this.facebookMarketplaceEnabled = false;
    this.advertiseAllVehicles = false;
    this.facebookPixelId = 0;
    this.facebookPixelHash = '';
    this.facebookAdsAccountId = 0;
    this.facebookAdsCustomLabel0 = '';
    this.facebookAdsCustomLabel1 = '';
    this.facebookAdsCustomLabel2 = '';
    this.facebookAdsFallbackLabel0 = '';
    this.facebookAdsFallbackLabel1 = '';
    this.facebookAdsFallbackLabel2 = '';
    this.facebookAdsDefaultLabel = '';
    this.microsoftAdsAccountId = '';
    this.facebookDealerName = '';
    this.microsoftAdsEnabled = false;
    this.microsoftAdsUetId = 0;
    this.googleStoreCode = '';
    this.carsForSaleEnabled = false;
    this.useDealerId = false;
    this.advertiseAllGoogleVehicles = false;
    this.uberallLocationId = 0;
    this.gmbPostsEnabled = false;
    this.usedMediaFriendlyImage = 0;
    this.newMediaFriendlyImage = 0;
    this.facebookPageId = 0;
    this.facebookShareActive = false;
    this.facebookPageAccessToken = '';
    this.googleAnalyticsMeasurementId = '';
    this.useGoogleAnalyticsTracking = false;
    this.gbpNewFinalUrl = 'vdp';
    this.gbpUsedFinalUrl = 'vdp';
    this.minimumImageRequirement = 0;
  }
}
