import {FormItemDropdown} from '../../../model/form-models/form-item-dropdown';
import {FormItemSubheader} from '../../../model/form-models/form-item-subheader';
import {FormItemTextInput} from '../../../model/form-models/form-item-text-input';
import {FormItemToggleSwitch} from '../../../model/form-models/form-item-toggle-switch';
import {AbstractFormConfig} from './abstract-form-config';
import {FormItemDecimalInput} from '../../../model/form-models/form-item-decimal-input';

export class VehicleAdTypesSettingsForm extends AbstractFormConfig {
  form = {
    generalConfiguration: {
      includeSelectInventoryIncentives: new FormItemToggleSwitch({
        key: 'includeSelectInventoryIncentives',
        label: 'Include Select Inventory Incentives',
        controlType: 'toggleSwitch'
      }),
      rebateDisclosureFormat: new FormItemDropdown(
        {
          key: 'rebateDisclosureFormat',
          label: 'Rebate Disclosure Format',
          controlType: 'dropdown',
          options: [
            {value: 'grouped', label: 'Grouped'},
            {value: 'itemized', label: 'Itemized'}
          ],
          order: 1
        }),
    },
    incentiveConfiguration: {
      useIncentiveService: new FormItemToggleSwitch({
        required: false,
        key: 'useIncentiveService',
        label: 'Use Incentive Service',
        controlType: 'toggleSwitch',
        tooltip: 'When enabled, the best available incentives are pulled from a service during New ad creation.'
      }),
      enhancedIncentives: new FormItemToggleSwitch({
        required: false,
        key: 'enhancedIncentives',
        label: 'Enhanced Incentives',
        controlType: 'toggleSwitch',
        tooltip: 'When enabled, incentives and MPOP Vehicle Ad sale end dates will be automatically kept up-to-date. This feature requires that the Use Incentive Service feature is enabled.'
      })
    },
    purchaseConfiguration: {
      showPurchaseOffer: new FormItemToggleSwitch({
        key: 'showPurchaseOffer',
        label: 'Show Purchase Offers',
        controlType: 'toggleSwitch'
      }),
      showMathBox: new FormItemToggleSwitch({
        key: 'showMathBox',
        label: 'Show Math Box',
        controlType: 'toggleSwitch',
        businessCategory: 'New'
      }),
      showSavingsOffer: new FormItemToggleSwitch({
        key: 'showSavingsOffer',
        label: 'Show Savings Offers',
        controlType: 'toggleSwitch'
      }),
      showPreRebateSubtotal: new FormItemToggleSwitch({
        key: 'showPreRebateSubtotal',
        label: 'Show Pre-rebate Subtotal',
        controlType: 'toggleSwitch'
      }),
      showLineItemSuperscripts: new FormItemToggleSwitch({
        key: 'showLineItemSuperscripts',
        label: 'Show Line Item Superscripts',
        controlType: 'toggleSwitch'
      }),
      includePurchaseDealerIncentives: new FormItemToggleSwitch({
        key: 'includePurchaseDealerIncentives',
        label: 'Include Dealer Incentives',
        controlType: 'toggleSwitch'
      }),
    },
    leaseConfiguration: {
      showLeaseOffer: new FormItemToggleSwitch({
        key: 'showLeaseOffer',
        label: 'Show Lease Offers',
        controlType: 'toggleSwitch'
      }),
      showAlternateLeaseOffer: new FormItemToggleSwitch({
        key: 'showAlternateLeaseOffer',
        label: 'Show Alternate Lease Offers',
        controlType: 'toggleSwitch'
      }),
      leaseSellingPriceType: new FormItemDropdown(
        {
          key: 'leaseSellingPriceType',
          label: 'Selling price',
          controlType: 'dropdown',
          options: [
            {value: 'discountPrice', label: 'Discount price'},
            {value: 'msrp', label: 'MSRP'}
          ],
          order: 1
        }),
      includeLeaseDealerIncentives: new FormItemToggleSwitch({
        key: 'includeLeaseDealerIncentives',
        label: 'Include Dealer Incentives',
        controlType: 'toggleSwitch'
      }),
      leaseIncentiveType: new FormItemDropdown(
        {
          key: 'leaseIncentiveType',
          label: 'Lease Incentive',
          controlType: 'dropdown',
          options: [
            {value: 'subvented fallback', label: 'Subvented (Fallback)'},
            {value: 'subvented no fallback', label: 'Subvented (No Fallback)'},
            {value: 'standard', label: 'Standard'}
          ]
        }
      ),
      moneyFactorMarkup: new FormItemDecimalInput({
        key: 'moneyFactorMarkup',
        label: 'Money Factor Markup',
        controlType: 'decimal',
        tooltip: 'The markup is added to the best rate from the Incentive Service. Lease APR/2400 converts percentage value to money factor.'
      })
    },
    alternateLeaseConfiguration: {},
    financeConfiguration: {
      showFinanceOffer: new FormItemToggleSwitch({
        key: 'showFinanceOffer',
        label: 'Show Finance Offers',
        controlType: 'toggleSwitch'
      }),
      financeAprSource: new FormItemDropdown(
        {
          key: 'financeAprSource',
          label: 'Finance APR Source',
          controlType: 'dropdown',
          businessCategory: 'New',
          options: [
            {value: 'incentiveService', label: 'Incentive Service'},
            {value: 'accountDefaults', label: 'Account Defaults'}
          ],
          tooltip: 'When Incentive Service is selected, the Finance APR with the longest term is pulled from the Real-Time Incentive Service. ' +
            'When Account Defaults is selected, the Finance APR will come from the defaults configured for the account.'
        }
      ),
      includeFinanceDealerIncentives: new FormItemToggleSwitch({
        key: 'includeFinanceDealerIncentives',
        label: 'Include Dealer Incentives',
        controlType: 'toggleSwitch'
      }),
    },
    aprConfiguration: {
      showAprOffer: new FormItemToggleSwitch({
        key: 'showAprOffer',
        label: 'Show APR Offers',
        controlType: 'toggleSwitch'
      }),
      useChromeIncentivesAprOffer: new FormItemToggleSwitch({
        key: 'useChromeIncentivesAprOffer',
        label: 'Use Best Available APR From Incentive Service',
        controlType: 'toggleSwitch',
        tooltip: 'When enabled, the lowest available Finance APR with the longest correlating term is pulled from the Real-Time Incentive Service. When disabled, the available APR will come from the New Ad Defaults for the account.'
      })
    }
  };
}

