import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ShortcodeService} from '../../../../service/shortcode.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MenuItem, MessageService} from 'primeng/api';
import {AccountShortcodeModel} from '../../../../model/account-shortcode.model';
import {NavigationService} from '../../../../service/navigation.service';
import {forkJoin, Observable} from 'rxjs';

@Component({
  selector: 'auto-account-shortcodes',
  templateUrl: './account-shortcodes.component.html',
  styleUrls: ['./account-shortcodes.component.scss']
})
export class AccountShortcodesComponent implements OnInit {
  accountId: number;
  accountShortcodesMainFormGroup: UntypedFormGroup;
  accountShortcodesFormArray: UntypedFormArray;
  accountShortcodes: AccountShortcodeModel[];
  saveInProgress: boolean;
  settingsSideNav: MenuItem[];

  constructor(
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private titleService: Title,
    private shortcodeService: ShortcodeService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Account Shortcodes');
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.initNavigation();
      this.getAccountShortcodes();
    });
    this.saveInProgress = false;
  }

  /**
   * Init navigation
   */
  initNavigation() {
    this.navigationService.getMenuItems(this.accountId, 'settings-view-side-menu.configuration.json')
      .then((navItems) => {
        this.settingsSideNav = navItems;
      });
  }

  /**
   * Retrieves account shortcodes from the backend and initializes the form
   */
  getAccountShortcodes(): void {
    this.shortcodeService.getAccountShortcodes(this.accountId)
      .subscribe((result) => {
        this.accountShortcodes = result.sort((a, b) => (a.sourceShortcode.id > b.sourceShortcode.id) ? 1 : -1);
        this.initForm();
      });
  }

  /**
   * Initializes the account shortcodes form
   */
  initForm(): void {
    const existingAccountShortcodes = this.buildAccountShortcodeFormGroup();
    this.accountShortcodesFormArray = this.formBuilder.array(existingAccountShortcodes);
    this.accountShortcodesMainFormGroup = this.formBuilder.group({
      accountShortcodesFormArray: this.accountShortcodesFormArray
    });
  }

  /**
   * Function to build account shortcode form group array
   * The result of this function is used to generate the FormArray for accountShortcodes
   */
  buildAccountShortcodeFormGroup(): UntypedFormGroup[] {
    const accountShortcodeForms = [];
    if (this.accountShortcodes !== undefined) {
      this.accountShortcodes.forEach((accountShortcode) => {
        accountShortcodeForms.push(this.createAccountShortcodeForm(accountShortcode));
      });
    }

    return accountShortcodeForms;
  }

  /**
   * Helper function to create account shortcode form group
   * @param accountShortcode
   */
  createAccountShortcodeForm(accountShortcode): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(accountShortcode.id),
      accountId: new UntypedFormControl(this.accountId),
      value: new UntypedFormControl(accountShortcode.value),
      sourceShortcode: new UntypedFormControl(accountShortcode.sourceShortcode)
    });
  }

  /**
   * Handles the update(s) and creation(s) of account shortcodes
   */
  handleAccountShortcodesSave(): void {
    this.saveInProgress = true;
    let observables: Observable<any>[] = [];
    this.accountShortcodesFormArray.controls.forEach((accountShortcodeFormControl) => {
      if (accountShortcodeFormControl.dirty) {
        let accountShortcode = this.mapAccountShortcodeModel(accountShortcodeFormControl);
        if (accountShortcode.id) {
          observables.push(this.shortcodeService.updateAccountShortcode(accountShortcode));
        } else {
          observables.push(this.shortcodeService.saveAccountShortcode(accountShortcode));
        }
      }
    });
    this.completeSave(observables);
  }

  /**
   * Maps an accountShortcode form control to an AccountShortcodeModel
   * @param accountShortcodeFormControl
   */
  mapAccountShortcodeModel(accountShortcodeFormControl): AccountShortcodeModel {
    let accountShortcode = new AccountShortcodeModel();
    accountShortcode.id = accountShortcodeFormControl['controls'].id.value;
    accountShortcode.accountId = this.accountId;
    accountShortcode.value = accountShortcodeFormControl['controls'].value.value;
    accountShortcode.sourceShortcode = accountShortcodeFormControl['controls'].sourceShortcode.value;

    return accountShortcode;
  }

  /**
   * Joins all of the observables emitted from the save event and responds accordingly
   * @param observables
   */
  completeSave(observables: Observable<any>[]): void {
    forkJoin(observables)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
          this.getAccountShortcodes();
          this.accountShortcodesMainFormGroup.markAsPristine();
          this.saveInProgress = false;
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save shortcode.',
            life: 5000
          });
        });
  }
}
