import {Component, Inject, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UserService} from '../../../../../service/user.service';
import {DOCUMENT} from '@angular/common';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {Observable, of, switchMap} from 'rxjs';
import {MpopUserModel} from '../../../../../model/mpop-user.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'auto-mpop-users',
  templateUrl: './mpop-users.component.html',
  styleUrls: ['./mpop-users.component.scss']
})
/**
 * class MpopUsersComponent
 */
export class MpopUsersComponent implements OnInit {

  public documentHostname: string;
  public documentProtocol: string;
  public sortByOptions = ['Active', 'Inactive', 'Show All'];
  public selectedOption = 'Active';
  public mpopUsers = [];
  public filteredMpopUsers: Observable<MpopUserModel[]>;
  public userSearchFilter: string;
  public selectedUser: MpopUserModel | null = null;
  public activeColor = 'white';
  public inactiveColor = '#e5e5e5';
  public selectedColor = '#a9b2cc';
  public isUserSuperAdmin = false;
  private filterConditions = {
    'Active': user => user.active,
    'Inactive': user => !user.active,
    'Show All': () => true,
  };

  /**
   * Constructor for MpopUsersComponent
   *
   * @param document
   * @param titleService
   * @param userService
   * @param messageService
   * @param route
   * @param router
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private userService: UserService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.documentHostname = this.document.location.hostname;
    this.documentProtocol = this.document.location.protocol;
  }

  /**
   * Method to initialize the component
   */
  ngOnInit(): void {
    this.titleService.setTitle('MPOP Users');
    this.userService.getMpopUsers().subscribe(
      (result) => {
        this.mpopUsers = result.sort((a, b) => a.name.localeCompare(b.name));
        this.mpopUsers.forEach(user => {
          user.backgroundColor = user.active ? this.activeColor : this.inactiveColor;
          user.created = new Date(user.created.date).toLocaleDateString();
          if (user.userType === 'Super Admin') {
            this.isUserSuperAdmin = true;
          }
        });
        this.filterOptions();
        this.initializeSelectedUserFromRoute();

      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get MPOP users'
        });
      }
    );
  }

  /**
   * Initializes the selected user based on the userId query parameter
   */
  initializeSelectedUserFromRoute(): void {
    this.route.queryParamMap.subscribe((params) => {
      let userId = params.get('userId');

      if (userId) {
        let selectedUser = this.mpopUsers.find((user) => user.id == userId);
        this.selectUser(selectedUser);
      }
    });
  }

  /**
   * Method to load users lazily
   *
   * @param event
   */
  loadUsersLazy(event: LazyLoadEvent) {
    setTimeout(() => {
        const loadedUsers = this.mpopUsers.slice(event.first, (event.first + event.rows));
        Array.prototype.splice.apply(this.mpopUsers, [event.first, event.rows].concat(loadedUsers));

        event.forceUpdate();
      },
      1000
    );
  }

  /**
   * Method to select a user
   * @param user
   */
  selectUser(user) {
    this.mpopUsers.forEach((mpopUser) => {
      if (mpopUser.id === user.id) {
        mpopUser.backgroundColor = this.selectedColor;
      } else if (mpopUser.active) {
        mpopUser.backgroundColor = this.activeColor;
      } else {
        mpopUser.backgroundColor = this.inactiveColor;
      }
    });
    this.updateUserIdQueryParam(user.id);
    this.selectedUser = user;
  }

  /**
   * Updates the userId query parameter in the URL
   * @param userId
   */
  updateUserIdQueryParam(userId: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { userId },
      queryParamsHandling: 'merge'
    });
  }

  /**
   * Method to close user profile via event emitter
   */
  closeUserProfile(user: MpopUserModel | null) {
    user.backgroundColor = this.inactiveColor;
    if (user.active) {
      user.backgroundColor = this.activeColor;
    }
    this.selectedUser = null;
  }

  /**
   * Method to filter options based on the selected dropdown option
   */
  filterOptions() {
    this.filteredMpopUsers = of(this.mpopUsers.filter(this.filterConditions[this.selectedOption]));
  }
}
