import {AbstractHttpService} from './abstract-http-service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {AccountInventoryUrlModel} from '../model/account-inventory-url.model';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AccountInventoryUrlService extends AbstractHttpService {
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  patchOptions = {
    headers: this.contentTypeJsonHeadersForPatch,
    withCredentials: true
  };

  constructor(private httpClient: HttpClient) {
    super();
  }

  getAccountInventoryUrls(accountId: number, newUsed: string): Observable<AccountInventoryUrlModel>{
    return this.httpClient.get<AccountInventoryUrlModel>(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-inventory-url/${accountId}?urlType=${newUsed}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateAccountInventoryUrls(accountInventoryUrl: AccountInventoryUrlModel): Observable<any> {
    return this.httpClient.patch(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-inventory-url`,
      accountInventoryUrl,
      this.patchOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateAccountVlpUrls(accountInventoryUrl: AccountInventoryUrlModel): Observable<any> {
    return this.httpClient.patch(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-vlp-url`,
      accountInventoryUrl,
      this.patchOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
