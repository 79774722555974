import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitializationService {

  private componentInitSubject = new Subject<void>();

  componentInitCompleted$ = this.componentInitSubject.asObservable();

  /**
   * Notifies the child component that an operation from the parent has completed.
   */
  notifyComponentInitializationCompleted() {
    this.componentInitSubject.next();
  }
}
