import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[autoTrackUnsavedChanges]'
})
export class TrackUnsavedChangesDirective {

  constructor(private element: ElementRef) { }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event) {
    if (this.element.nativeElement.classList.contains('ng-dirty')) {
      const confirmMessage = 'Are you sure you want to leave this page? Unsaved changes will be lost.';
      event.returnValue = confirmMessage;

      return confirmMessage;
    }
  }
}
