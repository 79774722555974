<auto-main-navigation [accountId]="accountId"></auto-main-navigation>

<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">Inventory Settings</h1>
  </p-header>

  <div class="main-content-wrapper p-grid">
    <div class="p-col-4">
      <p-panelMenu [model]="settingsSideNav">
      </p-panelMenu>
    </div>

    <p-panel class="p-col ad-types-wrapper">
      <p-header class="account-inventory-header-wrapper">
        <h2 class="ad-types-header-text">{{this.activeBusinessCategoryName}} Inventory URLs</h2>
      </p-header>
      <p-tabMenu #tab
                 styleClass="business-category-tabs"
                 [model]="businessCategoryTabs"
                 [activeItem]="businessCategoryTabs[0]"
                 (click)="setActiveBusinessCategory(tab.activeItem)">
      </p-tabMenu>

        <div *ngIf="this.activeBusinessCategoryName == 'New'">
          <div class="p-grid field-form-wrapper">
            <div class="p-col-4 field-form-inputs-wrapper" style="margin: auto 0">
              <label> URL Template </label>
            </div>
            <div class="p-col-7 value-input-wrapper">
              <input
                type="text"
                pInputText [(ngModel)]="ncsAutoPopulateField"
                class="text-input"/>
            </div>
              <button pButton
                      class="p-button-success auto-populate-wrapper"
                      label="Auto-Populate"
                      (click)="autoPopulate('New')">
              </button>
          </div>
            <p-card class="p-col" [style]="{'border-style': 'solid', 'border-color':'lightgray', 'border-width': 'thin'}">
              <div class="p-grid field-form-wrapper">
                <form *ngIf="ncsAccountInventoryUrl" [formGroup]="ncsAccountInventoryUrlMainFormGroup" autoTrackUnsavedChanges class="p-col">
                  <div formArrayName="ncsAccountInventoryUrlFormArray">
                    <div *ngFor="let ncsAccountInventoryUrl of this.ncsAccountInventoryUrlFormArray.controls; let i=index">
                      <div *ngIf="this.ncsMakes[i]">
                        <h2>{{this.ncsMakes[i]}}</h2>
                        <br>
                      </div>
                      <div [formGroupName]="i" class="p-grid">
                        <div class="p-col-4 field-form-inputs-wrapper" style="margin: auto 0">
                          <label> {{ncsAccountInventoryUrl.value.make}} {{ncsAccountInventoryUrl.value.model}} </label>
                        </div>
                        <div class="p-col-7 value-input-wrapper">
                          <input
                            id="ncsValue"
                            type="text"
                            pInputText
                            class="text-input"
                            formControlName="url"/>
                        </div>
                      </div>
                    </div>
                    <button pButton
                            class="p-button-success save-button-wrapper"
                            label="Save"
                            (click)="handleAccountInventorySave('New')"
                            [disabled]="this.ncsAccountInventoryUrlMainFormGroup.pristine || this.saveInProgress">
                    </button>
                  </div>
                </form>
              </div>
            </p-card>
        </div>
        <div *ngIf="this.activeBusinessCategoryName === 'Used'">
          <div class="p-grid field-form-wrapper">
            <div class="p-col-4 field-form-inputs-wrapper" style="margin: auto 0">
              <label> URL Template </label>
            </div>
            <div class="p-col-7 value-input-wrapper">
              <input
                type="text"
                pInputText [(ngModel)]="ucsAutoPopulateField"
                class="text-input"/>
            </div>
            <button pButton
                    class="p-button-success auto-populate-wrapper"
                    label="Auto-Populate"
                    (click)="autoPopulate('Used')">
            </button>
          </div>
          <p-card class="p-col" [style]="{'border-style': 'solid', 'border-color':'lightgray', 'border-width': 'thin'}">
            <div class="p-grid field-form-wrapper">
              <form *ngIf="ucsAccountInventoryUrl" [formGroup]="ucsAccountInventoryUrlMainFormGroup" autoTrackUnsavedChanges class="p-col">
                <div formArrayName="ucsAccountInventoryUrlFormArray">
                  <div *ngFor="let ucsAccountInventoryUrl of this.ucsAccountInventoryUrlFormArray.controls; let i=index">
                    <div *ngIf="this.ucsMakes[i]">
                      <h2>{{this.ucsMakes[i]}}</h2>
                      <br>
                    </div>
                    <div [formGroupName]="i" class="p-grid">
                      <div class="p-col-4 field-form-inputs-wrapper" style="margin: auto 0">
                        <label> {{ucsAccountInventoryUrl.value.make}} {{ucsAccountInventoryUrl.value.model}} </label>
                      </div>
                      <div class="p-col-7 value-input-wrapper">
                        <input
                          id="ucsValue"
                          type="text"
                          pInputText
                          class="text-input"
                          formControlName="url"/>
                      </div>
                    </div>
                  </div>
                  <button pButton
                          class="p-button-success save-button-wrapper"
                          label="Save"
                          (click)="handleAccountInventorySave('Used')"
                          [disabled]="this.ucsAccountInventoryUrlMainFormGroup.pristine || this.saveInProgress">
                  </button>
                </div>
              </form>
            </div>
          </p-card>
        </div>
    </p-panel>
  </div>
</p-panel>
<auto-footer></auto-footer>
