import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MediaManagementComponent} from "../media-management.component";
import {MediaManagementModel} from "../../../../../model/media-management.model";

@Component({
  selector: 'auto-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrl: './general-settings.component.scss'
})
export class GeneralSettingsComponent extends MediaManagementComponent implements OnInit {

  @Output() dataEmitter = new EventEmitter<UntypedFormGroup>();
  @Input() settings: MediaManagementModel;

  newMediaImage: any[] = [];
  usedMediaImage: any[] = [];
  mediaFriendlyImageForm: UntypedFormGroup;

  ngOnInit(): void {
    // Initialize new and used media image options for dropdown where 25 is the max number of images
    for (let image = 1; image <= 25; image++) {
      const item = {label: image, value: `${image}`};
      this.newMediaImage.push(item);
      this.usedMediaImage.push(item);
    }

    const lastImage = {label: "Last Image", value: "last"};
    this.newMediaImage.push(lastImage);
    this.usedMediaImage.push(lastImage);
    this.initializeGeneralSettingsForm();
  }

  /**
   * Initializes General Settings Form
   */
   initializeGeneralSettingsForm() {
    this.mediaFriendlyImageForm = this.formBuilder.group({
      newMediaFriendlyImage: new UntypedFormControl(this.settings.newMediaFriendlyImage),
      usedMediaFriendlyImage: new UntypedFormControl(this.settings.usedMediaFriendlyImage)
    })
    this.dataEmitter.emit(this.mediaFriendlyImageForm);
  }
}
