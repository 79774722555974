<div id="footer"
     class="footer">
    <span>Having trouble? Give our support team a call
      <i class="fa fa-phone" aria-hidden="true"></i>
      <a href="tel:1-855-787-9770">855-787-9770</a>
    </span>
  <p>
    {{ currentDate }}, Dealer Teamwork LLC. All Rights Reserved. | Patented
  </p>
</div>
