export class CampaignAutomationConfiguration {
    configurationId: number;
    accountId: number;
    campaign: string;
    standardCampaignName: string;
    campaignType: string;
    updatesEnabled: boolean;
    modifiedUser: string;
    modified: Date;
    campaignLastUpdated: Date;

    constructor(configurationId, accountId, campaign, standardCampaignName, campaignType, updatesEnabled, modifiedUser) {
        this.configurationId = configurationId;
        this.accountId = accountId;
        this.campaign = campaign;
        this.standardCampaignName = standardCampaignName;
        this.campaignType = campaignType;
        this.updatesEnabled = updatesEnabled;
        this.modifiedUser = modifiedUser;
    }
}