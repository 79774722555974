<auto-main-navigation [accountId]="accountId"></auto-main-navigation>

<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">Account Shortcodes</h1>
  </p-header>
  <div class="main-content-wrapper p-grid">
    <div class="p-col-3">
      <p-panelMenu [model]="settingsSideNav">
      </p-panelMenu>
    </div>
    <p-card class="p-col">
      <div class="p-grid field-form-wrapper">
        <form *ngIf="accountShortcodes" [formGroup]="accountShortcodesMainFormGroup" autoTrackUnsavedChanges class="p-col">
          <div class="p-grid">
            <h3 class="p-col-4 field-form-header-wrapper">Shortcode</h3>
            <h3 class="p-col-7 field-form-header-wrapper">Value</h3>
          </div>
          <div formArrayName="accountShortcodesFormArray">
            <div *ngFor="let accountShortcode of this.accountShortcodesFormArray.controls; let i=index">
              <div [formGroupName]="i" class="p-grid">
                <div class="p-col-4 field-form-inputs-wrapper" style="margin: auto 0">
                  <label> {{accountShortcode.value.sourceShortcode.name}} </label>
                  <span class="pi pi-info-circle tooltip-wrapper"
                        pTooltip={{accountShortcode.value.sourceShortcode.description}}
                        tooltipPosition="right"></span>
                </div>
                <div class="p-col-7 value-input-wrapper">
                  <input
                    id="value"
                    type="text"
                    pInputText
                    class="text-input"
                    formControlName="value"/>
                </div>
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-6 field-form-inputs-wrapper">
              <button pButton type="button" label="Save" class="save-button"
                      (click)="handleAccountShortcodesSave()"
                      [disabled]="accountShortcodesMainFormGroup.pristine || saveInProgress"></button>
            </div>
          </div>
        </form>
      </div>
    </p-card>
  </div>
</p-panel>
<auto-footer></auto-footer>
