import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase'
})
export class SentenceCase implements PipeTransform {
  transform(inputString: string, _args?: any): any {
    let sentenceCase = inputString.replace( /([A-Z])/g, " $1" );
    return sentenceCase.charAt(0).toUpperCase() + sentenceCase.slice(1);
  }
}
