export class ImportPageRequestModel {
  pageId: number;
  accountId: number;
  importFromAccountId: number;
  adminUserId: number;
  inventoryUrlArray: string[];

  constructor(pageId, accountId, importFromAccountId) {
    this.pageId = pageId;
    this.accountId = accountId;
    this.importFromAccountId = importFromAccountId;
    this.adminUserId = 0;
    this.inventoryUrlArray = [];
  }
}
