export class InventoryUrlModel {
  inventoryId: number;
  year: string;
  make: string;
  model: string;
  trim: string;
  url: string;
  vlpUrl: string;

  constructor() {
    this.inventoryId = null;
    this.year = null;
    this.trim = null;
    this.url = null;
    this.model = null;
    this.make = null;
    this.vlpUrl = null;
  }

}
