<p-panel>
  <div class="main-content-wrapper">
    <p-card class="p-col"
    [style]="cardStyle">
      <ng-template pTemplate="header" class="card-header">
        <img src="https://mpop-prod-primary.s3.amazonaws.com/DT/dt_logoGRAY.png"
             alt="Dealer Teamwork Logo"
             class="top-bar-logo"/>
        <div class="card-header-wrapper">
          <label class="card-header-text">Password Reset Request</label>
        </div>
      </ng-template>
      <form [formGroup]="usernameForm">
          <div class="tab-panel-input-wrapper">
            <label for="username" class="field-input-label input-label">Username:</label>
            <input
              id="username"
              pInputText
              class="text-input"
              formControlName="username">
          </div>
          <div *ngIf="!submitted">
            <button pButton
                    id="requestResetLinkButton"
                    class="p-button-success reset-button"
                    [disabled]="!usernameForm.valid || usernameForm.controls.username.value.length === 0"
                    label="Send Request"
                    (click)="sendRequestToResetPassword()">
            </button>
          </div>
          <div class="loading-indicator" *ngIf="submitted">
            <p-progressSpinner></p-progressSpinner>
          </div>
      </form>
      <div class="footer">
        <button pButton
                id="backToLogin"
                class="back-to-login-button"
                label="Back to Login"
                (click)="backToLogin()">
        </button>
      </div>
    </p-card>
  </div>
</p-panel>
<auto-footer></auto-footer>
