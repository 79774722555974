<div class="form-group">
  <label for="new-ads-sort">New Ads Sort</label>
  <div class="dropdown-container">
    <p-dropdown
      id="new-ads-sort"
      [options] ="newPageAdSortOptions"
      [style]="{'width':'15rem'}"
      [(ngModel)]="selectedNewPageAdSortOption">
    </p-dropdown>
  </div>
</div>
<div class="form-group">
  <label class="input-switch-label" for="new-ads-sort">Featured Ads First</label>
  <div class="input-switch-container">
    <p-inputSwitch
      id="featured-ads-first"
      [(ngModel)]="featuredAdsFirst"
      class="toggle-switch">
    </p-inputSwitch>
  </div>
</div>
<div class="form-group">
  <label for="used-ads-sort">Used Ads Sort</label>
  <div class="dropdown-container">
    <p-dropdown
      id="used-ads-sort"
      [options] ="usedPageAdSortOptions"
      [style]="{'width':'15rem'}"
      [(ngModel)]="selectedUsedPageAdSortOption">
    </p-dropdown>
  </div>
</div>
<div class="modal-button-wrapper">
  <button type="button" pButton (click)="updatePageConfiguration()" icon="pi pi-save" class="p-button-success modal-button" label="Save"></button>
</div>
