import { Injectable } from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {AccountBusinessCategoryModel} from '../model/account-business-category.model';

@Injectable({
  providedIn: 'root'
})
export class AccountBusinessCategoryService extends AbstractHttpService {
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  getOptions = {
    headers: this.acceptJsonHeaders,
    withCredentials: true
  };

  patchOptions = {
    headers: this.contentTypeJsonHeadersForPatch,
    withCredentials: true
  };

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  getAccountBusinessCategories(accountId: number): Observable<AccountBusinessCategoryModel[]> {
    return this.httpClient.get<AccountBusinessCategoryModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/account_business_categories?account=${accountId}`,
      this.getOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
