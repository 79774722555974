import { Component, OnInit } from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {NavigationService} from '../../../../service/navigation.service';
import {ActivatedRoute} from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {AccountInventoryUrlModel} from '../../../../model/account-inventory-url.model';
import {AccountInventoryUrlService} from '../../../../service/account-inventory-urls.service';
import {BusinessCategoryConfigurationsService} from '../../../../service/business-category-configurations.service';
import {InventoryUrlModel} from '../../../../model/inventory-url.model';

@Component({
  selector: 'auto-account-inventory-urls',
  templateUrl: './account-inventory-urls.component.html',
  styleUrls: ['./account-inventory-urls.component.scss']
})
export class AccountInventoryUrlsComponent implements OnInit {
  accountId: number;
  settingsSideNav: MenuItem[];
  ncsAccountInventoryUrl: InventoryUrlModel[];
  ucsAccountInventoryUrl: InventoryUrlModel[];
  ncsAccountInventoryUrlMainFormGroup: UntypedFormGroup;
  ncsAccountInventoryUrlFormArray: UntypedFormArray;
  ucsAccountInventoryUrlMainFormGroup: UntypedFormGroup;
  ucsAccountInventoryUrlFormArray: UntypedFormArray;
  activeBusinessCategoryId: string;
  activeBusinessCategoryName: string;
  businessCategoryConfigurations = null;
  businessCategoryTabs: MenuItem[] = [];
  componentName = null;
  ncsMakes = {};
  ucsMakes = {};
  saveInProgress: boolean;
  ncsAutoPopulateField: string;
  ucsAutoPopulateField: string;

  constructor(private navigationService: NavigationService,
              private route: ActivatedRoute,
              private titleService: Title,
              private formBuilder: UntypedFormBuilder,
              private accountInventoryUrlService: AccountInventoryUrlService,
              private messageService: MessageService,
              private businessCategoryConfigurationsService: BusinessCategoryConfigurationsService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Inventory URLs');
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.initNavigation();
      this.getAccountInventoryUrls();
      this.getBusinessCategoryConfigurations(this.accountId);
    });
  }

  /**
   * Gets the account inventory urls
   */
  getAccountInventoryUrls() {
    this.accountInventoryUrlService.getAccountInventoryUrls(this.accountId, 'New').subscribe(
      (ncsAccountInventoryModel) => {
        this.ncsAutoPopulateField = ncsAccountInventoryModel.templateUrl;
        this.ncsMakes = this.initMakesSet(ncsAccountInventoryModel, 'New');
        this.initForm('new');
      }
    );
    this.accountInventoryUrlService.getAccountInventoryUrls(this.accountId, 'Used').subscribe(
      (ucsAccountInventoryModel) => {
        this.ucsAutoPopulateField = ucsAccountInventoryModel.templateUrl;
        this.ucsMakes = this.initMakesSet(ucsAccountInventoryModel, 'Used');
        this.initForm('used');
      }
    );
  }

  /**
   * initialize the forms
   * @param newUsed
   */
  initForm(newUsed: string) {
    const existingAccountInventoryUrls = this.buildAccountInventoryUrlFormGroup(newUsed);
    if (newUsed === 'new'){
      this.ncsAccountInventoryUrlFormArray = this.formBuilder.array(existingAccountInventoryUrls);
      this.ncsAccountInventoryUrlMainFormGroup = this.formBuilder.group({
        ncsAccountInventoryUrlFormArray: this.ncsAccountInventoryUrlFormArray
      });
    } else{
      this.ucsAccountInventoryUrlFormArray = this.formBuilder.array(existingAccountInventoryUrls);
      this.ucsAccountInventoryUrlMainFormGroup = this.formBuilder.group({
        ucsAccountInventoryUrlFormArray: this.ucsAccountInventoryUrlFormArray
      });
    }
  }

  /**
   * initializes the Makes Set object
   * @param accountInventoryModel
   * @param newUsed
   */
  initMakesSet(accountInventoryModel, newUsed): any[] {
    const makesSet = new Set<string>();
    const makes = [];
    this.ncsAccountInventoryUrl = accountInventoryModel.inventoryUrls.sort((a, b) => (a.make + a.model > b.make + b.model) ? 1 : -1);
    if (newUsed === 'Used'){
      this.ucsAccountInventoryUrl = this.ncsAccountInventoryUrl;
      this.ncsAccountInventoryUrl = [];
    }
    for (let index = 0; index < accountInventoryModel.inventoryUrls.length; index++) {
      if (!makesSet.has(accountInventoryModel.inventoryUrls[index].make)) {
        makesSet.add(accountInventoryModel.inventoryUrls[index].make);
        makes[index] = accountInventoryModel.inventoryUrls[index].make;
      }
    }
    return makes;
  }

  /**
   * Builds the account inventory url form group
   * @param newUsed
   */
  buildAccountInventoryUrlFormGroup(newUsed: string): UntypedFormGroup[]{
    const accountInventoryUrlForms = [];
    if (newUsed === 'new' && this.ncsAccountInventoryUrl !== undefined) {
        this.ncsAccountInventoryUrl.forEach((ncsAccountInventoryUrl) => {
          accountInventoryUrlForms.push(this.createAccountInventoryUrlForm(ncsAccountInventoryUrl));
        });
    } else if (newUsed === 'used' && this.ucsAccountInventoryUrl !== undefined){
      this.ucsAccountInventoryUrl.forEach((ucsAccountInventoryUrl) => {
        accountInventoryUrlForms.push(this.createAccountInventoryUrlForm(ucsAccountInventoryUrl));
      });
    }
    return accountInventoryUrlForms;
  }

  /**
   * Auto-populates the url fields
   * @param newUsed
   */
  autoPopulate(newUsed: string): void {
    if (newUsed === 'New' && this.ncsAutoPopulateField !== undefined) {
      this.ncsAccountInventoryUrlFormArray.controls.forEach((ncsAccountInventoryUrlFormControl) => {
        const replacedTemplate = this.replaceShortcode(this.ncsAutoPopulateField, ncsAccountInventoryUrlFormControl['controls']);
        ncsAccountInventoryUrlFormControl['controls'].url.setValue(replacedTemplate);
        ncsAccountInventoryUrlFormControl.markAsDirty();
      });
      this.ncsAccountInventoryUrlMainFormGroup = this.formBuilder.group({
        ncsAccountInventoryUrlFormArray: this.ncsAccountInventoryUrlFormArray
      });
      this.ncsAccountInventoryUrlMainFormGroup.markAsDirty();
    }else if (newUsed === 'Used' && this.ucsAutoPopulateField !== undefined) {
      this.ucsAccountInventoryUrlFormArray.controls.forEach((ucsAccountInventoryUrlFormControl) => {
        const replacedTemplate = this.replaceShortcode(this.ucsAutoPopulateField, ucsAccountInventoryUrlFormControl['controls']);
        ucsAccountInventoryUrlFormControl['controls'].url.setValue(replacedTemplate);
        ucsAccountInventoryUrlFormControl.markAsDirty();
      });
      this.ncsAccountInventoryUrlMainFormGroup = this.formBuilder.group({
        ncsAccountInventoryUrlFormArray: this.ncsAccountInventoryUrlFormArray
      });
      this.ucsAccountInventoryUrlMainFormGroup.markAsDirty();
    }
  }

  /**
   * Replaces the shortcodes in the template url
   * @param template
   * @param formControls
   */
  replaceShortcode(template, formControls): string {
    return template.replaceAll('[model]', formControls.model.value.toLowerCase())
                   .replaceAll('[make]', formControls.make.value.toLowerCase())
                   .replaceAll(' ', '+');
  }

  /**
   * Handles the account inventory save
   * @param newUsed
   */
  handleAccountInventorySave(newUsed: string): void {
    this.saveInProgress = true;
    const accountInventoryUrl = new AccountInventoryUrlModel();
    accountInventoryUrl.accountId = this.accountId;
    accountInventoryUrl.newUsed = newUsed.toLowerCase();
    accountInventoryUrl.inventoryUrls = [];
    if (newUsed === 'New') {
      accountInventoryUrl.templateUrl = this.ncsAutoPopulateField;
      this.ncsAccountInventoryUrlFormArray.controls.forEach((ncsAccountInventoryUrlFormControl) => {
        if (ncsAccountInventoryUrlFormControl.dirty) {
          const inventoryUrl = this.mapAccountInventoryUrlModel(ncsAccountInventoryUrlFormControl);
          accountInventoryUrl.inventoryUrls.push(inventoryUrl);
        }
      });
    } else {
      accountInventoryUrl.templateUrl = this.ucsAutoPopulateField;
      this.ucsAccountInventoryUrlFormArray.controls.forEach((ucsAccountInventoryUrlFormControl) => {
        if (ucsAccountInventoryUrlFormControl.dirty) {
          const inventoryUrl = this.mapAccountInventoryUrlModel(ucsAccountInventoryUrlFormControl);
          accountInventoryUrl.inventoryUrls.push(inventoryUrl);
        }
      });
    }
    this.completeSave(accountInventoryUrl, newUsed);
  }

  /**
   * finishes the save of account inventory fields
   * @param accountInventoryUrl
   * @param newUsed
   */
  completeSave(accountInventoryUrl, newUsed): void {
    this.accountInventoryUrlService.updateAccountInventoryUrls(accountInventoryUrl)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
          this.getAccountInventoryUrls();
          if (newUsed === 'New') {
            this.ncsAccountInventoryUrlMainFormGroup.markAsPristine();
          } else {
            this.ucsAccountInventoryUrlMainFormGroup.markAsPristine();
          }
          this.saveInProgress = false;
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save ' + newUsed + ' inventory URLs.',
            life: 5000
          });
        });
  }

  /**
   * reverse transforms the accountInventoryForm to a accountInventoryUrlModel
   * @param accountInventoryUrlFormControl
   */
  mapAccountInventoryUrlModel(accountInventoryUrlFormControl): InventoryUrlModel{
   const inventoryUrl = new InventoryUrlModel();
   inventoryUrl.inventoryId = accountInventoryUrlFormControl['controls'].inventoryId.value;
   inventoryUrl.trim = accountInventoryUrlFormControl['controls'].trim.value;
   inventoryUrl.make = accountInventoryUrlFormControl['controls'].make.value;
   inventoryUrl.model = accountInventoryUrlFormControl['controls'].model.value;
   inventoryUrl.year = accountInventoryUrlFormControl['controls'].year.value;
   inventoryUrl.url = accountInventoryUrlFormControl['controls'].url.value;
   return inventoryUrl;
  }

  /**
   * creates the account inventory url Form
   * @param accountInventoryUrl
   */
  createAccountInventoryUrlForm(accountInventoryUrl): UntypedFormGroup{
    return this.formBuilder.group({
      inventoryId: new UntypedFormControl(accountInventoryUrl.inventoryId),
      trim: new UntypedFormControl(accountInventoryUrl.trim),
      year: new UntypedFormControl(accountInventoryUrl.year),
      make: new UntypedFormControl(accountInventoryUrl.make),
      model: new UntypedFormControl(accountInventoryUrl.model),
      url: new UntypedFormControl(accountInventoryUrl.url)
    });
  }

  /**
   * Get account's business category configurations
   *
   * @param accountId
   */
  getBusinessCategoryConfigurations(accountId) {
    this.businessCategoryConfigurationsService.getBusinessCategoryConfigurations(this.componentName, accountId)
      .subscribe(
        (configurations) => {
          this.businessCategoryConfigurations = configurations;
          const empty: any = {};
          if (this.businessCategoryConfigurations['businessCategoryTypes'] !== empty) {
            this.buildBusinessCategoryTabs(this.businessCategoryConfigurations['businessCategoryTypes']);
          }
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to find business category configurations. Please try again.',
            life: 5000
          });
        });
  }

  /**
   * Builds category tabs
   * @param businessCategories
   */
  buildBusinessCategoryTabs(businessCategories) {
    businessCategories.forEach((businessCategory) => {
      const businessCategoryObject = {
        label: `${businessCategory.name}`,
        id: businessCategory.guid
      };
      this.businessCategoryTabs.push(businessCategoryObject);
    });
    this.setActiveBusinessCategory(this.businessCategoryTabs[0]);
  }

  /**
   * set active business category form
   *
   * @param businessCategory
   */
  setActiveBusinessCategory(businessCategory): void {
    this.activeBusinessCategoryName = businessCategory.label;
    this.activeBusinessCategoryId = businessCategory.id;
  }

  /**
   * Init navigation
   */
  initNavigation() {
    this.navigationService.getMenuItems(this.accountId, 'inventory-settings-view-side-menu.configuration.json')
      .then((navItems) => {
        this.settingsSideNav = navItems;
      });
  }
}
