export class VehicleAdOfferRule {
  ruleId: number;
  accountId: number;
  newUsed: string;
  adDataField: string;
  operator: string;
  value: string;
  action: string;
  actionValue: string;

  constructor(
    ruleId,
    accountId,
    newUsed,
    adDataField,
    operator,
    value,
    action,
    actionValue
  ) {
    this.ruleId = ruleId;
    this.accountId = accountId;
    this.newUsed = newUsed;
    this.adDataField = adDataField;
    this.operator = operator;
    this.value = value;
    this.action = action;
    this.actionValue = actionValue;
  }
}
