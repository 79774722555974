import {Component, OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {NavigationService} from '../../../../service/navigation.service';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {AccountInventoryUrlModel} from '../../../../model/account-inventory-url.model';
import {AccountInventoryUrlService} from '../../../../service/account-inventory-urls.service';
import {InventoryUrlModel} from '../../../../model/inventory-url.model';

@Component({
  selector: 'auto-account-vlp-urls',
  templateUrl: './account-vlp-urls.component.html',
  styleUrl: './account-vlp-urls.component.scss'
})
export class AccountVlpUrlsComponent implements OnInit {
  accountId: number;
  settingsSideNav: MenuItem[];
  ncsAccountInventoryUrl: InventoryUrlModel[];
  accountVlpUrlMainFormGroup: UntypedFormGroup;
  accountVlpUrlFormArray: UntypedFormArray;
  componentName = null;
  makes = {};
  saveInProgress: boolean;
  accountUrl: string;

  constructor(private navigationService: NavigationService,
              private route: ActivatedRoute,
              private titleService: Title,
              private formBuilder: UntypedFormBuilder,
              private accountInventoryUrlService: AccountInventoryUrlService,
              private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Inventory URLs');
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.initNavigation();
      this.getAccountVlpUrls();
    });
  }

  /**
   * Gets the account inventory urls
   */
  getAccountVlpUrls() {
    this.accountInventoryUrlService.getAccountInventoryUrls(this.accountId, 'New').subscribe(
      (ncsAccountInventoryModel) => {
        this.accountUrl = ncsAccountInventoryModel.accountUrl;
        this.makes = this.initMakesSet(ncsAccountInventoryModel);
        this.initForm();
      }
    );
  }

  /**
   * initialize the form
   */
  initForm() {
    const existingAccountVlpUrls = this.buildAccountVlpUrlFormGroup();
    this.accountVlpUrlFormArray = this.formBuilder.array(existingAccountVlpUrls);
    this.accountVlpUrlMainFormGroup = this.formBuilder.group({
      accountVlpUrlFormArray: this.accountVlpUrlFormArray
    });
  }

  /**
   * initializes the Makes Set object
   * @param accountInventoryModel
   */
  initMakesSet(accountInventoryModel): any[] {
    const makesSet = new Set<string>();
    const makes = [];
    this.ncsAccountInventoryUrl = accountInventoryModel.inventoryUrls.sort((a, b) => {
      const first = a.make + a.model;
      const second = b.make + b.model;
      if (first > second) {
        return 1;
      } else if (first < second) {
        return -1;
      } else {
        return 0;
      }
    });

    for (let index = 0; index < accountInventoryModel.inventoryUrls.length; index++) {
      const make = accountInventoryModel.inventoryUrls[index].make;
      if (!makesSet.has(make)) {
        makesSet.add(make);
        makes[index] = make;
      }
    }

    return makes;
  }

  /**
   * Builds the account vlp url form group
   */
  buildAccountVlpUrlFormGroup(): UntypedFormGroup[] {
    const accountInventoryUrlForms = [];
    this.ncsAccountInventoryUrl.forEach((ncsAccountInventoryUrl) => {
      accountInventoryUrlForms.push(this.createAccountInventoryUrlForm(ncsAccountInventoryUrl));
    });
    return accountInventoryUrlForms;
  }

  /**
   * Handles the account inventory save
   * @param newUsed
   */
  handleAccountInventorySave(newUsed: string): void {
    this.saveInProgress = true;
    const accountInventoryUrl = new AccountInventoryUrlModel();
    accountInventoryUrl.accountId = this.accountId;
    accountInventoryUrl.newUsed = newUsed.toLowerCase();
    accountInventoryUrl.inventoryUrls = [];
    this.accountVlpUrlFormArray.controls.forEach((accountVlpUrlFormControl) => {
      if (accountVlpUrlFormControl.dirty) {
        const inventoryUrl = this.mapAccountInventoryUrlModel(accountVlpUrlFormControl);
        accountInventoryUrl.inventoryUrls.push(inventoryUrl);
      }
    });
    this.completeSave(accountInventoryUrl);
  }

  /**
   * finishes the save of account inventory fields
   * @param accountInventoryUrl
   */
  completeSave(accountInventoryUrl): void {
    this.accountInventoryUrlService.updateAccountVlpUrls(accountInventoryUrl)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
          this.getAccountVlpUrls();
          this.accountVlpUrlMainFormGroup.markAsPristine();
          this.saveInProgress = false;
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save VLP URLs.',
            life: 5000
          });
        });
  }

  /**
   * reverse transforms the accountInventoryForm to a accountInventoryUrlModel
   * @param accountInventoryUrlFormControl
   */
  mapAccountInventoryUrlModel(accountInventoryUrlFormControl): InventoryUrlModel {
    const inventoryUrl = new InventoryUrlModel();
    inventoryUrl.inventoryId = accountInventoryUrlFormControl['controls'].inventoryId.value;
    inventoryUrl.trim = accountInventoryUrlFormControl['controls'].trim.value;
    inventoryUrl.make = accountInventoryUrlFormControl['controls'].make.value;
    inventoryUrl.model = accountInventoryUrlFormControl['controls'].model.value;
    inventoryUrl.year = accountInventoryUrlFormControl['controls'].year.value;
    inventoryUrl.vlpUrl = accountInventoryUrlFormControl['controls'].vlpUrl.value;
    return inventoryUrl;
  }

  /**
   * creates the account inventory url Form
   * @param accountInventoryUrl
   */
  createAccountInventoryUrlForm(accountInventoryUrl): UntypedFormGroup {
    return this.formBuilder.group({
      inventoryId: new UntypedFormControl(accountInventoryUrl.inventoryId),
      trim: new UntypedFormControl(accountInventoryUrl.trim),
      year: new UntypedFormControl(accountInventoryUrl.year),
      make: new UntypedFormControl(accountInventoryUrl.make),
      model: new UntypedFormControl(accountInventoryUrl.model),
      vlpUrl: new UntypedFormControl(accountInventoryUrl.vlpUrl)
    });
  }

  /**
   * Init navigation
   */
  initNavigation() {
    this.navigationService.getMenuItems(this.accountId, 'inventory-settings-view-side-menu.configuration.json')
      .then((navItems) => {
        this.settingsSideNav = navItems;
      });
  }
}
