<h3>Account Creation</h3>
<div class="tab-panel-input-wrapper">
  <p-dropdown *ngIf="subMccOptions[0].items.length > 0"
              class="dropdown-container"
              [options]="subMccOptions"
              [(ngModel)]="selectedOemProgram"
              [group]="true"
              [style]="{'min-width': '250px'}"
              optionLabel="label"
              pTooltip="Select the manager account where the sub-account will be created.">
  </p-dropdown>
  <button pButton
          [disabled]="adwordsAccountId !== '' || creationInProgress"
          [pTooltip]="showCreateAccountToolTip()"
          class="p-button-success"
          (click)="createGoogleAdsAccount()"
          label="Create Account">
  </button>
</div>
<p-progressSpinner
  *ngIf="creationInProgress"
  [style]="{ width: '50px', height: '50px' }"
  strokeWidth="4">
</p-progressSpinner>
<div>
  <auto-automation-configuration-settings
    [accountId]="accountId"
    [userId]="userId"
  ></auto-automation-configuration-settings>
</div>
<div>
  <auto-automation-account-settings
    [accountId]="accountId"
    [userId]="userId"
  ></auto-automation-account-settings>
</div>
