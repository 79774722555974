<div class="p-grid vertical-container p-align-center p-dir-col">
  <div class="loading-indicator" *ngIf="submitted">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="p-col p-col-align-center">
    <div class="box box-stretched">
      <div class="logo">
        <img src="https://s3.amazonaws.com/mpop-prod-primary/DT/dt_logoWhite.png" alt="dealer teamwork logo">
      </div>
      <p-card class="p-align-center login">
        <div class="p-grid p-dir-col">
          <p-messages [closable]="false"></p-messages>
          <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
            <div class="p-col">
              <input formControlName="username" type="text" pInputText placeholder="Username"/>
            </div>
            <div class="p-col">
              <input formControlName="password" type="password" pInputText placeholder="Password"/>
            </div>

            <div class="p-col">
              <button [disabled]="loginForm.pristine || loginForm.invalid || submitted" pButton type="submit" label="Sign In"
                      (click)="loginUser()"></button>
            </div>
          </form>
          <div class="p-col p-col-align-center">
            <a href="{{forgotLink}}">Forgot your password?</a>
          </div>
        </div>
      </p-card>
      <div class="footer">
        <p>Having trouble? Give our support team a call <i aria-hidden="true"></i> <a href="tel:1-855-787-9770">855-787-9770</a>
        </p>
        <p>© {{copyrightYear}}, Dealer Teamwork LLC. All Rights Reserved. | Patented</p>
      </div>
    </div>
  </div>
</div>
