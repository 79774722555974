import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './security/login/login.component';
import {AdTypesComponent} from './components/views/settings/ad-types/ad-types.component';
import {ManageFiltersComponent} from './components/views/ad-filters/manage-filters/manage-filters.component';
import {MappingRulesComponent} from './components/views/inventory/mapping-rules/mapping-rules.component';
import {PagesListComponent} from './components/views/pages/pages-list/pages-list.component';
import { GoogleAdsSettingsComponent } from './components/views/settings/google-ads-settings/google-ads-settings.component';
import {ManagePasswordComponent} from './components/views/password-settings/manage-password/manage-password.component';
import {SourceShortcodesComponent} from './components/views/settings/source-shortcodes/source-shortcodes.component';
import {AccountShortcodesComponent} from './components/views/settings/account-shortcodes/account-shortcodes.component';
import {AccountInventoryUrlsComponent} from './components/views/inventory/account-inventory-urls/account-inventory-urls.component';
import {AccountVlpUrlsComponent} from './components/views/inventory/account-vlp-urls/account-vlp-urls.component';
import {AuthGuard} from './auth-guard.guard';
import {environment} from '../environments/environment';
import {ForgotPasswordComponent} from './components/views/password-settings/forgot-password/forgot-password.component';
import {EventPageTemplateComponent} from './components/views/templates/event-page-template/event-page-template.component';
import {ResetPasswordComponent} from './components/views/password-settings/reset-password/reset-password.component';
import {AccountDashboardComponent} from "./components/views/dashboard/account-dashboard/account-dashboard.component";
import {FeatureFlagGuard} from "./feature-flag.guard";
import {NewAdTypesComponent} from "./components/views/settings/new-ad-types/new-ad-types.component";
import {MpopUsersComponent} from "./components/views/mpop-side-nav/users/mpop-users/mpop-users.component";
import {MediaManagementComponent} from "./components/views/settings/media-management/media-management.component";

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: 'settings/ad-types/:accountId',
    component: AdTypesComponent,
    canActivate: [AuthGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      title: 'Ad Types'
    }
  },
  {
    path: 'settings/new-ad-types/:accountId',
    component: NewAdTypesComponent,
    canActivate: [AuthGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      title: 'New Ad Types'
    }
  },
  {
    path: 'settings/media-management/:accountId',
    component: MediaManagementComponent,
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      featureFlag: {
        featureName: 'mediaManagement',
        redirectUrl: '/mpop/:accountId#/media-management',
      },
      title: 'Media Management'
    }
  },
  {
    path: 'ad-filters/manage-filters/:accountId',
    component: ManageFiltersComponent,
    canActivate: [AuthGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      title: 'Manage Filters'
    }
  },
  {
    path: 'inventory/mapping-rules/:accountId',
    component: MappingRulesComponent,
    canActivate: [AuthGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      title: 'Mapping Rules'
    }
  },
  {
    path: 'inventory/account-inventory-urls/:accountId',
    component: AccountInventoryUrlsComponent,
    canActivate: [AuthGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      title: 'Account Inventory URLs'
    }
  },
  {
    path: 'inventory/account-vlp-urls/:accountId',
    component: AccountVlpUrlsComponent,
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      featureFlag: {
        featureName: 'vlpUrls',
        redirectUrl: '/settings/account/edit-account-vlp-urls/:accountId',
      },
      title: 'Account New VLP URLs'
    }
  },
  {
    path: 'pages/:accountId',
    component: PagesListComponent,
    canActivate: [AuthGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      userAccessKeys: 'pages',
      title: 'Pages'
    }
  },
  {
    path: 'google-ads-settings/:accountId',
    component: GoogleAdsSettingsComponent,
    canActivate: [AuthGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      title: 'Google Ads Settings'
    }
  },
  {
    path: 'manage-password',
    component: ManagePasswordComponent,
    data: {
      title: 'Manage Password'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password'
    }
  },
  {
    path: 'event-page',
    component: EventPageTemplateComponent,
    data: {
      title: 'Event Page Template'
    }
  },
  {
    path: 'global-shortcodes',
    component: SourceShortcodesComponent,
    canActivate: [AuthGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      title: 'Global Shortcodes'
    }
  },
  {
    path: 'mpop-users',
    component: MpopUsersComponent,
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      featureFlag: {
        featureName: 'mpopUserAccounts',
        redirectUrl: '/users',
      },
      title: 'Mpop Users'
    }
  },
  {
    path: 'account-shortcodes/:accountId',
    component: AccountShortcodesComponent,
    canActivate: [AuthGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      title: 'Account Shortcodes'
    }
  },
  {
    path: 'dashboard',
    component: AccountDashboardComponent,
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: {
      externalErrorPage: `https://${environment.autoDomain}:${environment.autoPort}/_error/401`,
      userAccessKeys: ['basic'],
      featureFlag: {
        featureName: 'accountDashboard',
        redirectUrl: '/mpop/#/dashboard',
      },
      title: 'Account Dashboard'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
