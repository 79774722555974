import {Component, Input, OnInit} from "@angular/core";
import {DashboardAccountInterface} from "../../../../../model/dashboard-account.interface";
import {AccountStatusInterface} from "./account-status.interface";
import {PermissionsInterface} from "../../../../../model/permissions.interface";

@Component({
  selector: "auto-account-card",
  templateUrl: "./account-card.component.html",
  styleUrls: ["./account-card.component.scss"]
})
export class AccountCardComponent implements OnInit {
  @Input() account: DashboardAccountInterface;
  @Input() permissions: PermissionsInterface;

  /**
   * Maps status color to status names
   * @private
   */
  private statusColorMap: AccountStatusInterface[] = [
    {
      statusNames: ["not_active", "paused"],
      color: "#D32F2F"
    },
    {
      statusNames: ["onboarding"],
      color: "#FBC02D"
    },
    {
      statusNames: ["live"],
      color: "#388E3C"
    },
    {
      statusNames: ["ncda"],
      color: "#1976D2"
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Gets the destination url for the card title. If the account has the
   * dashboard report enabled, the reporting dashboard url will be returned.
   * In all other cases, the new specials page url will be returned.
   */
  getDestinationUrl()
  {
    const accountId: number = this.account.accountId;
    let destinationUrl: string = `/new-specials/${accountId}`;

    if (this.account.hasReportingDashboard)
    {
      destinationUrl = `/mpop/${accountId}#/reporting-dashboard/`;
    }

    return destinationUrl;
  }

  /**
   * Determines the color associated with a status. If there is no match,
   * the value provided by the account data is used.
   */
  getStatusColor(): string {
    const accountStatus: string = this.account.status;
    let statusColor: string = this.account.statusColor;

    for (let colorMap of this.statusColorMap)
    {
      if (colorMap.statusNames.includes(accountStatus))
      {
        statusColor = colorMap.color;
      }
    }

    return statusColor;
  }

}
