<div class="nav-container">
  <p-toolbar styleClass="top-bar">
    <div class="p-toolbar-group-left">
      <i class="pi pi-bars toggle-side-nav"
         (click)="displaySidebar = !displaySidebar">
      </i>
      <a [routerLink]="mpopDashboardUrl">
      <img src="https://mpop-prod-primary.s3.amazonaws.com/DT/dt_logoWhite.png"
           alt="Dealer Teamwork Logo"
           class="top-bar-logo"/>
      </a>
    </div>
    <img class="racing-stripes p-toolbar-group-right"
         src="https://mpop-prod-primary.s3.amazonaws.com/DT/dt-racing-stripes.png"
         alt="Dealer Teamwork racing stripes"/>
  </p-toolbar>

  <div class="top-menubar-wrapper" *ngIf="accountId">
    <p class="account-name-wrapper">
    {{accountName}}
    </p>
    <p-menubar styleClass="top-nav"
               *ngIf="isLoaded"
               [model]="topNavItems"
               [autoDisplay]="false">
    </p-menubar>
  </div>
</div>

<p-sidebar [(visible)]="displaySidebar"
           [modal]="false"
           [showCloseIcon]="false"
           styleClass="side-nav-sidebar"
           position="left">
  <p-panelMenu [model]="sideNavItems"
               [multiple]="false">
  </p-panelMenu>
</p-sidebar>
