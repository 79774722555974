import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoginModel} from '../model/login_model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private headers = new HttpHeaders()
    .set("Content-Type", "application/json");

  constructor(private httpClient: HttpClient) {
  }

  public async doUserLogin(loginModel: LoginModel) {
    const headers = this.headers;
    const json = JSON.stringify(loginModel);
    const body = JSON.parse(json);
    let result = {};
    try {
      result = await this.httpClient.post(`//${environment.autoDomain}:${environment.autoPort}/api/login`, body,
        {
          headers
        }
      ).toPromise().then(result => result);
    }
    catch (errorResponse) {
      console.log(errorResponse);
      result = errorResponse
      if (errorResponse.hasOwnProperty('error')) {
        result = errorResponse.error;
      }
    }
    return result;
  }

}
