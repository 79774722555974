<div [ngSwitch]="true"
     [formGroup]="form"
     class="p-field p-grid">
  <label *ngSwitchCase="formItem.controlType !== 'subheader'"
         [attr.for]="formItem.key + '_' + formName"
         class="p-col-12 p-md-6">
    {{formItem.label}}
    <i class="pi pi-info-circle form-item-tooltip" *ngIf="formItem.tooltip !== ''" [pTooltip]="formItem.tooltip"></i>
  </label>

  <h5 *ngSwitchCase="formItem.controlType === 'subheader'"
      [id]="formItem.key + '_' + formName"
      style="font-size: 20px; margin: 0"
      class="p-col-12">
    {{formItem.label}}
  </h5>

  <div  class="p-col-12 p-md-6">

    <input *ngSwitchCase="formItem.controlType === 'text'"
           [type]="formItem.type"
           [id]="formItem.key + '_' + formName"
           [formControlName]="formItem.key"
           pInputText>

    <p-dropdown *ngSwitchCase="formItem.controlType === 'dropdown'"
                [id]="formItem.key + '_' + formName"
                [formControlName]="formItem.key"
                [options]="formItem.options">
    </p-dropdown>

    <p-inputSwitch  *ngSwitchCase="formItem.controlType === 'toggleSwitch'"
                    [id]="formItem.key + '_' + formName"
                    [formControlName]="formItem.key">
    </p-inputSwitch>

    <input *ngSwitchCase="formItem.controlType === 'decimal'"
           type="number"
           [id]="formItem.key + '_' + formName"
           [formControlName]="formItem.key"
           (change)="setIsDecimalValue()"
           pInputText>

  </div>

  <div class="errorMessage" *ngIf="!isValid">{{formItem.label}} is required</div>
  <span *ngIf="!isDecimalValue" class="p-invalid p-col-12 p-md-6">{{formItem.label}} must be a decimal value less than 1.</span>
</div>
