import {InventoryUrlModel} from './inventory-url.model';

export class AccountInventoryUrlModel {
  accountId: number;
  newUsed: string;
  inventoryUrls: InventoryUrlModel[];
  templateUrl: string;
  accountUrl: string;

  constructor() {
    this.accountId = null;
    this.newUsed = null;
    this.inventoryUrls = null;
    this.templateUrl = null;
    this.accountUrl = null;
  }
}
