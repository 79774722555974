<router-outlet></router-outlet>
<p-toast position="top-right"></p-toast>
<p-dialog header="Session Timeout" [(visible)]="displayTimeoutModal" modal="true" [draggable]="false" [resizable]="false" [closable]="false" [closeOnEscape]="false">
  <div class="countdown-text-wrapper">
  {{idleState}}
  </div>
  <div class="timeout-button-wrapper">
    <button type="button" pButton (click)="closeTimeoutModal()" class="p-button-success modal-button" label="I'm here!"></button>
  </div>
</p-dialog>
