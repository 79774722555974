export enum MediaManagementEnumerator {

  MicrosoftCatalogUrl = 'https://admin.dealerteamwork.com/includes/ajax/mpop-api/offers/[account_folder]/microsoft-ads',

  GoogleVlaFeedCatalogUrl = 'https://mpop-[environment]-primary.s3.amazonaws.com/google-vla-feed/[account_folder]/[account_folder].txt',

  FacebookAdsCatalogUrl = 'https://admin.dealerteamwork.com/includes/ajax/mpop-api/offers/[account_folder]/facebook-ads',

  FacebookDefaultLabelPattern = "^((?!\\[\\w*.*?\\]).)*$"
}
