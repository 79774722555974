<form [formGroup]="facebookSettingsForm" autoTrackUnsavedChanges>
  <div class="tab-panel-content-wrapper">
    <div class="p-grid p-col-12 setting-content">
      <label class="field-input-label tab-input-label"><b>Facebook Page ID</b></label>
      <input type="text" pInputText class="text-input" formControlName="facebookPageId"/>
    </div>
    <div class="p-grid p-col-12 setting-content">
      <label class="field-input-label tab-input-label"><b>Facebook Dealer Name</b></label>
      <input type="text" pInputText class="text-input" formControlName="facebookDealerName"/>
    </div>
    <p-divider></p-divider>
    <div class="p-grid p-col-12 setting-content">
      <label class="field-switch-label"><b>Meta Ads</b></label>
      <p-inputSwitch formControlName="facebookAdsEnabled"></p-inputSwitch>
    </div>
    <div [hidden]="!facebookSettingsForm.get('facebookAdsEnabled').value" class="facebook-ads">
      <div class="p-grid setting-content">
        <label class="field-input-label tab-input-label"><b>Meta Ads Catalog URL</b></label>
        <input
          type="text"
          pInputText
          class="text-input"
          readonly
          formControlName="facebookAdsCatalogUrl"/>
        <i
          class="pi pi-fw pi-copy"
          (click)="copyToClipboardService.copyToClipboard(this.facebookSettingsForm.controls.facebookAdsCatalogUrl.value)">
        </i>
      </div>
      <div class="tab-panel-input-wrapper final-url-dropdown">
        <label class="label-field field-switch-label">Advertise All Vehicles in Inventory
          <span class="pi pi-info-circle"
                pTooltip="When enabled, all vehicles in inventory will be exported in the Facebook Ads catalog.
                 When disabled, only active MPOP Ads will be exported in the catalog."
                tooltipPosition="top">
        </span>
        </label>
        <p-inputSwitch formControlName="advertiseAllVehicles"></p-inputSwitch>
      </div>
      <div class="p-grid setting-content">
        <label class="field-input-label tab-input-label"><b>Meta Dataset ID</b></label>
        <input type="text" pInputText class="text-input" formControlName="facebookPixelId"/>
      </div>
      <div class="p-grid setting-content">
        <label class="field-input-label tab-input-label"><b>Meta Ads Account ID</b></label>
        <input type="text" pInputText class="text-input" formControlName="facebookAdsAccountId"/>
      </div>
      <p-divider></p-divider>
      <div class="p-grid p-col-12 setting-content">
        <div class="custom-field-container">
          <label><b>Ad Label Configuration </b>
            <span class="pi pi-info-circle"
                  pTooltip="Custom Labels allow us to create dynamic ad content in Facebook Ads using shortcodes for offer details.
                   If data is missing, it will use the Fallback Label, and if that’s also empty, the Default Label will be used."
                  tooltipPosition="top">
            </span>
          </label>
        </div>
      </div>
      <div class="p-grid p-col-12 setting-content">
        <div class="custom-field-container">
          <label><b>Default Label</b></label>
          <input type="text" pInputText class="custom-text-field" formControlName="facebookAdsDefaultLabel"/>
          <small *ngIf=facebookSettingsForm.controls.facebookAdsDefaultLabel.invalid class="p-d-block p-error block">
            <b>Invalid Input:</b> this field cannot be empty and/or short-codes are not allowed.
          </small>
        </div>
        <div class="custom-field-container"></div>
      </div>
      <div class="p-grid p-col-12 setting-content">
        <div class="custom-field-container">
          <label><b>Custom Label 0 </b>
            <span class="pi pi-info-circle"
                  pTooltip="Intended for New Vehicle Advertising."
                  tooltipPosition="top">
            </span>
          </label>
          <input type="text" pInputText class="custom-text-field" formControlName="facebookAdsCustomLabel0"/>
        </div>
        <div class="custom-field-container">
          <label><b>Custom Fallback 0</b></label>
          <input type="text" pInputText class="custom-text-field" formControlName="facebookAdsFallbackLabel0"/>
        </div>
      </div>

      <div class="p-grid p-col-12 setting-content">
        <div class="custom-field-container">
          <label><b>Custom Label 1 </b>
            <span class="pi pi-info-circle"
                  pTooltip="Intended for Used Vehicle Advertising."
                  tooltipPosition="top">
            </span>
          </label>
          <input type="text" pInputText class="custom-text-field" formControlName="facebookAdsCustomLabel1"/>
        </div>
        <div class="custom-field-container">
          <label><b>Custom Fallback 1</b></label>
          <input type="text" pInputText class="custom-text-field" formControlName="facebookAdsFallbackLabel1"/>
        </div>
      </div>
      <div class="p-grid p-col-12 setting-content">
        <div class="custom-field-container">
          <label><b>Custom Label 2 </b>
            <span class="pi pi-info-circle"
                  pTooltip="Available for additional customized ad use cases."
                  tooltipPosition="top">
            </span>
          </label>
          <input type="text" pInputText class="custom-text-field" formControlName="facebookAdsCustomLabel2"/>
        </div>
        <div class="custom-field-container">
          <label><b>Custom Fallback 2</b></label>
          <input type="text" pInputText class="custom-text-field" formControlName="facebookAdsFallbackLabel2"/>
        </div>
      </div>
    </div>
  </div>
</form>

