<auto-main-navigation [accountId]="accountId"></auto-main-navigation>
<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">MPOP Settings</h1>
  </p-header>
  <div class="main-content-wrapper p-grid">
    <div class="p-col-3">
      <p-panelMenu [model]="settingsSideNav">
      </p-panelMenu>
    </div>
    <p-panel class="p-col ad-types-wrapper">
      <p-header class="ad-types-header-wrapper">
        <h2 class="ad-types-header-text">Ad Types</h2>
      </p-header>
      <p-panel styleClass="ad-types-form-panel">
        <p-header class="ad-types-header-wrapper form-panel-header">
          <h3 class="ad-types-header-text">Vehicle Ads</h3>
          <div class="ad-types-header-input">
            <div class="ad-types-header-input">
              <p-inputSwitch [ngModel]="accountAdTypes !== null && accountAdTypes.vehicleAdType.enabled" (ngModelChange)="accountAdTypes !== null && (accountAdTypes.vehicleAdType.enabled = $event)"
                             (onChange)="handleVehicleAdsEnabledToggle()">
              </p-inputSwitch>
            </div>
          </div>
        </p-header>
        <!--        TODO: this should use an async pipe instead of null to determine when vehicle ads is enabled (same above for switch)-->
        <div class="form-body-wrapper" *ngIf="accountAdTypes !== null && accountAdTypes.vehicleAdType.enabled">
          <form [formGroup]="masterFormGroup" autoTrackUnsavedChanges
                *ngIf="businessCategoryConfigurations !== null">
            <div class="ad-types-save-button">
              <button pButton
                      id="vehicleAdSaveButton"
                      class="p-button-success"
                      label="Save"
                      (click)="handleVehicleAdsSave()"
                      [disabled]="!masterFormGroup.valid">
              </button>
            </div>
            <p-tabView>
              <p-tabPanel header="New">
                <p-accordion [multiple]="true">
                  <p-accordionTab header="Incentives">
                    <form [formGroup]="masterFormGroup.controls['New']['controls']['incentiveConfiguration']">
                      <div class="p-field p-grid">
                        <label for="useIncentiveServiceNew" class="p-col-12 p-md-6 ng-star-inserted">Use Incentive
                          Service
                          <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                             pTooltip="When enabled, the best available incentives are pulled from a service during New ad creation."
                             tooltipPosition="right"></i>
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="useIncentiveServiceNew"
                          formControlName="useIncentiveService"
                          (onChange)="setIncentiveControlsStatus()">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="enhancedIncentives" class="p-col-12 p-md-6 ng-star-inserted sub-label">Enhanced Incentives
                          <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                             pTooltip="When enabled, incentives and MPOP Vehicle Ad sale end dates will be automatically kept up-to-date. This feature requires that the Use Incentive Service feature is enabled."
                             tooltipPosition="right"></i>
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="enhancedIncentives"
                          formControlName="enhancedIncentives"
                          (onChange)="handleEnhancedIncentivesToggle()">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="includeSelectInventoryIncentivesNew" class="p-col-12 p-md-6 ng-star-inserted sub-label">Use VIN Specific Incentives
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="useVinSpecificIncentivesNew"
                          formControlName="useVinSpecificIncentives">
                        </p-inputSwitch>
                      </div>
                    </form>
                    <form [formGroup]="masterFormGroup.controls['New']['controls']['generalConfiguration']">
                      <div class="p-field p-grid">
                        <label for="includeSelectInventoryIncentivesNew" class="p-col-12 p-md-6 ng-star-inserted sub-label">Include
                          Select Inventory Incentives
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="includeSelectInventoryIncentivesNew"
                          formControlName="includeSelectInventoryIncentives">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="rebateDisclosureFormatNew" class="p-col-12 p-md-6 ng-star-inserted">Rebate
                          Disclosure
                          Format
                        </label>
                        <p-dropdown
                          class="p-col-12 p-md-6"
                          id="rebateDisclosureFormatNew"
                          [options]="rebateDisclosureFormatOptions"
                          optionLabel="label"
                          optionValue="value"
                          formControlName="rebateDisclosureFormat">
                        </p-dropdown>
                      </div>
                    </form>
                  </p-accordionTab>
                  <auto-offer-rules
                    [accountId]="accountId"
                    [condition]="'New'"
                    (formDirty)="handleOfferRulesFormDirty($event)"
                    (deleteRules)="handleDeleteOfferRules()">
                  </auto-offer-rules>
                  <p-accordionTab header="Fees">
                    <div class="p-d-flex">
                      <form
                        [formGroup]="masterFormGroup.controls['New']['controls']['feesConfiguration']">
                        <table class="p-datatable">
                          <thead class="p-datatable-thead">
                          <tr class="p-datatable-header">
                            <th class="p-mr-2">
                              Fee
                            </th>
                            <th class="p-mr-2">
                              Value
                            </th>
                            <th class="p-mr-2 fee-table-column">
                              Lease
                            </th>
                            <th class="p-mr-2 fee-table-column">
                              Finance
                            </th>
                          </tr>
                          </thead>
                          <tbody class="p-datatable-tbody">
                          <tr formGroupName="documentFee" class="p-mb-2">
                            <td class="p-mr-2">
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['documentFee']['type'] | sentenceCase}}
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['documentFee']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionLabel="label"
                                optionValue="value"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionLabel="label"
                                optionValue="value"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          <tr formGroupName="licenseFee" class="p-mb-2">
                            <td class="p-mr-2">
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['licenseFee']['type'] | sentenceCase}}
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['licenseFee']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          <tr formGroupName="titleFee" class="p-mb-2">
                            <td class="p-mr-2">
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['titleFee']['type'] | sentenceCase}}
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['titleFee']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          <tr formGroupName="acquisitionFee" class="p-mb-2">
                            <td class="p-mr-2">
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['acquisitionFee']['type'] | sentenceCase}}
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['acquisitionFee']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          <tr formGroupName="taxes" class="p-mb-2">
                            <td class="p-mr-2">
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['taxes']['type'] | sentenceCase}}
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['taxes']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          <tr formGroupName="dispositionFee" class="p-mb-2">
                            <td class="p-mr-2">
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['dispositionFee']['type'] | sentenceCase}}
                              {{newBusinessCategoryType['configuration']['feesConfiguration']['dispositionFee']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <h4 class="form-section-sub-header">Purchase Fee</h4>
                        <div formGroupName="customFee">
                          <div class="p-field p-grid">
                            <label for="custom-fee-enabled-new" class="p-col-12 p-md-6 ng-star-inserted">Custom Purchase
                              Fee
                              <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                                 pTooltip="When enabled, this custom purchase fee will be applied in the math box for all new ads. The mathbox will reflect a final price including applicable purchase rebates and this fee."
                                 tooltipPosition="right"></i>
                            </label>
                            <p-inputSwitch
                              class="p-col-12 p-md-6"
                              formControlName="enabled"
                              id="custom-fee-enabled-new"
                            ></p-inputSwitch>
                          </div>
                          <div class="p-grid purchase-fee-grid">
                            <div class="p-col-6">
                              <label for="display-name-new">Display Name</label>
                            </div>
                            <div class="p-col-6">
                              <input type="text" pInputText formControlName="displayName" id="display-name-new">
                            </div>
                            <div class="p-col-6">
                              <label for="value-new">Value ($)</label>
                            </div>
                            <div class="p-col-6">
                              <input type="text" pInputText formControlName="value" id="value-new">
                            </div>
                            <!-- TODO - Uncomment this in LC-10819 -->
<!--                            <div class="p-col-6">-->
<!--                              <label for="pricing-option-new">Pricing Option-->
<!--                                <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"-->
<!--                                   pTooltip="'Add Purchase Fee to Sale Price': The purchase fee will be added to the sale price in the ad and reflected in the final price. The fee will still be itemized separately.-->
<!--                                             'Purchase Fee is Included in Sale Price': The purchase fee is included in the sale price, but the fee will still be itemized separately."-->
<!--                                   tooltipPosition="right"></i></label>-->
<!--                            </div>-->
<!--                            <div class="p-col-6">-->
<!--                              <p-dropdown-->
<!--                                [options]="pricingOptions"-->
<!--                                optionValue="value"-->
<!--                                optionLabel="label"-->
<!--                                formControlName="pricingOption"-->
<!--                                id="pricing-option-new">-->
<!--                              </p-dropdown>-->
<!--                            </div>-->
                            <div class="p-col-6">
                              <label for="final-price-text-new">Final Price Text</label>
                            </div>
                            <div class="p-col-6">
                              <input type="text" pInputText formControlName="finalPriceText" id="final-price-text-new">
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </p-accordionTab>
                  <p-accordionTab header="Purchase & Savings Offers">
                    <form [formGroup]="masterFormGroup.controls['New']['controls']['purchaseConfiguration']">
                      <div class="p-field p-grid">
                        <label for="showPurchaseOfferNew" class="p-col-12 p-md-6 ng-star-inserted">Show Purchase Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showPurchaseOfferNew"
                          formControlName="showPurchaseOffer">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="showMathBoxNew" class="p-col-12 p-md-6 ng-star-inserted">Show Math Box
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showMathBoxNew"
                          formControlName="showMathBox">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="showSavingsOfferNew" class="p-col-12 p-md-6 ng-star-inserted">Show Savings Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showSavingsOfferNew"
                          formControlName="showSavingsOffer">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="showPreRebateSubtotalNew" class="p-col-12 p-md-6 ng-star-inserted">Show Pre-Rebate
                          Subtotal
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showPreRebateSubtotalNew"
                          formControlName="showPreRebateSubtotal">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="showLineItemSuperscriptsNew" class="p-col-12 p-md-6 ng-star-inserted">Show Line Item
                          Superscripts
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showLineItemSuperscriptsNew"
                          formControlName="showLineItemSuperscripts">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="includePurchaseDealerIncentivesNew" class="p-col-12 p-md-6 ng-star-inserted">Include
                          Dealer Incentives
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="includePurchaseDealerIncentivesNew"
                          formControlName="includePurchaseDealerIncentives">
                        </p-inputSwitch>
                      </div>
                    </form>
                  </p-accordionTab>
                  <p-accordionTab header="Lease Offer">
                    <form [formGroup]="masterFormGroup.controls['New']['controls']['leaseConfiguration']">
                      <div class="p-field p-grid">
                        <label for="showLeaseOfferNew" class="p-col-12 p-md-6 ng-star-inserted">Show Lease Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showLeaseOfferNew"
                          formControlName="showLeaseOffer">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="showAlternateLeaseOfferNew" class="p-col-12 p-md-6 ng-star-inserted">Show Alternate
                          Lease Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showAlternateLeaseOfferNew"
                          formControlName="showAlternateLeaseOffer">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="leaseSellingPriceTypeNew" class="p-col-12 p-md-6 ng-star-inserted">Selling Price
                        </label>
                        <p-dropdown
                          class="p-col-12 p-md-6"
                          id="leaseSellingPriceTypeNew"
                          [options]="leaseSellingPriceOptions"
                          optionLabel="label"
                          optionValue="value"
                          formControlName="leaseSellingPriceType">
                        </p-dropdown>
                      </div>
                      <div class="p-field p-grid">
                        <div class="p-col-6">
                          <div class="p-field p-grid purchase-fee-grid">
                            <p-dropdown
                              class="p-col-12 p-md-6"
                              id="calculateLeaseFrom"
                              [options]="calculateLeaseFromOptions"
                              optionLabel="label"
                              optionValue="value"
                              formControlName="calculateLeaseFrom">
                            </p-dropdown>
                            <div class="p-col-6">
                              <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                                 pTooltip="Adjusting this setting will change the lease payment calculation method.

                             A lease 'down payment' is a portion of the vehicle's cost paid upfront and reduces monthly lease payments. 'Due at signing' is the total amount due at the time of signing and includes the down payment, first month's payment, plus any fees required to start the lease."
                                 tooltipPosition="right"></i>
                            </div>
                          </div>
                        </div>
                        <div class="p-col-2">
                          <input pInputText
                                 id="leaseDownDueAmount"
                                 formControlName="leaseDownDueAmount"
                                 type="number"
                                 step="0.001"
                                 class="lease-down-due-wrapper"
                          />
                        </div>
                        <p-dropdown
                          class="p-col-12 p-md-1"
                          id="leasePaymentType"
                          [options]="leasePaymentTypeOptions"
                          optionLabel="label"
                          optionValue="value"
                          formControlName="leasePaymentType">
                        </p-dropdown>
                        <div *ngIf="leasePaymentTypeControl.value === 'percent'" class="p-col-12 p-md-3">
                          <div class="lease-pricing-base-wrapper purchase-fee-grid">
                            <div class="p-col-2">
                              <label for="leasePricingBase">of
                              </label>
                            </div>
                            <div class="p-col-10">
                              <p-dropdown
                                id="leasePricingBase"
                                [options]="leasePricingBaseOptions"
                                optionLabel="label"
                                optionValue="value"
                                formControlName="leasePricingBase">
                              </p-dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="p-field p-grid">
                        <label for="includeLeaseDealerIncentivesNew" class="p-col-12 p-md-6 ng-star-inserted">Include
                          Dealer
                          Incentives
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="includeLeaseDealerIncentivesNew"
                          formControlName="includeLeaseDealerIncentives">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="leaseIncentiveTypeNew" class="p-col-12 p-md-6 ng-star-inserted">Lease Incentive
                        </label>
                        <p-dropdown
                          class="p-col-12 p-md-6"
                          id="leaseIncentiveTypeNew"
                          [options]="leaseIncentiveTypeOptions"
                          optionLabel="label"
                          optionValue="value"
                          formControlName="leaseIncentiveType">
                        </p-dropdown>
                      </div>
                      <div class="p-grid purchase-fee-grid">
                        <div class="p-col-6">
                          <label for="moneyFactorMarkupNew">Money Factor Markup
                            <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                               pTooltip="The markup is added to the best rate from the Incentive Service. Lease APR/2400 converts percentage value to money factor."
                               tooltipPosition="right"></i>
                          </label>
                        </div>
                        <div class="p-col-6">
                          <input pInputText
                                 id="moneyFactorMarkupNew"
                                 formControlName="moneyFactorMarkup"
                                 type="number"
                                 step="0.01"
                          />
                        </div>
                        <span *ngIf=newMoneyFactorMarkupControl.invalid
                              class="p-invalid p-col-12 p-md-6 ng-star-inserted">
                            Money Factor Markup must be a decimal value less than 1.
                        </span>
                      </div>
                    </form>
                  </p-accordionTab>
                  <p-accordionTab header="Finance Offer">
                    <form [formGroup]="masterFormGroup.controls['New']['controls']['financeConfiguration']">
                      <div class="p-field p-grid">
                        <label for="showFinanceOfferNew" class="p-col-12 p-md-6 ng-star-inserted">Show Finance Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showFinanceOfferNew"
                          formControlName="showFinanceOffer">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="financeAprSourceNew" class="p-col-12 p-md-6 ng-star-inserted">Finance APR Source
                          <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                             pTooltip="When Incentive Service is selected, the Finance APR with the longest term is pulled from the Real-Time Incentive Service. When Account Defaults is selected, the Finance APR will come from the defaults configured for the account."
                             tooltipPosition="right"></i>
                        </label>
                        <p-dropdown
                          class="p-col-12 p-md-6"
                          id="financeAprSourceNew"
                          [options]="financeAprSourceOptions"
                          optionLabel="label"
                          optionValue="value"
                          formControlName="financeAprSource">
                        </p-dropdown>
                      </div>
                      <div class="p-field p-grid">
                        <label for="includeFinanceDealerIncentivesNew" class="p-col-12 p-md-6 ng-star-inserted">Include
                          Dealer Incentives
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="includeFinanceDealerIncentivesNew"
                          formControlName="includeFinanceDealerIncentives">
                        </p-inputSwitch>
                      </div>
                    </form>
                  </p-accordionTab>
                  <p-accordionTab header="APR Offer">
                    <form [formGroup]="masterFormGroup.controls['New']['controls']['aprConfiguration']">
                      <div class="p-field p-grid">
                        <label for="showAprOfferNew" class="p-col-12 p-md-6 ng-star-inserted">Show APR Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showAprOfferNew"
                          formControlName="showAprOffer">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="useChromeIncentivesAprOfferNew" class="p-col-12 p-md-6 ng-star-inserted"> APR Source
                          <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                             pTooltip="When using the Incentive Service, the lowest available Finance APR with the longest correlating term is applied. When no APR is found, the Account Default is used as a fallback if available."
                             tooltipPosition="right"></i>
                        </label>
                        <p-dropdown
                          class="p-col-12 p-md-6"
                          id="useChromeIncentivesAprOfferNew"
                          [options]="aprSourceOptions"
                          optionLabel="label"
                          optionValue="value"
                          formControlName="useChromeIncentivesAprOffer">
                        </p-dropdown>
                      </div>
                    </form>
                  </p-accordionTab>
                </p-accordion>
              </p-tabPanel>
              <p-tabPanel header="Used">
                <p-accordion [multiple]="true">
                  <auto-offer-rules
                    [accountId]="accountId"
                    [condition]="'Used'"
                    (formDirty)="handleOfferRulesFormDirty($event)"
                    (deleteRules)="handleDeleteOfferRules()">
                  </auto-offer-rules>
                  <p-accordionTab header="Fees">
                    <div class="p-d-flex">
                      <form
                        [formGroup]="masterFormGroup.controls['Used']['controls']['feesConfiguration']">
                        <table class="p-datatable">
                          <thead class="p-datatable-thead">
                          <tr class="p-datatable-header">
                            <th class="p-mr-2">
                              Fee
                            </th>
                            <th class="p-mr-2">
                              Value
                            </th>
                            <th class="p-mr-2 fee-table-column">
                              Lease
                            </th>
                            <th class="p-mr-2 fee-table-column">
                              Finance
                            </th>
                          </tr>
                          </thead>
                          <tbody class="p-datatable-tbody">
                          <tr formGroupName="documentFee" class="p-mb-2">
                            <td class="p-mr-2">
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['documentFee']['type'] | sentenceCase}}
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['documentFee']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionLabel="label"
                                optionValue="value"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionLabel="label"
                                optionValue="value"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          <tr formGroupName="licenseFee" class="p-mb-2">
                            <td class="p-mr-2">
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['licenseFee']['type'] | sentenceCase}}
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['licenseFee']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          <tr formGroupName="titleFee" class="p-mb-2">
                            <td class="p-mr-2">
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['titleFee']['type'] | sentenceCase}}
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['titleFee']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          <tr formGroupName="acquisitionFee" class="p-mb-2">
                            <td class="p-mr-2">
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['acquisitionFee']['type'] | sentenceCase}}
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['acquisitionFee']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          <tr formGroupName="taxes" class="p-mb-2">
                            <td class="p-mr-2">
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['taxes']['type'] | sentenceCase}}
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['taxes']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          <tr formGroupName="dispositionFee" class="p-mb-2">
                            <td class="p-mr-2">
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['dispositionFee']['type'] | sentenceCase}}
                              {{usedBusinessCategoryType['configuration']['feesConfiguration']['dispositionFee']['isPercent'] ? '(%)' : '($)'}}
                            </td>
                            <td class="p-mr-2">
                              <input type="text" pInputText formControlName="value">
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="leaseApplication">
                              </p-dropdown>
                            </td>
                            <td class="p-mr-2">
                              <p-dropdown
                                [options]="feeType"
                                optionValue="value"
                                optionLabel="label"
                                formControlName="financeApplication">
                              </p-dropdown>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <h4 class="form-section-sub-header">Purchase Fee</h4>
                        <div formGroupName="customFee">
                          <div class="p-field p-grid">
                            <label for="custom-fee-enabled-used" class="p-col-12 p-md-6 ng-star-inserted">Custom
                              Purchase
                              Fee
                              <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                                 pTooltip="When enabled, this custom purchase fee will be applied in the math box for all used ads. The mathbox will reflect a final price including applicable purchase rebates and this fee."
                                 tooltipPosition="right"></i>
                            </label>
                            <p-inputSwitch
                              class="p-col-12 p-md-6"
                              formControlName="enabled"
                              id="custom-fee-enabled-used"
                            ></p-inputSwitch>
                          </div>
                          <div class="p-grid purchase-fee-grid">
                            <div class="p-col-6">
                              <label for="display-name-used">Display Name</label>
                            </div>
                            <div class="p-col-6">
                              <input type="text" pInputText formControlName="displayName" id="display-name-used">
                            </div>
                            <div class="p-col-6">
                              <label for="value-used">Value ($)</label>
                            </div>
                            <div class="p-col-6">
                              <input type="text" pInputText formControlName="value" id="value-used">
                            </div>
                            <!-- TODO - Uncomment this in LC-10819 -->
                            <!--                            <div class="p-col-6">-->
                            <!--                              <label for="pricing-option-new">Pricing Option-->
                            <!--                                <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"-->
                            <!--                                   pTooltip="'Add Purchase Fee to Sale Price': The purchase fee will be added to the sale price in the ad and reflected in the final price. The fee will still be itemized separately.-->
                            <!--                                             'Purchase Fee is Included in Sale Price': The purchase fee is included in the sale price, but the fee will still be itemized separately."-->
                            <!--                                   tooltipPosition="right"></i></label>-->
                            <!--                            </div>-->
                            <!--                            <div class="p-col-6">-->
                            <!--                              <p-dropdown-->
                            <!--                                [options]="pricingOptions"-->
                            <!--                                optionValue="value"-->
                            <!--                                optionLabel="label"-->
                            <!--                                formControlName="pricingOption"-->
                            <!--                                id="pricing-option-new">-->
                            <!--                              </p-dropdown>-->
                            <!--                            </div>-->
                            <div class="p-col-6">
                              <label for="final-price-text-used">Final Price Text</label>
                            </div>
                            <div class="p-col-6">
                              <input type="text" pInputText formControlName="finalPriceText" id="final-price-text-used">
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </p-accordionTab>
                  <p-accordionTab header="Purchase & Savings Offers">
                    <form [formGroup]="masterFormGroup.controls['Used']['controls']['purchaseConfiguration']">
                      <div class="p-field p-grid">
                        <label for="showPurchaseOfferUsed" class="p-col-12 p-md-6 ng-star-inserted">Show Purchase Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showPurchaseOfferUsed"
                          formControlName="showPurchaseOffer">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="showSavingsOfferUsed" class="p-col-12 p-md-6 ng-star-inserted">Show Savings Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showSavingsOfferUsed"
                          formControlName="showSavingsOffer">
                        </p-inputSwitch>
                      </div>
                    </form>
                  </p-accordionTab>
                  <p-accordionTab header="Lease Offer">
                    <form [formGroup]="masterFormGroup.controls['Used']['controls']['leaseConfiguration']">

                      <div class="p-field p-grid">
                        <label for="showLeaseOfferUsed" class="p-col-12 p-md-6 ng-star-inserted">Show Lease Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showLeaseOfferUsed"
                          formControlName="showLeaseOffer">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="showAlternateLeaseOfferUsed" class="p-col-12 p-md-6 ng-star-inserted">Show Alternate
                          Lease Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showAlternateLeaseOfferUsed"
                          formControlName="showAlternateLeaseOffer">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="leaseSellingPriceTypeUsed" class="p-col-12 p-md-6 ng-star-inserted">Selling Price
                        </label>
                        <p-dropdown
                          class="p-col-12 p-md-6"
                          id="leaseSellingPriceTypeUsed"
                          [options]="leaseSellingPriceOptions"
                          optionLabel="label"
                          optionValue="value"
                          formControlName="leaseSellingPriceType">
                        </p-dropdown>
                      </div>
                      <div class="p-field p-grid">
                        <label for="leaseIncentiveTypeUsed" class="p-col-12 p-md-6 ng-star-inserted">Lease Incentive
                        </label>
                        <p-dropdown
                          class="p-col-12 p-md-6"
                          id="leaseIncentiveTypeUsed"
                          [options]="leaseIncentiveTypeOptions"
                          optionLabel="label"
                          optionValue="value"
                          formControlName="leaseIncentiveType">
                        </p-dropdown>
                      </div>
                      <div class="p-grid purchase-fee-grid">
                        <div class="p-col-6">
                          <label for="moneyFactorMarkupUsed">Money Factor Markup
                            <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                               pTooltip="The markup is added to the best rate from the Incentive Service. Lease APR/2400 converts percentage value to money factor."
                               tooltipPosition="right"></i>
                          </label>
                        </div>
                        <div class="p-col-6">
                          <input pInputText
                                 id="moneyFactorMarkupUsed"
                                 formControlName="moneyFactorMarkup"
                                 type="number"
                                 step="0.01"
                          />
                        </div>
                        <span *ngIf=usedMoneyFactorMarkupControl.invalid
                              class="p-invalid p-col-12 p-md-6 ng-star-inserted">
                            Money Factor Markup must be a decimal value less than 1.
                        </span>
                      </div>
                    </form>
                  </p-accordionTab>
                  <p-accordionTab header="Finance Offer">
                    <form [formGroup]="masterFormGroup.controls['Used']['controls']['financeConfiguration']">
                      <div class="p-field p-grid">
                        <label for="showFinanceOfferUsed" class="p-col-12 p-md-6 ng-star-inserted">Show Finance Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showFinanceOfferUsed"
                          formControlName="showFinanceOffer">
                        </p-inputSwitch>
                      </div>
                      <div class="p-field p-grid">
                        <label for="financeAprSourceUsed" class="p-col-12 p-md-6 ng-star-inserted">Finance APR Source
                          <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                             pTooltip="When Incentive Service is selected, the Finance APR with the longest term is pulled from the Real-Time Incentive Service. When Account Defaults is selected, the Finance APR will come from the defaults configured for the account."
                             tooltipPosition="right"></i>
                        </label>
                        <p-dropdown
                          class="p-col-12 p-md-6"
                          id="financeAprSourceUsed"
                          [options]="financeAprSourceOptions"
                          optionLabel="label"
                          optionValue="value"
                          formControlName="financeAprSource">
                        </p-dropdown>
                      </div>
                    </form>
                  </p-accordionTab>
                  <p-accordionTab header="APR Offer">
                    <form [formGroup]="masterFormGroup.controls['Used']['controls']['aprConfiguration']">
                      <div class="p-field p-grid">
                        <label for="showAprOfferUsed" class="p-col-12 p-md-6 ng-star-inserted">Show APR Offers
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          id="showAprOfferUsed"
                          formControlName="showAprOffer">
                        </p-inputSwitch>
                      </div>
                    </form>
                  </p-accordionTab>
                </p-accordion>
              </p-tabPanel>
            </p-tabView>
          </form>
        </div>
      </p-panel>
      <p-panel styleClass="ad-types-form-panel" [collapsed]="promotionalAdTypesCollapsed"
               [toggleable]="accountAdTypes !== null && accountAdTypes.promotionalAdType.enabled"
               [expandIcon]="expandIcon"
               [collapseIcon]="collapseIcon">
        <p-header class="ad-types-header-wrapper form-panel-header">
          <h3 class="ad-types-header-text">Promotional Ads</h3>
          <p class="coming-soon-header">Coming Soon! <i class="pi pi-clock"></i></p>
          <div class="ad-types-header-input">
            <div class="ad-types-header-input">
              <p-inputSwitch
                [ngModel]="accountAdTypes !== null && accountAdTypes.promotionalAdType.enabled" (ngModelChange)="accountAdTypes !== null && (accountAdTypes.promotionalAdType.enabled = $event)"
                (onChange)="handlePromotionalAdsEnabledToggle()">
              </p-inputSwitch>
            </div>
          </div>
        </p-header>
        <div class="form-body-wrapper" *ngIf="accountAdTypes !== null && accountAdTypes.promotionalAdType.enabled">
          <div class="ad-types-save-button">
            <button pButton
                    class="p-button-success"
                    label="Save"
                    (click)="handlePromotionalAdsSave()"
                    [disabled]="!promotionalAdMasterFormGroup.valid">
            </button>
          </div>
          <p-tabMenu styleClass="business-category-tabs"
                     [model]="promotionalAdTabs"
                     [activeItem]="promotionalAdTabs[0]">
          </p-tabMenu>
          <form *ngIf="promotionalAdsConfigurations" [formGroup]="promotionalAdMasterFormGroup" autoTrackUnsavedChanges>
            <div class="p-fluid">
              <h4 class="form-section-header">General</h4>
              <div class="p-field p-grid">
                <label for="promotion-categories" class="p-col">Promotion Categories</label>
                <div class="p-col">
                  <p-multiSelect id="promotion-categories"
                                 [options]="accountServiceCategories"
                                 formControlName="subcategories"
                                 optionLabel="name"
                                 dataKey="id"
                                 placeholder="Sub-Category"
                                 filterPlaceHolder="Select All"
                                 resetFilterOnHide="true">
                  </p-multiSelect>
                </div>
              </div>
              <div class="p-field p-grid">
                <label for="disclosure-addon" class="p-col">Disclosure Add-on</label>
                <div class="p-col">
                <textarea id="disclosure-addon"
                          formControlName="disclosureAddon"
                          [rows]="5"
                          [cols]="60"
                          pInputTextarea
                          autoResize="autoResize">
                </textarea>
                </div>
              </div>
            </div>
            <div>
              <div>
                <h4 class="form-section-header">Calls to Action</h4>
                <div formArrayName="callsToAction" class="call-to-action-boundary">
                  <div class="call-to-action-list p-grid" cdkDropList (cdkDropListDropped)="callToActionDrop($event)">
                    <div *ngFor="let callToAction of this.callsToAction.controls; index as i" cdkDrag
                         class="p-col-fixed" cdkDragBoundary=".call-to-action-boundary" style="width:100%">
                      <p-card [formGroupName]="i">
                        <div class="p-grid">
                          <div cdkDragHandle class="p-col-fixed cta-handle" style="width:50px;">
                            <i class="pi pi-bars"></i>
                          </div>
                          <div class="p-col p-grid">
                            <div class="p-col-fixed" style="width:100%">
                              <input pInputText
                                     aria-label="Call to action text"
                                     class="call-to-action-text"
                                     formControlName="callToActionText"
                                     placeholder="Text displayed for CTA"
                                     [ngClass]="{'p-invalid': callToAction.get('callToActionText').hasError('alphanumeric') || callToAction.get('callToActionText').hasError('required')}"
                              />
                              <span *ngIf="callToAction.get('callToActionText').hasError('required')"
                                    class="p-invalid">
                                  CTA display text is required.
                                </span>
                            </div>
                            <div class="p-col-fixed" style="width:100%">
                              <p-dropdown class="action-type"
                                          aria-label="Action Type"
                                          [options]="actionOptions | async"
                                          formControlName="actionOptions"
                                          dataKey="value"
                                          optionLabel="label"
                                          placeholder="Action"
                                          [ngClass]="{'p-invalid': callToAction.get('actionOptions').hasError('emptyDropdownSelection')}">
                              </p-dropdown>
                              <span *ngIf="callToAction.get('actionOptions').hasError('emptyDropdownSelection')"
                                    class="p-invalid">
                                Action type is required.
                            </span>
                            </div>
                            <div class="p-col-fixed" style="width:46%">
                              <input pInputText
                                     aria-label="URL"
                                     class="url"
                                     placeholder="Custom destination URL"
                                     formControlName="actionUrl"/>
                            </div>
                            <div class="p-col-fixed" style="width:46%">
                              <p-dropdown class="target-selection"
                                          aria-label="Target Selection"
                                          [options]="targetOptions"
                                          formControlName="target"
                                          dataKey="value"
                                          optionLabel="label"
                                          placeholder="Target"
                                          [ngClass]="{'p-invalid': callToAction.get('target').hasError('emptyDropdownSelection')}">
                              </p-dropdown>
                              <span *ngIf="callToAction.get('target').hasError('emptyDropdownSelection')"
                                    class="p-invalid">
                                Target is required.
                            </span>
                            </div>
                            <div class="p-col trash-icon">
                              <i class="pi pi-trash" (click)="deleteCallToAction(i)"></i>
                            </div>
                          </div>
                        </div>
                      </p-card>
                    </div>
                  </div>
                </div>
                <div class="add-cta-button">
                  <button pButton type="button" icon="pi pi-plus" (click)="addNewCallToAction()"></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </p-panel>
    </p-panel>
  </div>
</p-panel>
<auto-footer></auto-footer>
