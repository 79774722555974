import { Injectable } from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {AdFilter} from '../model/ad-filter/ad-filter';
import {v4 as uuidv4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ManageAdFiltersService extends AbstractHttpService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  private sortArrayByGuid(array) {
    return array.reduce((accumulator, currentItem) => {
      if (!currentItem.guid) {
        currentItem.guid = uuidv4();
      }
      accumulator[currentItem.guid] = currentItem;

      return accumulator;
    }, {});
  }

  public getAccountAdFilters(accountId: number){
    return this.httpClient.get<AdFilter[]>('assets/mockData/ad-filters.json')
      .pipe(
        mergeMap((asIs: AdFilter[]) => asIs),
        map((adFilter: AdFilter) => ({
          guid: adFilter.guid,
          accountBusinessCategoryGuid: adFilter.accountBusinessCategoryGuid,
          name: adFilter.name,
          type: adFilter.type,
          description: adFilter.description,
          fallbackFilterGuid: adFilter.fallbackFilterGuid,
          category: adFilter.category,
          parameters: this.sortArrayByGuid(adFilter.parameters),
          sources: this.sortArrayByGuid(adFilter.sources),
          sortOption: adFilter.sortOption
        })),
        toArray(),
        catchError(this.handleError)
      );
  }

  public getAccountServiceCategories(accountId: number){
    return this.httpClient.get('assets/mockData/available-service-categories.json')
      .pipe(
      catchError(this.handleError)
    );
  }

  public getAccountSources(accountId: number){
    return this.httpClient.get('assets/mockData/account-sources.json')
      .pipe(
        catchError(this.handleError)
      );
  }
}
