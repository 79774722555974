export class ProjectedPageRequestModel {
  childAccountId: number;
  parentAccountId: number;
  projectedPageIds: number[];

  constructor() {
    this.childAccountId = null;
    this.parentAccountId = null;
    this.projectedPageIds = [];
  }
}
