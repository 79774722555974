import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {CookieService} from '../../../../service/cookie.service';
import {UserService} from '../../../../service/user.service';

@Component({
  selector: 'auto-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted = false;
  username: UntypedFormControl;
  successPageData = {
    state: {
      title: 'Request Successful',
      message: 'Check your email. The request link has been sent.',
      backToPageRoute: '/',
      backToPageName: 'login',
      autoToken: 'dt_event_page_temp_token'
    }};
  cardStyle = {
    'border-style': 'solid',
    'border-color': 'gray',
    'border-width': 'thin',
    'width' : '550px',
    'background-color': '#FFFFFF',
    'margin-left' : 'auto',
    'margin-right' : 'auto',
    'padding' : '2.5rem 2.5rem 0rem 2.5rem'};
  usernameForm = this.formBuilder.group({
    username: ['', [
      Validators.required,
      Validators.maxLength(64)
    ]]
  });

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private userService: UserService,
    private cookieService: CookieService
  ) { }

  /**
   * Initialize page
   */
  ngOnInit(): void {
    this.titleService.setTitle('Forgot Password Help');
    this.cookieService.setCookie(
      'auto-token',
      'dt_forgot_password_temp_token',
      0.000035, // This value will be converted into 3 seconds from now
      '/'
    );
  }

  /**
   * Calls the request reset password endpoint to send reset link to email
   */
  sendRequestToResetPassword(): void {
    this.submitted = true;
    this.userService.requestResetPassword({username: this.usernameForm.controls.username.value}).subscribe(
      () => {
        this.router.navigate(['/event-page'], this.successPageData);
      },
      (error) => {
        this.submitted = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 15000
        });
      });
  }

  /**
   * Sends user back to the login page
   */
  backToLogin() {
    this.router.navigate(['/']);
  }
}
