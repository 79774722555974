import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BudgetManagementService} from '../../../../service/budget-management.service';
import {BudgetFeaturesService} from '../../../../service/budget-features.service';
import {AccountFeatureService} from '../../../../service/account-feature.service';
import {MessageService} from 'primeng/api';
import {StringFormatterService} from '../../../../service/utilities/string-formatter.service';
import {forkJoin, Observable} from 'rxjs';
import {BudgetManagement} from '../../../../model/budget-management.model';
import {InitializationService} from '../../../../service/initialization.service';

@Component({
  selector: 'auto-budget-template',
  templateUrl: './budget-template.component.html',
  styleUrls: [
    './budget-template.component.scss',
    '../../settings/google-ads-settings/budget-management/budget-management.component.scss'
  ]
})
export class BudgetTemplateComponent implements OnInit {

  @Input() accountId: number;
  @Input() expandIcon: string;
  @Input() collapseIcon: string;
  @Input() activeBudgetManagements: any[];
  @Input() pendingBudgetManagements: any[];
  @Input() budgetType: string;

  budgetOverview  = [];
  activeBudgetConfigurationFormGroups = [];
  activeBudgetConfigurationFormArrays = [];
  activeBudgetConfigurationTotalsFormGroups = [];

  pendingBudgetConfigurationFormGroups = [];
  pendingBudgetConfigurationFormArrays = [];
  pendingBudgetConfigurationTotalsFormGroups = [];

  constructor(
    private budgetManagementService: BudgetManagementService,
    private budgetFeaturesService: BudgetFeaturesService,
    private accountFeatureService: AccountFeatureService,
    private messageService: MessageService,
    private initializationService: InitializationService,
    private formBuilder: UntypedFormBuilder,
    public stringFormatterService: StringFormatterService
  ) { }

  /**
   * Implemented ngOnInit function to initialize forms and overview budgets.
   */
  ngOnInit(): void {
    this.initializationService.componentInitCompleted$.subscribe(() => {
      this.initForms();
      this.initOverviewSection();
    });
  }

  /**
   * Initializes Form Groups for budget configurations
   */
  initForms() {
    this.activeBudgetManagements.forEach((activeBudgetManagement) => {
      if (activeBudgetManagement.campaignBudgets !== undefined) {
        const fixedAmountTotal = this.calculateFixedAmountTotal(activeBudgetManagement.campaignBudgets);
        const existingActiveBudgetConfigurations = this.buildBudgetConfigurationFormGroups(activeBudgetManagement, fixedAmountTotal);
        const activeBudgetConfigurationFormArray = this.formBuilder.array(existingActiveBudgetConfigurations);
        this.activeBudgetConfigurationFormArrays.push(activeBudgetConfigurationFormArray);
        const activeBudgetConfigurationFormGroup = this.formBuilder.group({activeBudgetConfigurationFormArray});
        this.activeBudgetConfigurationFormGroups.push(activeBudgetConfigurationFormGroup);
        this.activeBudgetConfigurationTotalsFormGroups.push(this.createBudgetConfigurationTotalFormGroup(existingActiveBudgetConfigurations, fixedAmountTotal));
        activeBudgetManagement.campaignBudgets.forEach((campaignBudget) => {
          if (!this.budgetOverview.some(budget => (budget.resourceName === campaignBudget.resourceName))) {
            let overviewBudget = {...campaignBudget};
            this.budgetOverview.push(overviewBudget);
          }
        });
      }
    });

    this.pendingBudgetManagements.forEach((pendingBudgetManagement) => {
      if (pendingBudgetManagement.campaignBudgets !== undefined) {
        const fixedAmountTotal = this.calculateFixedAmountTotal(pendingBudgetManagement.campaignBudgets);
        const existingPendingBudgetConfigurations = this.buildBudgetConfigurationFormGroups(pendingBudgetManagement, fixedAmountTotal);
        const pendingBudgetConfigurationFormArray = this.formBuilder.array(existingPendingBudgetConfigurations);
        this.pendingBudgetConfigurationFormArrays.push(pendingBudgetConfigurationFormArray);
        const pendingBudgetConfigurationFormGroup = this.formBuilder.group({pendingBudgetConfigurationFormArray});
        this.pendingBudgetConfigurationFormGroups.push(pendingBudgetConfigurationFormGroup);
        this.pendingBudgetConfigurationTotalsFormGroups.push(this.createBudgetConfigurationTotalFormGroup(existingPendingBudgetConfigurations, fixedAmountTotal));
      }
    });
  }

  /**
   * Initializes overview budget section.
   */
  initOverviewSection() {
    this.activeBudgetManagements.forEach((activeBudgetManagement) => {
      if (activeBudgetManagement.campaignBudgets !== undefined) {
        activeBudgetManagement.campaignBudgets.forEach((campaignBudget) => {
          this.updateOverviewValues(campaignBudget.resourceName);
        });
      }
    });
  }


  /**
   * Builds an array of form groups for a given budget management type
   * @param budgetManagementModel
   * @param fixedAmountTotal
   */
  buildBudgetConfigurationFormGroups(budgetManagementModel, fixedAmountTotal): UntypedFormGroup[] {
    const budgetConfigurationForms = [];
    budgetManagementModel.campaignBudgets.forEach((campaignBudget) => {
      budgetConfigurationForms.push(this.createBudgetConfigurationForm(campaignBudget, budgetManagementModel, fixedAmountTotal));
    });
    return budgetConfigurationForms;
  }

  /**
   * Creates an individual form group for a budget configuration
   * @param campaignBudget
   * @param budgetManagementModel
   * @param fixedAmountTotal
   */
  createBudgetConfigurationForm(campaignBudget, budgetManagementModel, fixedAmountTotal): UntypedFormGroup {
    return this.formBuilder.group({
      totalBudget: new UntypedFormControl(budgetManagementModel.totalBudget),
      adjustedTotalBudget: new UntypedFormControl(budgetManagementModel.adjustedTotalBudget),
      daysInBudget: new UntypedFormControl(this.getDaysInBudget(budgetManagementModel)),
      budgetName: new UntypedFormControl(campaignBudget.budgetName),
      percentage: new UntypedFormControl(this.getPercentage(campaignBudget), [Validators.required, Validators.pattern('^([0-9]|([1-9][0-9])|100)$'), Validators.min(0), Validators.max(100)]),
      totalSpend: new UntypedFormControl(this.calculateTotalSpend(campaignBudget, budgetManagementModel, fixedAmountTotal)),
      fixedAmount: new UntypedFormControl(campaignBudget.campaignBudgetConfiguration.fixedAmount),
      dailyBudget: new UntypedFormControl(this.calculateDailyBudget(campaignBudget, budgetManagementModel)),
      resourceName: campaignBudget.resourceName,
      salesforceBudgetId: budgetManagementModel.salesforceBudgetId,
      salesforceBudgetType: budgetManagementModel.budgetType,
      salesforceBudgetStartDate: budgetManagementModel.startDate.date,
      salesforceBudgetEndDate: budgetManagementModel.endDate.date
    });
  }

  /**
   * Gets the amount of days in the budget
   * @param budgetManagementModel
   */
  getDaysInBudget(budgetManagementModel): number {
    const startDate = new Date(budgetManagementModel.startDate.date);
    startDate.setHours( 0, 0, 0, 0);
    const endDate = new Date(budgetManagementModel.endDate.date);
    endDate.setHours( 0, 0, 0, 0);
    const diff = Math.abs(endDate.getTime() - startDate.getTime());
    return Math.round(diff / (1000 * 3600 * 24)) + 1;
  }

  /**
   * Creates the form group for the totals row in a given budget configuration
   * @param existingBudgetConfigurations
   * @param fixedAmountTotal
   */
  createBudgetConfigurationTotalFormGroup(existingBudgetConfigurations, fixedAmountTotal): UntypedFormGroup {
    let percentageTotal = 0;
    let totalSpendTotal = 0;
    let dailyBudgetTotal = 0;
    let maxBudget = 0;

    existingBudgetConfigurations.forEach((existingBudgetConfiguration) => {
      percentageTotal += existingBudgetConfiguration['controls'].percentage.value;
      totalSpendTotal += existingBudgetConfiguration['controls'].totalSpend.value;
      dailyBudgetTotal += existingBudgetConfiguration['controls'].dailyBudget.value;
      maxBudget = existingBudgetConfiguration['controls'].totalBudget.value;
      if (existingBudgetConfiguration['controls'].salesforceBudgetType.value === 'Heavy-Up') {
        maxBudget = existingBudgetConfiguration['controls'].adjustedTotalBudget.value;
      }
    });

    return this.formBuilder.group({
      percentageTotal: new UntypedFormControl(percentageTotal, [Validators.min(0), Validators.max(100)]),
      totalSpendTotal: new UntypedFormControl(totalSpendTotal, [Validators.min(maxBudget - 0.01), Validators.max(maxBudget + 0.01)]),
      dailyBudgetTotal: new UntypedFormControl(dailyBudgetTotal),
      fixedAmountTotal: new UntypedFormControl(fixedAmountTotal)
    });
  }

  /**
   * Gets the column for a given budget configuration
   * @param campaignBudget
   */
  getPercentage(campaignBudget): number {
    let percentage = 0;
    if (campaignBudget.campaignBudgetConfiguration.percentage !== undefined) {
      percentage = campaignBudget.campaignBudgetConfiguration.percentage;
    }
    return percentage;
  }

  /**
   * Calculates the total spend column for a given budget configuration
   * @param campaignBudget
   * @param budgetManagementModel
   * @param fixedAmountTotal
   */
  calculateTotalSpend(campaignBudget, budgetManagementModel, fixedAmountTotal): number {
    let totalSpend = 0;
    let totalBudget = budgetManagementModel.totalBudget;
    if (campaignBudget.campaignBudgetConfiguration.fixedAmount || campaignBudget.monthBudget > 0) {
      totalSpend = campaignBudget.monthBudget;
    }
    if (campaignBudget.campaignBudgetConfiguration.percentage !== undefined
      && totalSpend === 0
      && !campaignBudget.campaignBudgetConfiguration.fixedAmount
    ) {
      if (budgetManagementModel.budgetType === 'Heavy-Up') {
        totalBudget = budgetManagementModel.adjustedTotalBudget;
      }
      totalSpend = ((campaignBudget.campaignBudgetConfiguration.percentage * (totalBudget - fixedAmountTotal)) / 100);
    }
    return totalSpend;
  }

  /**
   * Calculates total of all fixed campaign budgets
   * @param campaignBudgets
   */
  calculateFixedAmountTotal(campaignBudgets){
    let fixedAmountTotal = 0;
    campaignBudgets.forEach((campaignBudget) => {
      if (campaignBudget.campaignBudgetConfiguration.fixedAmount) {
        fixedAmountTotal += campaignBudget.monthBudget;
      }
    });

    return fixedAmountTotal;
  }


  /**
   * Calculates the daily budget column for a given budget configuration
   * @param campaignBudget
   * @param budgetManagementModel
   */
  calculateDailyBudget(campaignBudget, budgetManagementModel): number {
    let dailyBudget = 0;
    const daysInBudget = this.getDaysInBudget(budgetManagementModel);
    let totalBudget = budgetManagementModel.totalBudget;
    if (campaignBudget.campaignBudgetConfiguration.fixedAmount) {
      dailyBudget = campaignBudget.monthBudget / daysInBudget;
    }

    if (campaignBudget.campaignBudgetConfiguration.percentage !== undefined && !campaignBudget.campaignBudgetConfiguration.fixedAmount) {
      if (budgetManagementModel.budgetType === 'Heavy-Up') {
        totalBudget = budgetManagementModel.adjustedTotalBudget;
      }
      dailyBudget = ((campaignBudget.campaignBudgetConfiguration.percentage * totalBudget) / 100) / daysInBudget;
    }
    return dailyBudget;
  }

  /**
   * Calculates the values in a given budget configuration table when the percentage column is updated
   * @param index
   * @param budgetStatus
   */
  calculateValues(index, budgetStatus): void {
    let percentageTotal = 0;
    let totalSpendTotal = 0;
    let dailyBudgetTotal = 0;
    let fixedAmountTotal = 0;
    let budgetConfigurationFormArray;
    let budgetConfigurationTotalsFormGroup;
    let modifiedResourceName;

    if (budgetStatus === 'active') {
      budgetConfigurationFormArray = this.activeBudgetConfigurationFormArrays[index];
      budgetConfigurationTotalsFormGroup = this.activeBudgetConfigurationTotalsFormGroups[index];
    } else {
      budgetConfigurationFormArray = this.pendingBudgetConfigurationFormArrays[index];
      budgetConfigurationTotalsFormGroup = this.pendingBudgetConfigurationTotalsFormGroups[index];
    }

    budgetConfigurationFormArray.controls.forEach((budgetConfigurationFormGroup) => {
      if (budgetConfigurationFormGroup.controls.fixedAmount.value) {
        fixedAmountTotal += +budgetConfigurationFormGroup.controls.totalSpend.value.toFixed(2);
        budgetConfigurationFormGroup.controls.percentage.setValue(0);
      }
    });

    budgetConfigurationFormArray.controls.forEach((budgetConfigurationFormGroup) => {
      if (!budgetConfigurationFormGroup.controls.fixedAmount.value) {
        let totalBudget = budgetConfigurationFormGroup.controls.totalBudget.value;
        if (this.budgetType === 'Heavy-Up' && (budgetStatus === 'active' || budgetStatus === 'pending')) {
          totalBudget = budgetConfigurationFormGroup.controls.adjustedTotalBudget.value;
        }
        budgetConfigurationFormGroup.controls.totalSpend.setValue(((budgetConfigurationFormGroup.controls.percentage.value * (totalBudget - fixedAmountTotal)) / 100));
      }

      budgetConfigurationFormGroup.controls.dailyBudget.setValue((budgetConfigurationFormGroup.controls.totalSpend.value / budgetConfigurationFormGroup.controls.daysInBudget.value));
      budgetConfigurationFormGroup.controls.totalSpend.setValue(+budgetConfigurationFormGroup.controls.totalSpend.value.toFixed(2));
      percentageTotal += budgetConfigurationFormGroup.controls.percentage.value;
      totalSpendTotal += budgetConfigurationFormGroup.controls.totalSpend.value;
      dailyBudgetTotal += +budgetConfigurationFormGroup.controls.dailyBudget.value.toFixed(2);

      modifiedResourceName = budgetConfigurationFormGroup.controls.resourceName.value;
      if (budgetStatus !== 'pending') {
        this.updateOverviewValues(modifiedResourceName);
      }
    });

    budgetConfigurationTotalsFormGroup.controls.percentageTotal.setValue(percentageTotal);
    budgetConfigurationTotalsFormGroup.controls.totalSpendTotal.setValue(+totalSpendTotal.toFixed(2));
    budgetConfigurationTotalsFormGroup.controls.dailyBudgetTotal.setValue(dailyBudgetTotal);
    budgetConfigurationTotalsFormGroup.controls.fixedAmountTotal.setValue(fixedAmountTotal);
  }

  /**
   * Updates the overview values after a configuration change has been made
   * @param resourceName
   */
  updateOverviewValues(resourceName): void {
    let monthBudget = 0;
    let dailyBudget = 0;
    let overviewBudget;
    let budgetConfigurations;
    let campaignBudgets;
    let targetSpend = 0;

    this.activeBudgetConfigurationFormArrays.forEach((formArray) => {
      const budget = formArray.value.find(formBudget => (formBudget.resourceName === resourceName));
      if (budget !== undefined) {
        const activeBudgetDays = this.calculateActiveBudgetDays(budget);
        monthBudget += budget.totalSpend;
        dailyBudget += budget.dailyBudget;
        targetSpend += this.calculateTotalExpectedSpend(budget.dailyBudget, activeBudgetDays);
      }
    });

    overviewBudget = this.budgetOverview.find(budgetOverview => (budgetOverview.resourceName === resourceName));
    budgetConfigurations = this.activeBudgetConfigurationFormArrays;

    overviewBudget.monthBudget = monthBudget;
    overviewBudget.dailyBudget = dailyBudget;
    overviewBudget.leftToSpend = monthBudget - overviewBudget.spendToDate;
    overviewBudget.targetSpend = targetSpend;

    overviewBudget.pacing = 0;
    const pacing = Math.round((overviewBudget.spendToDate / overviewBudget.targetSpend) * 100);
    if (isFinite(pacing)) {
      overviewBudget.pacing = pacing;
    }

    overviewBudget.progressPercent = 0;
    const progressPercent = Math.round((overviewBudget.spendToDate / monthBudget) * 100);
    if (isFinite(progressPercent)) {
      overviewBudget.progressPercent = progressPercent;
    }

    campaignBudgets = this.getAssociatedCampaignBudgets(overviewBudget.resourceName, budgetConfigurations);
    overviewBudget.newDailyBudget = this.processNewDayBudget(overviewBudget, campaignBudgets);

    this.budgetOverview.sort((a, b) => this.compareBudgets(a, b));
  }

  /**
   * Compares the month budgets of the first and second budgets
   * @param firstBudget
   * @param secondBudget
   */
  compareBudgets(firstBudget, secondBudget): number {
    let result = -1;
    if (firstBudget.monthBudget < secondBudget.monthBudget ||
      (firstBudget.monthBudget === secondBudget.monthBudget &&
        firstBudget.budgetName > secondBudget.budgetName)) {
      result = 1;
    }
    return result;
  }

  /**
   * Calculates the New Day Budget values after a configuration change has been made
   * @param resourceName
   * @param budgetConfigurations
   */
  getAssociatedCampaignBudgets(resourceName, budgetConfigurations): object[] {
    const campaignBudgets = [];
    let campaignBudget;
    budgetConfigurations.forEach((budgetConfiguration) => {
      campaignBudget = budgetConfiguration.value.find(budget => budget.resourceName === resourceName);
      if (campaignBudget !== undefined) {
        campaignBudgets.push(campaignBudget);
      }
    });

    return campaignBudgets;
  }

  /**
   * Processes the New Day Budget values after a configuration change has been made
   * This assumes that there are no Boost Budgets running concurrently
   * @param overviewBudget
   * @param campaignBudgets
   */
  processNewDayBudget(overviewBudget, campaignBudgets): number {
    let newDailyBudget;
    let boostBudget;
    let noBoost = true;
    const standardBudget = campaignBudgets.find(budget => budget.salesforceBudgetType !== 'Boost');
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const totalSpendToDate = overviewBudget.spendToDate;

    boostBudget = campaignBudgets.find(budget => budget.salesforceBudgetType === 'Boost' &&
      new Date(budget.salesforceBudgetEndDate) >= today &&
      new Date(budget.salesforceBudgetStartDate) <= today);
    if (boostBudget !== undefined) {
      newDailyBudget = this.handleNewDailyBudgetWithBoost(standardBudget, boostBudget, totalSpendToDate);
      if (newDailyBudget > 0) {
        noBoost = false;
      }
    }

    if (noBoost) {
      newDailyBudget = this.handleNewDailyBudget(standardBudget, boostBudget, totalSpendToDate, today);
    }

    return newDailyBudget;
  }

  /**
   * Handles the new daily budget with an active boost
   * @param standardBudget
   * @param boostBudget
   * @param totalSpendToDate
   */
  handleNewDailyBudgetWithBoost(standardBudget, boostBudget, totalSpendToDate): number {
    let boostNewDayValue;
    let standardNewDayValue;
    let activeBudgetDays;
    let boostActiveBudgetDays;
    let standardTotalExpectedSpend;
    let boostTotalExpectedSpend;
    let shortfall;
    let boostDaysLeftToSpend;
    let standardSpendToDate;
    let boostSpendToDate;
    let boostShortfall;
    let newDayValue = 0;

    activeBudgetDays = this.calculateActiveBudgetDays(standardBudget);
    boostActiveBudgetDays = this.calculateActiveBudgetDays(boostBudget);
    standardTotalExpectedSpend = this.calculateTotalExpectedSpend(standardBudget.dailyBudget, activeBudgetDays);
    boostTotalExpectedSpend = this.calculateTotalExpectedSpend(boostBudget.dailyBudget, boostActiveBudgetDays);
    shortfall = (standardTotalExpectedSpend + boostTotalExpectedSpend) - totalSpendToDate;
    boostDaysLeftToSpend = this.calculateDaysLeftToSpend(boostBudget);
    if (shortfall > 0 && boostDaysLeftToSpend > 0) {
      boostNewDayValue = (shortfall / boostDaysLeftToSpend) + boostBudget.dailyBudget;
      boostShortfall = boostNewDayValue * boostDaysLeftToSpend;
      boostSpendToDate = boostBudget.totalSpend - boostShortfall;
      if (boostSpendToDate <= 0) {
        boostSpendToDate = 0;
        boostNewDayValue = boostBudget.totalSpend / boostDaysLeftToSpend;
      }
      standardSpendToDate = totalSpendToDate - boostSpendToDate;
      standardNewDayValue = this.calculateNewDailyBudget(standardBudget, standardBudget.totalSpend, standardSpendToDate);
      newDayValue = standardNewDayValue + boostNewDayValue;
    }

    return newDayValue;
  }

  /**
   * Handles the new daily budget when no boost is currently active
   * @param standardBudget
   * @param boostBudget
   * @param totalSpendToDate
   * @param today
   */
  handleNewDailyBudget(standardBudget, boostBudget, totalSpendToDate, today): number {
    let standardSpendToDateTarget;
    let boostStartDate;
    let totalSpend;
    let activeBudgetDays;

    standardSpendToDateTarget = totalSpendToDate;
    totalSpend = standardBudget.totalSpend;
    if (boostBudget !== undefined) {
      boostStartDate = new Date(boostBudget.salesforceBudgetStartDate);
      if (boostStartDate > today) {
        activeBudgetDays = this.calculateActiveBudgetDays(standardBudget);
        totalSpend = standardBudget.totalSpend + boostBudget.totalSpend;
        standardSpendToDateTarget = this.calculateTotalExpectedSpend(standardBudget.dailyBudget, activeBudgetDays);
      }
    }

    return this.calculateNewDailyBudget(standardBudget, totalSpend, standardSpendToDateTarget);
  }

  /**
   * Calculates the number of days left to spend for a budget
   * @param campaignBudget
   */
  calculateDaysLeftToSpend(campaignBudget): number {
    const currentDate = new Date();
    currentDate.setHours( 0, 0, 0, 0);
    const endDate = new Date(campaignBudget.salesforceBudgetEndDate);
    endDate.setHours( 0, 0, 0, 0);
    const diff = Math.abs(endDate.getTime() - currentDate.getTime());

    return Math.round(diff / (1000 * 3600 * 24)) + 1;
  }

  /**
   * Calculates the number of days a budget has been active
   * @param campaignBudget
   */
  calculateActiveBudgetDays(campaignBudget): number {
    const currentDate = new Date();
    currentDate.setHours( 0, 0, 0, 0);
    const startDate = new Date(campaignBudget.salesforceBudgetStartDate);
    startDate.setHours( 0, 0, 0, 0);
    const diff = Math.abs(currentDate.getTime() - startDate.getTime());

    return Math.round(diff / (1000 * 3600 * 24)) + 1;
  }

  /**
   * Calculates the total expected spend for a budget
   * @param averageSpendPerDay
   * @param activeBudgetDays
   */
  calculateTotalExpectedSpend(averageSpendPerDay, activeBudgetDays): number {
    return averageSpendPerDay * activeBudgetDays;
  }

  /**
   * Formula to calculate the New Daily Budget number
   * @param campaignBudget
   * @param totalSpend
   * @param spendToDate
   */
  calculateNewDailyBudget(campaignBudget, totalSpend, spendToDate): number {
    const daysLeftToSpend = this.calculateDaysLeftToSpend(campaignBudget);
    let newDailyBudget = 0;
    const shortfall = totalSpend - spendToDate;
    if (shortfall > 0) {
      newDailyBudget = shortfall / daysLeftToSpend;
    }

    return newDailyBudget;
  }

  /**
   * Method to save budget configurations to the database
   * @param index
   * @param budgetType
   */
  handleBudgetConfigurationSave(index, budgetType): void {
    let budgetManagementFormGroup;
    let budgetConfigurationFormArray;
    let isCurrent = true;

    if (budgetType === 'active') {
      budgetManagementFormGroup = this.activeBudgetManagements[index];
      budgetConfigurationFormArray = this.activeBudgetConfigurationFormArrays[index];
      this.activeBudgetConfigurationFormArrays[index].markAsPristine();
      isCurrent = true;
    } else{
      budgetManagementFormGroup = this.pendingBudgetManagements[index];
      budgetConfigurationFormArray = this.pendingBudgetConfigurationFormArrays[index];
      this.pendingBudgetConfigurationFormArrays[index].markAsPristine();
      isCurrent = false;
    }

    let observables: Observable<any>[] = [];
    const budgetManagement = new BudgetManagement(
      budgetManagementFormGroup.campaignBudgets,
      budgetManagementFormGroup.salesforceBudgetName,
      budgetManagementFormGroup.status,
      budgetManagementFormGroup.budgetType,
      budgetManagementFormGroup.totalBudget,
      budgetManagementFormGroup.adjustedTotalBudget,
      budgetManagementFormGroup.startDate.date,
      budgetManagementFormGroup.endDate.date,
      budgetManagementFormGroup.specificProduct,
      this.accountId,
      budgetManagementFormGroup.salesforceBudgetId,
      isCurrent);

    for (let i = 0; i < budgetManagement.campaignBudgets.length; i++) {
      budgetManagement.campaignBudgets[i].campaignBudgetConfiguration.percentage
        = budgetConfigurationFormArray.controls[i].value.percentage;
      budgetManagement.campaignBudgets[i].campaignBudgetConfiguration.fixedAmount
        = budgetConfigurationFormArray.controls[i].value.fixedAmount;
      budgetManagement.campaignBudgets[i].dailyBudget
        = budgetConfigurationFormArray.controls[i].value.dailyBudget;
      budgetManagement.campaignBudgets[i].monthBudget
        = budgetConfigurationFormArray.controls[i].value.totalSpend;
    }

    observables.push(this.budgetManagementService.saveBudgetConfiguration(budgetManagement));
    this.completeSave(observables);
  }

  /**
   * Function to handle save messages and complete the saving process
   * @param observables
   */
  completeSave(observables: Observable<any>[]): void {
    forkJoin(observables)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
          this.budgetManagementService.getBudgetManagementData(this.accountId);
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save budget configuration.',
            life: 5000
          });
        });
  }
}
