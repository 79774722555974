<form [formGroup]="accountFeatureSwitchFormGroup" autoTrackUnsavedChanges>
<div class="p-grid" style="align-items: center;">
    <div class="p-col-2">
      <label for="account-budget-update-active" class="field-input-label tab-input-label">Automate Google Ads Account Budget
        <span class="pi pi-info-circle"
              pTooltip="If enabled, MPOP will update the Google Ads account budget each day based on the sum of the account's standard, boost, and heavy-up budgets from Salesforce. This feature requires that Google Ads is active."
              tooltipPosition="top"></span></label>
    </div>
    <div class="p-col-4">
      <p-inputSwitch
        formControlName="updateAccountBudget"
        id="account-budget-update-active">
      </p-inputSwitch>
    </div>
    <div class="p-col-2">
      <label for="campaign-budgets-update-active" class="field-input-label tab-input-label">Automate Google Ads Campaign Budgets
        <span class="pi pi-info-circle"
              pTooltip="If enabled, MPOP will update the account’s Google Ads campaign and shared budgets each day based on the configured budget allocation below and the campaign or shared budget’s current spend pacing for the month."
              tooltipPosition="top"></span></label>
    </div>
    <div class="p-col-4">
      <p-inputSwitch
        formControlName="updateCampaignBudget"
        id="campaign-budgets-update-active">
      </p-inputSwitch>
    </div>
  <div class="p-col-2">
    <label class="field-input-label tab-input-label">Account Budget</label>
  </div>
  <div class="p-col-4">
    ${{stringFormatterService.formatNumberToString(accountBudget)}}
  </div>
  <div class="p-col-2">
    <label class="field-input-label tab-input-label">Last Updated</label>
  </div>
  <div class="p-col-4">
    <label *ngIf="googleAdsCampaignBudgetsLastUpdated === null" class="field-input-label tab-input-label">Never</label>
    {{googleAdsCampaignBudgetsLastUpdated | date:'shortDate'}}
  </div>
  <div class="p-col-2">
    <label class="field-input-label tab-input-label">Last Updated</label>
  </div>
  <div class="p-col-4">
    <label *ngIf="googleAdsAccountBudgetLastUpdated === null" class="field-input-label tab-input-label">Never</label>
    {{googleAdsAccountBudgetLastUpdated | date:'shortDate'}}
  </div>
  <div class="p-col-2">
    <button pButton
            id="featureToggleSaveButton"
            class="p-button-success"
            pTooltip="Saves the settings for both the Account and Campaign Budget toggles"
            (click)="saveFeatures()"
            label="Save">
    </button>
  </div>
</div>
</form>
<p-tabView>
    <p-tabPanel header="Standard Budgets">
      <auto-budget-template
        [accountId] = "accountId"
        [expandIcon]="expandIcon"
        [collapseIcon]="collapseIcon"
        [activeBudgetManagements]="standardActiveBudgetManagements"
        [pendingBudgetManagements]="standardPendingBudgetManagements"
        [budgetType]="standardBudget">
      </auto-budget-template>
    </p-tabPanel>
    <p-tabPanel header="Heavy-Up Budgets">
      <auto-budget-template
        [accountId] = "accountId"
        [expandIcon]="expandIcon"
        [collapseIcon]="collapseIcon"
        [activeBudgetManagements]="heavyUpActiveBudgetManagements"
        [pendingBudgetManagements]="heavyUpPendingBudgetManagements"
        [budgetType]="heavyUpBudget">
      </auto-budget-template>
    </p-tabPanel>
  </p-tabView>
