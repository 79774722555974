export class AdFilterSortingOption {
  guid: string;
  adFilterGuid: string;
  label: string;
  sortBy: string;
  sortOrder: string;
  priority: number;

  constructor() {
    this.guid = null;
    this.adFilterGuid = null;
    this.label = null;
    this.sortOrder = null;
    this.sortOrder = null;
    this.priority = null;
  }
}
