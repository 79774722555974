export class CopyPageToAccountRequestModel {
  targetPageId: number;
  sourcePageId: number;
  ncsAccountId: number;
  accountName: string;
  pageName: string;
  oemId: number;

  constructor() {
    this.targetPageId = null;
    this.sourcePageId = null;
    this.ncsAccountId = null;
    this.accountName = null;
    this.pageName = null;
    this.oemId = null;
  }
}
