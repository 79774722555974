import {AbstractControl, ValidatorFn} from '@angular/forms';

export function emptyDropdownValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let result = null;
    if (control.value.value === null) {
      result = {'emptyDropdownSelection': {value: true}};
    }
    return result;
  };
}
