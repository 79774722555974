import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {SourceShortcodeModel} from '../../../../model/source-shortcode.model';
import {ShortcodeService} from '../../../../service/shortcode.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Observable} from 'rxjs';
import {forkJoin} from 'rxjs';
import {PascalValidator} from '../../../../validators/pascal-validator';

@Component({
  selector: 'auto-source-shortcodes',
  templateUrl: './source-shortcodes.component.html',
  styleUrls: ['./source-shortcodes.component.scss']
})
export class SourceShortcodesComponent implements OnInit {
  sourceShortcodesMainFormGroup: UntypedFormGroup;
  sourceShortcodesFormArray: UntypedFormArray;
  sourceShortcodes: SourceShortcodeModel[];

  constructor(
    private titleService: Title,
    private shortcodeService: ShortcodeService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Global Shortcodes');
    this.getSourceShortcodes();
  }

  /**
   * Retrieves source shortcodes from the backend and initializes the form
   */
  getSourceShortcodes(): void {
    this.shortcodeService.getSourceShortcodes()
      .subscribe((result) => {
        this.sourceShortcodes = result;
        this.initForm();
      });
  }

  /**
   * Initializes the source shortcodes form
   */
  initForm(): void {
    const existingSourceShortcodes = this.buildSourceShortcodeFormGroup();
    this.sourceShortcodesFormArray = this.formBuilder.array(existingSourceShortcodes);
    this.sourceShortcodesMainFormGroup = this.formBuilder.group({
      sourceShortcodesFormArray: this.sourceShortcodesFormArray
    });
  }

  /**
   * Function to build source shortcode form group array
   * The result of this function is used to generate the FormArray for sourceShortcodes
   */
  buildSourceShortcodeFormGroup(): UntypedFormGroup[] {
    const sourceShortcodeForms = [];
    if (this.sourceShortcodes !== undefined) {
      this.sourceShortcodes.forEach((sourceShortcode) => {
        sourceShortcodeForms.push(this.createSourceShortcodeForm(sourceShortcode, true));
      });
    }

    return sourceShortcodeForms;
  }

  /**
   * Helper function to create source shortcode form group
   * @param sourceShortcode
   * @param isExistingShortcode
   */
  createSourceShortcodeForm(sourceShortcode, isExistingShortcode: boolean): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(sourceShortcode.id),
      name: new UntypedFormControl({value: sourceShortcode.name, disabled: isExistingShortcode},
        [Validators.required, PascalValidator]),
      description: new UntypedFormControl(sourceShortcode.description),
      archived: new UntypedFormControl(sourceShortcode.archived)
    });
  }

  /**
   * Add source shortcode to sourceShortcodes array
   */
  addSourceShortcode(): void {
    const sourceShortcodes = this.sourceShortcodesMainFormGroup.get('sourceShortcodesFormArray') as UntypedFormArray;
    const newSourceShortcode = new SourceShortcodeModel();
    sourceShortcodes.push(this.createSourceShortcodeForm(newSourceShortcode, false));
  }

  /**
   * Handles creation of confirmation modal for deletion of projected page
   */
  openDeleteSourceShortcodeModal(index): void {
    this.confirmationService.confirm({
      key: 'deleteSourceShortcodeModal',
      defaultFocus: 'none',
      message: 'Deleting this shortcode will remove rendering of this value on live accounts. Please confirm this action.',
      accept: () => {
        this.deleteSourceShortcode(index);
      }
    });
  }

  /**
   * Delete source shortcode and re-initializes the form
   * @param index
   */
  deleteSourceShortcode(index): void {
    const sourceShortcodes = this.sourceShortcodesMainFormGroup.get('sourceShortcodesFormArray') as UntypedFormArray;
    let sourceShortcodeModel = this.mapSourceShortcodeModel(sourceShortcodes.at(index));
    sourceShortcodeModel.archived = true;
    this.shortcodeService.updateSourceShortcode(sourceShortcodeModel)
      .subscribe(
        () => {
          this.getSourceShortcodes();
          this.messageService.add({
            severity: 'success',
            summary: 'Shortcode deleted',
            detail: 'The shortcode was successfully deleted.',
            life: 5000
          });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to delete shortcode.',
            life: 5000
          });
        });
  }

  /**
   * Handles the update(s) and creation(s) of source shortcodes
   */
  handleSourceShortcodesSave(): void {
    let observables: Observable<any>[] = [];
    let shortcodeName = [];
    this.sourceShortcodesFormArray.controls.forEach((sourceShortcodeFormControl) => {
      if (sourceShortcodeFormControl.dirty) {
        let sourceShortcode = this.mapSourceShortcodeModel(sourceShortcodeFormControl);
        if (shortcodeName.includes(sourceShortcode.name)) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Shortcode names must not be duplicated.',
            life: 5000
          });
          return;
        }
        if (sourceShortcode.id) {
          observables.push(this.shortcodeService.updateSourceShortcode(sourceShortcode));
        } else {
          observables.push(this.shortcodeService.saveSourceShortcode(sourceShortcode));
          sourceShortcodeFormControl['controls'].name.disable();
        }
      }
      shortcodeName.push(sourceShortcodeFormControl['controls'].name.value);
    });
    this.completeSave(observables);
  }

  /**
   * Maps a sourceShortcode form control to a SourceShortcodeModel
   * @param sourceShortcodeFormControl
   */
  mapSourceShortcodeModel(sourceShortcodeFormControl): SourceShortcodeModel {
    let sourceShortcode = new SourceShortcodeModel();
    sourceShortcode.id = sourceShortcodeFormControl['controls'].id.value;
    sourceShortcode.name = sourceShortcodeFormControl['controls'].name.value;
    sourceShortcode.description = sourceShortcodeFormControl['controls'].description.value;
    sourceShortcode.archived = sourceShortcodeFormControl['controls'].archived.value;

    return sourceShortcode;
  }

  /**
   * Joins all of the observables emitted from the save event and responds accordingly
   * @param observables
   */
  completeSave(observables: Observable<any>[]): void {
    forkJoin(observables)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
          this.getSourceShortcodes();
          this.sourceShortcodesMainFormGroup.markAsPristine();
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save shortcode.',
            life: 5000
          });
        });
  }
}
