import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup,} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {GoogleAdsService} from '../../../../../../service/google-ads.service';
import {CampaignAutomationConfiguration} from '../../../../../../model/campaign-automation-configuration';
import {FeatureFlagGuard} from '../../../../../../feature-flag.guard';
import {EventEmitterService} from '../../../../../../service/event-emitter.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'auto-automation-configuration-settings',
    templateUrl: './automation-configuration-settings.component.html',
    styleUrl: './automation-configuration-settings.component.scss'
})

export class AutomationConfigurationSettingsComponent implements OnInit {

    @Input() accountId: number;
    @Input() userId: number;
    allSelected = {
        'New Sales': false,
        'Regional': false
    }
    regionalCampaignType = 'Regional';
    newSalesCampaignType = 'New Sales';
    newSalesCampaignsFormArrays = [];
    regionalCampaignsFormArrays = [];
    campaignAutomationConfigurationFormArrays = [];
    newSalesCampaigns = [];
    regionalCampaigns = [];
    selectedCampaignsToUpdate = [];
    updateCampaignOptions = [];
    updateInProgress = false;
    sendSlackNotification = true;
    displayUpdateModal = false;
    formIsDirty = false;
    campaignConfigurationsToSave = [];
    featureEnabled = false;
    allowedCampaignTypes = [this.newSalesCampaignType, this.regionalCampaignType];
    warningMessages = [];
    errorMessages = [];
    creationInProgress = false;
    createDetail = {
      1: 'The Create Regional Campaign Set process has started successfully and will complete within 5 minutes.',
      2: 'The Create New Sales Campaign Set process has started successfully and will complete within 5 minutes.',
    };
    updateDetail = {
      1: 'The Performance Max Branded Campaigns update process has started successfully and will complete within 5 minutes.',
      2: 'The Performance Max New Sales Campaigns update process has started successfully and will complete within 5 minutes.',
    };
    campaignTypeMap: {[key: string]: number} = {
      'Regional': 1,
      'New Sales': 2
    };
    private eventSubscription: Subscription;

    constructor(
        private googleAdsService: GoogleAdsService,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        protected featureFlagGuard: FeatureFlagGuard,
        private confirmationService: ConfirmationService,
        private eventEmitterService: EventEmitterService
    ) {
    }

    /**
     * On init lifecycle hook
     */
    async ngOnInit(): Promise<void> {
        this.featureEnabled = await this.featureFlagGuard.isFeatureOn('campaignAutomationConfiguration');
        this.eventSubscription = this.eventEmitterService.event$.subscribe(
            eventName => {
                if (eventName === 'getTextAssetValidation') {
                    this.getTextAssetValidation(this.accountId);
                }
            }
        );
        this.initializeConfigurationFormArrays();
    }

    /**
     * Save the campaign configurations
     */
    saveCampaignConfigurations() {
        this.googleAdsService.saveAutomationConfigurationSettings(this.campaignConfigurationsToSave).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Campaigns saved successfully'
                });
                this.formIsDirty = false;
                this.campaignAutomationConfigurationFormArrays.forEach((campaignAutomationConfigurationForm) => {
                    if (this.campaignConfigurationsToSave.some((campaign) =>
                      campaign.campaignType === campaignAutomationConfigurationForm.campaignType && campaign.updatesEnabled === true)) {
                      let campaignTypeId = this.campaignTypeMap[campaignAutomationConfigurationForm.campaignType];
                      this.createCampaignSet(campaignTypeId);
                      campaignAutomationConfigurationForm.forms.forEach((form) => {
                        form.markAsPristine();
                      });
                    }
                });
                this.initializeConfigurationFormArrays();
                this.campaignConfigurationsToSave = [];
            },
            () => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to save campaigns'
                });
                this.campaignConfigurationsToSave = [];
            }
        );
    }

  /**
   * Function to create a campaign set for the Google Ads account
   * @param campaignTypeId
   */
  createCampaignSet(campaignTypeId: number): void {
    this.creationInProgress = true;
    this.googleAdsService.createCampaignSet(this.accountId, campaignTypeId)
      .subscribe((result) => {
        if (result.message === 'Create Campaign Set process is in progress.') {
          this.messageService.add({
            severity: 'info',
            summary: 'In Progress',
            detail: result.message,
            life: 5000
          });
        } else {
          this.messageService.add({
            severity: 'success',
            summary: 'Process Started',
            detail: this.createDetail[campaignTypeId],
            life: 5000
          });
        }
          this.creationInProgress = false;
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.message,
            life: 5000
          });
          this.creationInProgress = false;
        }
      );
  }

  /**
   * Method to update the selected campaigns
   */
  updateCampaigns() {
    this.displayUpdateModal = false;
    this.selectedCampaignsToUpdate.forEach((campaign) => {
      this.updatePmaxCampaigns(this.campaignTypeMap[campaign.get('campaignType').value]);
    });
  }

  /**
   * Method to update the Performance Max Campaigns
   */
  updatePmaxCampaigns(campaignTypeId: number): void {
    this.updateInProgress = true;
    this.googleAdsService.updatePmaxCampaignRequest(
      this.accountId,
      campaignTypeId,
      this.sendSlackNotification
    ).subscribe((result) => {
        if (result.message === 'The Performance Max Campaigns update process is in progress.') {
          this.messageService.add({
            severity: 'info',
            summary: 'In Progress',
            detail: result.message,
            life: 5000
          });
        } else {
          this.messageService.add({
            severity: 'success',
            summary: 'Process Started',
            detail: this.updateDetail[campaignTypeId],
            life: 5000
          });
        }
        this.updateInProgress = false;
      },
      (error) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
        life: 5000
      });
        this.updateInProgress = false;
      }
    );
  }

  /**
   * Populate the update campaign options
   */
  populateUpdateCampaignOptions() {
    let campaignsUpdateOptions = [];
    this.campaignAutomationConfigurationFormArrays.forEach((campaignAutomationConfigurationForm) => {
      let campaignsOptions = {
        label: campaignAutomationConfigurationForm.campaignType,
        items: []
      };
      campaignAutomationConfigurationForm.forms.forEach((form) => {
        if (form.get('standardCampaignName').value.includes('Performance Max')) {
          campaignsOptions.items.push({label: form.get('campaign').value, value: form});
        }
      });
      campaignsUpdateOptions.push(campaignsOptions);
    });

    this.updateCampaignOptions = campaignsUpdateOptions;
  }

  /**
   * Open the update modal
   */
  openUpdateModal() {
    this.displayUpdateModal = true;
  }

  /**
   * Close the update modal
   */
  closeUpdateModal() {
    this.displayUpdateModal = false;
    this.selectedCampaignsToUpdate = [];
  }

    /**
     * Build the form group for the campaign automation configuration
     * @param configurations
     */
    buildCampaignAutomationConfigurationFormGroup(configurations: CampaignAutomationConfiguration): UntypedFormGroup {
        return this.formBuilder.group({
            configurationId: new UntypedFormControl(configurations.configurationId),
            accountId: new UntypedFormControl(configurations.accountId),
            campaign: new UntypedFormControl(configurations.campaign),
            standardCampaignName: new UntypedFormControl(configurations.standardCampaignName),
            campaignType: new UntypedFormControl(configurations.campaignType),
            updatesEnabled: new UntypedFormControl(configurations.updatesEnabled),
            modifiedUser: new UntypedFormControl(configurations.modifiedUser),
            modified: new UntypedFormControl(configurations.modified),
            campaignLastUpdated: new UntypedFormControl(configurations.campaignLastUpdated)
        });
    }

    /**
     * Populate the form with the configurations
     */
    private populateForm(configurations: CampaignAutomationConfiguration[]) {
        configurations.forEach((configuration) => {
            if (configuration.campaignType === this.newSalesCampaignType) {
                this.newSalesCampaigns.push(this.buildCampaignAutomationConfigurationFormGroup(configuration));
            } else if (configuration.campaignType === this.regionalCampaignType) {
                this.regionalCampaigns.push(this.buildCampaignAutomationConfigurationFormGroup(configuration));
            }
        });
    }

    /**
     * Select all campaigns for a given campaign type
     */
    selectAllCampaigns(configurations, campaignType): void {
        if (this.allowedCampaignTypes.includes(campaignType)) {
            this.allSelected[campaignType] = !this.allSelected[campaignType];
            this.formIsDirty = true;
            configurations.forEach(control => {
                control.get('updatesEnabled').setValue(this.allSelected[campaignType]);
                control.markAsDirty();
            });
        }
    }

    /**
     * Select a single campaign
     */
    selectCampaign(campaign, campaignForms): void {
        this.formIsDirty = true;
        campaign.markAsDirty();
        let allSelected = true;
        campaignForms.forms.forEach(control => {
            allSelected = allSelected && control.get('updatesEnabled').value;
        });

        this.allSelected[campaignForms.campaignType] = allSelected;
    }

    /**
     * Open the confirm save modal
     */
    openConfirmSaveModal() {
      this.campaignConfigurationsToSave = [];
        this.confirmationService.confirm({
            key: 'saveCampaignsModal',
            defaultFocus: 'none',
            message: this.getSaveMessage(),
            header: 'Save Confirmation',
            accept: () => {
                this.saveCampaignConfigurations();
            }
        });
    }

    /**
     * Get the save message
     */
    getSaveMessage(): string {
        let result = 'Are you sure you want to save the following changes:';
        this.campaignAutomationConfigurationFormArrays.forEach((campaignType) => {
            campaignType.forms.forEach((form) => {
                let isOptedInText = 'Opted Into: ';
                if (!form.get('updatesEnabled').value) {
                    isOptedInText = 'Opted Out of: ';
                }
                if (form.dirty || form.get('configurationId').value === null) {
                    result += `<br><b>${isOptedInText}</b>`;
                    result += ` <i>${form.get('campaign').value}</i>`;
                    this.campaignConfigurationsToSave.push(this.createCampaignAutomationConfiguration(form));
                }
            });

        });

        return result;
    }

    /**
     * Create a campaign automation configuration
     * @param form
     */
    createCampaignAutomationConfiguration(form): CampaignAutomationConfiguration {
        return new CampaignAutomationConfiguration(
            form.get('configurationId').value,
            form.get('accountId').value,
            form.get('campaign').value,
            form.get('standardCampaignName').value,
            form.get('campaignType').value,
            form.get('updatesEnabled').value,
            form.get('modifiedUser').value
        );
    }

    /**
     * Get the text asset validation
     * @param accountId
     */
    getTextAssetValidation(accountId: number): void {
        this.warningMessages = [];
        this.errorMessages = [];
        this.googleAdsService.getTextAssetValidation(accountId).subscribe(
            (response) => {
                this.warningMessages = response.warnings;
                this.errorMessages = response.errors;
            }
        );
    }

    /**
     * Initialize the select all checkboxes
     */
     initializeSelectAll() {
        this.campaignAutomationConfigurationFormArrays.forEach((campaignType) => {
            let allSelected = true;
            campaignType.forms.forEach(control => {
                allSelected = allSelected && control.get('updatesEnabled').value;
            });
            this.allSelected[campaignType.campaignType] = allSelected;
        });
    }

  /**
   * Reset the forms
   */
  resetForms() {
      this.regionalCampaigns = [];
      this.newSalesCampaigns = [];
      this.campaignAutomationConfigurationFormArrays = [];
    }

  /**
   * Initialize the configuration form arrays on page load and configuration save
   */
  initializeConfigurationFormArrays() {
    this.googleAdsService.getAutomationConfigurationSettings(this.accountId).subscribe(
      (response) => {
        this.resetForms();
        this.populateForm(response);
        this.regionalCampaignsFormArrays = this.regionalCampaigns;
        this.newSalesCampaignsFormArrays = this.newSalesCampaigns;
        this.campaignAutomationConfigurationFormArrays.push(
          {
            campaignType: this.regionalCampaignType,
            forms: this.regionalCampaignsFormArrays
          }
        );
        this.campaignAutomationConfigurationFormArrays.push(
          {
            campaignType: this.newSalesCampaignType,
            forms: this.newSalesCampaignsFormArrays
          }
        );
        this.populateUpdateCampaignOptions();
        this.getTextAssetValidation(this.accountId);
        this.initializeSelectAll();
      }
    );
  }
}
