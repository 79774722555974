import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {SourceShortcodeModel} from '../model/source-shortcode.model';
import {AccountShortcodeModel} from '../model/account-shortcode.model';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ShortcodeService extends AbstractHttpService {
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  getOptions = {
    headers: this.acceptJsonHeaders,
    withCredentials: true
  };

  patchOptions = {
    headers: this.contentTypeJsonHeadersForPatch,
    withCredentials: true
  };

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  getSourceShortcodes(): Observable<SourceShortcodeModel[]> {
    return this.httpClient.get<SourceShortcodeModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/source-shortcode`,
      this.getOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  getAccountShortcodes(accountId: number): Observable<AccountShortcodeModel[]> {
    return this.httpClient.get<AccountShortcodeModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-shortcode/${accountId}`,
      this.getOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  saveSourceShortcode(sourceShortCode: SourceShortcodeModel): Observable<any> {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/source-shortcode`,
      sourceShortCode,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  saveAccountShortcode(accountShortCode: AccountShortcodeModel): Observable<any> {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-shortcode`,
      accountShortCode,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateSourceShortcode(sourceShortCode: SourceShortcodeModel): Observable<any> {
    return this.httpClient.patch(
      `//${environment.autoDomain}:${environment.autoPort}/api/source-shortcode/${sourceShortCode.id}`,
      sourceShortCode,
      this.patchOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateAccountShortcode(accountShortCode: AccountShortcodeModel): Observable<any> {
    return this.httpClient.patch(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-shortcode/${accountShortCode.id}`,
      accountShortCode,
      this.patchOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
