import { Injectable } from '@angular/core';
import {UserInterface} from "../../model/user.interface";

@Injectable({
  providedIn: 'root'
})
export class StringFormatterService {

  constructor() { }


  public titleCaseToCamelCase(inputString) {
    inputString = inputString.replace(/\s+/g, '');

    return this.firstLetterToLowerCase(inputString);
  }

  public camelCaseToSentenceCase(inputString) {
    let result = '';
    if (typeof inputString === 'string' && inputString.length > 0) {
      result = inputString.replace( /([A-Z])/g, ' $1' );
      result = result.charAt(0).toUpperCase() + result.slice(1);
    }
    return result;
  }

  public firstLetterToLowerCase(inputString) {
    let result = '';
    if (typeof inputString === 'string' && inputString.length > 0) {
      result = inputString.charAt(0).toLowerCase() + inputString.slice(1);
    }

    return result;
  }

  public formatNumberToString(inputNumber) {
    let result = '';

    if (typeof inputNumber === 'number') {
      result = inputNumber.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return result;
  }

  public formatGoogleAdsAccountId(adwordsId: string): string {
    const cleaned = ('' + adwordsId).replace(/\D/g, '');

    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      adwordsId =  `${match[1]}-${match[2]}-${match[3]}`;
    }

    return adwordsId;
  }

  public firstLetterToUpperCase(inputString) {
    let result = '';
    if (typeof inputString === 'string' && inputString.length > 0) {
      result = inputString.charAt(0).toUpperCase() + inputString.slice(1);
    }

    return result;
  }

  public trimWhiteSpace(inputString){
    let result = inputString;
    if (typeof result === 'string' || result instanceof String) {
      result = result.trim();
    }
    return result;
  }

  /**
   * Returns only the first name for a user
   * @param user
   */
  public fullNameToFirstName(user: UserInterface): string {
    const tokens = user.name.split(" ");
    let firstName = user.name;

    if (tokens.length > 0)
    {
      firstName = tokens[0];
    }

    return firstName;
  }

  /**
   * Converts any string to camelCase
   * @param input
   */
  public toCamelCase(input: string): string {
    return input
      .split(/\W+/)
      .filter(Boolean)
      .map((word, index) =>
        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join('');
  }

  public camelCaseToTitleWithAcronyms(input: string): string {
    let result = input.replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2');
    result = result.split(/\s+/).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

    return result;
  }
}
