import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AccountAdTypesInterface} from '../model/account-ad-types.interface';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {AbstractHttpService} from './abstract-http-service';
import {PromotionalAdsConfiguration} from '../model/promotional-ads-configuration';
import {AdTypeModel} from '../model/ad-type.model';
import {PromotionalAdsConfigurationInterface} from '../model/promotional-ads-configuration.interface';
import {AdFilter} from '../model/ad-filter/ad-filter';
import {BusinessSubcategoryInterface} from '../model/business-subcategory.interface';

@Injectable({
  providedIn: 'root'
})
export class AdTypesSettingsService extends AbstractHttpService {
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  constructor(private httpClient: HttpClient) {
    super();
  }

  getAdTypesAccountSettings(accountId: number): Observable<AccountAdTypesInterface> {
    return this.httpClient.get<AccountAdTypesInterface>(
      `//${environment.autoDomain}:${environment.autoPort}/api/ad-types?accountId=${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateAdTypesAccountSettings(accountSettings: AdTypeModel) {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/ad-types`,
      accountSettings,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  createAccountAdTypes(adTypeToggled: AdTypeModel) {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/ad-types`,
      adTypeToggled,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  initializePromotionalAdsConfigurations(accountId: number): Observable<any> {
    const postBody = {
      accountId
    };

    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/promotional-ads-configurations`,
      postBody,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public getPromotionalAdsConfigurations(accountId: number): Observable<PromotionalAdsConfigurationInterface[]>{
    return this.httpClient.get<PromotionalAdsConfigurationInterface[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/promotional-ads-configurations?accountId=${accountId}`,
      this.defaultOptions
    ).pipe(
      mergeMap((asIs: PromotionalAdsConfigurationInterface[]) => asIs),
      map((promotionalAdsConfiguration: PromotionalAdsConfigurationInterface) => ({
        guid: promotionalAdsConfiguration.guid,
        accountBusinessCategoryGuid: promotionalAdsConfiguration.accountBusinessCategoryGuid,
        accountBusinessCategoryName: promotionalAdsConfiguration.accountBusinessCategoryName,
        disclosureText: promotionalAdsConfiguration.disclosureText,
        businessSubcategories: promotionalAdsConfiguration.businessSubcategories,
        promotionalAdCallToActions: promotionalAdsConfiguration.promotionalAdCallToActions,
      })),
      toArray(),
      catchError(this.handleError)
    );
  }

  public getPromotionalAdTypeSubcategories(accountBusinessCategoryGuid: string): Observable<BusinessSubcategoryInterface[]>{
    return this.httpClient.get<BusinessSubcategoryInterface[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/business_subcategory?businessCategoryTypeGuid=${accountBusinessCategoryGuid}`,
      this.defaultOptions
    ).pipe(
      mergeMap((asIs: BusinessSubcategoryInterface[]) => asIs),
      map((subcategory: BusinessSubcategoryInterface) => ({
        id: subcategory.id,
        name: subcategory.name,
      })),
      toArray(),
      catchError(this.handleError)
    );
  }

  public getActionOptions() {
    return this.httpClient.get('assets/mockData/call-to-action-options.json')
      .pipe(
        catchError(this.handleError)
      );
  }

  public updatePromotionalAdTypeConfigurations(configurations: object): Observable<any> {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/promotional-ads-configurations`,
      configurations,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public getAdRuleDataMapping(): Observable<any> {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/ad-rule-data-mapping`,
      this.defaultOptions
      ).pipe(
      catchError(this.handleError)
    );
  }

  public getVehicleAdOfferRules(accountId: string, condition: string): Observable<any> {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/vehicle-ad-offer-rules?accountId=${accountId}&condition=${condition}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public updateVehicleAdOfferRules(vehicleAdOfferRules: any): Observable<any> {
    const url = `//${environment.autoDomain}:${environment.autoPort}/api/vehicle-ad-offer-rules`;
    return this.httpClient.put(
      url,
      {vehicleAdOfferRules: vehicleAdOfferRules},
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public deleteVehicleAdOfferRules(ruleIds: string): Observable<any> {
    return this.httpClient.delete(
      `//${environment.autoDomain}:${environment.autoPort}/api/vehicle-ad-offer-rules?ruleIds=${ruleIds}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
