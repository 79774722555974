import { Injectable } from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {MediaManagementModel} from "../model/media-management.model";

@Injectable({
  providedIn: 'root'
})
export class MediaManagementService extends AbstractHttpService {

  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  constructor(private httpClient: HttpClient) {
    super();
  }

  /**
   * Gets Media Management settings for the account
   */
  public getMediaManagementSettings(accountId: number): Observable<MediaManagementModel> {
    return this.httpClient.get<MediaManagementModel>(
      `//${environment.autoDomain}:${environment.autoPort}/api/media-management-settings/${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Uploads Google Ads Feed for the account
   * @param accountId
   */
  public uploadGoogleVehicleAdsFeed(accountId: number): Observable<any> {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/upload-gva-feed`,
      {
        accountId: accountId
      },
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Uploads Google Ads Feed for the account
   * @param accountId
   * @param measurementId
   */
  populateGoogleAnalyticsProperty(accountId: number, measurementId: string): Observable<any> {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/google-analytics/property/populate-property`,
      {
        accountId: accountId,
        measurementId: measurementId
      },
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Gets Google Analytics Properties for the account
   * @param accountId
   */
  getMediaTagScripts(accountId: number): Observable<any> {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/media_tag?accountId=${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Gets Google Analytics Properties for the account
   * @param accountId
   */
  getConversionsForAccount(accountId: number): Observable<any> {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/google_ads_conversion_action/get?accountId=${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Creates Google Ads Conversions for the account
   * @param accountId
   */
  createConversionsForAccount(accountId: number): Observable<any> {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/google_ads_conversion_action/create?accountId=${accountId}`,
      {
        accountId: accountId,
      },
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Generates Media Tag for the account
   * @param accountId
   */
  generateMediaTagForAccount(accountId: number): Observable<any> {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/media_tag/generate`,
      {
        accountId: accountId,
      },
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Saves Media Management settings for the account
   * @param settings
   */
  saveMediaManagementSettings(settings: any): Observable<any> {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/media-management-settings`,
        settings,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
