import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MediaManagementComponent} from "../media-management.component";
import {MediaManagementModel} from "../../../../../model/media-management.model";
import {AccountInterface} from "../../../../../model/account.interface";
import {MediaManagementEnumerator} from "../../../../../enumerators/media-management-enumerator";

@Component({
  selector: 'auto-facebook-settings',
  templateUrl: './facebook-settings.component.html',
  styleUrl: './facebook-settings.component.scss'
})
export class FacebookSettingsComponent extends MediaManagementComponent implements OnInit {

  @Output() dataEmitter = new EventEmitter<UntypedFormGroup>();
  @Input() settings: MediaManagementModel;
  @Input() account: AccountInterface;

  facebookSettingsForm: UntypedFormGroup;

  ngOnInit(): void {
    this.initializeFacebookSettingsFormGroup()
  }

  /**
   * Initializes Facebook Settings Form Group
   */
   initializeFacebookSettingsFormGroup() {
    const pattern = MediaManagementEnumerator.FacebookDefaultLabelPattern;
    this.facebookSettingsForm = this.formBuilder.group({
      facebookPageId: new UntypedFormControl(this.settings.facebookPageId),
      facebookShareActive: new UntypedFormControl(this.settings.facebookShareActive),
      facebookDealerName: new UntypedFormControl(this.settings.facebookDealerName),
      facebookMarketplaceEnabled: new UntypedFormControl(this.settings.facebookMarketplaceEnabled),
      facebookAdsEnabled: new UntypedFormControl(this.settings.facebookAdsEnabled),
      advertiseAllVehicles: new UntypedFormControl(this.settings.advertiseAllVehicles),
      facebookPixelId: new UntypedFormControl(this.settings.facebookPixelId),
      facebookAdsAccountId: new UntypedFormControl(this.settings.facebookAdsAccountId),
      facebookPixelHash: new UntypedFormControl(this.settings.facebookPixelHash),
      facebookAdsCustomLabel0: new UntypedFormControl(this.settings.facebookAdsCustomLabel0),
      facebookAdsCustomLabel1: new UntypedFormControl(this.settings.facebookAdsCustomLabel1),
      facebookAdsCustomLabel2: new UntypedFormControl(this.settings.facebookAdsCustomLabel2),
      facebookAdsFallbackLabel0: new UntypedFormControl(this.settings.facebookAdsFallbackLabel0),
      facebookAdsFallbackLabel1: new UntypedFormControl(this.settings.facebookAdsFallbackLabel1),
      facebookAdsFallbackLabel2: new UntypedFormControl(this.settings.facebookAdsFallbackLabel2),
      facebookAdsDefaultLabel: new UntypedFormControl(this.settings.facebookAdsDefaultLabel, [Validators.required, Validators.minLength(1), Validators.pattern(pattern)]),
      facebookPageAccessToken: new UntypedFormControl(this.settings.facebookPageAccessToken),
      facebookAdsCatalogUrl: new UntypedFormControl(
        this.replaceUrlPlaceholders(MediaManagementEnumerator.FacebookAdsCatalogUrl, this.account.accountFolder)
      )
    });

    this.dataEmitter.emit(this.facebookSettingsForm);
  }
}
