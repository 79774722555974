export class PromotePageRequestModel {
  oldProjectedPageId: number;
  newProjectedPageId: number;
  oldProjectedPageName: string;
  newProjectedPageName: string;
  ncsAccountId: number;

  constructor() {
    this.oldProjectedPageId = null;
    this.newProjectedPageId = null;
    this.oldProjectedPageName = null;
    this.newProjectedPageName = null;
    this.ncsAccountId = null;
  }
}
