<auto-main-navigation [accountId]="accountId"></auto-main-navigation>

<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">Inventory Settings</h1>
  </p-header>

  <div class="main-content-wrapper p-grid">
    <div class="p-col-3">
      <p-panelMenu [model]="settingsSideNav">
      </p-panelMenu>
    </div>

    <p-panel class="p-col ad-types-wrapper">

    <p-header class="mapping-rules-header-wrapper">
      <h2 class="ad-types-header-text">Mapping Rules</h2>
    </p-header>
    <div class="p-grid field-form-wrapper">
        <form *ngIf="accountMappingRules && adFieldOptions" [formGroup]="mappingRulesForm" autoTrackUnsavedChanges class="p-col">
          <div class="p-grid">
            <label class="p-col-4 field-input-label field-form-inputs-wrapper" for="ad-field">Ad Field</label>
            <label class="p-col-4 field-input-label field-form-inputs-wrapper" for="inventory-value">Inventory Value</label>
            <label class="p-col-4 field-input-label field-form-inputs-wrapper" for="replacement-value">Replacement Value</label>
          </div>
          <div formArrayName="rules">
            <div *ngFor="let rule of this.rules.controls; let i=index">
              <div [formGroupName]="i" class="p-grid">
                <div class="p-fluid field-form-inputs-wrapper">
                  <p-dropdown
                    id="ad-field"
                    [options]="adFieldOptions"
                    [style]="{width: '100%'}"
                    dataKey="value"
                    optionLabel="label"
                    filter="true"
                    formControlName="adField"
                    [ngClass]="{'p-invalid': !rule.pristine && rule.get('adField').hasError('emptyDropdownSelection')}">
                  </p-dropdown>
                  <span *ngIf="!rule.pristine && rule.get('adField').hasError('emptyDropdownSelection')" class="p-invalid is-required-warning">
                  Ad field selection is required.
                </span>
                </div>
                <div class="p-fluid field-form-inputs-wrapper">
                  <input type="text"
                         pInputText
                         id="inventory-value"
                         formControlName="inventoryValue"
                         [ngClass]="{'p-invalid': !rule.pristine && rule.get('inventoryValue').hasError('required')}"
                  />
                  <span *ngIf="!rule.pristine && rule.get('inventoryValue').hasError('required')" class="p-invalid is-required-warning">
                  Inventory value is required.
                </span>
                </div>
                <div class="p-fluid field-form-inputs-wrapper">
                  <input type="text"
                         pInputText
                         id="replacement-value"
                         formControlName="replacementValue"
                         [ngClass]="{'p-invalid': !rule.pristine && rule.get('replacementValue').hasError('required')}"
                  />
                  <span *ngIf="!rule.pristine && rule.get('replacementValue').hasError('required')" class="p-invalid is-required-warning">
                  Replacement value is required.
                </span>
                </div>
                <div class="p-fluid field-form-inputs-wrapper">
                  <button pButton class="delete-button" type="button" icon="pi pi-trash" (click)="deleteRule(i)"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="rule-button-wrapper">
          <button pButton type="button" class="add-rules-button" label="Add Rule" (click)="addRuleForm()"></button>
          <button *ngIf="accountMappingRules" class="save-button" pButton type="button" label="Save Rules" (click)="saveRules()" [disabled]="!mappingRulesForm.valid"></button>
          </div>
        </form>
      </div>
    </p-panel>
    </div>

</p-panel>

<auto-footer></auto-footer>
