import {Injectable} from '@angular/core';
import {UserInterface} from '../model/user.interface';
import {HttpClient, HttpContext, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AbstractHttpService} from './abstract-http-service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UserAccessModel} from '../model/user-access.model';
import {PageModel} from '../model/page.model';
import {FeatureFlagModel} from '../model/feature-flag.model';
import {IS_PUBLIC_ENDPOINT} from "../inteceptors/auth.inteceptor";
import {MpopUserModel} from "../model/mpop-user.model";

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractHttpService {
  private user;

  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withHeaders: true
  };

  publicEndpointOptions = {
    headers: this.acceptJsonHeaders,
    withHeaders: true,
    context: new HttpContext().set(IS_PUBLIC_ENDPOINT, true)
  }

  postOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  patchOptions = {
    headers: this.contentTypeJsonHeadersForPatch,
    withCredentials: true
  };

  constructor(private httpClient: HttpClient) {
    super();
  }

  public async getUserPromise(): Promise<UserInterface> {
    return this.getUser().toPromise();
  }

  public getUser(): Observable<UserInterface> {
    return this.httpClient.get<UserInterface>(
      `//${environment.autoDomain}:${environment.autoPort}/api/current_user_permissions`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public getMpopUsers(): Observable<MpopUserModel[]> {
    return this.httpClient.get<MpopUserModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/mpop-users`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Method to get the user logins
   *
   * @param userId
   * @return Observable<any>
   */
  public getRecentLogins(userId: number): Observable<any> {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/recent-logins?userId=${userId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public getFeatureFlags(): Observable<FeatureFlagModel[]> {
    return this.httpClient.get<FeatureFlagModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/feature-flag`,
      this.publicEndpointOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public refreshSession(sessionRequest): Promise<any> {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/refresh-session`,
      sessionRequest,
      this.postOptions
    ).toPromise();
  }

  public userHasAccessKeys(user: UserInterface, accountId: number, requiredAccessKeys: string[]): boolean {
    let hasAccess = true;
    requiredAccessKeys.forEach((requiredAccessKey) => {
      if (!(accountId in user.permissions)
        || !(requiredAccessKey in user.permissions[accountId])
        || user.permissions[accountId][requiredAccessKey] === false) {
        hasAccess = false;
      }
      if ((isNaN(accountId) || accountId === undefined) && !hasAccess) {
        const keys = Object.keys(user.permissions);
        if (user.permissions[keys.shift()][requiredAccessKey] === true) {
          hasAccess = true;
        }
      }
    });

    return hasAccess;
  }

  public changePassword(changePasswordData): Observable<any> {
    return this.httpClient.patch(
      `//${environment.autoDomain}:${environment.autoPort}/api/users/password-change`,
      changePasswordData,
      this.patchOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public resetPassword(resetPasswordData): Observable<any> {
    return this.httpClient.patch(
      `//${environment.autoDomain}:${environment.autoPort}/api/users/password-reset`,
      resetPasswordData,
      this.patchOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public getResetCodeStatus(resetCode): Observable<any> {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/users/reset-token/${resetCode}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Method to request a password reset
   *
   * @param username
   * @return Observable<any>
   */
  public requestResetPassword(username): Observable<any> {
    return this.httpClient.patch(
      `//${environment.autoDomain}:${environment.autoPort}/api/users/password-forgot-link`,
      username,
      this.patchOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public logout(logoutRequest): Observable<any> {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/logout`,
      logoutRequest,
      this.postOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
