export class PromotionalAdCallToAction {
  promotionalAdsConfigurationGuid: string;
  displayText: string;
  action: string;
  url: string;
  target: string;
  order: number;

  constructor() {
    this.promotionalAdsConfigurationGuid = null;
    this.displayText = null;
    this.action = null;
    this.url = null;
    this.target = null;
    this.order = null;
  }
}
