import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {CookieService} from './service/cookie.service';
import {UserService} from './service/user.service';
import {UserInterface} from './model/user.interface';
import { decodeJwt } from 'jose';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  user: UserInterface;
  accountId: number;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private userService: UserService) { }

  /**
   * Checks if view can be activated
   * @param next
   * @param state
   */
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.cookieService.setCookie('referrer', window.location.pathname, 1);
    this.accountId = parseInt(next.params.accountId);
    this.isJwtValid();
    this.isSessionValid();

    await this.userService.getUserPromise().then(result => {
      this.user = result;
    }).catch(() => {
      this.router.navigate(["/"]);
    });

    this.isUserValid(next);

    return true;
  }

  isJwtValid() {
    const jwtCookie = this.cookieService.getCookie('auto-token');
    let isExpired = true;

    if (jwtCookie) {
      const decodedJwt = decodeJwt(jwtCookie);
      isExpired = Date.now() >= decodedJwt.exp * 1000
    }

    if (!jwtCookie || isExpired) {
      this.router.navigate(["/"]);
    }
  }

  /**
   * Checks if the user has a valid session
   */
  isSessionValid() {
    const sessionCookie = this.cookieService.getCookie('PHPSESSID');
    if (!sessionCookie) {
      this.router.navigate(["/"]);
    }
  }

  /**
   * Checks if the user's permissions are valid
   * @param route
   */
  isUserValid(route) {
    let userAccessKeys = ['admin'];
    if ('userAccessKeys' in route.data) {
      userAccessKeys = [route.data['userAccessKeys']];
    }

    if (!this.userService.userHasAccessKeys(this.user, this.accountId, userAccessKeys)) {
      window.location.href = route.data['externalErrorPage'];
    }
  }
}
