import {Component, HostListener, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DynamicFormItemsService} from '../../../../service/dynamic-form-items.service';
import {StringFormatterService} from '../../../../service/utilities/string-formatter.service';
import {NavigationService} from '../../../../service/navigation.service';
import {Title} from '@angular/platform-browser';
import {AdTypesSettingsService} from '../../../../service/ad-types-settings.service';
import {AccountAdTypesInterface} from '../../../../model/account-ad-types.interface';
import {BusinessCategoryConfigurationsService} from '../../../../service/business-category-configurations.service';
import {PromotionalAdCallToAction} from '../../../../model/promotional-ad-call-to-action';
import {Observable} from 'rxjs';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {PromotionalAdsConfigurationInterface} from '../../../../model/promotional-ads-configuration.interface';
import {BusinessSubcategoryInterface} from '../../../../model/business-subcategory.interface';
import {emptyDropdownValidator} from '../../../../validators/empty-dropdown-selection.directive';
import {FeatureFlagGuard} from "../../../../feature-flag.guard";
import {OfferRulesComponent} from './offer-rules/offer-rules.component';

@Component({
  selector: 'auto-new-ad-types',
  templateUrl: './new-ad-types.component.html',
  styleUrls: ['./new-ad-types.component.scss']
})
export class NewAdTypesComponent implements OnInit {
  @ViewChildren(OfferRulesComponent) offerRulesComponents: QueryList<OfferRulesComponent>;
  accountId: number;
  accountAdTypes: AccountAdTypesInterface | null = null;
  businessCategoryConfigurations = null;
  newBusinessCategoryType = null;
  usedBusinessCategoryType = null;
  formItems;
  componentName = 'Vehicle Ads';
  masterFormGroup: UntypedFormGroup | null = null;
  newMoneyFactorMarkupControl: UntypedFormControl;
  usedMoneyFactorMarkupControl: UntypedFormControl;
  leasePaymentTypeControl: UntypedFormControl;
  calculateLeaseFromControl: UntypedFormControl;
  settingsSideNav: MenuItem[];
  promotionalAdMasterFormGroup: UntypedFormGroup | null = null;
  promotionalAdTypesCollapsed: boolean;
  accountServiceCategories: BusinessSubcategoryInterface[];
  promotionalAdsConfigurations = null;
  callsToAction: UntypedFormArray;
  actionOptions: Observable<any> | null;
  promotionalAdTabs: MenuItem[] = [];
  decimalPattern = '^(0(\\.\\d+)?|\\.\\d*[1-9]\\d*)$';
  targetOptions = [
    {label: 'Current Window', value: '_self'},
    {label: 'New Window/Tab', value: '_blank'}
  ];
  expandIcon = 'pi pi-chevron-right';
  collapseIcon = 'pi pi-chevron-down';
  feeType = [
    {label: 'Include in Payment', value: 'includeInPayment'},
    {label: 'Due at Signing', value: 'dueAtSigning'},
    {label: 'Extra', value: 'extra'},
    {label: 'Not Used', value: 'notUsed'}
  ];
  rebateDisclosureFormatOptions = [
    {value: 'grouped', label: 'Grouped'},
    {value: 'itemized', label: 'Itemized'}
  ];
  leaseSellingPriceOptions = [
    {value: 'discountPrice', label: 'Discount price'},
    {value: 'msrp', label: 'MSRP'}
  ];
  leaseIncentiveTypeOptions = [
    {value: 'subvented fallback', label: 'Subvented (Fallback to Standard)'},
    {value: 'subvented no fallback', label: 'Subvented (No Fallback)'},
    {value: 'standard', label: 'Standard'}
  ];
  financeAprSourceOptions = [
    {value: 'incentiveService', label: 'Incentive Service'},
    {value: 'incentiveServiceFallback', label: 'Incentive Service (Fallback to Account Defaults)'},
    {value: 'accountDefaults', label: 'Account Defaults'}
  ];
  aprSourceOptions = [
    {value: true, label: 'Incentive Service (Fallback to Account Defaults)'},
    {value: false, label: 'Account Defaults'}
  ];
  calculateLeaseFromOptions = [
    {value: 'leaseDownPayment', label: 'Down Payment'},
    {value: 'dueAtSigning', label: 'Due at Signing'}
  ];
  leasePaymentTypeOptions = [
    {value: 'dollar', label: '$'},
    {value: 'percent', label: '%'}
  ];
  leasePricingBaseOptions = [
    {value: 'capCost', label: 'Cap Cost'},
    {value: 'msrp', label: 'MSRP'},
    {value: 'discountPrice', label: 'Discount Price'}
  ];
  pricingOptions = [
    {value: 'addToSalePrice', label: 'Add Purchase Fee to Sale Price'},
    {value: 'includeInSalePrice', label: 'Purchase Fee is Included in Sale Price'}
    ];
  dirtyOfferRulesForms = 0;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private dynamicFormItemsService: DynamicFormItemsService,
    private formBuilder: UntypedFormBuilder,
    private navigationService: NavigationService,
    private stringFormatter: StringFormatterService,
    private adTypesSettingsService: AdTypesSettingsService,
    private messageService: MessageService,
    private businessCategoryConfigurationsService: BusinessCategoryConfigurationsService,
    private featureFlagGuard: FeatureFlagGuard
  ) {
  }

  /**
   * Init component
   */
  ngOnInit(): void {
    this.titleService.setTitle('Ad Types Settings');
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.featureFlagGuard.checkFeatureAccess('adTypes', 'settings/ad-types/' + this.accountId);
      this.initNavigation();
      this.getAdTypesAccountSettings()
        .then(() => {
          if (this.accountAdTypes.vehicleAdType.enabled && this.accountId) {
            this.getBusinessCategoryConfigurations(this.accountId);
          }
          if (this.accountId) {
            this.getPromotionalAdsConfigurations(this.accountId);
          }
        });
      this.getPromotionalAdTypeSubcategories('');
      this.actionOptions = this.adTypesSettingsService.getActionOptions();
    });
  }

  /**
   * Init navigation
   */
  initNavigation() {
    this.navigationService.getMenuItems(this.accountId, 'settings-view-side-menu.configuration.json')
      .then((navItems) => {
        this.settingsSideNav = navItems;
      });
  }

  /**
   * Get ad types account settings -- needed to set this.vehicleAdsEnabled
   */
  getAdTypesAccountSettings() {
    return new Promise(((resolve, reject) => {
      this.adTypesSettingsService.getAdTypesAccountSettings(this.accountId)
        .subscribe(
          (result: AccountAdTypesInterface) => {
            this.accountAdTypes = result;
            this.parseAdTypes(result);
            resolve(null);
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to find account info. Please try again.',
              life: 5000
            });
            reject();
          });
    }));
  }

  /**
   * Gets promotional ad types subcategories
   */
  getPromotionalAdTypeSubcategories(accountBusinessCategoryGuid) {
    this.adTypesSettingsService.getPromotionalAdTypeSubcategories(accountBusinessCategoryGuid)
      .subscribe(
        (result: BusinessSubcategoryInterface[]) => {
          this.accountServiceCategories = result;
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to find promotional ads subcategories. Please try again.',
            life: 5000
          });
        });
  }

  /**
   *  This is a dirty function to be able to parse the ad types into the defined interface
   * @param adTypes
   */
  parseAdTypes(adTypes) {
    adTypes.forEach((adType) => {
      this.accountAdTypes.accountId = adType.accountId;
      if (adType.name === 'vehicle') {
        this.accountAdTypes.vehicleAdType = adType;
      } else if (adType.name === 'promotional') {
        this.accountAdTypes.promotionalAdType = adType;
      }
    });
  }

  /**
   * Get account's business category configurations & init the vehicle ads form
   *
   * @param accountId
   */
  getBusinessCategoryConfigurations(accountId) {
    this.businessCategoryConfigurationsService.getBusinessCategoryConfigurations(this.componentName, accountId)
      .subscribe(
        (result) => {
          this.businessCategoryConfigurations = result;
          const empty: any = {};
          if (this.businessCategoryConfigurations['businessCategoryTypes'] !== empty && this.masterFormGroup === null) {
            this.initForm(this.businessCategoryConfigurations['businessCategoryTypes']);
          }
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to find business category configurations. Please try again.',
            life: 5000
          });
        });
  }

  /**
   * init form - trigger build FormItems, FormControls, and FormGroups
   */
  initForm(businessCategories: any[]) {
    const formItemsResult = {};
    businessCategories.forEach((businessCategory) => {
      const businessCategoryName = businessCategory.name;
      if  (businessCategoryName === 'New') {
        this.newBusinessCategoryType = businessCategory;
        formItemsResult[businessCategoryName] = this.handleBuildNewForm(businessCategory);
      } else if (businessCategoryName === 'Used') {
        this.usedBusinessCategoryType = businessCategory;
        formItemsResult[businessCategoryName] = this.handleBuildUsedForm(businessCategory);
      }
    });
    this.formItems = formItemsResult;
    businessCategories.forEach((businessCategory) => {
      const businessCategoryName = businessCategory['name'];
      this.formItems[businessCategoryName]['feesConfiguration'] = this.handleBuildFeesForm(businessCategory);
    });
    this.masterFormGroup = this.buildFormGroups(this.formItems);
    this.setIncentiveControlsStatus();
  }

  /**
   * Helper function to build New tab
   * @param businessCategory
   */
  handleBuildNewForm(businessCategory) {
    const configuration = businessCategory['configuration'];
    const businessCategoryFormItemsResult = {}

    businessCategoryFormItemsResult['aprConfiguration'] = new UntypedFormGroup({
      showAprOffer: new UntypedFormControl(configuration.aprConfiguration.showAprOffer),
      useChromeIncentivesAprOffer: new UntypedFormControl(configuration.aprConfiguration.useChromeIncentivesAprOffer)
    });
    businessCategoryFormItemsResult['financeConfiguration'] = new UntypedFormGroup({
      showFinanceOffer: new UntypedFormControl(configuration.financeConfiguration.showFinanceOffer),
      financeAprSource: new UntypedFormControl(configuration.financeConfiguration.financeAprSource),
      includeFinanceDealerIncentives: new UntypedFormControl(configuration.financeConfiguration.includeFinanceDealerIncentives)
    });
    businessCategoryFormItemsResult['generalConfiguration'] = new UntypedFormGroup({
      includeSelectInventoryIncentives: new UntypedFormControl(configuration.generalConfiguration.includeSelectInventoryIncentives),
      rebateDisclosureFormat: new UntypedFormControl(configuration.generalConfiguration.rebateDisclosureFormat)
    });
    businessCategoryFormItemsResult['incentiveConfiguration'] = new UntypedFormGroup({
      useIncentiveService: new UntypedFormControl(configuration.incentiveConfiguration.useIncentiveService),
      enhancedIncentives: new UntypedFormControl(configuration.incentiveConfiguration.enhancedIncentives),
      useVinSpecificIncentives: new UntypedFormControl(configuration.incentiveConfiguration.useVinSpecificIncentives)
    });
    businessCategoryFormItemsResult['leaseConfiguration'] = new UntypedFormGroup({
      showLeaseOffer: new UntypedFormControl(configuration.leaseConfiguration.showLeaseOffer),
      showAlternateLeaseOffer: new UntypedFormControl(configuration.leaseConfiguration.showAlternateLeaseOffer),
      leaseSellingPriceType: new UntypedFormControl(configuration.leaseConfiguration.leaseSellingPriceType),
      includeLeaseDealerIncentives: new UntypedFormControl(configuration.leaseConfiguration.includeLeaseDealerIncentives),
      leaseIncentiveType: new UntypedFormControl(configuration.leaseConfiguration.leaseIncentiveType),
      moneyFactorMarkup: new UntypedFormControl(configuration.leaseConfiguration.moneyFactorMarkup, [Validators.pattern(this.decimalPattern)]),
      calculateLeaseFrom: new UntypedFormControl(configuration.leaseConfiguration.calculateLeaseFrom),
      leaseDownDueAmount: new UntypedFormControl(configuration.leaseConfiguration.leaseDownDueAmount),
      leasePaymentType: new UntypedFormControl(configuration.leaseConfiguration.leasePaymentType),
      leasePricingBase: new UntypedFormControl(configuration.leaseConfiguration.leasePricingBase)
    });
    businessCategoryFormItemsResult['purchaseConfiguration'] = new UntypedFormGroup({
      showPurchaseOffer: new UntypedFormControl(configuration.purchaseConfiguration.showPurchaseOffer),
      showMathBox: new UntypedFormControl(configuration.purchaseConfiguration.showMathBox),
      showSavingsOffer: new UntypedFormControl(configuration.purchaseConfiguration.showSavingsOffer),
      showPreRebateSubtotal: new UntypedFormControl(configuration.purchaseConfiguration.showPreRebateSubtotal),
      showLineItemSuperscripts: new UntypedFormControl(configuration.purchaseConfiguration.showLineItemSuperscripts),
      includePurchaseDealerIncentives: new UntypedFormControl(configuration.purchaseConfiguration.includePurchaseDealerIncentives)
    });

    this.newMoneyFactorMarkupControl = businessCategoryFormItemsResult['leaseConfiguration'].controls.moneyFactorMarkup;
    this.calculateLeaseFromControl = businessCategoryFormItemsResult['leaseConfiguration'].controls.calculateLeaseFrom;
    this.leasePaymentTypeControl = businessCategoryFormItemsResult['leaseConfiguration'].controls.leasePaymentType;

    return businessCategoryFormItemsResult;
  }

  /**
   * Helper function to build Used tab
   * @param businessCategory
   */
  handleBuildUsedForm(businessCategory) {
    const configuration = businessCategory['configuration'];
    const businessCategoryFormItemsResult = {}

    businessCategoryFormItemsResult['aprConfiguration'] = new UntypedFormGroup({
      showAprOffer: new UntypedFormControl(configuration.aprConfiguration.showAprOffer)
    });
    businessCategoryFormItemsResult['financeConfiguration'] = new UntypedFormGroup({
      showFinanceOffer: new UntypedFormControl(configuration.financeConfiguration.showFinanceOffer),
      financeAprSource: new UntypedFormControl(configuration.financeConfiguration.financeAprSource)
    });
    businessCategoryFormItemsResult['leaseConfiguration'] = new UntypedFormGroup({
      showLeaseOffer: new UntypedFormControl(configuration.leaseConfiguration.showLeaseOffer),
      showAlternateLeaseOffer: new UntypedFormControl(configuration.leaseConfiguration.showAlternateLeaseOffer),
      leaseSellingPriceType: new UntypedFormControl(configuration.leaseConfiguration.leaseSellingPriceType),
      leaseIncentiveType: new UntypedFormControl(configuration.leaseConfiguration.leaseIncentiveType),
      moneyFactorMarkup: new UntypedFormControl(configuration.leaseConfiguration.moneyFactorMarkup, [Validators.pattern(this.decimalPattern)]),
    });
    businessCategoryFormItemsResult['purchaseConfiguration'] = new UntypedFormGroup({
      showPurchaseOffer: new UntypedFormControl(configuration.purchaseConfiguration.showPurchaseOffer),
      showSavingsOffer: new UntypedFormControl(configuration.purchaseConfiguration.showSavingsOffer)
    });

    this.usedMoneyFactorMarkupControl = businessCategoryFormItemsResult['leaseConfiguration'].controls.moneyFactorMarkup;

    return businessCategoryFormItemsResult;
  }

  /**
   * Helper function to build fees form
   * @param businessCategory
   */
  handleBuildFeesForm(businessCategory) {
    const feesConfiguration = businessCategory['configuration']['feesConfiguration'];

    return new UntypedFormGroup({
      documentFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.documentFee.id),
        type: new UntypedFormControl('documentFee'),
        value: new UntypedFormControl(feesConfiguration.documentFee.value),
        isPercent: new UntypedFormControl(feesConfiguration.documentFee.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.documentFee.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.documentFee.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.documentFee.accountBusinessCategoryGuid)
      }),
      licenseFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.licenseFee.id),
        type: new UntypedFormControl('licenseFee'),
        value: new UntypedFormControl(feesConfiguration.licenseFee.value),
        isPercent: new UntypedFormControl(feesConfiguration.licenseFee.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.licenseFee.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.licenseFee.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.licenseFee.accountBusinessCategoryGuid)
      }),
      titleFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.titleFee.id),
        type: new UntypedFormControl('titleFee'),
        value: new UntypedFormControl(feesConfiguration.titleFee.value),
        isPercent: new UntypedFormControl(feesConfiguration.titleFee.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.titleFee.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.titleFee.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.titleFee.accountBusinessCategoryGuid)
      }),
      acquisitionFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.acquisitionFee.id),
        type: new UntypedFormControl('acquisitionFee'),
        value: new UntypedFormControl(feesConfiguration.acquisitionFee.value),
        isPercent: new UntypedFormControl(feesConfiguration.acquisitionFee.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.acquisitionFee.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.acquisitionFee.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.acquisitionFee.accountBusinessCategoryGuid)
      }),
      taxes: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.taxes.id),
        type: new UntypedFormControl('taxes'),
        value: new UntypedFormControl(feesConfiguration.taxes.value),
        isPercent: new UntypedFormControl(feesConfiguration.taxes.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.taxes.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.taxes.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.taxes.accountBusinessCategoryGuid)
      }),
      dispositionFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.dispositionFee.id),
        type: new UntypedFormControl('dispositionFee'),
        value: new UntypedFormControl(feesConfiguration.dispositionFee.value),
        isPercent: new UntypedFormControl(feesConfiguration.dispositionFee.isPercent),
        leaseApplication: new UntypedFormControl(feesConfiguration.dispositionFee.leaseApplication),
        financeApplication: new UntypedFormControl(feesConfiguration.dispositionFee.financeApplication),
        enabled: new UntypedFormControl(false),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.dispositionFee.accountBusinessCategoryGuid)
      }),
      customFee: new UntypedFormGroup({
        id: new UntypedFormControl(feesConfiguration.customFee.id),
        type: new UntypedFormControl('customFee'),
        value: new UntypedFormControl(feesConfiguration.customFee.value),
        displayName: new UntypedFormControl(feesConfiguration.customFee.displayName),
        enabled: new UntypedFormControl(feesConfiguration.customFee.enabled),
        isPercent: new UntypedFormControl(false),
        leaseApplication: new UntypedFormControl('notUsed'),
        financeApplication: new UntypedFormControl('notUsed'),
        accountBusinessCategoryGuid: new UntypedFormControl(feesConfiguration.customFee.accountBusinessCategoryGuid),
        pricingOption : new UntypedFormControl(feesConfiguration.customFee.pricingOption),
        finalPriceText : new UntypedFormControl(feesConfiguration.customFee.finalPriceText)
      })
    });
  }

  /**
   * Build out FormControls and FormGroups for ad type settings form
   *
   * @param combinedFormItems
   */
  buildFormGroups(combinedFormItems) {
    const masterFormGroup = {};
    Object.keys(combinedFormItems).forEach((businessCategoryKey) => {
      masterFormGroup[businessCategoryKey] = {};
      const businessCategoryFormItems = combinedFormItems[businessCategoryKey];
      Object.keys(businessCategoryFormItems).forEach((settingsGroupKey) => {
          masterFormGroup[businessCategoryKey][settingsGroupKey] = businessCategoryFormItems[settingsGroupKey];
      });
      masterFormGroup[businessCategoryKey] = new UntypedFormGroup(masterFormGroup[businessCategoryKey]);
    });

    return new UntypedFormGroup(masterFormGroup);
  }

  /**
   * handler function called when Vehicle Ads switch is toggled
   */
  handleVehicleAdsEnabledToggle() {
    this.updateAdTypesAccountSettings(this.accountAdTypes.vehicleAdType);
    this.initializeBusinessCategoryConfigurations();
  }

  /**
   * handler function called when Promotional Ads switch is toggled
   */
  handlePromotionalAdsEnabledToggle() {
    this.updateAdTypesAccountSettings(this.accountAdTypes.promotionalAdType);
    this.initializeBusinessCategoryConfigurations();
    this.initializePromotionalAdsConfigurations();
  }

  /**
   * Update ad types account settings (for now just toggle Vehicle Ads)
   *
   * @param adTypesAccountSettings
   */
  updateAdTypesAccountSettings(adTypesAccountSettings) {
    // Check if need to initialize or update ad types
    if (adTypesAccountSettings.guid === null) {
      adTypesAccountSettings.guid = '';
      this.adTypesSettingsService.createAccountAdTypes(adTypesAccountSettings)
        .subscribe(() => {
            this.getAdTypesAccountSettings().then(r => {
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to initialize Ad Type settings',
              life: 5000
            });
          });
    } else {
      this.adTypesSettingsService.updateAdTypesAccountSettings(adTypesAccountSettings)
        .subscribe(
          () => {
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to save Ad type setting.',
              life: 5000
            });
          });
    }
  }

  /**
   * Initialize & populate business category configurations when Vehicle Ads is turned on for the first time
   */
  initializeBusinessCategoryConfigurations() {
    if ((this.accountAdTypes.vehicleAdType.enabled || this.accountAdTypes.promotionalAdType.enabled)
      && this.businessCategoryConfigurations === null) {
      this.businessCategoryConfigurationsService.initializeBusinessCategoryConfigurations(this.componentName, this.accountId)
        .subscribe(
          () => {
            this.getBusinessCategoryConfigurations(this.accountId);
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to initialize configurations.',
              life: 5000
            });
          });
    }
  }

  /**
   * Initialize & populate promotional ads configurations when Promotional Ads is turned on for the first time
   */
  initializePromotionalAdsConfigurations() {
    if (this.accountAdTypes.promotionalAdType.enabled && this.promotionalAdsConfigurations === null) {
      this.adTypesSettingsService.initializePromotionalAdsConfigurations(this.accountId)
        .subscribe(
          () => {
            this.getPromotionalAdsConfigurations(this.accountId);
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to initialize configurations.',
              life: 5000
            });
          });
    }
  }

  /**
   * Vehicle Ads save form
   */
  handleVehicleAdsSave(): void {
    const updatedConfigurations = this.mapConfigurationObject();
    this.businessCategoryConfigurationsService.updateBusinessCategoryConfigurations(updatedConfigurations)
      .subscribe(
        () => {
          this.masterFormGroup.markAsPristine();
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save changes.',
            life: 5000
          });
        });

    this.handleOfferRulesSave();
  }

  /**
   * Helper function to map the form values to the object used in the request body
   */
  mapConfigurationObject(): object {
    const updatedConfiguration = this.businessCategoryConfigurations;
    const formValuesMap = new Map(Object.entries(this.masterFormGroup.getRawValue()));
    formValuesMap.forEach((configurationValue, key) => {
      let nestedConfiguration = updatedConfiguration.businessCategoryTypes.find(item => item.name === key).configuration;
      nestedConfiguration = configurationValue;
      nestedConfiguration.feesConfiguration = this.convertFeesToNumber(nestedConfiguration.feesConfiguration);

      updatedConfiguration.businessCategoryTypes.find(item => item.name === key).configuration = nestedConfiguration;
    });

    return updatedConfiguration;
  }

  /**
   * Helper function to convert fee values from strings to numbers since the Prime Faces
   * form component converts numeric input fields to strings when calling getValue()
   *
   * @param feesConfiguration
   */
  convertFeesToNumber(feesConfiguration): object {
    const convertedConfigurations = feesConfiguration;
    convertedConfigurations.documentFee.value = +convertedConfigurations.documentFee.value;
    convertedConfigurations.licenseFee.value = +convertedConfigurations.licenseFee.value;
    convertedConfigurations.titleFee.value = +convertedConfigurations.titleFee.value;
    convertedConfigurations.acquisitionFee.value = +convertedConfigurations.acquisitionFee.value;
    convertedConfigurations.taxes.value = +convertedConfigurations.taxes.value;
    convertedConfigurations.dispositionFee.value = +convertedConfigurations.dispositionFee.value;
    convertedConfigurations.customFee.value = +convertedConfigurations.customFee.value;

    return convertedConfigurations;
  }

  handlePromotionalAdsSave(): void {
    let updatedConfigurations = this.mapPromotionalConfigurations();
    updatedConfigurations[0].accountId = this.accountId;
    this.adTypesSettingsService.updatePromotionalAdTypeConfigurations(updatedConfigurations[0])
      .subscribe(
        () => {
          this.promotionalAdMasterFormGroup.markAsPristine();
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save promotional ad changes.',
            life: 5000
          });
        });
  }

  /**
   * Function to get promotional ads configurations and set up promotional ads config form group
   * @param accountId
   */
  getPromotionalAdsConfigurations(accountId: number) {
    if (!this.promotionalAdsConfigurations) {
      this.adTypesSettingsService.getPromotionalAdsConfigurations(accountId)
        .subscribe((promotionalAdsConfigResult: PromotionalAdsConfigurationInterface[]) => {
          this.promotionalAdsConfigurations = promotionalAdsConfigResult;
          const existingCallsToAction = this.buildCallToActionFormGroup();
          this.callsToAction = this.formBuilder.array(existingCallsToAction);
          const configuration = this.promotionalAdsConfigurations[0];
          this.promotionalAdMasterFormGroup = this.formBuilder.group({
            disclosureAddon: new UntypedFormControl(configuration.disclosureText),
            subcategories: new UntypedFormControl(this.buildMultiselectOptions(configuration.businessSubcategories)),
            callsToAction: this.callsToAction
          });
          this.buildPromotionalCategoryTabs(this.promotionalAdsConfigurations);
        });
    }
  }

  /**
   * Function to populate promotional ads tabs - currently limited to one category
   * Will
   * @param promotionalAdCategories
   */
  buildPromotionalCategoryTabs(promotionalAdCategories) {
    promotionalAdCategories.forEach((category) => {
      const promotionalAdCategory = {
        label: `${category.accountBusinessCategoryName}`,
        id: category.guid
      };
      this.promotionalAdTabs.push(promotionalAdCategory);
    });
  }

  /**
   * Function to build promotional ads configuration call to action form group array
   * The result of this function is used to generate the FormArray for callsToAction
   */
  buildCallToActionFormGroup(): UntypedFormGroup[] {
    const callToActionForms = [];
    const configuration = this.promotionalAdsConfigurations[0];
    if (configuration.promotionalAdCallToActions !== undefined) {
      configuration.promotionalAdCallToActions.forEach((callToAction) => {
        callToActionForms.push(this.createCallToActionForm(callToAction));
      });
    }

    return callToActionForms;
  }

  /**
   * Build array of options for multi-select inputs
   *
   * @param subcategoryParameters
   */
  buildMultiselectOptions(subcategoryParameters) {
    const selectedItems = [];
    Object.keys(subcategoryParameters).forEach((key) => {
      const option = subcategoryParameters[key];
      selectedItems.push({
        name: option.name,
        id: option.id
      });
    });

    return selectedItems;
  }

  /**
   * helper function to create call to action form group
   *
   * @param callToAction
   */
  createCallToActionForm(callToAction): UntypedFormGroup {
    return this.formBuilder.group({
      target: new UntypedFormControl({value: callToAction.target}, [emptyDropdownValidator()]),
      actionUrl: new UntypedFormControl(callToAction.url),
      callToActionText: new UntypedFormControl(callToAction.displayText, [Validators.required]),
      actionOptions: new UntypedFormControl({value: callToAction.action}, [emptyDropdownValidator()]),
      order: new UntypedFormControl(callToAction.order)
    });
  }

  /**
   * Add call to action to callsToAction array
   */
  addNewCallToAction() {
    const callsToAction = this.promotionalAdMasterFormGroup.get('callsToAction') as UntypedFormArray;
    const newCallToAction = new PromotionalAdCallToAction();
    callsToAction.push(this.createCallToActionForm(newCallToAction));
    this.promotionalAdMasterFormGroup.markAsDirty();
  }

  /**
   * Delete call to action at CTA form array
   * @param index
   */
  deleteCallToAction(index) {
    const callsToAction = this.promotionalAdMasterFormGroup.get('callsToAction') as UntypedFormArray;
    callsToAction.removeAt(index);
    this.promotionalAdMasterFormGroup.markAsDirty();
    if (callsToAction.length === 0) {
      this.addNewCallToAction();
    }
  }

  /**
   * Function called on drop of reordered call to action
   * @param event
   */
  callToActionDrop(event: CdkDragDrop<string[]>) {
    const callsToAction = this.promotionalAdMasterFormGroup.get('callsToAction') as UntypedFormArray;
    this.moveItemInFormArray(callsToAction, event.previousIndex, event.currentIndex);
    this.promotionalAdMasterFormGroup.markAsDirty();
  }


  /**
   * Moves an item in a FormArray to another position
   * @param formArray
   * @param fromIndex
   * @param toIndex
   */
  moveItemInFormArray(
    formArray: UntypedFormArray,
    fromIndex: number,
    toIndex: number
  ): void {
    const direction = toIndex > fromIndex ? 1 : -1;

    const item = formArray.at(fromIndex);
    for (let iteration = fromIndex; iteration * direction < toIndex * direction; iteration = iteration + direction) {
      const current = formArray.at(iteration + direction);
      formArray.setControl(iteration, current);
    }
    formArray.setControl(toIndex, item);
  }

  /**
   * Function to convert promotional ad configuration properties to format to be saved
   */
  mapPromotionalConfigurations(): object {
    const updatedConfigurations = this.promotionalAdsConfigurations;
    const updatedCallsToAction = [];
    const callsToActionMap = new Map(Object.entries(this.promotionalAdMasterFormGroup.get('callsToAction').value));
    callsToActionMap.forEach((ctaValue, index) => {
      let callToActionReformatted = new PromotionalAdCallToAction();
      callToActionReformatted.action = ctaValue['actionOptions'].value;
      callToActionReformatted.displayText = ctaValue['callToActionText'];
      callToActionReformatted.url = ctaValue['actionUrl'];
      if (callToActionReformatted.url == null){
        callToActionReformatted.url = '';
      }
      callToActionReformatted.order = +index;
      callToActionReformatted.target = ctaValue['target'].value;
      callToActionReformatted.promotionalAdsConfigurationGuid = updatedConfigurations[0].guid;
      updatedCallsToAction.push(callToActionReformatted);
    });

    updatedConfigurations[0].disclosureText = this.promotionalAdMasterFormGroup.get('disclosureAddon').value;
    updatedConfigurations[0].businessSubcategories = this.promotionalAdMasterFormGroup.get('subcategories').value;
    updatedConfigurations[0].promotionalAdCallToActions = updatedCallsToAction;

    return updatedConfigurations;
  }

  /**
   * Method to handle the dirty state of the offer rules form
   * @param isDirty
   */
  handleOfferRulesFormDirty(isDirty: boolean) {
    if (isDirty) {
      this.dirtyOfferRulesForms += 1;
    } else {
      this.dirtyOfferRulesForms -= 1;
    }
  }

  /**
   * Method to handle the save of the offer rules form
   */
  handleOfferRulesSave() {
    this.offerRulesComponents.forEach((offerRulesComponent) => {
      offerRulesComponent.saveRules();
    });
    this.dirtyOfferRulesForms = 0;
  }

  /**
   * Method to handle the beforeunload event to prevent users from navigating away from the page
   * @param event
   */
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event): void {
    if (this.dirtyOfferRulesForms > 0) {
      event.preventDefault();
    }
  }

  /**
   * Method to handle the delete of the offer rules
   */
  handleDeleteOfferRules() {
    this.messageService.add({
      severity: 'success',
      summary: 'Delete Successful',
      detail: 'Ad Rule(s) deleted successfully.',
      life: 5000
    });
  }

  /**
   * Method to handle toggling of enhanced incentives
   */
  handleEnhancedIncentivesToggle() {
    this.messageService.add({
      severity: 'warn',
      summary: 'Warning: Action Required',
      detail: 'Please delete and re-create all New ads after turning Enhanced Incentives on or off.',
      life: 10000
    });
  }

  /**
   * Sets the controls for the incentive toggles that are dependent on the use incentive service setting
   */
  setIncentiveControlsStatus() {
    if (this.formItems['New'].incentiveConfiguration.controls.useIncentiveService.value === false) {
      this.formItems['New'].incentiveConfiguration.controls.enhancedIncentives.disable();
      this.formItems['New'].incentiveConfiguration.controls.useVinSpecificIncentives.disable();
      this.formItems['New'].generalConfiguration.controls.includeSelectInventoryIncentives.disable();
      this.formItems['New'].incentiveConfiguration.controls.enhancedIncentives.setValue(false);
      this.formItems['New'].incentiveConfiguration.controls.useVinSpecificIncentives.setValue(false);
      this.formItems['New'].generalConfiguration.controls.includeSelectInventoryIncentives.setValue(false);
    } else {
      this.formItems['New'].incentiveConfiguration.controls.enhancedIncentives.enable();
      this.formItems['New'].incentiveConfiguration.controls.useVinSpecificIncentives.enable();
      this.formItems['New'].generalConfiguration.controls.includeSelectInventoryIncentives.enable();
    }
  }
}
