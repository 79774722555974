import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {UserService} from '../../../../service/user.service';
import {CookieService} from '../../../../service/cookie.service';
import {matchValidator} from '../../../../validators/match-validator';
import * as jose from 'jose';
import {JWTPayload} from 'jose';

@Component({
  selector: 'auto-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  submitted = false;
  resetCodeProcessed = false;
  strongPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$&+,:;=?@#|\'<>.^"*_`()[\\]{}/\\\\~%!-])[A-Za-z\\d$&+,:;=?@#|\'<>.^"*_`()[\\]{}/\\\\~%!-]{8,}$';
  mediumPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$';
  newPassword: UntypedFormControl;
  confirmPassword: UntypedFormControl;
  decodedToken: JWTPayload;
  threeSeconds = 0.000035;
  sevenDays = 7;
  token: string;
  expiredPageData = {
    state: {
      title: 'Token Expired',
      message: 'Your password token has expired or is invalid.',
      backToPageRoute: '/',
      backToPageName: 'login',
      autoToken: 'dt_event_page_temp_token'
    }};
  successPageData = {
    state: {
      title: 'Request Successful',
      message: 'You have successfully reset your password.',
      backToPageRoute: '/',
      backToPageName: 'login',
      autoToken: 'dt_event_page_temp_token'
    }};
  cardStyle = {
    'border-style': 'solid',
    'border-color': 'gray',
    'border-width': 'thin',
    'width' : '550px',
    'background-color': '#FFFFFF',
    'margin-left' : 'auto',
    'margin-right' : 'auto',
    padding : '2.5rem 2.5rem 0rem 2.5rem'};
  passwordForm = this.formBuilder.group({
    newPassword: ['', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(this.strongPassword),
      matchValidator('confirmPassword', true)
    ]],
    confirmPassword: ['', [
      Validators.required,
      matchValidator('newPassword')
    ]]
  });
  protected readonly Object = Object;
  passwordCriteria = {
    'length': { name: 'At least 8 characters', met: false },
    'uppercase': { name: 'At least one uppercase letter', met: false },
    'lowercase': { name: 'At least one lowercase letter', met: false },
    'number': { name: 'At least one number', met: false },
    'special': { name: 'At least one special character', met: false }
  };

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private router: Router,
    private cookieService: CookieService,
    private userService: UserService
  ) { }

   ngOnInit(): void {
    this.titleService.setTitle('Reset Password');

    this.cookieService.setCookie(
      'auto-token',
      'dt_reset_password_temp_token',
      this.threeSeconds, // This value will be converted into 3 seconds from now
      '/'
    );

    this.route.queryParams.subscribe(async params => {
      this.token = params.token;

      try {
          this.decodedToken = jose.decodeJwt(this.token);
      } catch (e){
        console.error('Invalid JWT token');
        this.router.navigate(['/event-page'], this.expiredPageData);
      }
      this.handleIfTokenUsed();

      const now = Math.floor(Date.now() / 1000);
      await this.userService.getResetCodeStatus(this.decodedToken.resetCode).subscribe(
        (data) => {
          if (!data.hasCode) {
            this.router.navigate(['/event-page'], this.expiredPageData);
          }
          this.createAndSignSingleUseToken(now);
          this.resetCodeProcessed = true;
        },
      () => {
        this.router.navigate(['/event-page'], this.expiredPageData);
        }
      );

      if (this.decodedToken.exp < now) {
        this.router.navigate(['/event-page'], this.expiredPageData);
      }
    });
  }

  /**
   * Checks if there exists a single use token in the cookies and navigates to another view accordingly
   */
  handleIfTokenUsed(): void {
    const singleUseToken = this.cookieService.getCookie('single_use_token');
    if (singleUseToken) {
      const decodedCookieToken = jose.decodeJwt(singleUseToken);
      if (decodedCookieToken.resetCode === this.decodedToken.resetCode) {
        this.router.navigate(['/event-page'], this.expiredPageData);
      }
    }
  }

  /**
   * Creates a cookie to store the single use token.
   * @param now
   */
  createAndSignSingleUseToken(now: number): void {
    // @ts-ignore
    const key = new TextEncoder().encode(this.decodedToken.resetCode);
    const signedToken = new jose.SignJWT(this.decodedToken)
      .setExpirationTime(now)
      .setProtectedHeader({alg: 'HS256'})
      .sign(key);

    signedToken.then((newToken) => {
      this.cookieService.setCookie(
        'single_use_token',
        newToken,
        this.sevenDays,
        `${window.location.pathname}`
      );
    });
  }

  /**
   * Handle Reset password-settings
   */
  handleResetPassword(): void {
    this.submitted = true;
    this.userService.resetPassword(
      {
        token: this.decodedToken.resetCode,
        newUser: this.decodedToken.newUser,
        newPassword: this.passwordForm.controls.newPassword.value,
        confirmPassword: this.passwordForm.controls.confirmPassword.value})
      .subscribe(
        () => {
          this.router.navigate(['/event-page'], this.successPageData);
        },
        (error) => {
          this.submitted = false;
          const errorArray = error.split('. ').filter(Boolean);
          errorArray.forEach((errorMessage) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: errorMessage + '.',
              life: 15000
            });
          });
        });
    }

  /**
   * Method to check password criteria
   */
  checkPasswordCriteria() {
    this.passwordCriteria['length'].met = this.passwordForm.controls.newPassword.value.length >= 8;
    this.passwordCriteria['uppercase'].met = /[A-Z]/.test(this.passwordForm.controls.newPassword.value);
    this.passwordCriteria['lowercase'].met = /[a-z]/.test(this.passwordForm.controls.newPassword.value);
    this.passwordCriteria['number'].met = /\d/.test(this.passwordForm.controls.newPassword.value);
    this.passwordCriteria['special'].met = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.passwordForm.controls.newPassword.value);
  }
}
