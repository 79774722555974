<auto-main-navigation [accountId]="accountId"></auto-main-navigation>

<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">Inventory Settings</h1>
  </p-header>

  <div class="main-content-wrapper p-grid">
    <div class="p-col-4">
      <p-panelMenu [model]="settingsSideNav">
      </p-panelMenu>
    </div>

    <p-panel class="p-col ad-types-wrapper">
      <p-header class="account-inventory-header-wrapper">
        <h2 class="ad-types-header-text">Account New VLP URLs</h2>
      </p-header>

      <div class="p-grid field-form-wrapper">
        <div class="p-col-4 field-form-inputs-wrapper" style="margin: auto 0">
          <label> Account Website URL </label>
        </div>
        <div class="p-col-7 value-input-wrapper">
            <span class="disabled-text"
                  pTooltip="This setting can be found in Account -> Website"
                  tooltipPosition="top">
              {{ accountUrl }}
            </span>
        </div>
      </div>
      <p-card class="p-col" [style]="{'border-style': 'solid', 'border-color':'lightgray', 'border-width': 'thin'}">
        <div class="p-grid field-form-wrapper">
          <form *ngIf="ncsAccountInventoryUrl" [formGroup]="accountVlpUrlMainFormGroup" autoTrackUnsavedChanges
                class="p-col">
            <div formArrayName="accountVlpUrlFormArray">
              <div *ngFor="let ncsAccountInventoryUrl of this.accountVlpUrlFormArray.controls; let i=index">
                <div *ngIf="this.makes[i]">
                  <h2>{{ this.makes[i] }}</h2>
                  <br>
                </div>
                <div [formGroupName]="i" class="p-grid">
                  <div class="p-col-4 field-form-inputs-wrapper" style="margin: auto 0">
                    <label> {{ ncsAccountInventoryUrl.value.make }} {{ ncsAccountInventoryUrl.value.model }} </label>
                  </div>
                  <div class="p-col-7 value-input-wrapper">
                    <input
                      id="ncsValue"
                      type="text"
                      pInputText
                      class="text-input"
                      formControlName="vlpUrl"/>
                  </div>
                </div>
              </div>
              <button pButton
                      class="p-button-success save-button-wrapper"
                      label="Save"
                      (click)="handleAccountInventorySave('New')"
                      [disabled]="this.accountVlpUrlMainFormGroup.pristine || this.saveInProgress">
              </button>
            </div>
          </form>
        </div>
      </p-card>
    </p-panel>
  </div>
</p-panel>
<auto-footer></auto-footer>
