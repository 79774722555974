import {Injectable} from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BusinessCategoryConfigurationsService extends AbstractHttpService {
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  getBusinessCategoryConfigurations(componentName: string, accountId: number): Observable<any> {
    let url = `//${environment.autoDomain}:${environment.autoPort}/api/business_category_configurations?componentName=${componentName}&accountId=${accountId}`;
    if (componentName === null) {
      url = `//${environment.autoDomain}:${environment.autoPort}/api/business_category_configurations?accountId=${accountId}`;
    }
    return this.httpClient.get(
      url,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  initializeBusinessCategoryConfigurations(componentName: string, accountId: number): Observable<any> {
    const postBody = {
      componentName,
      accountId
    };

    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/business_category_configurations/initialize_configuration`,
      postBody,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateBusinessCategoryConfigurations(configurations: object): Observable<any> {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/business_category_configurations/update_configuration`,
      configurations,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

}
