import { Injectable } from '@angular/core';
import {MessageService} from 'primeng/api';
import {CookieService} from './cookie.service';
import {UserService} from './user.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private messageService: MessageService,
    private cookieService: CookieService,
    private userService: UserService,
    private router: Router
  ) { }

  /**
   * Clears session data
   */
  clearSession() {
    const sessionCookie = this.cookieService.getCookie('PHPSESSID');
    this.userService.logout({sessionKey: sessionCookie})
      .subscribe(
        () => {
          this.cookieService.clearSessionCookies();
          window.location.href = '/web/';
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
            life: 5000
          });
        });
    this.router.navigate(["/"]);
  }
}
