<auto-main-navigation [accountId]="accountId"></auto-main-navigation>

<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">MPOP Settings</h1>
  </p-header>

  <div class="main-content-wrapper p-grid">

    <div class="p-col-3">
      <p-panelMenu [model]="settingsSideNav">
      </p-panelMenu>
    </div>

    <p-panel class="p-col ad-types-wrapper">

      <p-header class="ad-types-header-wrapper">
        <h2 class="ad-types-header-text">Ad Types</h2>
      </p-header>

      <p-panel styleClass="ad-types-form-panel" [collapsed]="vehicleAdTypesCollapsed"
               [toggleable]="accountAdTypes !== null && accountAdTypes.vehicleAdType.enabled"
               [expandIcon]="expandIcon"
               [collapseIcon]="collapseIcon">
        <p-header class="ad-types-header-wrapper form-panel-header">
          <h3 class="ad-types-header-text">Vehicle Ads</h3>
          <div class="ad-types-header-input">
            <div class="ad-types-header-input">
              <p-inputSwitch [ngModel]="accountAdTypes !== null && accountAdTypes.vehicleAdType.enabled" (ngModelChange)="accountAdTypes !== null && (accountAdTypes.vehicleAdType.enabled = $event)"
                             (onChange)="handleVehicleAdsEnabledToggle()">
              </p-inputSwitch>
            </div>
          </div>
        </p-header>

        <!--        TODO: this should use an async pipe instead of null to determine when vehicle ads is enabled (same above for switch)-->
        <div class="form-body-wrapper" *ngIf="accountAdTypes !== null && accountAdTypes.vehicleAdType.enabled">
          <div class="ad-types-save-button">
            <button pButton
                    id="vehicleAdSaveButton"
                    class="p-button-success"
                    label="Save"
                    (click)="handleVehicleAdsSave()">
            </button>
          </div>
          <p-tabMenu #tab
                     styleClass="business-category-tabs"
                     [model]="businessCategoryTabs"
                     [activeItem]="businessCategoryTabs[0]"
                     (click)="setActiveBusinessCategory(tab.activeItem)">
          </p-tabMenu>

          <form [formGroup]="masterFormGroup" autoTrackUnsavedChanges
                *ngIf="businessCategoryConfigurations !== null">

            <div *ngFor="let businessCategory of businessCategoryConfigurations.businessCategoryTypes">
              <!--              TODO: unknown as to whether this is the best method to hide/show -- may be unperformant at some point-->
              <div [hidden]="activeBusinessCategoryId !== businessCategory.guid">
                <h4 class="form-section-header">General</h4>
                <auto-dynamic-form *ngIf="activeBusinessCategoryName == 'New'"
                                   [formName]="businessCategory.name"
                                   [formItems]="formItems[businessCategory.name]['incentiveConfiguration']"
                                   [form]="masterFormGroup.controls[businessCategory.name]['controls']['incentiveConfiguration']">
                </auto-dynamic-form>
                <auto-dynamic-form [formName]="businessCategory.name"
                                   [formItems]="formItems[businessCategory.name]['generalConfiguration']"
                                   [form]="masterFormGroup.controls[businessCategory.name]['controls']['generalConfiguration']">
                </auto-dynamic-form>
                <h4 class="form-section-header">Fees</h4>
                <div class="p-d-flex">
                  <form [formGroup]="masterFormGroup.controls[businessCategory.name]['controls']['feesConfiguration']">
                    <table class="p-datatable">
                      <thead class="p-datatable-thead">
                      <tr class="p-datatable-header">
                        <th class="p-mr-2">
                          Fee
                        </th>
                        <th class="p-mr-2">
                          Value
                        </th>
                        <th class="p-mr-2 fee-table-column">
                          Lease
                        </th>
                        <th class="p-mr-2 fee-table-column">
                          Finance
                        </th>
                      </tr>
                      </thead>
                      <tbody class="p-datatable-tbody">
                      <tr formGroupName="documentFee" class="p-mb-2">
                        <td class="p-mr-2">
                          {{businessCategory['configuration']['feesConfiguration']['documentFee']['type'] | sentenceCase}}
                          {{businessCategory['configuration']['feesConfiguration']['documentFee']['isPercent'] ? '(%)' : '($)'}}
                        </td>
                        <td class="p-mr-2">
                          <input type="text" pInputText formControlName="value">
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionLabel="label"
                            optionValue="value"
                            formControlName="leaseApplication">
                          </p-dropdown>
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionLabel="label"
                            optionValue="value"
                            formControlName="financeApplication">
                          </p-dropdown>
                        </td>
                      </tr>
                      <tr formGroupName="licenseFee" class="p-mb-2">
                        <td class="p-mr-2">
                          {{businessCategory['configuration']['feesConfiguration']['licenseFee']['type'] | sentenceCase}}
                          {{businessCategory['configuration']['feesConfiguration']['licenseFee']['isPercent'] ? '(%)' : '($)'}}
                        </td>
                        <td class="p-mr-2">
                          <input type="text" pInputText formControlName="value">
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionValue="value"
                            optionLabel="label"
                            formControlName="leaseApplication">
                          </p-dropdown>
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionValue="value"
                            optionLabel="label"
                            formControlName="financeApplication">
                          </p-dropdown>
                        </td>
                      </tr>
                      <tr formGroupName="titleFee" class="p-mb-2">
                        <td class="p-mr-2">
                          {{businessCategory['configuration']['feesConfiguration']['titleFee']['type'] | sentenceCase}}
                          {{businessCategory['configuration']['feesConfiguration']['titleFee']['isPercent'] ? '(%)' : '($)'}}
                        </td>
                        <td class="p-mr-2">
                          <input type="text" pInputText formControlName="value">
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionValue="value"
                            optionLabel="label"
                            formControlName="leaseApplication">
                          </p-dropdown>
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionValue="value"
                            optionLabel="label"
                            formControlName="financeApplication">
                          </p-dropdown>
                        </td>
                      </tr>
                      <tr formGroupName="acquisitionFee" class="p-mb-2">
                        <td class="p-mr-2">
                          {{businessCategory['configuration']['feesConfiguration']['acquisitionFee']['type'] | sentenceCase}}
                          {{businessCategory['configuration']['feesConfiguration']['acquisitionFee']['isPercent'] ? '(%)' : '($)'}}
                        </td>
                        <td class="p-mr-2">
                          <input type="text" pInputText formControlName="value">
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionValue="value"
                            optionLabel="label"
                            formControlName="leaseApplication">
                          </p-dropdown>
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionValue="value"
                            optionLabel="label"
                            formControlName="financeApplication">
                          </p-dropdown>
                        </td>
                      </tr>
                      <tr formGroupName="taxes" class="p-mb-2">
                        <td class="p-mr-2">
                          {{businessCategory['configuration']['feesConfiguration']['taxes']['type'] | sentenceCase}}
                          {{businessCategory['configuration']['feesConfiguration']['taxes']['isPercent'] ? '(%)' : '($)'}}
                        </td>
                        <td class="p-mr-2">
                          <input type="text" pInputText formControlName="value">
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionValue="value"
                            optionLabel="label"
                            formControlName="leaseApplication">
                          </p-dropdown>
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionValue="value"
                            optionLabel="label"
                            formControlName="financeApplication">
                          </p-dropdown>
                        </td>
                      </tr>
                      <tr formGroupName="dispositionFee" class="p-mb-2">
                        <td class="p-mr-2">
                          {{businessCategory['configuration']['feesConfiguration']['dispositionFee']['type'] | sentenceCase}}
                          {{businessCategory['configuration']['feesConfiguration']['dispositionFee']['isPercent'] ? '(%)' : '($)'}}
                        </td>
                        <td class="p-mr-2">
                          <input type="text" pInputText formControlName="value">
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionValue="value"
                            optionLabel="label"
                            formControlName="leaseApplication">
                          </p-dropdown>
                        </td>
                        <td class="p-mr-2">
                          <p-dropdown
                            [options]="feeType"
                            optionValue="value"
                            optionLabel="label"
                            formControlName="financeApplication">
                          </p-dropdown>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <h4 class="form-section-sub-header">Purchase Fee</h4>
                    <div formGroupName="customFee">
                      <div class="p-field p-grid">
                        <label for="custom-fee-enabled" class="p-col-12 p-md-6 ng-star-inserted">Custom Purchase Fee
                          <i class="p-element pi pi-info-circle form-item-tooltip ng-star-inserted"
                             pTooltip="When enabled, this custom purchase fee will be applied in the math box for all {{activeBusinessCategoryName.toLowerCase()}} ads. The mathbox will reflect a final price including applicable purchase rebates and this fee."
                             tooltipPosition="right"></i>
                        </label>
                        <p-inputSwitch
                          class="p-col-12 p-md-6"
                          formControlName="enabled"
                          id="custom-fee-enabled"
                        ></p-inputSwitch>
                      </div>
                      <div class="p-grid purchase-fee-grid">
                        <div class="p-col-6">
                          <label for="display-name">Display Name</label>
                        </div>
                        <div class="p-col-6">
                          <input type="text" pInputText formControlName="displayName" id="display-name">
                        </div>
                        <div class="p-col-6">
                          <label for="value">Value ($)</label>
                        </div>
                        <div class="p-col-6">
                          <input type="text" pInputText formControlName="value" id="value">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <h4 class="form-section-header">Purchase & Savings Offers</h4>
                <auto-dynamic-form [formName]="businessCategory.name"
                                   [formItems]="formItems[businessCategory.name]['purchaseConfiguration']"
                                   [form]="masterFormGroup.controls[businessCategory.name]['controls']['purchaseConfiguration']">
                </auto-dynamic-form>

                <h4 class="form-section-header">Lease Offer</h4>
                <auto-dynamic-form [formName]="businessCategory.name"
                                   [formItems]="formItems[businessCategory.name]['leaseConfiguration']"
                                   [form]="masterFormGroup.controls[businessCategory.name]['controls']['leaseConfiguration']">
                </auto-dynamic-form>

                <h4 class="form-section-header">Finance Offer</h4>
                <auto-dynamic-form [formName]="businessCategory.name"
                                   [formItems]="formItems[businessCategory.name]['financeConfiguration']"
                                   [form]="masterFormGroup.controls[businessCategory.name]['controls']['financeConfiguration']">
                </auto-dynamic-form>

                <h4 class="form-section-header">APR Offer</h4>
                <auto-dynamic-form [formName]="businessCategory.name"
                                   [formItems]="formItems[businessCategory.name]['aprConfiguration']"
                                   [form]="masterFormGroup.controls[businessCategory.name]['controls']['aprConfiguration']">
                </auto-dynamic-form>

              </div>
            </div>
          </form>
        </div>
      </p-panel>

      <p-panel styleClass="ad-types-form-panel" [collapsed]="promotionalAdTypesCollapsed"
               [toggleable]="accountAdTypes !== null && accountAdTypes.promotionalAdType.enabled"
               [expandIcon]="expandIcon"
               [collapseIcon]="collapseIcon">
        <p-header class="ad-types-header-wrapper form-panel-header">
          <h3 class="ad-types-header-text">Promotional Ads</h3>
          <p class="coming-soon-header">Coming Soon! <i class="pi pi-clock"></i></p>
          <div class="ad-types-header-input">
            <div class="ad-types-header-input">
              <p-inputSwitch
                [ngModel]="accountAdTypes !== null && accountAdTypes.promotionalAdType.enabled" (ngModelChange)="accountAdTypes !== null && (accountAdTypes.promotionalAdType.enabled = $event)"
                (onChange)="handlePromotionalAdsEnabledToggle()">
              </p-inputSwitch>
            </div>
          </div>
        </p-header>

        <div class="form-body-wrapper" *ngIf="accountAdTypes !== null && accountAdTypes.promotionalAdType.enabled">
          <div class="ad-types-save-button">
            <button pButton
                    class="p-button-success"
                    label="Save"
                    (click)="handlePromotionalAdsSave()"
                    [disabled]="!promotionalAdMasterFormGroup.valid">
            </button>
          </div>
          <p-tabMenu styleClass="business-category-tabs"
                     [model]="promotionalAdTabs"
                     [activeItem]="promotionalAdTabs[0]">
          </p-tabMenu>
          <form *ngIf="promotionalAdsConfigurations" [formGroup]="promotionalAdMasterFormGroup" autoTrackUnsavedChanges>
            <div class="p-fluid">
              <h4 class="form-section-header">General</h4>
              <div class="p-field p-grid">
                <label for="promotion-categories" class="p-col">Promotion Categories</label>
                <div class="p-col">
                  <p-multiSelect id="promotion-categories"
                                 [options]="accountServiceCategories"
                                 formControlName="subcategories"
                                 optionLabel="name"
                                 dataKey="id"
                                 placeholder="Sub-Category"
                                 filterPlaceHolder="Select All"
                                 resetFilterOnHide="true">
                  </p-multiSelect>
                </div>
              </div>
              <div class="p-field p-grid">
                <label for="disclosure-addon" class="p-col">Disclosure Add-on</label>
                <div class="p-col">
                <textarea id="disclosure-addon"
                          formControlName="disclosureAddon"
                          [rows]="5"
                          [cols]="60"
                          pInputTextarea
                          autoResize="autoResize">
                </textarea>
                </div>
              </div>
            </div>
            <div>
              <div>
                <h4 class="form-section-header">Calls to Action</h4>
                <div formArrayName="callsToAction" class="call-to-action-boundary">
                  <div class="call-to-action-list p-grid" cdkDropList (cdkDropListDropped)="callToActionDrop($event)">
                    <div *ngFor="let callToAction of this.callsToAction.controls; index as i" cdkDrag
                         class="p-col-fixed" cdkDragBoundary=".call-to-action-boundary" style="width:100%">
                      <p-card [formGroupName]="i">
                        <div class="p-grid">
                          <div cdkDragHandle class="p-col-fixed cta-handle" style="width:50px;">
                            <i class="pi pi-bars"></i>
                          </div>
                          <div class="p-col p-grid">
                            <div class="p-col-fixed" style="width:100%">
                              <input pInputText
                                     aria-label="Call to action text"
                                     class="call-to-action-text"
                                     formControlName="callToActionText"
                                     placeholder="Text displayed for CTA"
                                     [ngClass]="{'p-invalid': callToAction.get('callToActionText').hasError('alphanumeric') || callToAction.get('callToActionText').hasError('required')}"
                              />
                              <span *ngIf="callToAction.get('callToActionText').hasError('required')"
                                    class="p-invalid">
                                  CTA display text is required.
                                </span>
                            </div>
                            <div class="p-col-fixed" style="width:100%">
                              <p-dropdown class="action-type"
                                          aria-label="Action Type"
                                          [options]="actionOptions | async"
                                          formControlName="actionOptions"
                                          dataKey="value"
                                          optionLabel="label"
                                          placeholder="Action"
                                          [ngClass]="{'p-invalid': callToAction.get('actionOptions').hasError('emptyDropdownSelection')}">
                              </p-dropdown>
                              <span *ngIf="callToAction.get('actionOptions').hasError('emptyDropdownSelection')"
                                    class="p-invalid">
                                Action type is required.
                            </span>
                            </div>
                            <div class="p-col-fixed" style="width:46%">
                              <input pInputText
                                     aria-label="URL"
                                     class="url"
                                     placeholder="Custom destination URL"
                                     formControlName="actionUrl"/>
                            </div>
                            <div class="p-col-fixed" style="width:46%">
                              <p-dropdown class="target-selection"
                                          aria-label="Target Selection"
                                          [options]="targetOptions"
                                          formControlName="target"
                                          dataKey="value"
                                          optionLabel="label"
                                          placeholder="Target"
                                          [ngClass]="{'p-invalid': callToAction.get('target').hasError('emptyDropdownSelection')}">
                              </p-dropdown>
                              <span *ngIf="callToAction.get('target').hasError('emptyDropdownSelection')"
                                    class="p-invalid">
                                Target is required.
                            </span>
                            </div>
                            <div class="p-col trash-icon">
                              <i class="pi pi-trash" (click)="deleteCallToAction(i)"></i>
                            </div>
                          </div>
                        </div>
                      </p-card>
                    </div>
                  </div>
                </div>
                <div class="add-cta-button">
                  <button pButton type="button" icon="pi pi-plus" (click)="addNewCallToAction()"></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </p-panel>

    </p-panel>
  </div>


</p-panel>


<auto-footer></auto-footer>
