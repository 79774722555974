import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import {PanelModule} from 'primeng/panel';
import {CardModule} from 'primeng/card';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {PasswordModule} from 'primeng/password';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MessagesModule} from 'primeng/messages';
import {ProgressSpinner, ProgressSpinnerModule} from 'primeng/progressspinner';
import {AppRoutingModule} from '../app-routing.module';

@NgModule({
  declarations: [LoginComponent],
    imports: [
        CommonModule,
        PanelModule,
        CardModule,
        InputTextModule,
        ButtonModule,
        PasswordModule,
        FormsModule,
        ReactiveFormsModule,
        MessagesModule,
        ProgressSpinnerModule,
        AppRoutingModule,
    ]
})
export class SecurityModule { }
