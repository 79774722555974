import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MediaManagementComponent} from "../media-management.component";
import {MediaManagementModel} from "../../../../../model/media-management.model";
import {AccountInterface} from "../../../../../model/account.interface";
import {MediaManagementEnumerator} from "../../../../../enumerators/media-management-enumerator";

@Component({
  selector: 'auto-microsoft-settings',
  templateUrl: './microsoft-settings.component.html',
  styleUrl: './microsoft-settings.component.scss'
})
export class MicrosoftSettingsComponent extends MediaManagementComponent implements OnInit {

  @Output() dataEmitter = new EventEmitter<UntypedFormGroup>();
  @Input() settings: MediaManagementModel;
  @Input() account: AccountInterface;
  microsoftSettingsForm: UntypedFormGroup;

  ngOnInit(): void {
    this.initializeMicrosoftSettingsFormGroup();
  }

  /**
   * Initializes Microsoft Settings Form Group
   */
  initializeMicrosoftSettingsFormGroup() {
    this.microsoftSettingsForm = this.formBuilder.group({
      microsoftAdsEnabled: new UntypedFormControl(this.settings.microsoftAdsEnabled),
      microsoftAdsAccountId: new UntypedFormControl(this.settings.microsoftAdsAccountId),
      microsoftAdsUetId: new UntypedFormControl(this.settings.microsoftAdsUetId),
      microsoftAdsCatalogUrl: new UntypedFormControl(
        this.replaceUrlPlaceholders(MediaManagementEnumerator.MicrosoftCatalogUrl, this.account.accountFolder)
      )
    });
    this.dataEmitter.emit(this.microsoftSettingsForm);
  }
}
