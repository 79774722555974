import { Injectable } from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {MappingRule} from '../model/mapping-rule';
import {AdFieldOption} from '../model/ad-field-option';
import {environment} from '../../environments/environment';
import {AccountInventoryField} from '../model/account-inventory-field';
import {AccountMappingRules} from '../model/account-mapping-rules';

@Injectable({
  providedIn: 'root'
})
export class MappingRulesService extends AbstractHttpService {
  defaultOptions = {
    withCredentials: true
  };

  constructor(private httpClient: HttpClient) {
    super();
  }

  /**
   * Returns ad field options based on available account inventory fields
   */
  public getAdFields(accountId: number) {
    return this.httpClient.get(`//${environment.autoDomain}:${environment.autoPort}/api/account_inventory_fields?accountId=${accountId}`,
      this.defaultOptions
      ).pipe(
        mergeMap((asIs: AccountInventoryField[]) => asIs),
        map((accountInventoryField: AccountInventoryField) => ({
          niceName: accountInventoryField.niceName,
          name: accountInventoryField.name
        })),
        toArray(),
        catchError(this.handleError)
      );
  }

  /**
   * Returns the current mapping rules for the account
   *
   * @param accountId
   */
  public getMappingRules(accountId: number) {
    return this.httpClient.get<AccountMappingRules>(`//${environment.autoDomain}:${environment.autoPort}/api/account_mapping_rules?accountId=${accountId}`,
      this.defaultOptions
      ).pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Handles updating the account's mapping rules
   *
   * @param mappingRules
   */
  public updateMappingRules(mappingRules: AccountMappingRules) {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/account_mapping_rules/update`,
      mappingRules,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
