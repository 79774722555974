import {Component, Input, EventEmitter, OnInit, Output} from '@angular/core';
import {GoogleAdsService} from "../../../../../service/google-ads.service";
import {MessageService, SelectItemGroup} from "primeng/api";
import {StringFormatterService} from "../../../../../service/utilities/string-formatter.service";
import {EventEmitterService} from "../../../../../service/event-emitter.service";

@Component({
  selector: 'auto-google-ads-automation',
  templateUrl: './google-ads-automation.component.html',
  styleUrls: ['./google-ads-automation.component.scss']
})
export class GoogleAdsAutomationComponent implements OnInit {

  @Input() adwordsAccountId: string;
  @Input() accountId: number;
  @Input() userId: number;
  @Output() onGoogleAccountCreation: EventEmitter<any> = new EventEmitter<any>();

  creationInProgress = false;
  createdAdwordsAccountId: string;
  accountOemPrograms = [];
  parentCustomerId: string;
  selectedOemProgram = null;
  subMccAccounts = [];
  subMccOptions = [
    {
      label: 'Manager:',
      value: null,
      items: []
    },
    {
      label: 'Sub Manager:',
      value: null,
      items: []
    }
  ];

  constructor(
    private messageService: MessageService,
    private googleAdsService: GoogleAdsService,
    private stringFormatterService: StringFormatterService,
    private eventEmitterService: EventEmitterService
  ) {
  }

  ngOnInit(): void {
    this.getGoogleAdsSubMccAccounts();
  }

  /**
   * Function to create a Google Ads account
   */
  createGoogleAdsAccount(): void {
    this.creationInProgress = true;
    this.googleAdsService.createGoogleAdsAccount(this.accountId, this.selectedOemProgram.value).subscribe(
      (response) => {
        this.createdAdwordsAccountId = response.adwordsAccountId;
        this.messageService.add({
          severity: 'success',
          summary: 'Update complete',
          detail: 'Google Ads account successfully created. ID: ' + this.createdAdwordsAccountId,
          life: 5000
        });
        this.creationInProgress = false;
        this.eventEmitterService.emitEvent('getTextAssetValidation');
        this.onGoogleAccountCreation.emit();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 5000
        });
        this.creationInProgress = false;
      }
    );
  }

  /**
   * Function to show tooltip for create Google Ads account button
   */
  showCreateAccountToolTip(): string {
    let toolTip = null;
    if (this.adwordsAccountId !== '') {
      toolTip = 'MPOP account has a Google Ads account ID associated';
    } else if (this.creationInProgress) {
      toolTip = 'Please wait for the account creation process to complete.';
    }
    return toolTip;
  }

  /**
   *  Function to get OEM programs for account
   *
   * @param accountId
   */
  getAccountOemPrograms(accountId: number): void {
    this.googleAdsService.getAccountOemPrograms(this.accountId).subscribe(
      (response) => {
        this.accountOemPrograms = response.programs;
        this.initializeSubMccDropDown();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get OEM programs for account.',
          life: 5000
        });
      }
    );
  }

  /**
   *  Function to get Sub MCCs for an account
   *
   */
  getGoogleAdsSubMccAccounts(): void {
    this.googleAdsService.getGoogleAdsSubMccAccounts().subscribe(
      (response) => {
        this.subMccAccounts = response.subMccAccounts;
        this.parentCustomerId = response.parentCustomerId;
        this.getAccountOemPrograms(this.accountId);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get Sub MCCs for account.',
          life: 5000
        });
      }
    );
  }

  /**
   * Function to initialize the sub MCC drop down
   */
  initializeSubMccDropDown(): void {
    const program = this.getAssociatedAccountProgram();
    const formattedAccountId = this.parentCustomerId.replace(/-/g, '');
    this.populateDropdown(program, formattedAccountId);

    if (this.selectedOemProgram === undefined || this.selectedOemProgram === null) {
      this.selectedOemProgram = this.subMccOptions[0].items[0];
    }
  }

  /**
   * Function to get the OEM program for the account
   */
  getAssociatedAccountProgram(): string {
    const oemProgramMccMapping = {
      'Dealer Teamwork Auto': 'Dealer Teamwork Auto',
      'Ford Direct Program': 'Ford AdVantage - DT',
      'FCA Program': 'FCA',
      'Acura Program': 'Acura - DT',
      'Honda Program': 'Honda - DT',
      'BMW Program': 'BMW'
    };

    let associatedAccountProgram = 'Dealer Teamwork Auto';
    for (const program in this.accountOemPrograms) {
      if (this.accountOemPrograms[program]) {
        associatedAccountProgram = oemProgramMccMapping[program];
        break;
      }
    }

    return associatedAccountProgram;
  }

  /**
   * Function to populate the sub MCC drop down
   *
   * @param program
   * @param formattedAccountId
   */
  populateDropdown(program: string, formattedAccountId: string): void {
    for (const subMcc of this.subMccAccounts) {
      const option = {
        label: subMcc.accountName + ' (' + this.stringFormatterService.formatGoogleAdsAccountId(subMcc.customerId) + ')',
        value: Number(subMcc.customerId)
      };

      if (subMcc.customerId === formattedAccountId) {
        this.subMccOptions[0].items.push(option);
      } else {
        this.subMccOptions[1].items.push(option);
      }

      if (program === subMcc.accountName) {
        this.selectedOemProgram = option;
      }
    }
  }
}
