import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {

  constructor() { }

  /**
   * Gets cookie value by name
   * @param name
   */
  public getCookie(name: string = 'auto-token') {
    const cookieArray: Array<string> = document.cookie.split(';');
    const cookieName = `${name}=`;
    let cookie: string;

    for (let i = 0; i < cookieArray.length; i += 1) {
      const current = cookieArray[i].replace(/^\s+/g, '');
      if (current.indexOf(cookieName) === 0) {
        cookie = current.substring(cookieName.length, current.length);
        break;
      }
    }

    return cookie;
  }

  /**
   * Expires given cookie
   * @param name
   * @param isSubDomain
   */
  deleteCookie(name: string, isSubDomain: boolean = false) {
    const expirationDate = new Date(0);
    const subDomain = '.' + document.domain;
    let cookie = `${name}=; expires=${expirationDate.toUTCString()}; path=/`;
    if (isSubDomain) {
      cookie = `${name}=; expires=${expirationDate.toUTCString()}; path=/; domain=${subDomain}`;
    }
    document.cookie = cookie;
  }

  public setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const date: Date = new Date();
    date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires: string = 'expires=' + date.toUTCString();
    document.cookie = name + '=' + value + '; ' + expires + (path.length > 0 ? '; path=' + path : '');
  }

  public clearSessionCookies(): void {
    this.deleteCookie('auto-token', true);
    this.deleteCookie('dt');
    this.deleteCookie('dtl');
    this.deleteCookie('PHPSESSID');
  }
}
