import {Component, Input} from '@angular/core';
import {FormItemBase} from '../../../model/form-models/form-item-base';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'auto-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {
  @Input() form: UntypedFormGroup;
  @Input() formItems: FormItemBase<any>[];
  @Input() formName: string;

  constructor() { }

}
