import { Pipe, PipeTransform } from '@angular/core';
import {PageModel} from '../model/page.model';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Pipe({
  name: 'filter'
})
export class PageFilterPipe implements PipeTransform {

  transform(displayedPages: any, pageSearchFilter?: any): any {
    if (pageSearchFilter === undefined || displayedPages == null) {
      return displayedPages;
    }
    return displayedPages.filter(pages =>
      pages.titleName.toLowerCase().includes(pageSearchFilter.toLowerCase()) ||
      pages.pageType.toLowerCase().includes(pageSearchFilter.toLowerCase()));
  }
}

