import {Injectable} from '@angular/core';
import {MessageService} from "primeng/api";
import {Clipboard} from "@angular/cdk/clipboard";

@Injectable({
  providedIn: 'root'
})
export class CopyToClipboardService {

  constructor(
    private messageService: MessageService,
    private clipboard: Clipboard
  ) {}

  /**
   * Copy the given value to the clipboard
   * @param value
   */
  copyToClipboard(value: string) {
    this.clipboard.copy(value);
    this.messageService.add({
      severity: 'info',
      detail: 'Successfully copied to clipboard'
    });
  }
}
