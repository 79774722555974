import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MpopUserModel} from '../../../../../model/mpop-user.model';
import {DOCUMENT} from '@angular/common';
import {UserService} from '../../../../../service/user.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Observable} from 'rxjs';

@Component({
  selector: 'auto-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
/**
 * class UserProfile
 */
export class UserProfileComponent implements OnChanges {
  @Input() user: MpopUserModel;
  @Input() isUserSuperAdmin: boolean;
  @Output() onUserProfileClose: EventEmitter<MpopUserModel> = new EventEmitter<MpopUserModel>();
  documentHostname: string;
  documentProtocol: string;
  loginLogsLoading = true;

  /**
   * Constructor for UserProfile
   * @param document
   * @param userService
   * @param confirmationService
   * @param messageService
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.documentHostname = this.document.location.hostname;
    this.documentProtocol = this.document.location.protocol;
  }

  /**
   * Method to initialize the component on changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.loginLogsLoading = true;
    this.getRecentLogins();
  }

  /**
   * Method to close the user profile
   */
  closeUserProfile(): void {
    this.loginLogsLoading = true;
    this.onUserProfileClose.emit(this.user);
  }

  /**
   * Method to filter recent logins
   * @return boolean
   */
  hasRecentLogins(): boolean {
    let result = false;
    if (this.user.recentLogins.length > 0) {
      result = true;
    }

    return result;
  }

  userCanEdit(): boolean {
    return this.isUserSuperAdmin || this.user.userType !== 'Admin';
  }

  /**
   * Method to open the reset password link modal
   */
  openResetPasswordLinkModal() {
    const resetMessage =
      `Are you sure you want to reset ${this.user.name}'s password? <br><br> <i>Note: This action will
        email ${this.user.email} with a link to reset their password. This action cannot be undone.</i>`;
    this.confirmationService.confirm({
      key: 'sendResetPasswordLink',
      defaultFocus: 'none',
      message: resetMessage,
      header: `Reset User Password`,
      accept: () => {
        this.sendResetPasswordEmail();
      }
    });
  }

  /**
   * Method to send reset password email
   */
  sendResetPasswordEmail(): void {
    this.userService.requestResetPassword({username: this.user.username}).subscribe(
      (result) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Reset password email sent'
        });
      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to send reset password email'
        });
      }
    );
  }

  /**
   * Method to get recent logins
   */
  getRecentLogins(): void {
    this.userService.getRecentLogins(this.user.id).subscribe(
      (result) => {
        this.user.recentLogins = result;
        this.loginLogsLoading = false;
      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get recent logins'
        });
      }
    );
  }
}
