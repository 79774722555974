import { Injectable } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormItemBase} from '../model/form-models/form-item-base';

@Injectable({
  providedIn: 'root'
})
export class FormControlService {

  constructor() { }

  public toFormGroup(formItems: FormItemBase<string>[]) {
    const group: any = {};

    formItems.forEach((formItem) => {
      group[formItem.key] = new UntypedFormControl(formItem.value);
    });

    return new UntypedFormGroup(group);
  }
}
