import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NavigationService} from '../../../../service/navigation.service';
import {MenuItem, MessageService} from 'primeng/api';
import {MediaManagementService} from '../../../../service/media-management.service';
import {MediaManagementModel} from '../../../../model/media-management.model';
import {AccountService} from '../../../../service/account.service';
import {AccountInterface} from '../../../../model/account.interface';
import {CopyToClipboardService} from "../../../../service/utilities/copy-to-clipboard.service";

@Component({
  selector: 'auto-media-management',
  templateUrl: './media-management.component.html',
  styleUrls: ['./media-management.component.scss']
})
export class MediaManagementComponent implements OnInit {

  accountId: number;
  settingsSideNav: MenuItem[];
  mediaManagementSettings: MediaManagementModel;
  googleSettingsForm: UntypedFormGroup;
  facebookSettingsForm: UntypedFormGroup;
  microsoftSettingsForm: UntypedFormGroup;
  generalSettingsForm: UntypedFormGroup;
  account: AccountInterface;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    private navigationService: NavigationService,
    protected messageService: MessageService,
    protected mediaManagementService: MediaManagementService,
    protected accountService: AccountService,
    protected copyToClipboardService: CopyToClipboardService
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Media Management');
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.initNavigation();
    });
    this.mediaManagementService.getMediaManagementSettings(this.accountId).subscribe({
      next: (settings) => {
        this.accountService.getAccount(this.accountId).subscribe({
          next: (account) => {
            this.mediaManagementSettings = settings;
            this.account = account;
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to load media management settings'
            });
          },
        });
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load media management settings'
        });
      },
    });
  }

  /**
   * Initialize the side navigation menu
   */
  initNavigation(): void {
    this.navigationService.getMenuItems(this.accountId, 'settings-view-side-menu.configuration.json')
      .then((navItems) => {
        this.settingsSideNav = navItems;
      });
  }

  /**
   * Initialize the general settings form group
   * @param formGroup
   */
  onGeneralFormGroupInitialized(formGroup: UntypedFormGroup) {
    this.generalSettingsForm = formGroup;
  }

  /**
   * Initialize the google settings form group
   * @param settings
   */
  onGoogleFormGroupInitialized(settings: UntypedFormGroup) {
    this.googleSettingsForm = settings;
  }

  /**
   * Initialize the facebook settings form group
   * @param settings
   */
  onFacebookFormGroupInitialized(settings: UntypedFormGroup) {
    this.facebookSettingsForm = settings;
  }


  /**
   * Initialize the microsoft settings form group
   * @param settings
   */
  onMicrosoftFormGroupInitialized(settings: UntypedFormGroup) {
    this.microsoftSettingsForm = settings;
  }

  /**
   * Save the media management settings
   */
  saveMediaManagementSettings() {
    let settings = this.createMediaManagementSettings();
    this.mediaManagementService.saveMediaManagementSettings(settings).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Media management settings saved successfully'
        });
        this.generalSettingsForm.markAsPristine();
        this.googleSettingsForm.markAsPristine();
        this.facebookSettingsForm.markAsPristine();
        this.microsoftSettingsForm.markAsPristine();
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save media management settings'
        });
      },
    });
  }

  /**
   * Create a media management settings object from the form values
   */
  createMediaManagementSettings(): MediaManagementModel {
    let setting = new MediaManagementModel();
    setting.ncsAccountId = this.googleSettingsForm.controls.ncsAccountId.value;
    setting.googleStoreCode = this.googleSettingsForm.controls.googleStoreCode.value;
    setting.useDealerId = this.googleSettingsForm.controls.useDealerId.value;
    setting.advertiseAllGoogleVehicles = this.googleSettingsForm.controls.advertiseAllGoogleVehicles.value;
    setting.gbpNewFinalUrl = this.googleSettingsForm.controls.gbpNewFinalUrl.value;
    setting.gbpUsedFinalUrl = this.googleSettingsForm.controls.gbpUsedFinalUrl.value;
    setting.carsForSaleEnabled = this.googleSettingsForm.controls.carsForSaleEnabled.value;
    setting.gmbPostsEnabled = this.googleSettingsForm.controls.gmbPostsEnabled.value;
    setting.minimumImageRequirement = this.googleSettingsForm.controls.minimumImageRequirement.value;
    setting.googleAnalyticsMeasurementId = this.googleSettingsForm.controls.googleAnalyticsMeasurementId.value;
    setting.useGoogleAnalyticsTracking = this.googleSettingsForm.controls.useGoogleAnalyticsTracking.value;

    setting.facebookPageId = this.facebookSettingsForm.controls.facebookPageId.value;
    setting.facebookShareActive = this.facebookSettingsForm.controls.facebookShareActive.value;
    setting.facebookDealerName = this.facebookSettingsForm.controls.facebookDealerName.value;
    setting.facebookMarketplaceEnabled = this.facebookSettingsForm.controls.facebookMarketplaceEnabled.value;
    setting.facebookAdsEnabled = this.facebookSettingsForm.controls.facebookAdsEnabled.value;
    setting.advertiseAllVehicles = this.facebookSettingsForm.controls.advertiseAllVehicles.value;
    setting.facebookPixelId = this.facebookSettingsForm.controls.facebookPixelId.value;
    setting.facebookAdsAccountId = this.facebookSettingsForm.controls.facebookAdsAccountId.value;
    setting.facebookPixelHash = this.facebookSettingsForm.controls.facebookPixelHash.value;
    setting.facebookAdsCustomLabel0 = this.facebookSettingsForm.controls.facebookAdsCustomLabel0.value;
    setting.facebookAdsCustomLabel1 = this.facebookSettingsForm.controls.facebookAdsCustomLabel1.value;
    setting.facebookAdsCustomLabel2 = this.facebookSettingsForm.controls.facebookAdsCustomLabel2.value;
    setting.facebookAdsFallbackLabel0 = this.facebookSettingsForm.controls.facebookAdsFallbackLabel0.value;
    setting.facebookAdsFallbackLabel1 = this.facebookSettingsForm.controls.facebookAdsFallbackLabel1.value;
    setting.facebookAdsFallbackLabel2 = this.facebookSettingsForm.controls.facebookAdsFallbackLabel2.value;
    setting.facebookAdsDefaultLabel = this.facebookSettingsForm.controls.facebookAdsDefaultLabel.value;
    setting.facebookPageAccessToken = this.facebookSettingsForm.controls.facebookPageAccessToken.value;

    setting.microsoftAdsEnabled = this.microsoftSettingsForm.controls.microsoftAdsEnabled.value;
    setting.microsoftAdsAccountId = this.microsoftSettingsForm.controls.microsoftAdsAccountId.value;
    setting.microsoftAdsUetId = this.microsoftSettingsForm.controls.microsoftAdsUetId.value;
    setting.newMediaFriendlyImage = this.generalSettingsForm.controls.newMediaFriendlyImage.value;
    setting.usedMediaFriendlyImage = this.generalSettingsForm.controls.usedMediaFriendlyImage.value;
    setting.uberallLocationId = this.mediaManagementSettings.uberallLocationId;

    return setting;
  }

  /**
   * Replace placeholders in the url with actual values
   * @param url
   * @param accountFolder
   */
  replaceUrlPlaceholders(url: string, accountFolder: string): string {
    return url.replace('[environment]', this.getEnvironmentName())
      .replace(/\[account_folder\]/g, accountFolder);
  }

  /**
   * Get the environment name from the url
   */
  getEnvironmentName(): string {
    let regex = /https?:\/\/(.*?)\-admin/;
    let match = window.location.href.match(regex);
    let environment = "prod";

    if (match && match[1]) {
      environment = match[1];
    }

    return environment;
  }

  /**
   * Get the save button disabled state
   */
  getSaveButtonDisabled(): boolean {
    let result = false;
    if (this.facebookSettingsForm && this.googleSettingsForm && this.microsoftSettingsForm && this.generalSettingsForm) {
      result = (this.facebookSettingsForm.pristine &&
          this.googleSettingsForm.pristine &&
          this.microsoftSettingsForm.pristine &&
          this.generalSettingsForm.pristine) ||
        (!this.facebookSettingsForm.valid && this.facebookSettingsForm.controls.facebookAdsEnabled.value);
    }

    return result;
  }
}
