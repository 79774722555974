import {Component, Input, OnInit} from '@angular/core';
import {NavigationService} from '../../../service/navigation.service';
import {AccountService} from '../../../service/account.service';
import {CookieService} from '../../../service/cookie.service';
import {MenuItem} from 'primeng/api';
import {Router} from '@angular/router';
import {AccountInterface} from '../../../model/account.interface';

@Component({
  selector: 'auto-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit {
  @Input() accountId: string;

  isLoaded = false;
  displaySidebar = false;

  accountName: string;
  topNavItems: MenuItem[];
  sideNavItems: MenuItem[];

  mpopDashboardUrl = "/dashboard";

  constructor(
    private navigationService: NavigationService,
    private accountService: AccountService,
    private cookieService: CookieService,
    private router: Router
  ) { }

  ngOnInit() {
    const authToken = this.cookieService.getCookie();
    if (authToken) {
      if (this.accountId) {
        this.accountService.getAccount(parseInt(this.accountId)).subscribe(
          (account) => {
            this.initNavigation(account);
            this.accountName = account.name;
          }
        );
      } else {
          this.initNavigation(null);
      }
    } else {
      this.router.navigateByUrl('/');
    }
  }

  initNavigation(account: AccountInterface): void {
    this.navigationService.getMenuItems(this.accountId, 'top-nav.configuration.json', account).then((menuItems) => {
      this.topNavItems = menuItems;
      this.isLoaded = true;
    });

    this.navigationService.getMenuItems(this.accountId, 'side-nav.configuration.json', account).then((menuItems) => {
      this.sideNavItems = menuItems;
    });
  }
}
