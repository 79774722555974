export class CampaignAutomationSettingsModel {
  accountId: number;
  oemProgram: string;
  dealerId: string;
  makeString: string;
  websiteUrl: string;
  stateAbbr: string;
  city: string;
  zipCode: string;
  latitude: string;
  longitude: string;
  usePremiumAssets: boolean;
  modifiedUser: number;

  constructor(
    accountId: number,
    oemProgram: string,
    dealerId: string,
    makeString: string,
    websiteUrl: string,
    stateAbbr: string,
    city: string,
    zipCode: string,
    latitude: string,
    longitude: string,
    usePremiumAssets: boolean,
    modifiedUser?: number
  ) {
    this.accountId = accountId;
    this.oemProgram = oemProgram;
    this.dealerId = dealerId;
    this.makeString = makeString;
    this.websiteUrl = websiteUrl;
    this.stateAbbr = stateAbbr;
    this.city = city;
    this.zipCode = zipCode;
    this.latitude = latitude;
    this.longitude = longitude;
    this.usePremiumAssets = usePremiumAssets;
    this.modifiedUser = modifiedUser;
  }
}
