import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PageService} from '../../../../service/page.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'auto-page-configuration',
  templateUrl: './page-configuration.component.html',
  styleUrls: ['./page-configuration.component.scss']
})
export class PageConfigurationComponent implements OnInit {
  accountId: number;
  selectedNewPageAdSortOption: string;
  selectedUsedPageAdSortOption: string;
  featuredAdsFirst: boolean;
  newPageAdSortOptions = [
    'Manual Sort',
    'Lowest Lease Payment',
    'Highest Lease Payment',
    'Lowest Finance Payment',
    'Highest Finance Payment',
    'Lowest Buy Price',
    'Highest Buy Price',
    'Savings Amount',
    'Year (Oldest - Newest)',
    'Year (Newest - Oldest)',
    'Lowest Payment',
    'Highest Payment'
  ];
  usedPageAdSortOptions = [
    'Manual Sort',
    'Lowest Finance Payment',
    'Highest Finance Payment',
    'Lowest Buy Price',
    'Highest Buy Price',
    'Savings Amount',
    'Year (Oldest - Newest)',
    'Year (Newest - Oldest)',
    'On Make'
  ];
  constructor(
    private route: ActivatedRoute,
    private pageService: PageService,
    private messageService: MessageService
) { }

  /**
   * init component
   */
  async ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.pageService.getPageConfiguration(this.accountId).subscribe(
        (pageConfiguration) => {
          this.selectedNewPageAdSortOption = pageConfiguration.newAdSortSetting;
          this.selectedUsedPageAdSortOption = pageConfiguration.usedAdSortSetting;
          this.featuredAdsFirst = pageConfiguration.featuredAdsFirst;
          this.addInventoryAgeOptions(pageConfiguration.dateInStockMapped);
        }
      );
    });
  }

  /**
   * Add date in stock options if the date in stock is mapped
   * @param dateInStockMapped
   */
  addInventoryAgeOptions(dateInStockMapped: boolean) {
    if (dateInStockMapped) {
      this.newPageAdSortOptions.push('Inventory Age (Newest to Oldest)', 'Inventory Age (Oldest to Newest)');
      this.usedPageAdSortOptions.push('Inventory Age (Newest to Oldest)', 'Inventory Age (Oldest to Newest)');
    }
  }

  updatePageConfiguration() {
    const pageConfigurationData = {
      accountId: this.accountId,
      newAdSortSetting: this.selectedNewPageAdSortOption,
      usedAdSortSetting: this.selectedUsedPageAdSortOption,
      featuredAdsFirst: this.featuredAdsFirst
    };
    this.pageService.updatePageConfiguration(pageConfigurationData)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
          this.pageService.getPages(this.accountId, null);
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save changes.',
            life: 5000
          });
        });
  }
}
