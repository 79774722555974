import { Pipe, PipeTransform } from '@angular/core';
import {MpopUserModel} from "../model/mpop-user.model";

@Pipe({
  name: 'userFilterPipe'
})
export class UserFilterPipe implements PipeTransform {

  transform(displayedUsers: MpopUserModel[], userSearchFilter?: string): any {
    if (!userSearchFilter || !displayedUsers) {
      return displayedUsers;
    }

    return displayedUsers.filter(user => this.matchesFilter(user, userSearchFilter));
  }

  matchesFilter(user, userSearchFilter) {
    return user.name.toLowerCase().includes(userSearchFilter.toLowerCase()) ||
      user.userType.toLowerCase().includes(userSearchFilter.toLowerCase()) ||
      user.email.toLowerCase().includes(userSearchFilter.toLowerCase()) ||
      user.phone.toLowerCase().includes(userSearchFilter.toLowerCase()) ||
      user.dealerAccountGroup.toLowerCase().includes(userSearchFilter.toLowerCase());
  }

}
