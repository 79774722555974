import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

import {FormItemBase} from '../../../model/form-models/form-item-base';

@Component({
  selector: 'auto-dynamic-form-item',
  templateUrl: './dynamic-form-item.component.html',
  styleUrls: ['./dynamic-form-item.component.scss']
})
export class DynamicFormItemComponent {
  isDecimalValue = true;
  @Input() formItem: FormItemBase<string>;
  @Input() form: UntypedFormGroup;
  @Input() formName: string;

  get isValid() {
    let result = true;
    if (this.formItem.key in this.form.controls) {
      result = this.form.controls[this.formItem.key].valid;
    }

    return result;
  }

  /**
   * Check to see if given value is a decimal
   */
  setIsDecimalValue() {
    this.isDecimalValue = true;
    const decimalControlType = 'decimal';
    const key = this.formItem.key;

    if (this.formItem.controlType === decimalControlType) {
      let value = parseFloat(this.form.controls[key].value);
      const splitValue = value.toString().split('.');
      let lengthValue = 0;

      if (splitValue.length > 1) {
        lengthValue = splitValue[1].length;
      }

      const element = document.getElementById('vehicleAdSaveButton') as HTMLInputElement;
      element.removeAttribute('disabled');

      if (isNaN(value)) {
        this.form.controls[key].setValue(0);
        value = 0;
      }

      if (value < 0 || value > 0.9999999999 || lengthValue > 10) {
        element.setAttribute('disabled', 'disabled');
        this.isDecimalValue = false;
      }
    }
  }
}
