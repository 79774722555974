<form [formGroup]="microsoftSettingsForm" autoTrackUnsavedChanges>
  <div class="tab-panel-content-wrapper">
    <div class="p-grid p-col-12 setting-content">
      <label class="field-switch-label"><b>Microsoft Ads</b></label>
      <p-inputSwitch formControlName="microsoftAdsEnabled"></p-inputSwitch>
    </div>
    <div [hidden]="!microsoftSettingsForm.get('microsoftAdsEnabled').value" class="microsoft-ads">
      <div class="p-grid setting-content">
        <label class="field-input-label tab-input-label"><b>Microsoft Ads Catalog URL</b></label>
        <input
          type="text"
          pInputText
          class="text-input"
          readonly
          formControlName="microsoftAdsCatalogUrl"/>
        <i
          class="pi pi-fw pi-copy"
          (click)="copyToClipboardService.copyToClipboard(this.microsoftSettingsForm.controls.microsoftAdsCatalogUrl.value)">
        </i>
      </div>
      <div class="p-grid setting-content">
        <label class="field-input-label tab-input-label"><b>Microsoft Ads Account ID</b></label>
        <input type="text" pInputText class="text-input" formControlName="microsoftAdsAccountId"/>
      </div>
      <div class="p-grid setting-content">
        <label class="field-input-label tab-input-label"><b>Microsoft Ads UET ID</b></label>
        <input type="text" pInputText class="text-input" formControlName="microsoftAdsUetId"/>
      </div>
    </div>
  </div>
</form>

