<auto-main-navigation></auto-main-navigation>

<!--<div class="filters-placeholder">Filters</div>-->

<div class="wrapper">

  <h2 class="p-align-center">Welcome Back, {{name}}!</h2>
  <input type="search"
         pInputText
         placeholder="Search Account Name or ID"
         [(ngModel)]="search"
         (input)="onSearchInput()" />

  <p-paginator (onPageChange)="onPageChange($event)"
               [totalRecords]="filteredAccounts.length"
               [first]="first"
               [rows]="rows"
               [showFirstLastIcon]="false"
               [alwaysShow]="false"
               *ngIf="!showLoadingSpinner">
  </p-paginator>

  <p-progressSpinner *ngIf="showLoadingSpinner"></p-progressSpinner>

  <div class="account-list">
      <auto-account-card *ngFor="let account of currentPageData"
                         [account]="account"
                         [permissions]="permissions[account.accountId]"
      >
      </auto-account-card>
  </div>

  <p-paginator (onPageChange)="onPageChange($event)"
               [totalRecords]="filteredAccounts.length"
               [first]="first"
               [rows]="rows"
               [showFirstLastIcon]="false"
               [alwaysShow]="false"
               *ngIf="!showLoadingSpinner">
  </p-paginator>
</div>

<auto-footer></auto-footer>
