import {Component, Input, OnInit} from '@angular/core';
import {BudgetManagementService} from '../../../../../service/budget-management.service';
import {MessageService} from 'primeng/api';
import {StringFormatterService} from '../../../../../service/utilities/string-formatter.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BudgetFeaturesService} from '../../../../../service/budget-features.service';
import {AccountFeatureService} from '../../../../../service/account-feature.service';
import {AccountFeatureModel} from '../../../../../model/account-feature.model';
import {InitializationService} from '../../../../../service/initialization.service';

@Component({
  selector: 'auto-budget-management',
  templateUrl: './budget-management.component.html',
  styleUrls: ['./budget-management.component.scss']
})
export class BudgetManagementComponent implements OnInit {
  @Input() accountId: number;
  @Input() expandIcon: string;
  @Input() collapseIcon: string;

  accountFeatureSwitchFormGroup: UntypedFormGroup | null = null;
  standardBudget = 'Standard';
  heavyUpBudget = 'Heavy-Up';
  standardActiveBudgetManagements = [];
  standardPendingBudgetManagements = [];
  heavyUpActiveBudgetManagements = [];
  heavyUpPendingBudgetManagements = [];

  accountBudget = 0;
  googleAdsAccountBudgetLastUpdated: string;
  googleAdsCampaignBudgetsLastUpdated: string;

  constructor(
    private budgetManagementService: BudgetManagementService,
    private budgetFeaturesService: BudgetFeaturesService,
    private accountFeatureService: AccountFeatureService,
    private messageService: MessageService,
    private initializationService: InitializationService,
    public stringFormatterService: StringFormatterService
  ) { }

  ngOnInit(): void {
    this.initializeBudgets();
    this.buildBudgetFeaturesFormGroup();
    this.budgetFeaturesService.getBudgetFeatures(this.accountId).subscribe(
      (budgetFeatures) => {
        this.accountFeatureSwitchFormGroup.controls.updateAccountBudget.setValue(budgetFeatures.updateGoogleAdsAccountBudget);
        this.accountFeatureSwitchFormGroup.controls.updateCampaignBudget.setValue(budgetFeatures.updateGoogleAdsCampaignBudgets);
        this.googleAdsAccountBudgetLastUpdated = budgetFeatures.googleAdsAccountBudgetLastUpdated;
        this.googleAdsCampaignBudgetsLastUpdated = budgetFeatures.googleAdsCampaignBudgetsLastUpdated;
      }
    );
  }

  /**
   * Initializes both standard and heavy-up budgets
   */
  initializeBudgets(): void
  {
    this.budgetManagementService.getBudgetManagementData(this.accountId).subscribe(
      (budgetManagementData) => {
        budgetManagementData.forEach((budgetManagement) => {
          if (budgetManagement.budgetType === 'Heavy-Up') {
            if (budgetManagement.status === 'Pending') {
              this.heavyUpPendingBudgetManagements.push(budgetManagement);
            } else if (budgetManagement.status === 'Active') {
              this.heavyUpActiveBudgetManagements.push(budgetManagement);
              this.accountBudget += budgetManagement.totalBudget;
            }
          } else {
            if (budgetManagement.status === 'Pending') {
              this.standardPendingBudgetManagements.push(budgetManagement);
            } else if (budgetManagement.status === 'Active') {
              this.standardActiveBudgetManagements.push(budgetManagement);
              this.accountBudget += budgetManagement.totalBudget;
            }
          }
        });
        this.initializationService.notifyComponentInitializationCompleted();
      }
    );
  }

  /**
   * Saves account and campaign budget features
   */
  saveFeatures(): void {
    if (!this.accountFeatureSwitchFormGroup.controls.updateAccountBudget.pristine) {
      this.handleAccountBudgetUpdateActiveSwitchToggle();
    }
    if (!this.accountFeatureSwitchFormGroup.controls.updateCampaignBudget.pristine) {
      this.handleCampaignBudgetsUpdateActiveSwitchToggle();
    }
  }

  /**
   * Handles toggling the Automate Google Ads Account Budget switch
   */
  handleAccountBudgetUpdateActiveSwitchToggle(): void {
    this.accountFeatureService.updateAccountFeature(new AccountFeatureModel(
      this.accountId,
      'updateGoogleAdsAccountBudget',
      this.accountFeatureSwitchFormGroup.controls.updateAccountBudget.value))
      .subscribe(
        () => {
          this.accountFeatureSwitchFormGroup.controls.updateAccountBudget.markAsPristine();
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
            life: 5000
          });
        });
  }

  /**
   * Handles toggling the Automate Google Ads Campaign Budgets switch
   */
  handleCampaignBudgetsUpdateActiveSwitchToggle(): void {
    this.accountFeatureService.updateAccountFeature(new AccountFeatureModel(
      this.accountId,
      'updateGoogleAdsCampaignBudgets',
      this.accountFeatureSwitchFormGroup.controls.updateCampaignBudget.value))
      .subscribe(
        () => {
          this.accountFeatureSwitchFormGroup.controls.updateCampaignBudget.markAsPristine();
          this.messageService.add({
            severity: 'success',
            summary: 'Update complete',
            detail: 'Your changes were saved.',
            life: 5000
          });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
            life: 5000
          });
        });
  }

  /**
   * Builds form controls for the budget features switches
   */
  buildBudgetFeaturesFormGroup(): void
  {
    this.accountFeatureSwitchFormGroup = new UntypedFormGroup(
      {
        updateAccountBudget: new UntypedFormControl(false),
        updateCampaignBudget: new UntypedFormControl(false)
      }
    );
  }
}
