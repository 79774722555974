<p-panel [toggleable]="true" [collapsed]="false" [collapseIcon]="collapseIcon" [expandIcon]="expandIcon">
  <p-header><h3>Overview</h3></p-header>
  <div class="p-grid">
    <div class="overview-card" *ngFor="let budget of budgetOverview">
      <p-card class="p-card">
        <div class="budget-field-label budget-name-label">
          <label><b>{{budget.budgetName}}</b></label>
        </div>
        <div class="p-grid">
          <div class="p-col-10" style="align-items: center; margin-right: -8.5rem;">
            <div class="p-field month-budget-field">
              <label>Month Budget</label>
              <label><b>${{stringFormatterService.formatNumberToString(budget.monthBudget)}} (${{stringFormatterService.formatNumberToString(budget.dailyBudget)}}/day)</b></label>
            </div>
            <p-progressBar class="p-progressbar" [style]="{'border-radius': '50px'}" [value]=budget.progressPercent></p-progressBar>
          </div>
        </div>
        <div class="card-footer">
          <div class="p-field">
            <label class="overview-label">Spend to Date</label>
            <label class="overview-label"><b>${{stringFormatterService.formatNumberToString(budget.spendToDate)}}</b></label>
          </div>
          <div class="p-field">
            <label class="overview-label">Target Spend</label>
            <label class="overview-label"><b>${{stringFormatterService.formatNumberToString(budget.targetSpend)}}</b></label>
          </div>
          <div class="p-field">
            <label class="overview-label">Pacing</label>
            <label class="overview-label" [ngClass]="{'ten-off-pace': budget.pacing < 90 || budget.pacing > 110, 'twenty-off-pace': budget.pacing < 80 || budget.pacing > 120}"><b>{{budget.pacing}}%</b></label>
          </div>
          <div class="p-field">
            <label class="overview-label">Left to Spend</label>
            <label class="overview-label"><b>${{stringFormatterService.formatNumberToString(budget.leftToSpend)}}</b></label>
          </div>
          <div class="p-field">
            <label class="overview-label">New Daily Budget</label>
            <label class="next-day-budget-label"><b>${{stringFormatterService.formatNumberToString(budget.newDailyBudget)}}</b></label>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</p-panel>
<p-panel [toggleable]="true" [collapsed]="true" [collapseIcon]="collapseIcon" [expandIcon]="expandIcon">
  <p-header><h3>Active Budgets</h3></p-header>
  <div *ngFor="let budget of activeBudgetManagements; let i=index">
    <label class="budget-field-label"><b>{{budget.salesforceBudgetName}}</b></label>
    <label class="budget-field-label">Budget: ${{stringFormatterService.formatNumberToString(budget.totalBudget)}}</label>
    <label *ngIf="budgetType === 'Heavy-Up'" class="budget-field-label">Adjusted Salesforce Budget: ${{stringFormatterService.formatNumberToString(budget.adjustedTotalBudget)}}</label>
    <label class="budget-field-label">Start Date: {{budget.startDate.date | date:'shortDate'}}</label>
    <label class="budget-field-label">End Date: {{budget.endDate.date | date:'shortDate'}}</label>
    <label *ngIf="budget.lastUpdatedDate" class="budget-field-label">Last Updated: {{budget.lastUpdatedDate | date:'shortDate'}}</label>
    <label *ngIf="budget.lastUpdatedUser" class="budget-field-label">Updated By: {{budget.lastUpdatedUser}}</label>
    <div class="p-grid field-form-wrapper">
      <form *ngIf="budget.campaignBudgets" [formGroup]="activeBudgetConfigurationFormGroups[i]"
            autoTrackUnsavedChanges class="p-col">
        <table class="p-datatable full-table">
          <thead class="p-datatable-thead">
          <tr class="p-datatable-header">
            <th style="text-align:center">
              Google Budget
            </th>
            <th style="text-align:center">
              Percentage
              <span *ngIf="budgetType === 'Standard'"
                    class="pi pi-info-circle"
                    pTooltip="Percentage of remaining Salesforce budget allocated. Remaining salesforce budget (total Salesforce budget less fixed monthly budget amounts): ${{stringFormatterService.formatNumberToString(budget.totalBudget - activeBudgetConfigurationTotalsFormGroups[i].controls.fixedAmountTotal.value)}}"
                    tooltipPosition="right"></span>
              <span *ngIf="budgetType === 'Heavy-Up'"
                    class="pi pi-info-circle"
                    pTooltip="Percentage of remaining Salesforce budget allocated. Remaining salesforce budget (total Salesforce budget less fixed monthly budget amounts): ${{stringFormatterService.formatNumberToString(budget.adjustedTotalBudget - activeBudgetConfigurationTotalsFormGroups[i].controls.fixedAmountTotal.value)}}"
                    tooltipPosition="right"></span>
            </th>
            <th style="text-align:center">
              Month Budget
            </th>
            <th style="text-align:center">
              Fixed Amount
            </th>
            <th style="text-align:center">
              Daily Budget
            </th>
          </tr>
          </thead>
          <tbody class="p-datatable-tbody">
          <ng-container formArrayName="activeBudgetConfigurationFormArray">
            <ng-container
              *ngFor="let activeBudgetConfiguration of this.activeBudgetConfigurationFormArrays[i].controls; let j=index">
              <tr [formGroupName]="j">
                <td style="text-align:center">{{activeBudgetConfiguration.value.budgetName}}</td>
                <td style="text-align:center">
                  <span *ngIf="activeBudgetConfiguration.value.fixedAmount" style="font-size: 20px; color: #212121" > -- </span>
                  <input *ngIf="!activeBudgetConfiguration.value.fixedAmount"
                         type="number"
                         min="0"
                         max="100"
                         pInputText
                         class="text-input"
                         style="font-size: 20px; color: #212121"
                         formControlName="percentage"
                         (change)="calculateValues(i, 'active')" />
                  <span *ngIf="!activeBudgetConfiguration.value.fixedAmount"> %</span>
                  <span *ngIf="activeBudgetConfiguration.invalid" class="p-invalid">
                  Must be between 0 and 100
                </span>
                </td>
                <td style="text-align:center">
                  $ <input *ngIf="activeBudgetConfiguration.value.fixedAmount"
                           type="number"
                           step="0.01"
                           min="0.00"
                           class="text-input"
                           style="text-align:center;
                                 font-size: 20px;
                                 color: #212121;"
                           formControlName="totalSpend"
                           (change)="calculateValues(i, 'active')"/>
                  <span *ngIf="!activeBudgetConfiguration.value.fixedAmount">
                          {{stringFormatterService.formatNumberToString(activeBudgetConfiguration.value.totalSpend)}}
                        </span>
                </td>
                <td style="text-align:center">
                  <p-inputSwitch
                    formControlName="fixedAmount"
                    (onChange)="calculateValues(i, 'active')"
                  ></p-inputSwitch>
                </td>
                <td style="text-align:center">${{stringFormatterService.formatNumberToString(activeBudgetConfiguration.value.dailyBudget)}}</td>
              </tr>
            </ng-container>
          </ng-container>
          <tr *ngIf="!activeBudgetConfigurationTotalsFormGroups[i].invalid" style="background:lightgrey">
            <td style="text-align:center"><i>Total</i></td>
            <td
              style="text-align:center">{{activeBudgetConfigurationTotalsFormGroups[i].controls.percentageTotal.value}} %</td>
            <td
              style="text-align:center">${{stringFormatterService.formatNumberToString(activeBudgetConfigurationTotalsFormGroups[i].controls.totalSpendTotal.value)}}</td>
            <td style="text-align:center"><i> </i></td>
            <td
              style="text-align:center">${{stringFormatterService.formatNumberToString(activeBudgetConfigurationTotalsFormGroups[i].controls.dailyBudgetTotal.value)}}</td>
          </tr>
          <tr *ngIf="activeBudgetConfigurationTotalsFormGroups[i].invalid" style="background:#FF3838">
            <td style="text-align:center"><i>Total</i></td>
            <td
              style="text-align:center">{{activeBudgetConfigurationTotalsFormGroups[i].controls.percentageTotal.value}} %</td>
            <td
              style="text-align:center">${{stringFormatterService.formatNumberToString(activeBudgetConfigurationTotalsFormGroups[i].controls.totalSpendTotal.value)}}</td>
            <td style="text-align:center"><i> </i></td>
            <td
              style="text-align:center">${{stringFormatterService.formatNumberToString(activeBudgetConfigurationTotalsFormGroups[i].controls.dailyBudgetTotal.value)}}</td>
          </tr>
          </tbody>
        </table>
        <div *ngIf="activeBudgetConfigurationTotalsFormGroups[i].controls.totalSpendTotal.value !== budget.totalBudget && !this.activeBudgetConfigurationFormArrays[i].dirty" >
          The account’s Salesforce Budget was updated. Please update budget configurations.
        </div>
        <div class="tab-panel-input-wrapper" style="margin-top: 12px">
          <button pButton
                  class="p-button-success"
                  label="Save"
                  (click)="handleBudgetConfigurationSave(i,'active')"
                  [disabled]="activeBudgetConfigurationTotalsFormGroups[i].invalid">
          </button>
        </div>
      </form>
    </div>
    <hr>
  </div>
</p-panel>
<p-panel [toggleable]="true" [collapsed]="true" [collapseIcon]="collapseIcon" [expandIcon]="expandIcon">
  <p-header><h3>Future Budgets</h3></p-header>
  <div *ngFor="let budget of pendingBudgetManagements; let i=index">
    <label class="budget-field-label"><b>{{budget.salesforceBudgetName}}</b></label>
    <label class="budget-field-label">Budget: ${{stringFormatterService.formatNumberToString(budget.totalBudget)}}</label>
    <label *ngIf="budgetType === 'Heavy-Up'" class="budget-field-label">Adjusted Salesforce Budget: ${{stringFormatterService.formatNumberToString(budget.adjustedTotalBudget)}}</label>
    <label class="budget-field-label">Start Date: {{budget.startDate.date | date:'shortDate'}}</label>
    <label class="budget-field-label">End Date: {{budget.endDate.date | date:'shortDate'}}</label>
    <label *ngIf="budget.lastUpdatedDate" class="budget-field-label">Last Updated: {{budget.lastUpdatedDate | date:'shortDate'}}</label>
    <label *ngIf="budget.lastUpdatedUser" class="budget-field-label">Updated By: {{budget.lastUpdatedUser}}</label>
    <div class="p-grid field-form-wrapper">
      <form *ngIf="budget.campaignBudgets" [formGroup]="pendingBudgetConfigurationFormGroups[i]"
            autoTrackUnsavedChanges class="p-col">
        <table class="p-datatable full-table">
          <thead class="p-datatable-thead">
          <tr class="p-datatable-header">
            <th style="text-align:center">
              Google Budget
            </th>
            <th style="text-align:center">
              Percentage
              <span
                *ngIf="budgetType === 'Standard'"
                class="pi pi-info-circle"
                pTooltip="Percentage of remaining Salesforce budget allocated. Remaining salesforce budget (total Salesforce budget less fixed monthly budget amounts): ${{stringFormatterService.formatNumberToString(budget.totalBudget - pendingBudgetConfigurationTotalsFormGroups[i].controls.fixedAmountTotal.value)}}"
                tooltipPosition="right"></span>
              <span
                *ngIf="budgetType === 'Heavy-Up'"
                class="pi pi-info-circle"
                pTooltip="Percentage of remaining Salesforce budget allocated. Remaining salesforce budget (total Salesforce budget less fixed monthly budget amounts): ${{stringFormatterService.formatNumberToString(budget.adjustedTotalBudget - pendingBudgetConfigurationTotalsFormGroups[i].controls.fixedAmountTotal.value)}}"
                tooltipPosition="right"></span>
            </th>
            <th style="text-align:center">
              Month Budget
            </th>
            <th style="text-align:center">
              Fixed Amount
            </th>
            <th style="text-align:center">
              Daily Budget
            </th>
          </tr>
          </thead>
          <tbody class="p-datatable-tbody">
          <ng-container formArrayName="pendingBudgetConfigurationFormArray">
            <ng-container
              *ngFor="let pendingBudgetConfiguration of this.pendingBudgetConfigurationFormArrays[i].controls; let j=index">
              <tr [formGroupName]="j">
                <td style="text-align:center">{{pendingBudgetConfiguration.value.budgetName}}</td>
                <td style="text-align:center">
                  <span *ngIf="pendingBudgetConfiguration.value.fixedAmount" style="font-size: 20px; color: #212121" > -- </span>
                  <input *ngIf="!pendingBudgetConfiguration.value.fixedAmount"
                         type="number"
                         min="0"
                         max="100"
                         pInputText
                         class="text-input"
                         style="font-size: 20px; color: #212121"
                         formControlName="percentage"
                         (change)="calculateValues(i, 'pending')" />
                  <span *ngIf="!pendingBudgetConfiguration.value.fixedAmount"> %</span>
                  <span *ngIf="pendingBudgetConfiguration.invalid" class="p-invalid">
                  Must be between 0 and 100
                </span>
                </td>
                <td style="text-align:center">
                  $ <input *ngIf="pendingBudgetConfiguration.value.fixedAmount"
                           type="number"
                           step="0.01"
                           min="0.00"
                           class="text-input"
                           style="text-align:center;
                                 font-size: 20px;
                                 color: #212121;"
                           formControlName="totalSpend"
                           (change)="calculateValues(i, 'pending')"/>
                  <span *ngIf="!pendingBudgetConfiguration.value.fixedAmount">
                          {{stringFormatterService.formatNumberToString(pendingBudgetConfiguration.value.totalSpend)}}
                        </span>
                </td>
                <td style="text-align:center">
                  <p-inputSwitch
                    formControlName="fixedAmount"
                    (onChange)="calculateValues(i, 'pending')"
                  ></p-inputSwitch>
                </td>
                <td style="text-align:center">${{stringFormatterService.formatNumberToString(pendingBudgetConfiguration.value.dailyBudget)}}</td>
              </tr>
            </ng-container>
          </ng-container>
          <tr *ngIf="!pendingBudgetConfigurationTotalsFormGroups[i].invalid" style="background:lightgrey">
            <td style="text-align:center"><i>Total</i></td>
            <td
              style="text-align:center">{{pendingBudgetConfigurationTotalsFormGroups[i].controls.percentageTotal.value}} %</td>
            <td
              style="text-align:center">${{stringFormatterService.formatNumberToString(pendingBudgetConfigurationTotalsFormGroups[i].controls.totalSpendTotal.value)}}</td>
            <td style="text-align:center"><i> </i></td>
            <td
              style="text-align:center">${{stringFormatterService.formatNumberToString(pendingBudgetConfigurationTotalsFormGroups[i].controls.dailyBudgetTotal.value)}}</td>
          </tr>
          <tr *ngIf="pendingBudgetConfigurationTotalsFormGroups[i].invalid" style="background:#FF3838">
            <td style="text-align:center"><i>Total</i></td>
            <td
              style="text-align:center">{{pendingBudgetConfigurationTotalsFormGroups[i].controls.percentageTotal.value}} %</td>
            <td
              style="text-align:center">${{stringFormatterService.formatNumberToString(pendingBudgetConfigurationTotalsFormGroups[i].controls.totalSpendTotal.value)}}</td>
            <td style="text-align:center"><i> </i></td>
            <td
              style="text-align:center">${{stringFormatterService.formatNumberToString(pendingBudgetConfigurationTotalsFormGroups[i].controls.dailyBudgetTotal.value)}}</td>
          </tr>
          </tbody>
        </table>
        <div *ngIf="pendingBudgetConfigurationTotalsFormGroups[i].controls.totalSpendTotal.value !== budget.totalBudget && !this.pendingBudgetConfigurationFormArrays[i].dirty" >
          The account’s Salesforce Budget was updated. Please update budget configurations.
        </div>
        <div class="tab-panel-input-wrapper" style="margin-top: 12px">
          <button pButton
                  class="p-button-success"
                  label="Save"
                  (click)="handleBudgetConfigurationSave(i,'pending')"
                  [disabled]="pendingBudgetConfigurationTotalsFormGroups[i].invalid">
          </button>
        </div>
      </form>
    </div>
    <hr>
  </div>
</p-panel>
