<auto-main-navigation [accountId]=""></auto-main-navigation>
<div class="layout">
  <div class="user-list">
    <p-panel class="panel">
      <p-header class="header-row">
        <a href="{{ documentProtocol }}//{{ documentHostname }}/users/add-user">
          <button pButton class="p-button-success user-button" label="Add New User"></button>
        </a>
        <div class="filter-row">
          <div class="p-input-icon-left input-bar p-mr-2">
            <i class="pi pi-search"></i><input type="text" pInputText [(ngModel)]="userSearchFilter"
                                               placeholder="Search Users..."/>
          </div>
          <p-dropdown class="dropdown-bar" [style]="{'min-width': '150px'}" [options]="sortByOptions"
                      [(ngModel)]="selectedOption" (onChange)="filterOptions()">
          </p-dropdown>
        </div>
      </p-header>
      <p-virtualScroller
        [value]="filteredMpopUsers | async | userFilterPipe:userSearchFilter"
        scrollHeight="100vh"
        [itemSize]="100"
        [lazy]="true"
        (onLazyLoad)="loadUsersLazy($event)">
        <ng-template let-user pTemplate="item">
          <p-card class="p-card" (click)="selectUser(user)"
                  [ngClass]="{'p-card-selected': selectedUser === user}"
                  [style]="{ border: '1px solid black', backgroundColor: user.backgroundColor}">
            <div class="p-grid">
              <div class="p-col-12 p-md-3">
                <div class="user-label-field"><label>{{user.name}}</label></div>
                <div><em style="font-size: 14px">{{user.userType}}</em></div>
              </div>
              <div class="p-col-6 p-md-3">
                <div class="user-label-field"><i class="pi pi-envelope icon-style"></i><label> {{user.email !== '' ? user.email : 'N/A'}}</label></div>
              </div>
              <div class="p-col-6 p-md-3 user-label-field">
                <div *ngIf="user.phone !== ''">
                  <i class="pi pi-phone icon-style"></i><label
                  class="user-label-field"> {{user.phone}}</label>
                </div>
              </div>
              <div class="p-col-6 p-md-3 user-label-field dealer-field" *ngIf="user.dealerAccountGroup !== ''">
                <div *ngIf="user.dealerAccountGroup.includes(':dealerAccount')">
                  <i class="pi pi-user icon-style"></i>
                </div>
                <div *ngIf="user.dealerAccountGroup.includes(':dealerGroup')">
                  <i class="pi pi-users icon-style"></i>
                </div>
                <label class="user-label-field">&nbsp;{{user.dealerAccountGroup.split(':')[0]}}</label>
              </div>
            </div>
          </p-card>
        </ng-template>
      </p-virtualScroller>
    </p-panel>
  </div>
  <div class="user-details" *ngIf="selectedUser">
    <auto-user-profile [user]="selectedUser"
                       [isUserSuperAdmin]="isUserSuperAdmin"
                       (onUserProfileClose)="closeUserProfile($event)">
    </auto-user-profile>
  </div>
</div>
<auto-footer></auto-footer>
