import { Injectable } from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AccountInterface} from '../model/account.interface';
import {environment} from '../../environments/environment';
import {CompactMpopAccountInterface} from '../model/compact-mpop-account.interface';
import {DashboardAccountInterface} from "../model/dashboard-account.interface";

@Injectable({
  providedIn: 'root'
})
export class AccountService extends AbstractHttpService{
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  constructor(
    private httpClient: HttpClient
) {
    super();
  }

  getAccount(accountId: number): Observable<AccountInterface> {
    return this.httpClient.get<AccountInterface>(
      `//${environment.autoDomain}:${environment.autoPort}/api/accounts/${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  getCompactMpopAccounts(): Observable<CompactMpopAccountInterface[]>{
    return this.httpClient.get<CompactMpopAccountInterface[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/compact-mpop-accounts`,
      this.defaultOptions
    ).pipe(
      mergeMap((asIs: CompactMpopAccountInterface[]) => asIs),
      map((compactMpopAccount: CompactMpopAccountInterface) => ({
        id: compactMpopAccount.id,
        name: compactMpopAccount.name,
        accountStatusTypeId: compactMpopAccount.accountStatusTypeId,
      })),
      toArray(),
      catchError(this.handleError)
    );
  }

  getDashboardAccounts(): Observable<DashboardAccountInterface[]> {
    return this.httpClient.get<DashboardAccountInterface[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/accounts/dashboard-accounts`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
