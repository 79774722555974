<auto-main-navigation [accountId]="accountId"></auto-main-navigation>

<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">Google Ads Settings</h1>
  </p-header>
  <div class="main-content-wrapper">
    <p-tabView>
      <p-tabPanel header="Account Configuration">
        <form [formGroup]="accountConfigurationFormGroup" autoTrackUnsavedChanges>
          <div class="tab-panel-content-wrapper">
            <div class="p-grid">
              <div class="tab-panel-input-wrapper p-col-12">
                <label for="adwords-active" class="field-input-label tab-input-label">Active</label>
                <p-inputSwitch
                  pTooltip="Google Ads account ID is required."
                  [tooltipDisabled]="!showAccountConfigurationToolTip"
                  tooltipPosition="top"
                  formControlName="adwordsActive"
                  id="adwords-active">
                </p-inputSwitch>
              </div>
              <div class="tab-panel-input-wrapper p-col-12">
                <label for="adwords-account-id" class="field-input-label tab-input-label">Google Ads Account ID</label>
                <input type="text" pInputText id="adwords-account-id"
                  (input)="handleActiveSwitchDisabledStatus()" formControlName="adwordsAccountId"
                       class="text-input"/>
              </div>
              <div class="tab-panel-input-wrapper p-col-12">
                <label for="account-short-name" class="field-input-label tab-input-label">Account Short Name</label>
                <input type="text" pInputText id="account-short-name" class="text-input"
                       formControlName="accountShortName"/>
              </div>
            </div>

            <h3>Customizers</h3>
            <div class="p-grid">
              <div class="tab-panel-input-wrapper p-col-4">
                <label for="slogan1" class="field-input-label tab-customizer-label">Slogan 1</label>
                <div class="input-validation-wrapper">
                  <input type="text" pInputText id="slogan1"
                         formControlName="slogan1" class="text-input"/>
                  <small *ngIf=accountConfigurationFormGroup.controls.slogan1.invalid class="p-d-block p-error block">
                    Check spacing, input (!*+) and/or length (&#8804; 90)
                  </small>
                </div>
              </div>
              <div class="tab-panel-input-wrapper p-col-4">
                <label for="custom-headline-1" class="field-input-label tab-customizer-label">Headline 1</label>
                <div class="input-validation-wrapper">
                  <input type="text" pInputText id="custom-headline-1"
                         formControlName="customHeadline1" class="text-input"/>
                  <small *ngIf=accountConfigurationFormGroup.controls.customHeadline1.invalid class="p-d-block p-error block">
                    Check spacing, input (!*+) and/or length (&#8804; 30)
                  </small>
                </div>
              </div>
              <div class="tab-panel-input-wrapper p-col-4">
                <label for="custom-description-1" class="field-input-label tab-customizer-label">Description 1</label>
                <div class="input-validation-wrapper">
                  <input type="text" pInputText id="custom-description-1"
                         formControlName="customDescription1" class="text-input"/>
                  <small *ngIf=accountConfigurationFormGroup.controls.customDescription1.invalid class="p-d-block p-error block">
                    Check spacing, input (!*+) and/or length (&#8804; 90)
                  </small>
                </div>
              </div>

              <div class="tab-panel-input-wrapper p-col-4">
                <label for="slogan2" class="field-input-label tab-customizer-label">Slogan 2</label>
                <div class="input-validation-wrapper">
                  <input type="text" pInputText id="slogan2"
                         formControlName="slogan2" class="text-input"/>
                  <small *ngIf=accountConfigurationFormGroup.controls.slogan2.invalid class="p-d-block p-error block">
                    Check spacing, input (!*+) and/or length (&#8804; 90)
                  </small>
                </div>
              </div>
              <div class="tab-panel-input-wrapper p-col-4">
                <label for="custom-headline-2" class="field-input-label tab-customizer-label">Headline 2</label>
                <div class="input-validation-wrapper">
                  <input type="text" pInputText id="custom-headline-2"
                         formControlName="customHeadline2" class="text-input"/>
                  <small *ngIf=accountConfigurationFormGroup.controls.customHeadline2.invalid class="p-d-block p-error block">
                    Check spacing, input (!*+) and/or length (&#8804; 30)
                  </small>
                </div>
              </div>
              <div class="tab-panel-input-wrapper p-col-4">
                <label for="custom-description-2" class="field-input-label tab-customizer-label">Description 2</label>
                <div class="input-validation-wrapper">
                  <input type="text" pInputText id="custom-description-2"
                         formControlName="customDescription2" class="text-input"/>
                  <small *ngIf=accountConfigurationFormGroup.controls.customDescription2.invalid class="p-d-block p-error block">
                    Check spacing, input (!*+) and/or length (&#8804; 90)
                  </small>
                </div>
              </div>
              <div class="tab-panel-input-wrapper p-col-4">
                <label for="slogan3" class="field-input-label tab-customizer-label">Slogan 3</label>
                <div class="input-validation-wrapper">
                  <input type="text" pInputText id="slogan3"
                         formControlName="slogan3" class="text-input"/>
                  <small *ngIf=accountConfigurationFormGroup.controls.slogan3.invalid class="p-d-block p-error block">
                    Check spacing, input (!*+) and/or length (&#8804; 90)
                  </small>
                </div>
              </div>
              <div class="tab-panel-input-wrapper p-col-4">
                <label for="custom-headline-3" class="field-input-label tab-customizer-label">Headline 3</label>
                <div class="input-validation-wrapper">
                  <input type="text" pInputText id="custom-headline-3"
                         formControlName="customHeadline3" class="text-input"/>
                  <small *ngIf=accountConfigurationFormGroup.controls.customHeadline3.invalid class="p-d-block p-error block">
                    Check spacing, input (!*+) and/or length (&#8804; 30)
                  </small>
                </div>
              </div>
              <div class="tab-panel-input-wrapper p-col-4">
                <label for="custom-description-3" class="field-input-label tab-customizer-label">Description 3</label>
                <div class="input-validation-wrapper">
                  <input type="text" pInputText id="custom-description-3"
                         formControlName="customDescription3" class="text-input"/>
                  <small *ngIf=accountConfigurationFormGroup.controls.customDescription3.invalid class="p-d-block p-error block">
                    Check spacing, input (!*+) and/or length (&#8804; 90)
                  </small>
                </div>
              </div>
              <div class="tab-panel-input-wrapper p-col-4">
                <label for="sales-event" class="field-input-label tab-customizer-label">Sales Event</label>
                <div class="input-validation-wrapper">
                  <input type="text" pInputText id="sales-event"
                       formControlName="salesEvent" class="text-input"/>
                  <small *ngIf=accountConfigurationFormGroup.controls.salesEvent.invalid class="p-d-block p-error block">
                    Check spacing and/or input (!*+)
                  </small>
                </div>
              </div>
              <div class="tab-panel-input-wrapper p-col-4">
                <label for="sale-end-date" class="field-input-label tab-customizer-label">Sale End Date</label>
                <p-calendar id="sale-end-date"
                            class="text-input"
                            formControlName="saleEndDate"
                            [showIcon]="true">
                </p-calendar>
              </div>

            </div>
            <div class="tab-panel-input-wrapper">
              <button pButton
                      id="accountConfigurationSaveButton"
                      class="p-button-success"
                      label="Save"
                      [disabled]="accountConfigurationFormGroup.invalid"
                      (click)="handleAccountConfigurationSave()">
              </button>
            </div>
          </div>
        </form>
      </p-tabPanel>
      <p-tabPanel header="Campaign Management">
        <p-panel *ngFor="let accountCampaignStrategy of accountCampaignStrategies, index as item"
                 [toggleable]="true" [collapsed]="false" [collapseIcon]="collapseIcon" [expandIcon]="expandIcon">
          <p-header>
            <h3>{{accountCampaignStrategy.name}}</h3>
          </p-header>
          <form [formGroup]="campaignManagementFormGroup.controls['strategies']['controls'][item]" autoTrackUnsavedChanges>
            <div *ngIf="accountCampaignStrategy.name === 'New Campaigns' && !showAccountConfigurationToolTip" class="p-grid p-justify-end">
              <div class="p-col-1 export-menu-container">
                <p-menu #exportMenu [model]="subMenuModel" [popup]="true"></p-menu>
                <i class="pi pi-ellipsis-h p-mr-2" (click)="exportMenu.toggle($event)"></i>
              </div>
            </div>
            <div class="tab-panel-content-wrapper p-grid">
              <div class="tab-panel-input-wrapper p-col-12">
                <label for="campaign-strategy-enabled"
                       class="field-input-label tab-input-label">Enabled
                  <span class="pi pi-info-circle"
                        pTooltip="{{accountCampaignStrategy.tooltipText}}"
                        tooltipPosition="top"></span>
                </label>
                <p-inputSwitch
                  pTooltip="This feature requires a Google Ads account ID to be saved for the MPOP account."
                  [tooltipDisabled]="!showCampaignManagementToolTip"
                  tooltipPosition="top"
                  formControlName="campaignStrategyEnabled"
                  id="campaign-strategy-enabled">
                </p-inputSwitch>
              </div>
              <div class="tab-panel-input-wrapper p-col-12">
                <label for="target-campaign" class="field-input-label tab-input-label">Target Campaign</label>
                <input
                  type="text"
                  pTooltip="The single campaign to target for ad customizer updates. When this is set, ad customizers will target ad groups within this campaign."
                  tooltipPosition="top"
                  pInputText
                  id="target-campaign"
                  class="text-input"
                  formControlName="targetCampaign"/>
              </div>
                <div *ngIf="accountCampaignStrategy.shortName === 'New'"  class="tab-panel-input-wrapper p-col-12">
                    <label for="campaign-template" class="field-input-label tab-input-label">Additional Campaigns</label>
                    <input
                            type="text"
                            pTooltip="Additional campaigns will utilize model-specific customizer data if their Campaign or associated Ad Group name include a [dt_model] tag matching our models table. Campaigns should be comma-separated."
                            tooltipPosition="top"
                            pInputText
                            id="additional-campaigns"
                            class="text-input"
                            formControlName="additionalCampaigns"/>
                </div>
              <div class="tab-panel-input-wrapper p-col-12">
                <label for="campaign-template" class="field-input-label tab-input-label">Campaign Template</label>
                <input
                  type="text"
                  pTooltip="A template matching the naming convention of campaigns within this account. “[dt_model]” will be replaced with “dt_[model_name]” (example: “dt_Cherokee”)."
                  tooltipPosition="top"
                  pInputText
                  id="campaign-template"
                  class="text-input"
                  formControlName="campaignTemplate"/>
              </div>
              <div *ngIf="accountCampaignStrategy.showFinalTemplateUrl" class="tab-panel-input-wrapper  p-col-12">
                <label for="campaign-template" class="field-input-label tab-input-label">Final URL Path Template</label>
                <input
                  type="text"
                  pInputText
                  id="final-url-path-template"
                  placeholder="{{accountCampaignStrategy.placeholderText}}"
                  class="text-input url-template-input"
                  formControlName="finalUrlPathTemplate"/>
              </div>

              <div class="tab-panel-input-wrapper p-col-12">
                <p *ngIf="accountCampaignStrategy.lastUpdated | hasProperty: 'date'">
                  Last Updated: {{accountCampaignStrategy.lastUpdated.date | date: 'short'}}</p>
                <p *ngIf="!(accountCampaignStrategy.lastUpdated | hasProperty: 'date')">Last Updated: Never</p>
              </div>

              <div class="tab-panel-input-wrapper">
                <button pButton
                        id="campaignManagementSaveButton"
                        class="p-button-success"
                        label="Save"
                        (click)="handleCampaignManagementSave(accountCampaignStrategy)">
                </button>
              </div>
              <div class="tab-panel-input-wrapper">
                <!--TODO: remove disabled name criteria when implementing Used Campaign Creation-->
                <button pButton
                        id="createCampaignsButton"
                        class="p-button-success"
                        label="Create Campaigns"
                        pTooltip="All Campaigns, Ad Groups, Keywords, and Ads that do not already exist will be created for this Campaign Strategy."
                        [disabled]="!accountCampaignStrategy.enabled || accountCampaignStrategy.name != 'New Campaigns'"
                        (click)="handleCreateCampaign(accountCampaignStrategy)">
                </button>
              </div>
            </div>
          </form>
        </p-panel>
      </p-tabPanel>
      <p-tabPanel header="Budget Management">
      <auto-budget-management
        [accountId] = "accountId"
        [expandIcon]="expandIcon"
        [collapseIcon]="collapseIcon">
      </auto-budget-management>
      </p-tabPanel>
      <p-tabPanel header="Automation">
        <auto-google-ads-automation
          [adwordsAccountId]="adwordsAccountId"
          [accountId]="accountId"
          [userId] = "userId"
          (onGoogleAccountCreation)="handleAdwordsAccountIdCreation()">
        </auto-google-ads-automation>
      </p-tabPanel>
    </p-tabView>
  </div>
</p-panel>
<auto-footer></auto-footer>
