<p-panel *ngIf="featureEnabled" header="Campaign Management" [toggleable]="true"
         [collapseIcon]="'pi pi-chevron-right'"
         [expandIcon]="'pi pi-chevron-down'">
    <div class="p-grid">
        <div *ngFor="let campaignForms of campaignAutomationConfigurationFormArrays" class="campaign-form">
            <label class="campaign-type-header"><b>{{ campaignForms.campaignType }} Campaigns:</b></label>
            <div class="campaign-field">
                <p-checkbox binary="true" [ngModel]="allSelected[campaignForms.campaignType]"
                            (onChange)="selectAllCampaigns(campaignForms.forms, campaignForms.campaignType)"></p-checkbox>
                <label class="campaign-name-field">
                    <b *ngIf="allSelected[campaignForms.campaignType]">Deselect All</b>
                    <b *ngIf="!allSelected[campaignForms.campaignType]">Select All</b>
                </label>
            </div>
            <div *ngFor="let campaign of campaignForms.forms; let i=index">
                <form [formGroup]="campaignForms.forms[i]" autoTrackUnsavedChanges>
                    <div class="campaign-field">
                        <p-checkbox binary="true" formControlName="updatesEnabled"
                                    (onChange)="selectCampaign(campaign, campaignForms)"></p-checkbox>
                        <label (mouseover)="op.toggle($event);" (mouseout)="op.hide()"
                               class="campaign-name-field"><b>{{ campaign.controls.campaign.value }}</b></label>
                        <p-overlayPanel #op [style]="{'background-color' : '#151515', 'color' : 'white'}">
                            <div class="flex flex-column gap-3 w-25rem">
                                <div>
                                    <span>Campaign Opted In:
                                        <b *ngIf="campaign.get('updatesEnabled').value">Yes</b>
                                        <b *ngIf="!campaign.get('updatesEnabled').value">No</b>
                                    </span>
                                    <br>
                                    <span>Campaign Last Updated:
                                        <b *ngIf="campaign.get('campaignLastUpdated').value !== null">{{ campaign.get('campaignLastUpdated').value.date  | date:'shortDate' }}</b>
                                        <b *ngIf="campaign.get('campaignLastUpdated').value  === null">N/A</b>
                                    </span>
                                    <br>
                                    <span>Setting Last Modified:
                                        <b *ngIf="campaign.get('modified').value !== null">{{ campaign.get('modified').value.date  | date:'shortDate' }}</b>
                                        <b *ngIf="campaign.get('modified').value  === null">N/A</b>
                                    </span>
                                    <br>
                                    <span>Setting Modified By:
                                        <b *ngIf="campaign.get('modifiedUser').value !== null">{{ campaign.get('modifiedUser').value }}</b>
                                        <b *ngIf="campaign.get('modifiedUser').value === null">N/A</b>
                                    </span>
                                </div>
                            </div>
                        </p-overlayPanel>
                    </div>
                </form>
            </div>
        </div>
    </div>
  <div class="p-grid">
    <div class="tab-panel-input-wrapper">
      <button pButton type="button" [disabled]="!formIsDirty || errorMessages.length > 0" class="p-button-success"
              (click)="openConfirmSaveModal()" label="Save"></button>
    </div>
    <div class="tab-panel-input-wrapper">
      <button pButton type="button" [disabled]="errorMessages.length > 0" class="update-button"
              (click)="openUpdateModal()" label="Update Campaigns"></button>
    </div>
  </div>
    <div *ngIf="warningMessages.length > 0">
        <div *ngFor="let requiredFields of warningMessages">
            <small class="p-d-block p-message-warn block warning-message">
                {{requiredFields}}
            </small>
        </div>
    </div>
    <div *ngIf="errorMessages.length > 0">
        <div *ngFor="let requiredFields of errorMessages">
            <small class="p-d-block p-error block">
                {{requiredFields}}
            </small>
        </div>
    </div>
  <p-dialog header="Update Campaigns"
            [(visible)]="displayUpdateModal" [draggable]="false"
            [dismissableMask]="true"
            modal="true"
            position="top"
            [resizable]="false" (onHide)="closeUpdateModal()">
    <p-multiSelect
      [options]="updateCampaignOptions"
      placeholder="Select Campaigns to Update"
      [group]="true"
      appendTo="body"
      [(ngModel)]="selectedCampaignsToUpdate"
      [style]="{'width':'33rem', 'padding':'0.5rem'}">
      <ng-template let-group pTemplate="group">
        <div class="p-d-flex p-ai-center">
          <span>{{ group.label }}</span>
        </div>
      </ng-template>
    </p-multiSelect>
    <ng-template pTemplate="footer">
        <div class="update-campaign-button">
          <button pButton type="button" [disabled]="selectedCampaignsToUpdate.length === 0"
                  (click)="updateCampaigns()" class="p-button-success" label="Update">
          </button>
          <button pButton type="button" label="Cancel" (click)="closeUpdateModal()" class="p-button-secondary"></button>
        </div>
      </ng-template>
    <div *ngIf="selectedCampaignsToUpdate.length !== 0">
      <p>Selected Campaigns to Update:</p>
      <ul>
        <li *ngFor="let campaign of selectedCampaignsToUpdate">
          <b>{{campaign.get('campaign').value}}</b>
        </li>
      </ul>
    </div>
  </p-dialog>
    <div class="modal-button-wrapper">
        <p-confirmDialog header="Save Campaigns" icon="ui-icon-blank" acceptLabel="Confirm" rejectLabel="Cancel"
                         key="saveCampaignsModal"
                         acceptButtonStyleClass="p-button-success"
                         rejectButtonStyleClass="reject-button"></p-confirmDialog>
    </div>
</p-panel>
