<auto-main-navigation [accountId]="accountId"></auto-main-navigation>

<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">Pages</h1>
  </p-header>
  <div class="main-content-wrapper" *ngIf="isLoaded">
    <p-panel class="p-col">
      <p-header class="add-and-filter-row">
        <p-menu #pageMenu [popup]="true" [model]="menuModel"></p-menu>
        <button pButton type="button" class="p-mr-2" icon="pi pi-plus" (click)="pageMenu.toggle($event)"></button>
        <div>
          <p-dropdown class="p-mr-2"
                      [style] = "{'min-width': '200px'}"
                      [options] ="sortByOptions"
                      [(ngModel)]="selectedSortOption"
                      (onChange)="sortPage()">
          </p-dropdown>
        </div>
        <div>
          <p-toggleButton
                          pTooltip="Down: Descending
                                    Up: Ascending"
                          onIcon="pi pi-arrow-circle-up"
                          offIcon="pi pi-arrow-circle-down"
                          [style] = "{'min-height': '36px'}"
                          (onChange)="arrowUp = !arrowUp; showPages()">
          </p-toggleButton>
        </div>
        <div>
            <label class="show-label">Show: </label>
          <p-dropdown class="p-mr-2"
                      [style] = "{'min-width': '200px'}"
                      [options] ="showOptions"
                      [(ngModel)]="selectedShowOption"
                      (onChange)="showPages()">
          </p-dropdown>
        </div>
        <div class="p-input-icon-left input-bar p-mr-2">
          <i class="pi pi-search"></i><input type="text" pInputText [(ngModel)]="pageSearchFilter" placeholder="Search pages..."/>
        </div>
        <p-dialog header="Project Page" [(visible)]="displayProjectPageModal" modal="true"
                  [draggable]="false" [dismissableMask]="true" [resizable]="false" (onHide)="closeModal()">
          <div class="input-wrapper">
            <label for="mpop-accounts" class="field-input-label input-label">Account</label>
            <p-dropdown
              pTooltip="Pages can only be projected from one account at a time."
              [tooltipDisabled]="!accountDropdownDisabled"
              tooltipPosition="top"
              id="mpop-accounts"
              appendTo="body"
              aria-label="Account"
              [options]="compactOEMAccounts"
              [(ngModel)]="selectedAccount"
              [disabled] = "accountDropdownDisabled"
              optionLabel="name"
              placeholder="Account"
              (onHide)="getPagesForAccount()"
              [style]="{'width':'33rem', 'padding':'0.5rem'}">
            </p-dropdown>
          </div>
          <div class="input-wrapper">
            <label for="pages" class="field-input-label input-label">Pages</label>
            <p-multiSelect *ngIf="accountPages[0].items.length > 0; else noPages"
              id="pages"
              appendTo="body"
              aria-label="Page"
              [options]="accountPages"
              [(ngModel)]="selectedAccountPageIds"
              [group]="true"
              resetFilterOnHide="true"
              optionLabel="label"
              optionValue="value"
              placeholder="Page"
              styleClass="pages-dropdowns"
              (onChange)="toggleAccountDropdownDisabled()"
              [style]="{'width':'33rem', 'padding':'0.5rem'}">
              <ng-template let-group pTemplate="group">
                <div class="p-d-flex p-ai-center">
                  <span>{{group.label}}</span>
                </div>
              </ng-template>
            </p-multiSelect>
            <ng-template #noPages>
              <p-multiSelect
                id="pages"
                appendTo="body"
                [options]="accountPages"
                [group]="true"
                placeholder="Page"
                [style]="{'width':'33rem', 'padding':'0.5rem'}">
              </p-multiSelect>
            </ng-template>
          </div>
          <div class="modal-button-wrapper">
            <button type="button" pButton (click)="projectPage()" icon="pi pi-check" class="p-button-success modal-button" label="Confirm"></button>
          </div>
        </p-dialog>
        <!-- Import Page Modal -->
        <p-dialog header="Import Page" (onHide)="closeModal()" [(visible)]="displayImportPageModal" modal="true"
                  [draggable]="false" [dismissableMask]="true" [resizable]="false">
          <div class="input-wrapper">
            <label class="field-input-label input-label">Copy from Account</label>
            <p-autoComplete
              [(ngModel)]="selectedAccount"
              [showEmptyMessage]="true"
              [suggestions]="filteredCompactAccounts"
              (completeMethod)="filterAccounts($event)"
              (onSelect)="getImportedPages()"
              (onClear)="getImportedPages()"
              field="name"
              placeholder="Search accounts..."
              [minLength]="1"
              appendTo="body"
              [dropdown]="true"
              [style]="{'width':'33rem', 'padding':'0.5rem'}">
              <ng-template let-account pTemplate="item">
                <div class="account-item">
                  <div>{{account.name}}</div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="input-wrapper" *ngIf="importablePages.length > 0 && selectedAccount !== null">
            <label class="field-input-label input-label">Copy Page</label>
            <div class="p-input-icon-left input-bar p-mr-2">
              <i class="pi pi-search"></i>
              <input type="text"
                     pInputText [(ngModel)]="importSearchFilter"
                     (input)="dt.filterGlobal($event.target.value, 'contains')"
                     placeholder="Search pages..."/>
            </div>
            <br>
            <p-table
              class="table"
              #dt [value] = "importablePages"
              [(selection)]="selectedImportPages"
              [lazy]="false"
              [globalFilterFields]="['title', 'type']"
              selectionMode="multiple"
              [scrollable] ="true"
               scrollHeight="500px">
              <ng-template pTemplate="body" let-pages>
                <tr [pSelectableRow]="pages">
                  <td>{{pages.title}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="modal-button-wrapper">
            <button type="button" icon="pi pi-check" class="p-button-success modal-button" pButton (click)="importPage()" label="Import"></button>
          </div>
        </p-dialog>
      </p-header>
      <!-- Edit URLs Modal -->
      <p-dialog header="Edit URLs For {{pageName}}" [(visible)]="displayEditLinksModal" modal="true" [draggable]="false" [dismissableMask]="true" [resizable]="false">
        <div class="input-wrapper">
          <label for="live-url" class="field-input-label input-label">Live URL</label>
          <input type="text" pInputText [(ngModel)]="pageUrl" id="live-url" class="url-input"/>
        </div>
        <div class="input-wrapper">
          <label for="inventory-url" class="field-input-label input-label">Inventory URL</label>
          <input type="text" pInputText [(ngModel)]="inventoryUrl" id="inventory-url" class="url-input"/>
        </div>

        <div class="modal-button-wrapper">
          <button type="button" pButton (click)="editLinks()" icon="pi pi-save" class="p-button-success modal-button" label="Save"></button>
        </div>
      </p-dialog>

      <!-- Delete Page Modal -->

      <div class="modal-button-wrapper">
        <p-confirmDialog header="Delete Page" icon="ui-icon-blank" acceptLabel="Confirm" rejectLabel="Cancel" key="deleteProjectedPageModal"
                         acceptButtonStyleClass="p-button-success modal-button" rejectButtonStyleClass="reject-button"></p-confirmDialog>
      </div>

      <!-- Custom Page Modal -->
      <div class="modal-button-wrapper">
        <p-confirmDialog header="Create Custom Page" icon="ui-icon-blank" acceptLabel="Confirm" rejectLabel="Cancel" key="buildCustomPage"
          acceptButtonStyleClass="p-button-success modal-button" rejectButtonStyleClass="reject-button"></p-confirmDialog>
      </div>

      <!-- Promote Page Modal -->
      <p-dialog header="Promote {{pageName}}" [(visible)]="displayPromotePageModal" modal="true" [draggable]="false" [dismissableMask]="true"
                [resizable]="false" (onHide)="resetProjectedPageAccount()">
        <div class="input-wrapper">
          <label for="mpop-accounts" class="field-input-label input-label">Account</label>
          <p-dropdown
            pTooltip="Pages can only be projected from one account at a time."
            [tooltipDisabled]="!accountDropdownDisabled"
            tooltipPosition="top"
            id="mpop-accounts"
            appendTo="body"
            aria-label="Account"
            [options]="compactOEMAccounts"
            [(ngModel)]="selectedPromotePageAccount"
            [disabled] = "accountDropdownDisabled"
            optionLabel="name"
            placeholder="Account"
            filter="true"
            (onChange)="getPromotablePagesForAccount()"
            [style]="{'width':'33rem', 'padding':'0.5rem'}">
          </p-dropdown>
        </div>
        <div class="input-wrapper">
          <label for="pages" class="field-input-label input-label">Page</label>
          <p-dropdown
            id="pages"
            appendTo="body"
            aria-label="Page"
            [options]="promotablePages"
            [(ngModel)]="pageToReplace"
            optionLabel="label"
            placeholder="Page"
            filter="true"
            (onChange)="toggleAccountDropdownDisabled()"
            [style]="{'width':'33rem', 'padding':'0.5rem'}">
            <ng-template let-group pTemplate="group">
              <div class="p-d-flex p-ai-center">
                <span>{{group.label}}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="modal-button-wrapper">
          <p-confirmDialog header="Promote Page" icon="ui-icon-blank" acceptLabel="Confirm" rejectLabel="Cancel" key="promotePageModal"
                           acceptButtonStyleClass="p-button-success" rejectButtonStyleClass="reject-button"></p-confirmDialog>
          <button type="button" pButton (click)="openConfirmPromotePageModal()" icon="pi pi-check" class="p-button-success modal-button" label="Confirm"></button>
        </div>
      </p-dialog>

      <!-- delete native page modal -->
      <p-confirmDialog header="Delete Page" icon="ui-icon-blank" acceptLabel="Confirm" rejectLabel="Cancel" key="confirmDeleteNativePageModal"
                       acceptButtonStyleClass="p-button-success" rejectButtonStyleClass="reject-button"></p-confirmDialog>

      <!-- copy to account modal -->
      <p-confirmDialog key="copyToAccountModal" header="Copy to Account" icon="ui-icon-blank" acceptLabel="Confirm" rejectLabel="Cancel"
                       acceptButtonStyleClass="p-button-success" rejectButtonStyleClass="reject-button"></p-confirmDialog>

      <!-- page cards section -->
      <p-tabView (onChange)="resetView()">
        <p-tabPanel header="Pages">
          <div class="page-card-section p-grid">
          <p-card class="p-col-4"
                  *ngFor="let page of displayedNativePages | async | filter:pageSearchFilter">
            <ng-template pTemplate="header" class="card-header">
              <div class="card-header-wrapper">
                <a *ngIf="page.pageUrl !== ''" [href]="page.pageUrl"  target="_blank" class="p-mr-2 card-link"><i class="pi pi-external-link"></i> Live</a>
                <a *ngIf="page.pageUrl === ''"  pTooltip="Live URL is Empty" target="_blank" class="p-mr-2 card-link"><i class="pi pi-external-link"></i> Live</a>
                <a href="{{documentProtocol}}//{{documentHostname}}/pages/preview/landing/{{account.accountFolder}}/{{page.id}}/custom.htm"
                   target="_blank" class="p-mr-2 card-link"><i
                  class="pi pi-external-link"></i> Preview</a>
                <a *ngIf="!isProduction && page.pageUrl !== '' && page.tokenViewUrl !== ''" [href]="page.tokenViewUrl" target="_blank" class="p-mr-2 card-link"><i class="pi pi-external-link"></i> Token View</a>
                <p-menu #menu [model]="subMenuModels[page.id]" [popup]="true"></p-menu>
                <i class="pi pi-ellipsis-h p-mr-2" (click)="menu.toggle($event)"></i>
                <i class="pi pi-sitemap projected-page-icon" pTooltip="Page is projected" *ngIf="page.isConnectedSource"></i>
                <i class="pi pi-eye-slash inactive-page-icon" pTooltip="Page is inactive" *ngIf="!page.active"></i>
              </div>
            </ng-template>
            <a href="{{documentProtocol}}//{{documentHostname}}/pages/{{accountId}}#/edit/{{page.id}}"
               class="page-title-link"><i class="pi pi-file"></i>
              {{page.titleName}}
            </a>
            <ng-template pTemplate="footer">
              <div  class="card-footer">
                <p>Type: {{page.pageType}}</p>
                <p>Last Modified: {{page.modified.date | date:'shortDate'}}</p>
              </div>
            </ng-template>
          </p-card>
          </div>
        </p-tabPanel>
        <!-- TODO:  Make this more dynamic for the tabs && check user has admin or above - might be tricky to keep user service decoupled from component -->
        <p-tabPanel header="Projected Pages">
          <div class="page-card-section p-grid">
            <p-card class="p-col-4" *ngFor="let page of displayedProjectedPages | async | filter:pageSearchFilter">
              <ng-template pTemplate="header" class="card-header">
                <div class="card-header-wrapper">
                  <a *ngIf="page.pageUrl !== ''" [href]="page.pageUrl"  target="_blank" class="p-mr-2 card-link"><i class="pi pi-external-link"></i> Live</a>
                  <a *ngIf="page.pageUrl === ''"  pTooltip="Live URL is Empty" target="_blank" class="p-mr-2 card-link"><i class="pi pi-external-link"></i> Live</a>
                  <a href="{{documentProtocol}}//{{documentHostname}}/pages/preview/landing/{{account.accountFolder}}/{{page.id}}/custom.htm"
                     target="_blank" class="p-mr-2 card-link"><i
                    class="pi pi-external-link"></i> Preview</a>
                  <a *ngIf="!isProduction && page.pageUrl !== ''" [href]="page.tokenViewUrl" target="_blank" class="p-mr-2 card-link"><i class="pi pi-external-link"></i> Token View</a>
                  <p-menu #menu [model]="subMenuModels[page.id]" [popup]="true"></p-menu>
                  <i class="pi pi-ellipsis-h p-mr-2" (click)="menu.toggle($event)"></i>
                    <i class="pi pi-eye-slash inactive-page-icon" pTooltip="Page is inactive" *ngIf="!page.active"></i>
                </div>
              </ng-template>
              <a class="page-title-link"><i class="pi pi-file"></i> {{page.titleName}} </a>
              <ng-template pTemplate="footer">
                <div class="card-footer">
                  <p>Type: {{page.pageType}}</p>
                  <p>Last Modified: {{page.modified.date | date: 'shortDate'}}</p>
                </div>
              </ng-template>
            </p-card>
          </div>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="pi pi-cog"></i>
          </ng-template>
          <auto-page-configuration>
          </auto-page-configuration>
        </p-tabPanel>
      </p-tabView>
      <p-dialog header="Select Source Page For {{pageName}}" [(visible)]="displayProjectNativePageModal" modal="true"
                [draggable]="false" [dismissableMask]="true" [resizable]="false">
        <div class="input-wrapper">
          <label for="mpop-accounts" class="field-input-label input-label">Account</label>
          <p-dropdown
            tooltipPosition="top"
            appendTo="body"
            aria-label="Account"
            [options]="compactOEMAccounts"
            [(ngModel)]="nativeProjectionSelectedAccountId"
            optionLabel="name"
            optionValue="id"
            placeholder="Account"
            (onHide)="getPagesForAccountForNativeProjection()"
            [style]="{'width':'33rem', 'padding':'0.5rem'}">
          </p-dropdown>
        </div>
        <div class="input-wrapper">
          <label for="pages" class="field-input-label input-label">Page</label>
          <p-dropdown
            appendTo="body"
            aria-label="Page"
            filter="true"
            [options]="nativeProjectionAccountPages"
            [(ngModel)]="nativeProjectionSelectedParentPageId"
            optionLabel="titleName"
            optionValue="id"
            placeholder="Page"
            styleClass="pages-dropdowns"
            [style]="{'width':'33rem', 'padding':'0.5rem'}">
          </p-dropdown>
        </div>
        <div class="modal-button-wrapper">
          <p-confirmDialog header="Project Page" icon="ui-icon-blank" acceptLabel="Confirm" rejectLabel="Cancel" key="confirmProjectNativePageModal"
                           acceptButtonStyleClass="p-button-success modal-button" rejectButtonStyleClass="reject-button"></p-confirmDialog>
          <button type="button" pButton (click)="openConfirmProjectNativePageModal()" icon="pi pi-check" class="p-button-success modal-button" label="Confirm"></button>
        </div>
      </p-dialog>
    </p-panel>
  </div>
</p-panel>
<auto-footer></auto-footer>
