import { Injectable } from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {AccountFeatureModel} from '../model/account-feature.model';

@Injectable({
  providedIn: 'root'
})
export class AccountFeatureService extends AbstractHttpService{
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  updateAccountFeature(accountFeatureData: AccountFeatureModel): Observable<any> {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-feature`,
      accountFeatureData,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
