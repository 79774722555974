<p-panel header="Automation Account Settings" [toggleable]="true" [collapseIcon]="'pi pi-chevron-right'" [expandIcon]="'pi pi-chevron-down'">
  <ng-template pTemplate="icons">
    <label class="p-panel-header-icon p-link mr-2">
      <span class="pi pi-info-circle"
            pTooltip="Settings used in the Campaign Automation processes above. Changing these settings will NOT modify existing similar settings for the MPOP Account."
            tooltipPosition="top"></span>
    </label>
  </ng-template>
  <div class="tab-panel-input-wrapper"></div>
  <form [formGroup]="campaignAutomationSettingsFormGroup" autoTrackUnsavedChanges>
    <div class="p-grid">
      <div class="tab-panel-input-wrapper p-col-4">
        <label class="field-input-label tab-setting-label">OEM Program</label>
        <p-dropdown [options]="oemPrograms" formControlName="oemProgram" size="35" [style]="{'width':'100%'}"></p-dropdown>
      </div>
      <div class="tab-panel-input-wrapper p-col-4">
        <label class="field-input-label tab-setting-label">Make Text</label>
        <input type="text" pInputText formControlName="makeString" size="35" pTooltip="Text used for make shortcode replacement in campaign assets that are not generated per account make." tooltipPosition="top"/>
      </div>
      <div class="tab-panel-input-wrapper p-col-4">
        <label class="field-input-label tab-setting-label">City</label>
        <input type="text" pInputText formControlName="city" size="35"/>
      </div>
      <div class="tab-panel-input-wrapper p-col-4">
        <label class="field-input-label tab-setting-label">Dealer ID</label>
        <input type="text" pInputText formControlName="dealerId" size="35" pTooltip="Dealer Code or Ford Direct Dealer ID for Ford Direct accounts" tooltipPosition="top"/>
      </div>
      <div class="tab-panel-input-wrapper p-col-4">
        <label class="field-input-label tab-setting-label">Latitude</label>
        <input type="text" pInputText formControlName="latitude" size="35"/>
      </div>
      <div class="tab-panel-input-wrapper p-col-4">
        <label class="field-input-label tab-setting-label">State Abbreviation</label>
        <input type="text" pInputText formControlName="stateAbbr" size="35"/>
      </div>
      <div class="tab-panel-input-wrapper p-col-4">
        <label class="field-input-label tab-setting-label">Website URL</label>
        <input type="text" pInputText formControlName="websiteUrl" size="35"/>
      </div>
      <div class="tab-panel-input-wrapper p-col-4">
        <label class="field-input-label tab-setting-label">Longitude</label>
        <input type="text" pInputText formControlName="longitude" size="35"/>
      </div>
      <div class="tab-panel-input-wrapper p-col-4">
        <label class="field-input-label tab-setting-label">Zip Code</label>
        <input type="text" pInputText formControlName="zipCode" size="35"/>
      </div>
      <div class="tab-panel-input-wrapper p-col-4">
        <label class="field-input-label tab-setting-label">Use Pricing Assets</label>
        <p-inputSwitch
          pTooltip="Enables pricing assets in Performance Max Campaign automation."
          tooltipPosition="bottom"
          formControlName="usePremiumAssets"
          id="premium-assets-active">
        </p-inputSwitch>
      </div>
    </div>
  </form>
  <div class="tab-panel-input-wrapper">
    <button pButton
            label="Sync with MPOP"
            class="p-button-info"
            style="margin-right:12px;"
            pTooltip="Populates values with the account's MPOP settings. These settings are not saved until you click the 'Save' button."
            tooltipPosition="top"
            (click)="getSettingsForAccountFromMpop(false)">
    </button>
    <button pButton
            label="Save"
            class="p-button-success"
            [disabled]="campaignAutomationSettingsFormGroup.pristine"
            (click)="saveAccountSettings()">
    </button>
  </div>
</p-panel>
