import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  private eventSubject = new Subject<any>();

  event$ = this.eventSubject.asObservable();
  constructor() { }

  /**
   * Method to emit an event between two components
   */
  emitEvent(data: any) {
    this.eventSubject.next(data);
  }
}
