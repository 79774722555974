<auto-main-navigation [accountId]="accountId"></auto-main-navigation>
<p-panel>
  <p-header class="page-header-wrapper">
    <h1 class="page-header-text">{{title}}</h1>
  </p-header>
  <div class="main-content-wrapper" style="text-align: center">
    <p-header class="page-header-wrapper">
      <h3 class="page-header-text">{{message}}</h3>
      <h3 class="page-header-text">Go back to the <a routerLink="{{backToPageRoute}}">{{backToPageName}} page</a>.</h3>
    </p-header>
  </div>
</p-panel>
<auto-footer></auto-footer>
