import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormItemsService {

  public getDynamicFormItems(formConfig: {}, initialValues: {}, businessCategoryName: string): {} {
    const result = {};
    Object.keys(formConfig).forEach((sectionKey) => {
      result[sectionKey] = [];
      const sectionConfig = formConfig[sectionKey];
      Object.keys(sectionConfig).forEach((itemKey) => {
        const currentItemConfig = sectionConfig[itemKey];
        if (itemKey in initialValues[sectionKey]) {
          currentItemConfig.value = initialValues[sectionKey][itemKey];
        }
        if (currentItemConfig.businessCategory === '' || currentItemConfig.businessCategory === businessCategoryName) {
          result[sectionKey].push(currentItemConfig);
        }
      });
    });

    return result;
  }
}
