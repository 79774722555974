import {AbstractControl, ValidatorFn} from '@angular/forms';

/**
 * Validator function for inputs using PascalCase
 *
 * @constructor
 */
export function PascalValidator(control: AbstractControl): ValidatorFn {
    let result = null;
    if (control.value) {
      const pascalRegEx = new RegExp(/^[A-Z0-9][a-zA-Z0-9]*$/);
      const lowercaseAfterNumberRegEx = new RegExp(/[0-9][a-z]/);
      if (!pascalRegEx.test(control.value) || control.value.search(lowercaseAfterNumberRegEx) !== -1) {
        result = { invalidPascal: true };
      }
    }

    return result;
}
