<p-accordionTab header="Ad Rules" *ngIf="featureEnabled">
  <tr class="p-grid form-row" title="Vehicle Ad Rules">
    <td class="p-col-1">
      <button pButton type="button" class="p-button-success" label="Add" (click)="addRule()"></button>
    </td>
    <td class="p-col-8">
<!--      <button pButton type="button" class="p-button-info" label="Set Active/Inactive" (click)="toggleRulesActive()"></button>-->
    </td>
    <td class="p-col-2">
      <button *ngIf="anySelected()" pButton type="button" class="p-button-danger" label="Delete Selected" (click)="openDeleteSelectedOfferRulesModal()">
      </button>
    </td>
  </tr>
  <div [formGroup]="offerRulesForm" *ngIf="isRulesLoaded">
    <div formArrayName="rules">
      <tr class="p-grid p-justify-even form row">
        <td class="p-col-1 center-checkbox position-relative">
          <p-checkbox binary="true" (onChange)="selectAll()"></p-checkbox>
          <div class="label-below"><small class="checkbox-label">{{ allSelected ? 'Deselect All' : 'Select All' }}</small></div>
        </td>
        <th class="p-col-2">Ad Data</th>
        <th class="p-col-3">Operator</th>
        <th class="p-col-2">Value</th>
        <th class="p-col-3">Action</th>
        <th class="p-col-1"></th>
      </tr>
      <div class="p-grid p-justify-center p-align-center p-justify-even form-row" *ngFor="let rule of rules.controls; let idx = index" [formGroupName]="idx">
        <td class="p-col-1 center-checkbox">
          <p-checkbox binary="true" formControlName="selected"></p-checkbox>
        </td>
        <td class="p-col-2 position-relative">
          <p-dropdown [options]="adDataOptions" formControlName="adDataOption" placeholder="Select an Option" (onChange)="updateDataType(rule)"></p-dropdown>
          <div *ngIf="rule.get('adDataOption').invalid && rule.get('adDataOption').touched">
            <small class="invalid-text">Selected is required.</small>
          </div>
        </td>
        <td class="p-col-3 position-relative">
          <p-dropdown [options]="rule.value.operators" formControlName="selectedOperator" placeholder="Select an Option"></p-dropdown>
          <div *ngIf="rule.get('selectedOperator').invalid && rule.get('selectedOperator').touched">
            <small class="invalid-text">Operator is required.</small>
          </div>
        </td>
        <td class="p-col-2 position-relative">
          <div class="value-container">
            <span *ngIf="rule.get('dataType')?.value === 'dollar'" class="value-prefix">$</span>
            <input pInputText
                   id="value"
                   size="5"
                   formControlName="value"/>
            <span *ngIf="rule.get('dataType')?.value === 'percent'" class="value-suffix">%</span>
          </div>
          <div *ngIf="rule.get('value').invalid && rule.get('value').touched">
            <small class="invalid-text">Invalid Value.</small>
          </div>
        </td>
        <td class="p-col-3 position-relative">
          <p-dropdown [options]="actionOptions" formControlName="action" placeholder="Select an Option"></p-dropdown>
          <div *ngIf="rule.get('action').invalid && rule.get('action').touched">
            <small class="invalid-text">Action is required.</small>
          </div>
        </td>
        <td class="p-col-1 position-relative">
          <button pButton type="button" class="p-button-danger p-button-icon" (click)="openDeleteOfferRuleModal(idx)">
            <i class="pi pi-trash"></i>
          </button>
        </td>
      </div>
      <tr class="p-grid p-justify-even" *ngIf="rules.controls.length === 0">
        <div class="label-below no-rules">No Ad Rules created yet.</div>
      </tr>
    </div>
  </div>
  <div class="modal-button-wrapper">
    <p-confirmDialog header="Delete Rule" icon="ui-icon-blank" acceptLabel="Confirm" rejectLabel="Cancel" key="deleteOfferRule"
                     acceptButtonStyleClass="p-button-success modal-button" rejectButtonStyleClass="reject-button"></p-confirmDialog>
  </div>
  <div class="modal-button-wrapper">
    <p-confirmDialog header="Delete Rules" icon="ui-icon-blank" acceptLabel="Confirm" rejectLabel="Cancel" key="deleteSelectedOfferRules"
                     acceptButtonStyleClass="p-button-success modal-button" rejectButtonStyleClass="reject-button"></p-confirmDialog>
  </div>
</p-accordionTab>
