import {Component, OnInit} from '@angular/core';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {SessionService} from './service/session.service';
import {UserService} from './service/user.service';
import {CookieService} from './service/cookie.service';

@Component({
  // Keep as app-root, all others should be prefixed by "auto-"
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  idleState = 'Not started.';
  displayTimeoutModal = false;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private sessionService: SessionService,
    private userService: UserService,
    private cookieService: CookieService
  ) {
    if (location.pathname !== '/web/') {
      const sessionCookie = this.cookieService.getCookie('PHPSESSID');
      if (sessionCookie) {
        // sets an idle timeout of 25 minutes (25 minutes * 60 seconds/minute)
        idle.setIdle(25 * 60);
        // sets a timeout period of 5 minutes (5 minutes * 60 seconds/minute)
        idle.setTimeout(5 * 60);
        // sets the default interrupts - clicks, scrolls, or touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
        idle.onTimeoutWarning.subscribe((countdown) => {
          this.displayTimeoutModal = true;
          this.idleState = 'You will time out in ' + this.getFormattedCountdown(countdown);
        });
        idle.onIdleEnd.subscribe();
        idle.onTimeout.subscribe(() => {
          this.idleState = 'Timed out!';
          this.displayTimeoutModal = false;
          this.sessionService.clearSession();
        });

      // Keep alive is pinged every 10 minutes (10 minutes * 60 seconds/minute)
      keepalive.interval(10 * 60);

        keepalive.onPing.subscribe(() =>
          this.userService.refreshSession({sessionKey: sessionCookie})
        );

        this.reset();
      }
    }
  }

  /**
   * Formats the countdown clock on the timeout modal
   * @param countdown
   */
  getFormattedCountdown(countdown: number): string {
    const minutes: number = Math.floor(countdown / 60);
    return minutes + 'm and ' + ('00' + Math.floor(countdown - minutes * 60)).slice(-2) + 's';
  }

  /**
   * Resets the idle watch
   */
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
  }

  /**
   * Closes the timeout modal
   */
  closeTimeoutModal() {
    this.displayTimeoutModal = false;
  }

  ngOnInit(): void {
  }
}
