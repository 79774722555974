import {MappingRule} from './mapping-rule';

export class AccountMappingRules {
  accountId: number;
  mappingRules: MappingRule[];

  constructor() {
    this.accountId = null;
    this.mappingRules = [];
  }
}
