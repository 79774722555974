import {SourceShortcodeModel} from './source-shortcode.model';

export class AccountShortcodeModel  {
  id: number;
  accountId: number;
  value: string;
  sourceShortcode: SourceShortcodeModel;

  constructor() {
    this.id = null;
    this.accountId = null;
    this.value = null;
    this.sourceShortcode = null;
  }
}
