import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {NavigationService} from '../../../../service/navigation.service';
import {MessageService} from 'primeng/api';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ManageAdFiltersService} from '../../../../service/manage-ad-filters.service';
import {Observable} from 'rxjs';
import {AdFilter} from '../../../../model/ad-filter/ad-filter';
import {StringFormatterService} from '../../../../service/utilities/string-formatter.service';
import {v4 as uuidv4} from 'uuid';
import {MultiSelectMinimumOneValidator} from '../../../../validators/multi-select-minimum-one-validator';


@Component({
  selector: 'auto-manage-filters',
  templateUrl: './manage-filters.component.html',
  styleUrls: ['./manage-filters.component.scss']
})
export class ManageFiltersComponent implements OnInit {
  accountId: number;
  accountServiceCategories: Observable<any> | null;
  accountSources: Observable<any> | null;
  accountAdFilters: AdFilter[];
  activeFilter: AdFilter;
  availableFallbackFilters = [];
  accountFilterNames: string[] = [];
  isConflictingName = false;
  complexFormFields = [
    'parameters',
    'sortOption',
    'sources'
  ];
  // TODO: this should be derived from available ad fields
  sortByFieldOptions = [
    {label: 'Name', value: 'title'},
    {label: 'Subcategory', value: 'subcategoryId'},
    {label: 'Pricing Type', value: 'pricingType'},
    {label: 'Price', value: 'salePrice'},
    {label: 'Percent Off', value: 'percentOff'},
    {label: 'Expiration Date', value: 'expirationDate'},
    {label: 'Quality Score', value: 'qualityScore'},
    {label: 'Google Ads Active', value: 'googleAdsActive'},
  ];
  sortOrderOptions = [
    {label: 'Ascending', value: 'ascending'},
    {label: 'Descending', value: 'descending'},
  ];

  filterForm = this.fb.group({
    name: ['', Validators.required],
    type: [''],
    description: [''],
    fallbackFilterGuid: [''],
    category: [''],
    subcategories: [[], MultiSelectMinimumOneValidator()],
    sources: [[], MultiSelectMinimumOneValidator()],
    sortBy: [''],
    sortOrder: ['']
  });

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private manageAdFiltersService: ManageAdFiltersService,
    private stringFormatterService: StringFormatterService
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Manage Ad Filters');
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      if (this.accountId) {
        this.getAccountServiceCategories(this.accountId);
        this.getAccountSources(this.accountId);
        this.getAccountAdFilters(this.accountId);
      }
    });
  }

  /**
   * Get account's available service categories (set in MPOP)
   *
   * @param accountId
   */
  getAccountServiceCategories(accountId: number) {
    this.accountServiceCategories = this.manageAdFiltersService.getAccountServiceCategories(accountId);
  }

  /**
   * Get account's possible sources
   *
   * @param accountId
   */
  getAccountSources(accountId: number) {
    this.accountSources = this.manageAdFiltersService.getAccountSources(accountId);
  }

  /**
   * Get all existing ad filters for an account
   *
   * @param accountId
   */
  getAccountAdFilters(accountId: number) {
    this.manageAdFiltersService.getAccountAdFilters(accountId)
      .subscribe((adFiltersResult: AdFilter[]) => {
        this.accountAdFilters = adFiltersResult;
        if (this.accountAdFilters.length > 0) {
          this.setActiveFilterFormValues(this.accountAdFilters[0]);
          this.getFilterNames();
        }
      });
  }

  /**
   * Handle setting form values
   * First called on INIT
   * Thereafter, called when the user selects a different from from the dropdown
   *
   * @param adFilter
   */
  setActiveFilterFormValues(adFilter: AdFilter) {
    this.activeFilter = adFilter;
    Object.keys(this.filterForm.controls).forEach((formControlName) => {
      if (formControlName in this.activeFilter && !this.complexFormFields.includes(formControlName)) {
        this.filterForm.controls[formControlName].setValue(this.activeFilter[formControlName]);
      }
    });
    this.filterForm.controls.subcategories.setValue(this.buildMultiSelectOptions(this.activeFilter.parameters));
    this.filterForm.controls.sources.setValue(this.buildMultiSelectOptions(this.activeFilter.sources));
    this.filterForm.controls.sortBy.setValue({
      label: this.activeFilter.sortOption.label,
      value: this.activeFilter.sortOption.sortBy
    });
    this.filterForm.controls.sortOrder.setValue({
      label: this.stringFormatterService.camelCaseToSentenceCase(this.activeFilter.sortOption.sortOrder),
      value: this.activeFilter.sortOption.sortOrder
    });

    this.prepareAvailableFallbackFilters();
    this.filterForm.markAsPristine();
  }

  /**
   * Build array of options for multi-select inputs
   *
   * @param filterParameters
   */
  buildMultiSelectOptions(filterParameters) {
    const selectItems = [];
    Object.keys(filterParameters).forEach((guidKey) => {
      const option = filterParameters[guidKey];
      selectItems.push({
        value: option.value,
        label: option.label,
        guid: option.guid
      });
    });

    return selectItems;
  }

  /**
   * Prepare options for fallback filter input
   */
  prepareAvailableFallbackFilters() {
    this.availableFallbackFilters = [
      {label: 'None', value: null}
    ];

    this.accountAdFilters.forEach((filter) => {
      if (filter.guid !== this.activeFilter.guid) {
        this.availableFallbackFilters.push({
          label: filter.name,
          value: filter.guid
        });
      }
    });
  }

  /**
   * Create an empty filter & apply it to the form
   * Mark as dirty so that required fields are shown
   */
  handleNewFilter() {
    this.activeFilter = this.buildNewFilter();
    this.setActiveFilterFormValues(this.activeFilter);
    this.accountAdFilters.push(this.activeFilter);
    this.accountAdFilters = this.accountAdFilters.slice();
    this.getFilterNames();
  }

  /**
   * Build a blank filter
   */
  buildNewFilter() {
    const newFilter = new AdFilter();
    newFilter.guid = uuidv4();
    // TODO: this business category guid should be set by account data
    newFilter.accountBusinessCategoryGuid = null;
    newFilter.type = 'promotional';
    newFilter.category = 'service';
    newFilter.name = 'New Filter';

    return newFilter;
  }

  /**
   * Handle deleting the current activeFilter
   * TODO: add speed bump/replace filter logic
   */
  handleDeleteFilter() {
    const nextFilter = this.getNextFilter();
    this.accountAdFilters.splice(this.accountAdFilters.indexOf(this.activeFilter), 1);
    this.activeFilter = nextFilter;
    this.setActiveFilterFormValues(this.activeFilter);

    // .slice is required here because we need a new instance of the accountAdFilters array (there may be more efficient ways to do this)
    this.accountAdFilters = this.accountAdFilters.slice();
    this.getFilterNames();
  }

  /**
   * Find which filter should be showed next after delete
   */
  getNextFilter() {
    let nextFilter;
    const filterToRemove = this.activeFilter;
    const filterIndex = this.accountAdFilters.indexOf(filterToRemove);
    // if only filter, next filter is blank
    if (this.accountAdFilters.length === 1) {
      nextFilter = this.buildNewFilter();
    } else if (filterIndex === this.accountAdFilters.length - 1) {
      // if last, next filter is first in list
      nextFilter = this.accountAdFilters[0];
    } else {
      // if not last, next filter is...next filter
      nextFilter = this.accountAdFilters[filterIndex + 1];
    }

    return nextFilter;
  }

  /**
   * Prepare form & save
   * - translate form values back onto active filter
   * - save to DB
   * - mark as pristine (so user can navigate away/view other form)
   */
  handleSaveFilter() {
    this.prepareFilterDataForSave(this.filterForm.value);
    // TODO: remove these logs
    console.log('SAVING (well not really, but...)');
    console.log('FORM VALUES: ', this.filterForm.value);
    console.log('ACTIVE FILTER VALUES: ', this.activeFilter);
    // TODO: Save/update ad filter here -- all logic after depends on success of save
    this.filterForm.markAsPristine();
    this.getFilterNames();
    this.messageService.add({
      severity: 'success',
      summary: 'Filter saved.',
      life: 5000
    });
  }

  /**
   * Translate form values from form back onto active filter
   * - copy 1-to-1 matches back to activeFilter
   * - apply sort options
   * - determine which multi-select values are new/have been removed & update activeFilter
   *
   * @param filterFormValues
   */
  prepareFilterDataForSave(filterFormValues) {
    // set one-to-one matches values
    Object.keys(this.filterForm.controls).forEach((formControlName) => {
      if (formControlName in this.activeFilter && !this.complexFormFields.includes(formControlName)
      ) {
        this.activeFilter[formControlName] = filterFormValues[formControlName];
      }
    });
    // set sort option
    this.activeFilter.sortOption.label = filterFormValues.sortBy.label,
    this.activeFilter.sortOption.sortBy = filterFormValues.sortBy.value;
    this.activeFilter.sortOption.sortOrder = filterFormValues.sortOrder.value;
    // set parameters/sources
    const newParametersByGuid = this.handleCoalesceFormValuesArray(filterFormValues.subcategories, 'parameters');
    this.addNewParametersToActiveFilter(newParametersByGuid, 'parameters');
    const newSourcesByGuid = this.handleCoalesceFormValuesArray(filterFormValues.sources, 'sources');
    this.addNewParametersToActiveFilter(newSourcesByGuid, 'sources');
  }

  /**
   * Determine which multi-select values have been added & removed in form
   *
   * @param formValuesArray
   * @param propertyName
   */
  handleCoalesceFormValuesArray(formValuesArray, propertyName) {
    const formValuesByGuid = this.sortArrayOfObjectsByGuid(formValuesArray);
    Object.keys(this.activeFilter[propertyName]).forEach((activeFilterGuid) => {
      if (!(activeFilterGuid in formValuesByGuid)) {
        // if guid is not in formValuesArrayByGuid we know it has been removed & should be removed from activeFilter before saving
        delete this.activeFilter[propertyName][activeFilterGuid];
      } else {
        // if guid IS in formValuesArrayByGuid we know it is not new & thus does not need to be added to the activeFilter before saving
        delete formValuesByGuid[activeFilterGuid];
      }
    });

    return formValuesByGuid;
  }

  /**
   * For new multi-select values, add to activeFilter before save
   *
   * @param formValuesArrayByGuid
   * @param propertyName
   */
  addNewParametersToActiveFilter(formValuesArrayByGuid, propertyName) {
    const guidKeys = Object.keys(formValuesArrayByGuid);
    if (guidKeys.length > 0) {
      guidKeys.forEach((guid) => {
        const newItem = formValuesArrayByGuid[guid];
        newItem.adFilterGuid = this.activeFilter.guid;
        if (propertyName === 'parameters') {
          newItem.filterAction = 'match';
          newItem.parameterType = 'subcategory';
          newItem.targetColumn = 'serviceCategoryId';
        }

        this.activeFilter[propertyName][guid] = newItem;
      });
    }
  }

  /**
   * Store all account filter names in an array
   */
  getFilterNames() {
    const filterNames = [];
    this.accountAdFilters.forEach((filter) => {
      filterNames.push(filter.name);
    });

    this.accountFilterNames = filterNames;
  }

  /**
   * check to see if current filter form name value conflicts with any other filters
   */
  setIsConflictingName() {
    this.isConflictingName = this.accountFilterNames.includes(this.filterForm.value.name);
  }


  /**
   * Helper function to sort an array of objects w/ a key of .guid to an object with key/value pairs of GUID => Object
   *
   * @param array
   */
  sortArrayOfObjectsByGuid(array: any[]) {
    return array.reduce((accumulator, currentItem) => {
      if (!currentItem.guid) {
        currentItem.guid = uuidv4();
      }
      accumulator[currentItem.guid] = currentItem;

      return accumulator;
    }, {});
  }
}
