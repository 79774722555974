import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {CookieService} from '../../../../service/cookie.service';

@Component({
  selector: 'auto-event-page-template',
  templateUrl: './event-page-template.component.html',
  styleUrls: ['./event-page-template.component.scss']
})
export class EventPageTemplateComponent implements OnInit {
  accountId: number;
  userId: number;
  title = history.state.title;
  message = history.state.message;
  backToPageRoute = history.state.backToPageRoute;
  backToPageName = history.state.backToPageName;
  threeSeconds = 3000; // 3 seconds in milliseconds
  fiveSeconds = 5000; // 5 seconds in milliseconds
  constructor(    private titleService: Title,
                  private route: ActivatedRoute,
                  private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    if (history.state.autoToken) {
      this.cookieService.setCookie('auto-token', history.state.autoToken, 0.000035, '/');
    }
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      let expiration = this.threeSeconds;
      if (this.message.includes('Check your email')) {
        expiration = this.fiveSeconds;
      }
      setTimeout(() => {
        window.location.replace('/web/');
      }, expiration);
    });
  }
}
