import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {UntypedFormBuilder, UntypedFormControl, FormGroup, Validators} from '@angular/forms';
import {matchValidator} from '../../../../validators/match-validator';
import {UserService} from '../../../../service/user.service';
import {CookieService} from '../../../../service/cookie.service';

@Component({
  selector: 'auto-manage-password-settings',
  templateUrl: './manage-password.component.html',
  styleUrls: ['./manage-password.component.scss']
})
export class ManagePasswordComponent implements OnInit {
  submitted = false;
  strongPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$&+,:;=?@#|\'<>.^"*_`()[\\]{}/\\\\~%!-])[A-Za-z\\d$&+,:;=?@#|\'<>.^"*_`()[\\]{}/\\\\~%!-]{8,}$';
  mediumPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$';
  currentPassword: UntypedFormControl;
  newPassword: UntypedFormControl;
  confirmPassword: UntypedFormControl;
  cardStyle = {
    'border-style': 'solid',
    'border-color': 'gray',
    'border-width': 'thin',
    width : '550px',
    'background-color': '#FFFFFF',
    'margin-left' : 'auto',
    'margin-right' : 'auto',
    padding : '2.5rem 2.5rem 0rem 2.5rem'};
  passwordForm = this.formBuilder.group({
    currentPassword: ['', [
      Validators.required,
      Validators.minLength(1),
    ]],
    newPassword: ['', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(this.strongPassword),
      matchValidator('confirmPassword', true)
    ]],
    confirmPassword: ['', [
      Validators.required,
      matchValidator('newPassword')
    ]]
  });
  protected readonly Object = Object;
  passwordCriteria = {
    'length': { name: 'At least 8 characters', met: false },
    'uppercase': { name: 'At least one uppercase letter', met: false },
    'lowercase': { name: 'At least one lowercase letter', met: false },
    'number': { name: 'At least one number', met: false },
    'special': { name: 'At least one special character', met: false }
  };

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private cookieService: CookieService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Manage Password');
  }

  /**
   * Handle set password
   */
  handleSetPassword(): void {
    this.submitted = true;
    this.userService.changePassword(
      {currentPassword: this.passwordForm.controls.currentPassword.value,
        newPassword: this.passwordForm.controls.newPassword.value,
        confirmPassword: this.passwordForm.controls.confirmPassword.value})
      .subscribe(
        () => {
          this.clearSession();
        },
        (error) => {
          this.submitted = false;
          let errorArray = error.split('. ').filter(Boolean);
          errorArray.forEach((errorMessage) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: errorMessage + '.',
              life: 15000
            });
          });
        });
  }

  /**
   * Clears session data
   */
  clearSession() {
    const sessionCookie = this.cookieService.getCookie('PHPSESSID');
    this.userService.logout({sessionKey: sessionCookie})
      .subscribe(
        () => {
          this.cookieService.deleteCookie('auto-token', true);
          this.cookieService.deleteCookie('dt');
          this.cookieService.deleteCookie('dtl');
          this.cookieService.deleteCookie('PHPSESSID');
          window.location.href = '/web/';
        },
        (error) => {
          this.submitted = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
            life: 5000
          });
        });
  }

  /**
   * Method to check password criteria
   */
  checkPasswordCriteria() {
    this.passwordCriteria['length'].met = this.passwordForm.controls.newPassword.value.length >= 8;
    this.passwordCriteria['uppercase'].met = /[A-Z]/.test(this.passwordForm.controls.newPassword.value);
    this.passwordCriteria['lowercase'].met = /[a-z]/.test(this.passwordForm.controls.newPassword.value);
    this.passwordCriteria['number'].met = /\d/.test(this.passwordForm.controls.newPassword.value);
    this.passwordCriteria['special'].met = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.passwordForm.controls.newPassword.value);
  }
}
