<div class="p-card" [style]="{borderTopColor: getStatusColor()}">
  <div class="live-website" pTooltip="Live website">
    <a href="{{account.websiteUrl}}" target="_blank">
      <i class="pi pi-external-link"></i>
    </a>
  </div>


  <div class="header">
    <a href="{{getDestinationUrl()}}">
      <h3 class="p-card-title">{{account.name}}</h3>
      <li *ngIf="permissions.admin">
        <small class="p-card-subtitle">{{account.accountDescription}}</small>
      </li>
    </a>
  </div>

  <div class="p-card-body icon-container" >
    <ul>
        <li *ngIf="account.hasNewAds">
          <a href="/new-specials/{{account.accountId}}">
            <i class="pi pi-car"></i>
            New
          </a>
        </li>
      <li *ngIf="account.hasUsedAds">
        <a href="/used-specials/{{account.accountId}}">
          <i class="pi pi-car"></i>
          Used
        </a>
      </li>
      <li *ngIf="account.hasServiceSpecials">
        <a href="/service-specials/{{account.accountId}}">
          <i class="pi pi-wrench"></i>
            Service
        </a>
      </li>
      <li *ngIf="account.hasPages && permissions.pages">
        <a [routerLink]="['../pages', account.accountId]">
          <i class="pi pi-file"></i>
          Pages
        </a>
      </li>
      <li *ngIf="account.adwordsActive && permissions.admin">
        <a [routerLink]="['../google-ads-settings', account.accountId]">
          <i class="pi pi-google"></i>
          Ads
        </a>
      </li>
      <li>
        <a href="/settings/globals/view-specials-globals-general/{{account.accountId}}">
          <i class="pi pi-cog"></i>
          Settings
        </a>
      </li>
      <li *ngIf="account.accountInfo">
        <a href="/settings/account/view-account-info/{{account.accountId}}">
          <i class="pi pi-file-edit"></i>
          Account Info
        </a>
      </li>
      <li *ngIf="permissions.admin">
        <a href="/settings/account/view-account-inventory/{{account.accountId}}">
          <i class="pi pi-table"></i>
          Inventory
        </a>
      </li>
    </ul>
  </div>

  <small class="account-id">ID: {{account.accountId}}</small>
</div>
