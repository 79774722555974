import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../../service/user.service";
import {AccountService} from "../../../../service/account.service";
import {DashboardAccountInterface} from "../../../../model/dashboard-account.interface";
import {Title} from "@angular/platform-browser";
import {StringFormatterService} from "../../../../service/utilities/string-formatter.service";
import {PermissionsInterface} from "../../../../model/permissions.interface";

@Component({
  selector: "auto-account-dashboard",
  templateUrl: "./account-dashboard.component.html",
  styleUrls: ["./account-dashboard.component.scss"]
})
export class AccountDashboardComponent implements OnInit {

  /**
   * The users name to display in the welcome message
   */
  name: string = "";

  /**
   * The user's role/type
   */
  permissions: PermissionsInterface[];

  /**
   * Controls display of loading spinner
   */
  showLoadingSpinner: boolean = true;

  /**
   * All MPOP accounts associated with the user
   */
  allAccounts: DashboardAccountInterface[] = [];

  /**
   * Filtered accounts based on the search input
   */
  filteredAccounts: DashboardAccountInterface[] = [];

  /**
   * Search input value
   */
  search: string = "";

  /**
   * Shared pagination variables to keep top and bottom pagination components in sync
   */
  first: number = 0;
  page: number = 1;
  rows: number = 48;

  /**
   * List of accounts that should be displayed based on page number and filtering
   */
  currentPageData: DashboardAccountInterface[] = [];

  constructor(
    private userService: UserService,
    private accountService: AccountService,
    private titleService: Title,
    private stringFormatter: StringFormatterService
  ) {}

  /**
   * Populates the user's name and list of accounts upon initialization of the component
   */
  ngOnInit(): void {
    this.titleService.setTitle("Dashboard");

    this.userService.getUserPromise().then(user => {
      this.name = this.stringFormatter.fullNameToFirstName(user);
      this.permissions = user.permissions;
    });

    this.accountService.getDashboardAccounts().subscribe(accounts => {
      this.allAccounts = accounts;
      this.filteredAccounts = accounts;
      this.resetPagination();
      this.showLoadingSpinner = false;
    });
  }

  /**
   * Updates list of displayed accounts and sets new values for the page and first values to
   * synchronize the two paginators.
   * @param status
   */
  onPageChange(status?: any): void {
    this.page = status.page;
    this.first = status.first;
    this.currentPageData = this.filteredAccounts.slice(status.first, status.first + status.rows);
  }

  /**
   * Filters all accounts based on the value of the search input
   */
  onSearchInput(): void {
    const matchedAccounts: DashboardAccountInterface[] = [];

    for (const account of this.allAccounts)
    {
      const searchValue: string = this.search.toLowerCase();
      const accountName: string = account.name.toLowerCase();
      const accountId: string = account.accountId.toString();
      const accountDescription: string = account.accountDescription.toLowerCase();
      const canSearchByDescription: boolean = this.permissions && this.permissions[account.accountId].admin;

      if (accountName.includes(searchValue) ||
        accountId.includes(searchValue) ||
        (canSearchByDescription && accountDescription.includes(searchValue))) {
        matchedAccounts.push(account);
      }
    }

    this.filteredAccounts = matchedAccounts;
    this.resetPagination();
  }

  /**
   * Resets the pagination to the first page
   * @private
   */
  private resetPagination(): void {
    this.currentPageData = this.filteredAccounts.slice(0, this.rows);
    this.page = 0;
    this.first = 0;
  }
}
