export abstract class AbstractFormConfig {
  constructor() {}

  // object describing the form layout
  form: {};

  // getter to return the form config object
  getFormConfig(): {} {
    return this.form;
  }
}
