import {Injectable} from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {PageModel} from '../model/page.model';
import {environment} from '../../environments/environment';
import {ProjectedPageRequestModel} from '../model/projected-page-request.model';
import {PromotePageRequestModel} from '../model/promote-page-request.model';
import {ConnectNativePageRequestModel} from '../model/connect-native-page-request.model';
import {CopyPageToAccountRequestModel} from '../model/copy-page-to-account-request.model';
import {ImportPageRequestModel} from '../model/import-page-request.model';
import {PageConfigurationModel} from '../model/page-configuration.model';
@Injectable({
  providedIn: 'root'
})
export class PageService extends AbstractHttpService{
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  patchOptions = {
    headers: this.contentTypeJsonHeadersForPatch,
    withCredentials: true
  };

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  /**
   * Method to get ncs special pages for a given account
   *
   * @param accountId
   * @param childAccountId
   * @param excludeInactivePages
   */
  getPages(accountId: number, childAccountId: number, excludeInactivePages: boolean = true): Observable<PageModel[]> {
    let path = '/api/pages?ncsAccountId=' + accountId + '&excludeInactivePages=' + excludeInactivePages;
    if (childAccountId) {
      path += '&childAccountId=' + childAccountId;
    }

    return this.httpClient.get<PageModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}` + path,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  projectPage(projectedPageRequest: ProjectedPageRequestModel) {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/projected-pages`,
      projectedPageRequest,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  connectNativePage(connectNativePageRequest: ConnectNativePageRequestModel) {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/connect-native-page`,
      connectNativePageRequest,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  addPage(accountId) {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/pages/add-page`,
      {
        accountId
      },
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  importPage(importPageRequest: ImportPageRequestModel) {
      return this.httpClient.post(
        `//${environment.autoDomain}:${environment.autoPort}/api/pages/import`,
        importPageRequest,
        this.defaultOptions
      ).pipe(
        catchError(this.handleError)
      );
  }

  updatePage(pageData): Observable<any> {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/pages/${pageData.id}`,
      pageData,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  deleteNativePage(pageId: number) {
    return this.httpClient.delete(
      `//${environment.autoDomain}:${environment.autoPort}/api/pages/${pageId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  promotePage(promotePageRequest: PromotePageRequestModel) {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/projected-pages/promote-page`,
      promotePageRequest,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  copyPageToAccount(copyPageToAccountRequest: CopyPageToAccountRequestModel) {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/projected-pages/copy-page-to-account`,
      copyPageToAccountRequest,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  deleteProjectedPage(pageId: number) {
    const path = '/api/pages/delete-projected-page/' + pageId;
    return this.httpClient.delete(
      `//${environment.autoDomain}:${environment.autoPort}` + path,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  getPageConfiguration(accountId: number): Observable<PageConfigurationModel> {
    return this.httpClient.get<PageConfigurationModel>(
      `//${environment.autoDomain}:${environment.autoPort}/api/page-configuration/` + accountId,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError),
    );
  }

  updatePageConfiguration(pageConfigurationData): Observable<any> {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/page-configuration`,
      pageConfigurationData,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
