import {Injectable} from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {GoogleAdsSettingsModel} from '../model/google-ads-settings.model';
import {AccountCampaignStrategyModel} from '../model/account-campaign-strategy.model';
import {AccountOemProgramsModel} from '../model/account-oem-programs.model';
import {CampaignAutomationSettingsModel} from '../model/campaign-automation-settings.model';

@Injectable({
  providedIn: 'root'
})
export class GoogleAdsService extends AbstractHttpService{
  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  patchOptions = {
    headers: this.contentTypeJsonHeadersForPatch,
    withCredentials: true
  };

  campaignAutomationConfigurationPathName = 'campaign-automation-configuration';
  textAssetValidationPathName = 'text-asset-validation';

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  getGoogleAdsSettings(accountId: number): Observable<GoogleAdsSettingsModel> {
    return this.httpClient.get<GoogleAdsSettingsModel>(
      `//${environment.autoDomain}:${environment.autoPort}/api/google-ads-settings?accountId=${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateGoogleAdsSettings(settingsData): Observable<any> {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/google-ads-settings`,
      settingsData,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  getAccountCampaignStrategies(accountId: string): Observable<AccountCampaignStrategyModel[]> {
    return this.httpClient.get<AccountCampaignStrategyModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-campaign-strategy/${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  getAccountOemPrograms(accountId: number): Observable<AccountOemProgramsModel> {
    return this.httpClient.get<AccountOemProgramsModel>(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-settings/oem-programs?accountId=${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  getGoogleAdsSubMccAccounts(): Observable<any> {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/google-ads/sub-mcc-account`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  initializeAccountCampaignStrategy(accountCampaignStrategyData): Observable<any> {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-campaign-strategy/initialize`,
      accountCampaignStrategyData,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateAccountCampaignStrategy(accountCampaignStrategyData): Observable<any> {
    return this.httpClient.patch(
      `//${environment.autoDomain}:${environment.autoPort}/api/account-campaign-strategy/${accountCampaignStrategyData.id}`,
      accountCampaignStrategyData,
      this.patchOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  createCampaignRequest(createCampaignRequestData): Observable<any> {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/create-campaigns`,
      createCampaignRequestData,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  createGoogleAdsAccount(accountId, subMccId): any {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/google-ads-account`,
      {
        accountId,
        subMccId
      },
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Method to update pmax campaigns
   *
   * @param accountId
   * @param campaignTypeId
   * @param sendSlackNotification
   */
  updatePmaxCampaignRequest(accountId: number, campaignTypeId: number, sendSlackNotification: boolean): any {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/update-pmax-campaigns`,
      {
        accountId: accountId,
        campaignTypeId: campaignTypeId,
        sendSlackNotification: sendSlackNotification,
        automatedUpdate: false
      },
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  createCampaignSet(accountId, campaignTypeId): any {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/campaign-set`,
      { accountId, campaignTypeId },
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  exportCampaigns(accountId: number): Observable<Blob>
  {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/google-ads/${accountId}/campaigns/export?strategy=New`,
      {responseType: 'blob'}
    ).pipe(
      catchError(this.handleError)
    );
  }

  exportAdGroups(accountId: number)
  {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/google-ads/${accountId}/ad-groups/export?strategy=New`,
      {responseType: 'blob'}
    ).pipe(
      catchError(this.handleError)
    );
  }

  exportAds(accountId: number): Observable<Blob>
  {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/google-ads/${accountId}/ads/export?strategy=New`,
      {responseType: 'blob'}
    ).pipe(
      catchError(this.handleError)
    );
  }

  exportKeywords(accountId: number)
  {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/google-ads/${accountId}/keywords/export?strategy=New`,
      {responseType: 'blob'}
    ).pipe(
      catchError(this.handleError)
    );
  }

  createAutomationAccountSettings(settingsData: CampaignAutomationSettingsModel): Observable<any>
  {
    return this.httpClient.post(
      `//${environment.autoDomain}:${environment.autoPort}/api/campaign-automation-settings/create`,
      settingsData,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
  getAutomationAccountSettings(accountId: number): Observable<any>
  {
    return this.httpClient.get<CampaignAutomationSettingsModel>(
      `//${environment.autoDomain}:${environment.autoPort}/api/campaign-automation-settings/${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  getAutomationAccountSettingsFromMpop(accountId: number): Observable<any>
  {
    return this.httpClient.get<CampaignAutomationSettingsModel>(
      `//${environment.autoDomain}:${environment.autoPort}/api/campaign-automation-settings/mpop/${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateAutomationAccountSettings(settingsData: CampaignAutomationSettingsModel): Observable<any>
  {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/campaign-automation-settings/update`,
      settingsData,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  queuePmaxUpdate(accountId: number, campaignTypeId: number): Observable<any>
  {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/queue-pmax-update`,
      {
        accountId: accountId,
        campaignTypeId: campaignTypeId,
        sendSlackNotification: false,
        automatedUpdate: true
      },
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Method to get automation configuration settings
   */
  getAutomationConfigurationSettings(accountId: number): Observable<any>
  {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/${this.campaignAutomationConfigurationPathName}/${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Method to save automation configuration settings
   */
  saveAutomationConfigurationSettings(configurations: any): Observable<any>
  {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/${this.campaignAutomationConfigurationPathName}`,
        {campaignAutomationConfigurations : configurations},
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Method to get text asset validation
   * @param accountId
   */
  getTextAssetValidation(accountId: number): Observable<any>
  {
    return this.httpClient.get(
      `//${environment.autoDomain}:${environment.autoPort}/api/${this.textAssetValidationPathName}/${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
